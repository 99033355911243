import types from "redux/actions/types";
import { IBankaccountState, BankaccountActions } from "../types/bankaccount";
import { IBankaccount } from "redux/types/bankaccount";

const initialState: IBankaccountState = {
  loading: true,
  bankaccount: {} as IBankaccount,
  bankaccounts: [] as IBankaccount[],
  totalCount:0
};

const bankaccountReducer = (state = initialState, action: BankaccountActions) => {
  switch (action.type) {
    case types.BANKACCOUNT_REGISTER_SUCCESS:
    case types.BANKACCOUNT_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        bankaccounts: [...state.bankaccounts, action.payload] as IBankaccount[],
      };
    case types.GET_BANKACCOUNTS:
      return {
        ...state,
        loading: false,
        bankaccounts: action?.payload?.data as IBankaccount[],
        totalCount: action?.payload?.totalCount as Number,

      };

    case types.DELETE_BANKACCOUNT:
      return {
        ...state,
        bankaccounts: state.bankaccounts.filter(
          (bankaccount) => bankaccount._id !== action.payload
        ),
      };

    case types.UPDATE_BANKACCOUNT:
      return {
        ...state,
        bankaccounts: state.bankaccounts.map((bankaccount) =>
        bankaccount._id === action.payload.id
            ? { ...action.payload.bankaccount }
            : bankaccount
        ),
      };
    case types.BANKACCOUNT_REGISTER_FAIL:
    case types.BANKACCOUNT_AUTH_ERROR:

    default:
      return state;
  }
};
export default bankaccountReducer;
