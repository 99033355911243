import { Dispatch } from "redux";
import axios from "utils/axios";
import types from "./types";
import { setAlert } from "./alert";
import { ProfileActions } from "redux/types/profile";
import { AlertActions } from "redux/types/alert";

// REGISTER Module
let orgId = localStorage.getItem("orgId")
export const registerProfile =
    (body: any, history: any) =>
        async (dispatch: Dispatch<ProfileActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.post(`section/create`, body, config);
                dispatch({
                    type: types.PROFILE_REGISTER_SUCCESS,
                    payload: data,
                });
                dispatch<any>(
                    setAlert({
                        msg: "Profile Register successful!",
                        status: 200,
                        alertType: "success",
                    })
                );
                dispatch<any>(getProfiles({ orgId: orgId }));
                // history.push("/boardnames");
            } catch (error: any) {
                
                dispatch({ type: types.PROFILE_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: error?.response?.data,
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };

// GET Profiles
export const getProfiles =
    (userId: any) => async (dispatch: Dispatch<ProfileActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.get(`user/${userId}`, config);
            dispatch({ type: types.GET_PROFILES, payload: data });
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when fetching the Profiles!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };
// UPDATE Modules DATA

export const updateProfiles =
    (body: any, id: any, history: any, setIsLoading?: any) =>
        async (dispatch: Dispatch<ProfileActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.put(
                    `user/update/${id}`,
                    body,
                    config
                );
                dispatch({
                    type: types.UPDATE_PROFILE,
                    payload: data,
                });

                dispatch<any>(
                    setAlert({
                        msg: "Profile Data Updated!",
                        status: 200,
                        alertType: "success",
                    })
                );
                dispatch<any>(getProfiles(id));
                // history.push("/boardnames");
            } catch (error: any) {
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when updating the Profile!",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            } finally {
                if (setIsLoading) {
                    setIsLoading(false);
                }

            }
        };

// DELETE Module
export const deleteProfile =
    (id: number) => async (dispatch: Dispatch<ProfileActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            await axios.delete(`section/delete/${id}`, config);
            dispatch({ type: types.DELETE_PROFILE, payload: id });
            dispatch<any>(
                setAlert({
                    msg: "section has been deleted!",
                    status: 200,
                    alertType: "success",
                })
            );
            dispatch<any>(getProfiles({ orgId: orgId }));
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when deleting the Profile!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };
