import ActionTypes from "../actions/types";
import { AllSubCategoryActions } from "../types/inventorySubCategory"; 

const initialState = {
  loading: false,
  error: '',
  totalCount: 0,
  subCategory: [], 
  subcategorysByCategory : []
};

const SubCategory = (state = initialState, action: AllSubCategoryActions) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_SUB_CATEGORY_REQUEST:
    case ActionTypes.CREATE_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ActionTypes.GET_SUB_CATEGORY_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        subcategorysByCategory: action.payload.data,
        error: '',
      };
    case ActionTypes.GET_ALL_SUB_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          subCategory: action.payload.data, 
          totalCount: action.payload.totalCount,
          error: '',
        };
    case ActionTypes.GET_ALL_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        subCategory: [], 
        totalCount: 0,
        error: action.payload,
      };
    case ActionTypes.CREATE_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case ActionTypes.CREATE_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.GET_SUB_CATEGORY_BY_ID:
      return {
        ...state,
        loading: false,
        subCategory: [action.payload], 
      };
    case ActionTypes.UPDATE_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategory: state.subCategory.map((SubCategory) =>
          SubCategory._id === action.payload.id
            ? { ...action.payload.subcategory }
            : SubCategory
        ),
      };
    case ActionTypes.DELETE_SUB_CATEGORY:
      return {
        ...state,
        subCategory: state.subCategory.filter(
          (SubCategory) => SubCategory._id !== action
        ),
      };
    default:
      return state;
  }
};

export default SubCategory;
