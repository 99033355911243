import types from "redux/actions/types";
import { ILeaveCalculations, ILeaveCalculationsAction, ILeaveCalculationsState } from "redux/types/leaveCalculations";

const initialState: ILeaveCalculationsState = {
    loading: true,
    leaveTypeCalculation: {} as ILeaveCalculations,
    totalCount: 0
};
export const leaveCalculationReducer = (state = initialState, action: ILeaveCalculationsAction) => {
    switch (action.type) {
        case types.GET_LEAVE_TYPE_CALCULATIONS:
            return {
                ...state,
                loading: false,
                leaveTypeCalculation: action?.payload,
                // totalCount: action?.payload?.length,
            }

        default:
            return state
    }
}
export default leaveCalculationReducer;