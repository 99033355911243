import React, {useState, useEffect} from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from "redux/reducers";

function SearchRoutes({searchText, setSearchBox, setSearchText} : {searchText : string, setSearchBox: any, setSearchText: any }) {

    interface searchRouteData {
        title: string;
        routeDesc : string; 
        route : string;
        display: boolean;
        image?: string;
    }
    const _user = useSelector((state: RootState)=> state.user)
    const [searchOptionBox, setSearchOptionBox] = useState<searchRouteData[]>([]);
    const [count,setCount] = useState(0);
    useEffect(() => {
        const BMRP = _user?.permissions?.[0]?.BMRP;
        if(BMRP){
        const newSearchData = [];
        let modules : any = [];
        let submodules : any = [];
        for (let rp of BMRP){
            if (rp.branchmodule.module.type === "module"){
                modules.push({_id : rp.branchmodule.module._id, 
                    name: rp.branchmodule.module.name, 
                    route_path : rp.branchmodule.module.route_path, 
                    routeDesc : `Dashboard / ${rp.branchmodule.module.route_path.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`,
                    subModule : []});
            }
            if (rp.branchmodule.module.type === "submodule"){
                submodules.push(rp.branchmodule.module);
            }
        }
        for (let submod of submodules){
            for (let module of modules){ 
            if(submod.parentId === module._id){
                module.subModule.push(submod._id);
            }
        }
        }

        for (let rp of BMRP){
            if (rp.branchmodule.module.type === "submoduleroute"){
                const parentModuleId = rp.branchmodule.module.parentId;
                for (let module of modules){
                    if (module.subModule.includes(parentModuleId)){
                        let route = "modules/" + module.route_path + "/" + rp.branchmodule.module.route_path;
                        let image = "/images/modules/submoduleslist/" + module.route_path + "/" + rp.branchmodule.module.route_path;
                        let routeDesc = `${module.routeDesc} / ${rp.branchmodule.module.route_path.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').split("/").join(" / ")}`
                        let title = rp.branchmodule.module.name;
                        if (rp.branchmodule.module.alias_name){
                            title = rp.branchmodule.module.alias_name;
                        } else if (rp.branchmodule.alias_name) {
                            title = rp.branchmodule.alias_name;
                        }
                        let searchOption = { title, routeDesc, route, image, _id : module._id, display: true }
                        newSearchData.push(searchOption);
                    }
            }
        }
        setCount(newSearchData.length);
        setSearchOptionBox(newSearchData);
        }
    }
    }, [_user])

    useEffect(() => {
        const searchTerm = searchText.toLowerCase();
        let falseCount = 0
        for (let el of searchOptionBox){
            if (el.title.toLowerCase().indexOf(searchTerm) > -1){
                el.display = true;
            } else {
                el.display = false;
                falseCount += 1
            }
        }
        setCount(falseCount);
    }, [searchText])

    const clickHandler = (selectedModule) => {
        localStorage.setItem("routeId", selectedModule._id);
        setSearchText("");
        setSearchBox(false);
    }

    return (<>
    {searchOptionBox && count !== searchOptionBox.length ? searchOptionBox.map((item: searchRouteData) => <Link onClick={() => clickHandler(item)} to={item.route} 
    style={{display : item.display === true ? "block" : "none"}} className="search-route">
        <p className="mb-1 fs-text d-flex align-items-center gap-2"><img src={`${item.image}.svg`}/> {item.title}</p>
        <p className="fs-small mb-0 theme-secondary-color">{item.routeDesc}</p>
    </Link>) : <div className='text-center py-5 px-3'><img src="/images/search-zero-state.svg" /></div>}
    </>)
}


export default React.memo(SearchRoutes);