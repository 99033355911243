import ActionTypes from "../actions/types";
import { LedgerBookActions } from "redux/types/expensesLedgerBook";


const initialState = {
    loading : true,
    incomeError : '',
    expenditureError : '',
    totalCount : 0,
    income : [],
    expenditure: [],
    incomeOverAllAmount: 0,
    expenditureOverAllAmount: 0,
    methodWiseIncome : [],
    methodWiseTransactions : [],
    ledgerTransactions: [],
    transactionsOverAllAmount: 0,
    depositsOverAllAmount: 0,
}

const ExpensesLedgerBookReducer = (state = initialState, action : LedgerBookActions) => {
    switch(action.type){

        case ActionTypes.GET_INCOMES_SUCCESS:
            return {
                ...state,
                loading : false,
                income : action.payload.data,
                totalCount : action.payload.totalCount,
                incomeOverAllAmount : action.payload.overallTotalAmount,
                incomeerror : '',
            };
        case ActionTypes.GET_INCOMES_FAILURE:
            return {
                ...state,
                loading : false,
                income : [],
                totalCount : 0,
                incomeOverAllAmount:0,
                incomeerror : action.payload,
            };
        case ActionTypes.GET_EXPENDITURES_SUCCESS:
            return {
                ...state,
                loading : false,
                expenditure : action.payload.data,
                totalCount : action.payload.totalCount,
                expenditureOverAllAmount : action.payload.overallTotalAmount,
                expenditureError : '',
            };
        case ActionTypes.GET_EXPENDITURES_FAILURE:
            return {
                ...state,
                loading : false,
                expenditure : [],
                totalCount : 0,
                expenditureOverAllAmount: 0,
                expenditureError : action.payload,
            };
        case ActionTypes.GET_METHOD_WISE_INCOME_SUCCESS:
            return {
                ...state,
                loading : false,
                methodWiseIncome : action.payload.data,
                totalCount : action.payload.totalCount,
                depositsOverAllAmount:action.payload.overallTotalAmount,
                error : '',
            };
        case ActionTypes.GET_METHOD_WISE_INCOME_FAILURE:
            return {
                ...state,
                loading : false,
                methodWiseIncome : [],
                totalCount : 0,
                depositsOverAllAmount:0,
                error : action.payload,
            };
        case ActionTypes.GET_METHOD_WISE_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading : false,
                methodWiseTransactions : action.payload.data,
                totalCount : action.payload.totalCount,
                transactionsOverAllAmount: action.payload.overallTotalAmount,
                error : '',
            };
        case ActionTypes.GET_METHOD_WISE_TRANSACTIONS_FAILURE:
            return {
                ...state,
                loading : false,
                methodWiseTransactions : [],
                totalCount : 0,
                transactionsOverAllAmount: 0,
                error : action.payload,
            };
        case ActionTypes.GET_LEDGER_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading : false,
                ledgerTransactions : action.payload.data,
                // transactionsOverAllAmount: action.payload.overallTotalAmount,
                error : '',
            };
        case ActionTypes.GET_LEDGER_TRANSACTIONS_FAILURE:
            return {
                ...state,
                loading : false,
                ledgerTransactions : [],
                // transactionsOverAllAmount: 0,
                error : action.payload,
            };
        default : return state;
    }
}

export default ExpensesLedgerBookReducer;