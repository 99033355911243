import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";

const Macenabled: React.FC = (): JSX.Element => {
  return (
    <>
      
      <div className="note mb-4">
                <h5 className="frbold">Note:</h5>
                  <p>If you want to restrict any user to work from a dedicated Desktop / Laptop, you can define that desktop / laptop MAC against that user, so that the user access will be opened only from that desktop/laptop. Best suited for Accountants for fee collections.</p>
                </div>

                <Row>

                <Col  className="mb-3">
                  <div className="d-flex align-items-center">
                  <h6 className="web-text6 mb-0">Do you want MAC settings for your organisation :</h6>  
                   
                  <div className="ms-3 d-flex">
                      <Form.Check
                        inline
                        label="Yes"
                        name="group1"  
                        type="radio"  className="maclabel"                
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="group1"     
                        type="radio"   className="maclabel"              
                      />
                    
                    </div>  
                  </div>
                      


                              
                </Col>

            

                </Row>



    </>
  )
}
export default React.memo(Macenabled);
