import { Dispatch } from "redux";
import axios from "utils/axiosifs";
import axios1 from "utils/axios";

import types from "./types";
import { setAlert } from "./alert";
import { LogoActions } from "redux/types/logosettings";
import { AlertActions } from "redux/types/alert";
import { NavigateFunction } from "react-router";

// REGISTER Module
export const registerLogo =
    (body: any, type?: any) =>
        async (dispatch: Dispatch<LogoActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "multipart/form-data",
                },
            };

            try {
                const { data } = await axios.post(`media/upload`, body, config);
                dispatch({
                    type: types.LOGO_REGISTER_SUCCESS,
                    payload: data,
                });
                if (type === "gallery" || type === "receipt-template"){
                    return;
                }
                dispatch<any>(
                    setAlert({
                        msg: "Uploading file successful!",
                        status: 200,
                        alertType: "success",
                    })
                );
            } catch (error: any) {
                dispatch({ type: types.LOGO_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when uploading the file.",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };
export const createOrgLogo =
    (body: any, history: any) =>
        async (dispatch: Dispatch<LogoActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios1.post(`organization/uploadLogo`, body, config);
                dispatch({
                    type: types.LOGO_REGISTER_SUCCESS,
                    payload: data,
                });
            } catch (error: any) {
                dispatch({ type: types.LOGO_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when uploading Organization logo.",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };
export const createBranchLogo =
    (body: any, history: any) =>
        async (dispatch: Dispatch<LogoActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios1.post(`branch/uploadLogo`, body, config);
                dispatch({
                    type: types.LOGO_REGISTER_SUCCESS,
                    payload: data,
                });
            } catch (error: any) {
                dispatch({ type: types.LOGO_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when uploading branch logo.",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };
// GET Modules
export const getLogos =
    () => async (dispatch: Dispatch<LogoActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.get(`masterclass/masterclasses`, config);
            dispatch({ type: types.GET_LOGOS, payload: data });
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when fetching the Logos!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };

// UPDATE Modules DATA
export const updateLogos =
    (body: any, id: any, history: any) =>
        async (dispatch: Dispatch<LogoActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.patch(
                    `masterclass/updateLogo/${id}`,
                    body,
                    config
                );
                dispatch({
                    type: types.UPDATE_LOGO,
                    payload: data,
                });

                dispatch<any>(
                    setAlert({
                        msg: "Logo Data Updated!",
                        status: 200,
                        alertType: "success",
                    })
                );
                dispatch<any>(getLogos());
                history.push("/masterclass");
            } catch (error: any) {
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when updating the Logo!",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            } finally {
                // setSubmitting(false);
            }
        };

// DELETE Module
export const deleteLogo =
    (id: string) => async (dispatch: Dispatch<LogoActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            await axios.delete(`media/file?path=${id}`, config);
            dispatch({ type: types.DELETE_LOGO, payload: id });
            dispatch<any>(
                setAlert({
                    msg: "Selected File has been deleted!",
                    status: 200,
                    alertType: "success",
                })
            );
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when deleting the Logo!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };

//Remove mediaFile
export const removeLogoFile =
(id: number) => async (dispatch: Dispatch<LogoActions | AlertActions>) => {
    const config: any = {
        header: {
            "Content-Type": "application/json",
        },
    };

    try {
        await axios.delete(`media/file?path=${id}`, config);

        // dispatch<any>(getLogos());
    } catch (error: any) {
        dispatch<any>(
            setAlert({
                msg: "Something went wrong when deleting the Logo!",
                status: error?.response?.status,
                alertType: "danger",
            })
        );
    }
};