import types from "redux/actions/types";
import { ISubjectState, SubjectActions } from "../types/subject";
import { ISubject } from "redux/types/subject";

const initialState: ISubjectState = {
    loading: true,
    totalCount : 0,
    subject: {} as ISubject,
    subjects: [] as ISubject[],
};

const subjectReducer = (
    state = initialState,
    action: SubjectActions
): ISubjectState => {
    switch (action.type) {
        case types.SUBJECT_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // subjects: [...state.subjects, action.payload] as ISubject[],
            };
        case types.GET_SUBJECTS_REQUEST:
            return {
                ...state,
                loading: true,
                totalCount : 0,
                // subjects: [...state.subjects, action.payload] as ISubject[],
            };
        case types.GET_SUBJECTS:
            return {
                ...state,
                loading: false,
                totalCount : action.payload.totalCount,
                subjects: action.payload.subjects as ISubject[],
            };

        case types.DELETE_SUBJECT:
            return {
                ...state,
                subjects: state.subjects.filter(
                    (subject:any) => subject._id !== action.payload
                ),
            };

        case types.UPDATE_SUBJECT:
            return {
                ...state,
                subjects: state.subjects.map((subject:any) =>
                subject._id === action.payload.id
                        ? { ...action.payload.subject }
                        : subject
                ),
            };
        case types.SUBJECT_REGISTER_FAIL:
        case types.SUBJECT_AUTH_ERROR:

        default:
            return state;
    }
};

export default subjectReducer;
