import types from "redux/actions/types";
import { ILeaveReport, ILeaveReportState } from "redux/types/LeaveReport";

const initialState: ILeaveReportState = {
    loading: true,
    leaveReport: {} as ILeaveReport,
    leavereports: [] as ILeaveReport[] ,
    totalCount: 0,

};
export const leavereportReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.GET_LEAVE_REPORT:
            
            return {
                ...state,
                loading: false,
                leavereports: action?.payload?.data,
                totalCount: action?.payload?.totalCount
            };
       
        default:
            return state
    }
}
export default leavereportReducer;