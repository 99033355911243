import types from "redux/actions/types";
import { IEnquiryfollowupState, EnquiryfollowupActions } from "../types/enquiryfollowup";
import { IEnquiryfollowup } from "redux/types/enquiryfollowup";

const initialState: IEnquiryfollowupState = {
  loading: true,
  enquiryfollowup: {} as IEnquiryfollowup,
  enquiryfollowups: [] as IEnquiryfollowup[],
  enquiryHistory: [] as any,
};

const enquiryfollowupReducer = (state = initialState, action: EnquiryfollowupActions) => {
  switch (action.type) {
    case types.ENQUIRYFOLLOWUP_REGISTER_SUCCESS:
    case types.ENQUIRYFOLLOWUP_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiryfollowups: [...state.enquiryfollowups, action.payload] as IEnquiryfollowup[],
      };
    case types.GET_ENQUIRYFOLLOWUPS:
      return {
        ...state,
        loading: false,
        enquiryfollowups: action.payload as IEnquiryfollowup[],
      };

    case types.DELETE_ENQUIRYFOLLOWUP:
      return {
        ...state,
        enquiryfollowups: state.enquiryfollowups.filter(
          (enquiryfollowup) => enquiryfollowup._id !== action.payload
        ),
      };

    case types.HISTORY_ENQUIRYFOLLOWUP:
      return {
        ...state,
        loading:false,
        enquiryHistory: action.payload,
      };

    case types.UPDATE_ENQUIRYFOLLOWUP:
      return {
        ...state,
        enquiryfollowups: state.enquiryfollowups.map((enquiryfollowup) =>
          enquiryfollowup._id === action.payload.id
            ? { ...action.payload.enquiryfollowup }
            : enquiryfollowup
        ),
      };
    case types.DELETE_HISTORY_ENQUIRY_FOLLOWUP:
      return {...state,enquiryHistory:[],loading:true}
    case types.ENQUIRYFOLLOWUP_REGISTER_FAIL:
      return state;
    case types.ENQUIRYFOLLOWUP_AUTH_ERROR:

    default:
      return state;
  }
};
export default enquiryfollowupReducer;


// import types from "redux/actions/types";
// import { IEnquiryfollowupState, EnquiryfollowupActions } from "../types/enquiryfollowup";
// import { IEnquiryfollowup } from "redux/types/enquiryfollowup";

// const initialState: IEnquiryfollowupState = {
//   loading: true,
//   enquiryfollowup: {} as IEnquiryfollowup,
//   enquiryfollowups: [] as IEnquiryfollowup[],
// };

// const enquiryfollowupReducer = (state = initialState, action: EnquiryfollowupActions) => {
//   switch (action.type) {
//     case types.ENQUIRYFOLLOWUP_REGISTER_SUCCESS:
//     case types.ENQUIRYFOLLOWUP_REGISTER_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         enquiryfollowups: [...state.enquiryfollowups, action.payload] as IEnquiryfollowup[],
//       };
//     case types.GET_ENQUIRYFOLLOWUPS:
//       return {
//         ...state,
//         loading: false,
//         enquiryfollowups: action.payload as IEnquiryfollowup[],
//       };

//     case types.DELETE_ENQUIRYFOLLOWUP:
//       return {
//         ...state,
//         enquiryfollowups: state.enquiryfollowups.filter(
//           (enquiryfollowup) => enquiryfollowup._id !== action.payload
//         ),
//       };

//     case types.UPDATE_ENQUIRYFOLLOWUP:
//       return {
//         ...state,
//         enquiryfollowups: state.enquiryfollowups.map((enquiryfollowup) =>
//         enquiryfollowup._id === action.payload.id
//             ? { ...action.payload.enquiryfollowup }
//             : enquiryfollowup
//         ),
//       };
//     case types.ENQUIRYFOLLOWUP_REGISTER_FAIL:
//     case types.ENQUIRYFOLLOWUP_AUTH_ERROR:

//     default:
//       return state;
//   }
// };
// export default enquiryfollowupReducer;
