import types from "redux/actions/types";
import { FinanceBulkUploadActions, IFinanceBulkUploadState } from "redux/types/financebulkupload";

const initialState: IFinanceBulkUploadState = {
    loading: true,
    financebulkupload: [],
    financebulkuploadvalidated: { status: "", count: [], message: [] },
};

const financeBulkUploadReducer = (
    state = initialState,
    action: FinanceBulkUploadActions
): IFinanceBulkUploadState => {
    switch (action.type) {
        case types.FINANCE_BULK_UPLOAD:
            return {
                ...state,
                loading: false,
                financebulkupload: action.payload,
            };
        case types.FINANCE_BULK_UPLOAD_FAILURE:
        default:
            return state;
    }
};

export default financeBulkUploadReducer;