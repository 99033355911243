import ActionTypes from "redux/actions/types";

const initialData = {
    instalments: []
}

export const FeeInstalments = (state = initialData, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_FEE_INSTALLMENTS:
            return { ...state, instalments: [...action?.payload] };
        default:
            return state;
    }
}