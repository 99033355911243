import types from "redux/actions/types";
import { ShuffleStudentsActions, PromoteStudentsActions, DemoteStudentsActions } from "redux/types/shuffleAndPromotion";

const initialState : any = {
  loading: true,
};

const shuffleStudentsReducer = (state = initialState, action: ShuffleStudentsActions | PromoteStudentsActions | DemoteStudentsActions): any => {
  switch (action.type) {
    case types.SHUFFLE_STUDENTS:
      return {
        ...state,
        loading: false,
      };
    case types.PROMOTE_STUDENTS:
      return {
        ...state,
        loading: false,
      };
    case types.DEMOTE_STUDENTS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
export default shuffleStudentsReducer;