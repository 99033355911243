import types from "redux/actions/types";
import { ISectionState, SectionActions } from "../types/section";
import { ISection } from "redux/types/section";

const initialState: ISectionState = {
    loading: true,
    section: {} as ISection,
    sections: {} as ISection,
};

const sectionReducer = (
    state = initialState,
    action: SectionActions
): ISectionState => {
    switch (action.type) {
        case types.SECTION_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // sections: [...state.sections, action.payload] as ISection[],
            };
        case types.GET_SECTIONS:
            return {
                ...state,
                loading: false,
                sections: action.payload as ISection,
            };

        case types.DELETE_SECTION:
            return {
                ...state,
                sections: state?.sections?.data.filter(
                    (section: any) => section._id !== action.payload
                ),
            };

        case types.UPDATE_SECTION:
            return {
                ...state,
                sections: state?.sections?.data.map((section: any) =>
                    section._id === action.payload.id
                        ? { ...action.payload.section }
                        : section
                ),
            };
        case types.SECTION_REGISTER_FAIL:
        case types.SECTION_AUTH_ERROR:

        default:
            return state;
    }
};

export default sectionReducer;
