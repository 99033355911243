
import ActionTypes from "../actions/types";
import { AllReportCardTemplateActions } from "redux/types/reportCardTemplate";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    reportTemplate : {},
    reportTemplates : [],
}

const reportCardTemplate = (state = initialState, action : AllReportCardTemplateActions) => {
    switch(action.type){
        case ActionTypes.CREATE_REPORT_CARD_TEMPLATE_SUCCESS:
        case ActionTypes.CREATE_REPORT_CARD_TEMPLATE_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_REPORT_CARD_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading : false,
                reportTemplates : action.payload.data,
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_REPORT_CARD_TEMPLATE_FAILURE:
            return {
                ...state,
                loading : false,
                reportTemplates : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.CREATE_REPORT_CARD_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading : false,
                error : '',
            }
        case ActionTypes.CREATE_REPORT_CARD_TEMPLATE_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
            case ActionTypes.UPDATE_REPORT_CARD_TEMPLATE_SUCCESS:
            return {
                ...state,
                reportTemplates: state.reportTemplates.map((reportTemplate) =>
                reportTemplate._id === action.payload.id
                    ? { ...action.payload.reportTemplate }
                    : reportTemplate
                ),
            };
        case ActionTypes.DELETE_REPORT_CARD_TEMPLATE:
            return {
                ...state,
                reportTemplates: state.reportTemplates.filter(
                (reportTemplate) => reportTemplate._id !== action.payload
                ),
            };
        default : return state;
    }
}

export default reportCardTemplate;