import React from "react";
import { Button, Col, Form, ListGroup, Row, Tab, Table  } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'
import { Link } from "react-router-dom";

const SectionStrength: React.FC = (): JSX.Element => {
    return (
       <>
                <div className="c-container">
                   <section className="bg-section mt-3">
                       

                    <Row className="mb-4">
                        <Col sm={3}>
                                <div className="section-small">
                                        <div className="mb-3">
                                            <Form.Label>Academic Year</Form.Label>
                                            <Form.Select>
                                                <option>2021 - 2022</option>
                                            </Form.Select>
                                        </div>
                                </div>
                        </Col>

                        <Col sm={3}>
                                <div className="section-small">
                                        <div className="mb-3">
                                            <Form.Label>Location / Zone</Form.Label>
                                            <Form.Select>
                                                <option>All Schools</option>
                                            </Form.Select>
                                        </div>
                                </div>
                        </Col>
                        <Col sm={3}>
                                <div className="section-small">
                                        <div className="mb-3">
                                            <Form.Label>Branch</Form.Label>
                                            <Form.Select>
                                                <option>Bhashyam Techno School</option>
                                            </Form.Select>
                                        </div>
                                </div>
                        </Col>

                        <Col sm={3}>
                                <div className="section-small">
                                        <div className="mb-3">
                                            <Form.Label>Class / Grade</Form.Label>
                                            <Form.Select>
                                                <option>All Classes</option>
                                            </Form.Select>
                                        </div>
                                </div>
                        </Col>

                        <Col sm={3}><Button className="btn-save">Fetch Details</Button></Col>

                    </Row>
                  
                                <div className="inner-section">
                                  

                                
                                  <Table responsive hover  className="c-table" >
                                    <thead>
                                        <tr>
                                        <th>S.No</th>
                                        <th>Class Name</th>
                                        <th>Section Name</th>    
                                        <th>Existing Student Strenght</th>  
                                        <th>Expected Strength</th>   
                                        <th>Actions</th>                                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>  
                                          1
                                        </td>
                                        <td>
                                        Class - IV
                                        </td>
                                        <td>
                                        Section - A
                                          </td>

                                          <td>  
                                          11
                                        </td>
                                        <td>
                                        <Form.Control type="text"  className="td-ip" />

                                        </td>
                                        <td>
                                           <a href="#" className="me-3"><img src="images/edit-icon.svg" /></a>
                                           <a href="#"><img src="images/delete-icon.svg" /></a>
                                        </td>
                                        </tr>

                                        <tr>
                                        <td>  
                                          2
                                        </td>
                                        <td>
                                        Class - VIII
                                        </td>
                                        <td>
                                        Section - A
                                          </td>

                                          <td>  
                                          11
                                        </td>
                                        <td>
                                        66

                                        </td>
                                        <td>
                                        <a href="#" className="me-3"><img src="images/edit-icon.svg" /></a>
                                           <a href="#"><img src="images/delete-icon.svg" /></a>
                                        </td>
                                        </tr>
                                    
                                    </tbody>
                                    </Table>


                                    
                                </div>

                      
                   

                   </section>

                 </div>
       
       
       </>
            


       
       
    )
}
export default SectionStrength;
