
import ActionTypes from "../actions/types";
import { AllExamRuleSetUpActions } from "redux/types/examrule_setup";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    examrulesetup : {},
    examrulesetups : [],
}

const examRuleSetUp = (state = initialState, action : AllExamRuleSetUpActions) => {
    switch(action.type){
        case ActionTypes.GET_ALL_EXAM_RULE_SETUP_REQUEST:
        case ActionTypes.CREATE_EXAM_RULE_SETUP_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_ALL_EXAM_RULE_SETUP_SUCCESS:
            return {
                ...state,
                loading : false,
                examrulesetups : action.payload.data,
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_ALL_EXAM_RULE_SETUP_FAILURE:
            return {
                ...state,
                loading : false,
                examrulesetups : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.CREATE_EXAM_RULE_SETUP_SUCCESS:
            return {
                ...state,
                loading : false,
                error : '',
            }
        case ActionTypes.CREATE_EXAM_RULE_SETUP_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
        case ActionTypes.GET_EXAM_RULE_SETUP_BY_ID:
            return {
                ...state, 
                loading : false,
                examrulesetup: { ...action.payload.data }
            }
        case ActionTypes.UPDATE_EXAM_RULE_SETUP_SUCCESS:
            return {
                ...state,
                examrulesetups: state.examrulesetups.map((examrulesetup) =>
                examrulesetup._id === action.payload.id
                    ? { ...action.payload.examrulesetup }
                    : examrulesetup
                ),
            };
        case ActionTypes.DELETE_EXAM_RULE_SETUP:
            return {
                ...state,
                examrulesetups: state.examrulesetups.filter(
                (examrulesetup) => examrulesetup._id !== action.payload
                ),
            };
        default : return state;
    }
}

export default examRuleSetUp;