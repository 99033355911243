import types from "redux/actions/types";
import { IClassState, ClassActions } from "../types/orgclass";
import { IClass } from "redux/types/orgclass"

const initialState: IClassState = {
  loading: true,
  orgclass: {} as IClass,
  orgclasses: {} as IClass,
  totalCount:0

};

const classReducer = (state = initialState, action: ClassActions) => {
  switch (action.type) {
    case types.CLASS_REGISTER_SUCCESS:
    case types.CLASS_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // orgclasses: [...state.orgclasses, action.payload] as IClass[],
      };
    case types.GET_CLASSES:
      return {
        ...state,
        loading: false,
        orgclasses: action?.payload?.data as IClass[],
        totalCount: action?.payload?.totalCount,

      };

    case types.DELETE_CLASS:
      return {
        ...state,
        orgclasses: state?.orgclasses?.data.filter(
          (cla:any) => cla._id !== action.payload
        ),
      };

    case types.UPDATE_CLASS:
      return {
        ...state,
        // orgclasses: state.orgclasses.map((cla:any) =>
        // cla._id === action.payload.id
        //     ? { ...action.payload.orgclass }
        //     : cla
        // ),
      };
    case types.CLASS_REGISTER_FAIL:
    case types.CLASS_AUTH_ERROR:

    default:
      return state;
  }
};
export default classReducer;
