import types from "redux/actions/types";

const initialState: any = {
    loading: true,
    error : false,
    totalCount : 0,
    attendanceSettingsData : [],
};

const assignTeacherReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.GET_ATTENDANCE_SETTINGS:
            return {
                ...state,
                loading: false,
                error : false,
                totalCount : action.payload.totalCount,
                attendanceSettingsData: action.payload.data,
            }
        case types.GET_ATTENDANCE_SETTINGS_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error : true,
                    attendanceSettingsData: [],
            }
        case types.CREATE_ATTENDANCE_SETTINGS:
            return {
                ...state,
                loading: false,
                error : false,
            }

        case types.UPDATE_ATTENDANCE_SETTINGS:
            return {
                ...state,
                loading: false,
                error : false,
            }
        case types.UPDATE_ATTENDANCE_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error : true,
            }
        default : 
            return state;
    }
}

export default assignTeacherReducer;
