import types from '../actions/types'
import { IStaffSessionSettings, StaffSessionSettingsActions,IStaffSessionSettingsData } from "redux/types/staffSessionSettings";



const initialState:IStaffSessionSettings = {
  staffSessionSettingsData: [],
  totalCount : 0,
  isLoading: true
};

const staffSessionSettingsReducer = (state = initialState, action: StaffSessionSettingsActions) => {
  
  switch (action.type) {
    case types.CREATE_STAFF_SESSION_SETTINGS:
      return {
        ...state,
        // staffSessionSettingsData: state.staffSessionSettingsData.map((item:any) => (
        //     item._id === action.payload._id ? item.sessions = action.payload.sessions: item.sessions
        // )),
      };

    case types.GET_STAFF_SESSION_SETTINGS:
    return {
        ...state,
        staffSessionSettingsData: action.payload.data,
        isLoading : false,
        totalCount: action.payload.totalCount

    };

    case types.UPDATE_STAFF_SESSION_SETTINGS:
    return {
    ...state,
    // staffSessionSettingsData: state.staffSessionSettingsData.map((item:any) => (
    //         item._id === action.payload._id ? item.sessions = action.payload.sessions: item.sessions
    //     )),
    };

    default:
      return state;
  }
};

export default staffSessionSettingsReducer;