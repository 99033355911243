import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import CloseButton from 'react-bootstrap/CloseButton'
import { Collapse, Toast as ToastContainer } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert'

import { useDispatch } from "react-redux";
import { removeAlert } from "redux/actions/alert";
import { IAlertState } from "redux/types/alert";

type Props = {
  data: IAlertState;
};

const AlertItem: React.FC<Props> = ({ data }): JSX.Element => {
  const dispatch = useDispatch();
  //'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
  return (
    <>
      <Alert variant={data.alertType} onClose={() => dispatch(removeAlert(data.id))} dismissible>
        {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
        <p>{data.msg}</p>
      </Alert>
    </>
  );
}

export default AlertItem;
