import Auth from "pages/auth/Auth";
import ResetPassword from "pages/auth/ResetPassword";
import ResetPasswordRequest from "pages/auth/ResetPasswordRequest";
import Favorites from "pages/Favorites/Favorites";
import studentprofile from "pages/Profile/studentprofile";
import Timeline from "pages/Timeline/Timeline";
import AcademicCourses from "pages/UIDrafts/AcademicCourses";
import AcademicyearMapping from "pages/UIDrafts/AcademicyearMapping";
import AcademicYearSettings from "pages/UIDrafts/AcademicYearSettings";

import ApiIntegrations from "pages/UIDrafts/ApiIntegrations";
import AssignclassGroup from "pages/UIDrafts/AssignclassGroup";
import AssignSections from "pages/UIDrafts/AssignSections";
import CreateclassGroups from "pages/UIDrafts/CreateclassGroups";
import CreateStudentSegment from "pages/UIDrafts/CreateStudentSegment";
import EmailSettings from "pages/UIDrafts/EmailSettings";
import EmergencyExitDetails from "pages/UIDrafts/EmergencyExitDetails";
import IntegrationUrls from "pages/UIDrafts/IntegrationUrls";
import ItcertificateFeeSettings from "pages/UIDrafts/ItcertificateFeeSettings";
import MobileappSettings from "pages/UIDrafts/MobileappSettings";
import PasswordSettings from "pages/UIDrafts/PasswordSettings";
import ReasonforInactivateStudents from "pages/UIDrafts/ReasonforInactivateStudents";
import SectionStrength from "pages/UIDrafts/SectionStrength";
import SecuritySettings from "pages/UIDrafts/SecuritySettings";
import StudentPromotion from "pages/UIDrafts/StudentPromotion";
import UsertypePreferences from "pages/UIDrafts/UsertypePreferences";
import React from "react";
// import ConcernForm from "pages/Modules/Academics/ConcernForm/ConcernForm";

// Components
const Dashboard = React.lazy(() => import("pages/Home/Dashboard"));
const PaymentProcessing = React.lazy(() => import("pages/Home/PaymentProcessing"));
const MainDashBoard = React.lazy(() => import("pages/Home/Dashboard/MainDashboard"));
// const Enquiry = React.lazy(() => import("pages/Modules/Admissions/Enquiry/indexOld"));
// const Assignfee = React.lazy(() => import("pages/Modules/Finance/assignfee"));
const Dairy = React.lazy(() => import("pages/Modules/Academics/Dairy"));
// const Enrollment = React.lazy(() => import("pages/Modules/Enrollment/enroll"));
// const AdmissionSettings = React.lazy(
//   () => import("pages/Modules/Admissions/AdmissionSettings")
// );
const Users = React.lazy(() => import("pages/UIDrafts/user/Users"));
const Assignfee = React.lazy(() => import("pages/Modules/Finance"));
const Login = React.lazy(() => import("pages/auth/Login"));
const SelectOrgPage = React.lazy(() => import("pages/auth/selectOrg"));
const Register = React.lazy(() => import("pages/auth/Register"));
const Settings = React.lazy(() => import("pages/Settings"));
const Modules = React.lazy(() => import("pages/Modules"));
const RoleTabs = React.lazy(
  () => import("pages/Modules/Organization/settings/createRole")
);
const RoleForm = React.lazy(
  () => import("pages/Modules/Organization/settings/createRole/createrole")
);
// const listUser = React.lazy(() => import("pages/Modules/Organization/settings/createUser/userslist"));
// const userCreate = React.lazy(() => import("pages/Modules/Organization/settings/createUser/createuser"));
const Shiftrole = React.lazy(() => import("pages/shiftRole/shiftrole"));
// const Timeline = React.lazy(() => import("pages/Timeline/Timeline"));

const PublicForms = React.lazy(()=>import("pages/Public"))
const Profile = React.lazy(() => import("pages/Profile/profile"));
const Page2 = React.lazy(() => import("pages/Profile/page2"));
const Page3 = React.lazy(() => import("pages/Profile/page3"));
const Page4 = React.lazy(() => import("pages/Profile/page4"));
const OrganizationSettings = React.lazy(
  () => import("pages/Settings/organizationSettings")
);
const ManageorganizationSettings = React.lazy(
  () => import("pages/Settings/manageorganizationSettings")
);
const FinanceSettings = React.lazy(
  () => import("pages/Settings/FinanceSettings")
);
const HrSettings = React.lazy(() => import("pages/Settings/Hrsettings"));
const SmsAlerts = React.lazy(() => import("pages/Settings/SmsAlerts"));
const AcademicYear = React.lazy(() => import("pages/Settings/AcademicYear"));
const LibrarySettings = React.lazy(
  () => import("pages/Settings/LibrarySettings")
);
const GradebookSettings = React.lazy(
  () => import("pages/Settings/GradebookSettings")
);
const CommunicationSettings = React.lazy(
  () => import("pages/Settings/CommunicationSettings")
);

const NotFound = React.lazy(() => import("components/not-found/NotFound"));

type ROUTES = {
  name: string;
  path: string;
  component: React.FC;
  auth: boolean;
  index?: boolean;
  subDomainCheck?: boolean;
};

export const totalRoutes: ROUTES[] = [
  {
    name: "select_org",
    path: "/",
    component: SelectOrgPage,
    index: false,
    auth: false,
    subDomainCheck: true,
  },
  {
    name: "login",
    path: "/login",
    component: Login,
    index: false,
    auth: false,
    subDomainCheck: true,
  },
  {
    name: "Auth",
    path: "/auth",
    component: Auth,
    index: false,
    auth: false,
    subDomainCheck: false, 
  },
  {
    name: "register",
    path: "/register",
    component: Register,
    index: false,
    auth: false,
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: Dashboard,
    auth: true,
    index: true,
  },
  {
    name: "Payment Processing",
    path: "/payment_processing",
    component: PaymentProcessing,
    auth: false,
    index: false,
  },
  {
    name: "users",
    path: "/users",
    component: Users,
    index: false,
    auth: true,
  },
  {
    name: "Settings",
    path: "/settings/*",
    component: Settings,
    index: false,
    auth: true,
  },
  {
    name: "Modules",
    path: "/modules/*",
    component: Modules,
    index: false,
    auth: true,
  },

  //to be removed ... already moved to settings route
  {
    name: "OrganizationSettings",
    path: "/organizationSettings",
    component: OrganizationSettings,
    index: false,
    auth: true,
  },
  {
    name: "ManageorganizationSettings",
    path: "/manageorganizationSettings",
    component: ManageorganizationSettings,
    index: false,
    auth: true,
  },

  {
    name: "FinanceSettings",
    path: "/financeSettings",
    component: FinanceSettings,
    index: false,
    auth: true,
  },

  {
    name: "HrSettings",
    path: "/hrSettings",
    component: HrSettings,
    index: false,
    auth: true,
  },

  {
    name: "SmsAlerts",
    path: "/smsAlerts",
    component: SmsAlerts,
    index: false,
    auth: true,
  },
  {
    name: "AcademicYear",
    path: "/academicYear",
    component: AcademicYear,
    index: false,
    auth: true,
  },
  {
    name: "CommunicationSettings",
    path: "/communicationSettings",
    component: CommunicationSettings,
    index: false,
    auth: true,
  },
  {
    name: "GradebookSettings",
    path: "/gradebookSettings",
    component: GradebookSettings,
    index: false,
    auth: true,
  },
  {
    name: "LibrarySettings",
    path: "/librarySettings",
    component: LibrarySettings,
    index: false,
    auth: true,
  },
  {
    name: "AssignSections",
    path: "/assignSections",
    component: AssignSections,
    index: false,
    auth: true,
  },
  {
    name: "SectionStrength",
    path: "/sectionStrength",
    component: SectionStrength,
    index: false,
    auth: true,
  },
  {
    name: "AssignclassGroup",
    path: "/assignclassGroup",
    component: AssignclassGroup,
    index: false,
    auth: true,
  },
  {
    name: "CreateclassGroups",
    path: "/createclassGroups",
    component: CreateclassGroups,
    index: false,
    auth: true,
  },
  {
    name: "UsertypePreferences",
    path: "/usertypePreferences",
    component: UsertypePreferences,
    index: false,
    auth: true,
  },

  {
    name: "PasswordSettings",
    path: "/passwordSettings",
    component: PasswordSettings,
    index: false,
    auth: true,
  },
  {
    name: "SecuritySettings",
    path: "/securitySettings",
    component: SecuritySettings,
    index: false,
    auth: true,
  },
  {
    name: "IntegrationUrls",
    path: "/integrationUrls",
    component: IntegrationUrls,
    index: false,
    auth: true,
  },
  {
    name: "MobileappSettings",
    path: "/mobileappSettings",
    component: MobileappSettings,
    index: false,
    auth: true,
  },
  {
    name: "AcademicyearMapping",
    path: "/academicyearMapping",
    component: AcademicyearMapping,
    index: false,
    auth: true,
  },
  {
    name: "AcademicCourses",
    path: "/academicCourses",
    component: AcademicCourses,
    index: false,
    auth: true,
  },

  {
    name: "ApiIntegrations",
    path: "/apiIntegrations",
    component: ApiIntegrations,
    index: false,
    auth: true,
  },
  {
    name: "Role",
    path: "/rolelist",
    component: RoleTabs,
    index: false,
    auth: true,
  },
  {
    name: "createRole",
    path: "/createRole",
    component: RoleForm,

    index: false,
    auth: true,
  },
  {
    name: "createRole",
    path: "/createRole/:id",
    component: RoleForm,

    index: false,
    auth: true,
  },
  // , {
  //   name: "createUser",
  //   path: "/createuser/:id?",
  //   component: userCreate,
  //   index: false,
  //   auth: true,
  // }
  // , {
  //   name: "createUser",
  //   path: "/createuser",
  //   component: userCreate,
  //   index: false,
  //   auth: true,
  // }
  // , {
  //   name: "listuser",
  //   path: "/listuser",
  //   component: listUser,
  //   index: false,
  //   auth: true,
  // }
  {
    name: "shiftrole",
    path: "/shiftrole",
    component: Shiftrole,
    index: false,
    auth: true,
  },
  {
    name: "Timeline",
    path: "/Timeline",
    component: Timeline,
    index: false,
    auth: true,
  },
  {
    name: "Profile",
    path: "/profile",
    component: Profile,
    index: false,
    auth: true,
  },
  {
    name: "Profile",
    path: "/studentprofile",
    component: studentprofile,
    index: false,
    auth: true,
  },
  {
    name: "Favorites",
    path: "/favorites",
    component: Favorites,
    index: false,
    auth: true,
  },
  {
    name: "Page2",
    path: "/page2",
    component: Page2,
    index: false,
    auth: true,
  },
  {
    name: "Page3",
    path: "/page3",
    component: Page3,
    index: false,
    auth: true,
  },
  {
    name: "Page4",
    path: "/page4",
    component: Page4,
    index: false,
    auth: true,
  },
  {
    name: "AcademicYearSettings",
    path: "/academicyearSettings",
    component: AcademicYearSettings,
    index: false,
    auth: true,
  },

  {
    name: "EmailSettings",
    path: "/emailSettings",
    component: EmailSettings,
    index: false,
    auth: true,
  },

  {
    name: "CreateStudentSegment",
    path: "/createStudentSegment",
    component: CreateStudentSegment,
    index: false,
    auth: true,
  },

  {
    name: "ItcertificateFeeSettings",
    path: "/itcertificateFeeSettings",
    component: ItcertificateFeeSettings,
    index: false,
    auth: true,
  },

  {
    name: "StudentPromotion",
    path: "/studentPromotion",
    component: StudentPromotion,
    index: false,
    auth: true,
  },

  {
    name: "ReasonforInactivateStudents",
    path: "/reasonforInactivateStudents",
    component: ReasonforInactivateStudents,
    index: false,
    auth: true,
  },

  {
    name: "EmergencyExitDetails",
    path: "/emergencyExitDetails",
    component: EmergencyExitDetails,
    index: false,
    auth: true,
  },

  // {
  //   name: "Enquiry",
  //   path: "/enquiry",
  //   component: Enquiry,
  //   index: false,
  //   auth: true,
  // },
  {
    name: "Dairy",
    path: "/dairy",
    component: Dairy,
    index: false,
    auth: true,
  },
  // , {
  //   name: "Enrollment",
  //   path: "/enrollment",
  //   component: Enrollment,
  //   index: false,
  //   auth: true,
  // }
  // {
  //   name: "Admissionsettings",
  //   path: "/admissionsettings",
  //   component: AdmissionSettings,
  //   index: false,
  //   auth: true,
  // },
  // {
  //   name: "Admissionsettings",
  //   path: "/admissionsettings",
  //   component: AdmissionSettings,
  //   index: false,
  //   auth: true,
  // },
  {
    name: "Assignfee",
    path: "modules/assignfee",
    component: Assignfee,
    index: false,
    auth: true,
  },
  {
    name: "ResetRequest",
    path: "/reset_request",
    component: ResetPasswordRequest,
    index: false,
    auth: false,
  },
  {
    name: "Reset Password",
    path: "/reset_password",
    component: ResetPassword,
    index: false,
    auth: false,
  },
  {
    name: "Public Forms",
    path: "/forms/*",
    component: PublicForms,
    index: false,
    auth: false,
  },

  
];
export const NOTFOUND: ROUTES = {
  name: "notfound",
  path: "*",
  component: NotFound,
  index: false,
  auth: false,
};
