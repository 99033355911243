import types from "redux/actions/types";
import { ICashflowaccountState, CashflowaccountActions } from "../types/cashflowaccount";
import { ICashflowaccount } from "redux/types/cashflowaccount";

const initialState: ICashflowaccountState = {
  loading: true,
  cashflowaccount: {} as ICashflowaccount,
  cashflowaccounts: [] as ICashflowaccount[],
  totalCount:0

};

const cashflowaccountReducer = (state = initialState, action: CashflowaccountActions) => {
  switch (action.type) {
    case types.CASHFLOWACCOUNT_REGISTER_SUCCESS:
    case types.CASHFLOWACCOUNT_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // cashflowaccounts: [...state.cashflowaccounts, action.payload] as ICashflowaccount[],
      };
    case types.GET_CASHFLOWACCOUNTS:
      return {
        ...state,
        loading: false,
        cashflowaccounts: action?.payload?.data as ICashflowaccount[],
        totalCount: action?.payload?.totalCount as Number,

      };

    case types.DELETE_CASHFLOWACCOUNT:
      return {
        ...state,
        cashflowaccounts: state.cashflowaccounts.filter(
          (cashflowaccount) => cashflowaccount._id !== action.payload
        ),
      };

    case types.UPDATE_CASHFLOWACCOUNT:
      return {
        ...state,
        cashflowaccounts: state.cashflowaccounts.map((cashflowaccount) =>
        cashflowaccount._id === action.payload.id
            ? { ...action.payload.cashflowaccount }
            : cashflowaccount
        ),
      };
    case types.CASHFLOWACCOUNT_REGISTER_FAIL:
    case types.CASHFLOWACCOUNT_AUTH_ERROR:

    default:
      return state;
  }
};
export default cashflowaccountReducer;
