import types from "redux/actions/types";
import { IAdmissiontypeState, AdmissiontypeActions } from "../types/admissiontype";
import { IAdmissiontypes } from "redux/types/admissiontype";

const initialState: IAdmissiontypeState = {
  loading: true,
  admissiontype: {} as IAdmissiontypes,
  admissiontypes: [] as IAdmissiontypes[],
};

const admissiontypeReducer = (state = initialState, action: AdmissiontypeActions) => {
  switch (action.type) {
    case types.ADMISSIONTYPE_REGISTER_SUCCESS:
    case types.ADMISSIONTYPE_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        admissiontypes: [...state.admissiontypes, action.payload] as IAdmissiontypes[],
      };
    case types.GET_ADMISSIONTYPES:
      return {
        ...state,
        loading: false,
        admissiontypes: action.payload as IAdmissiontypes[],
      };

    case types.DELETE_ADMISSIONTYPE:
      return {
        ...state,
        admissiontypes: state.admissiontypes.filter(
          (admissiontype) => admissiontype._id !== action.payload
        ),
      };

    case types.UPDATE_ADMISSIONTYPE:
      return {
        ...state,
        admissiontypes: state.admissiontypes.map((admissiontype) =>
        admissiontype._id === action.payload._id
            ? { ...action.payload.admissiontype }
            : admissiontype
        ),
      };
    case types.ADMISSIONTYPE_REGISTER_FAIL:
    case types.ADMISSIONTYPE_AUTH_ERROR:

    default:
      return state;
  }
};
export default admissiontypeReducer;
