import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";

const Ipblacklist: React.FC = (): JSX.Element => {
  return (
    <>
      

      <Form className="w-100 mw-100">
      <h3 className="f-24 frmed web-text5">Enter IP Address to be blocked</h3>
        <Row>

          <Col md={4} className="pe-5 mb-3">
                              <div className="section-small">                                        
                                            <Form.Label>IP Address</Form.Label>
                                            <Form.Control type="text" placeholder="Type Here" />                                    
                                </div>
          </Col>
        
        </Row>
        <Button className="btn-save">Save</Button>  
      </Form>

      <div className="inner-section p-0 mt-4">
        <div className="bg-section">
                <h3 className="f-24 frmed web-text5">Black List IPAddresses</h3>
                <div className="note mb-4">
                <h5 className="frbold">Note:</h5>
                  <p>The system will not be allowed to access from the below IP addresses.</p>
                </div>

                <Table responsive hover  className="c-table" >
                                    <thead>
                                        <tr>
                                        <th>IP Address</th>
                                        <th>Created Date</th>         
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>
                                        102.261.2.3.262
                                      </td>
                                        <td>
                                        15-12-2021
                                        </td>
                                        </tr>

                                    </tbody>
                                    </Table>   
        </div>

      </div>

    </>
  )
}
export default React.memo(Ipblacklist);
