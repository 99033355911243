import types from "redux/actions/types";
import { IBranchClassState, BranchClassActions } from "../types/branchclass";
import { IBranchClass } from "redux/types/branchclass";

const initialState: IBranchClassState = {
    loading: true,
    branchclass: {} as IBranchClass,
    branchclasses: {} as IBranchClass,
    totalCount:0
};

const branchclassReducer = (
    state = initialState,
    action: BranchClassActions
): IBranchClassState => {
    switch (action.type) {
        case types.BRANCHCLASS_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // branchclasses: [...state.branchclasses, action.payload] as IBranchClass[],
            };
        case types.GET_BRANCHCLASSES:
            return {
                ...state,
                loading: false,
                branchclasses: action.payload as IBranchClass,
            };

        case types.DELETE_BRANCHCLASS:
            return {
                ...state,
                branchclasses: state?.branchclasses?.data.filter(
                    (branchclass:any) => branchclass._id !== action.payload
                ),
            };

        case types.UPDATE_BRANCHCLASS:
            return {
                ...state,
                branchclasses: state?.branchclasses?.data.map((branchclass:any) =>
                branchclass._id === action.payload.id
                        ? { ...action.payload.branchclass }
                        : branchclass
                ),
            };
        case types.BRANCHCLASS_AUTH_ERROR:
        case types.BRANCHCLASS_REGISTER_FAIL:

        default:
            return state;
    }
};

export default branchclassReducer;
