import axios from "utils/axios"
import { setAlert } from "./alert";
import ActionTypes from "./types";

export const requestPassword = (body: any, navigate: any) => {
    return async (disptach: any) => {
        try {
            const { data } = await axios.post('user/resetpassword', body);
            disptach({ type: ActionTypes.REQUEST_RESET_PASSWORD, payload: data });
            disptach(setAlert({
                msg: 'Password Reset Successfull',
                status: 200,
                alertType: 'success'
            }))
            navigate('/login');
        } catch (error: any) {
            const message = error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong when reseting the password";
            disptach(setAlert({
                msg: message,
                status: error?.response?.status,
                alertType: 'danger'
            }))
        } finally {
            // navigate('/login');
        }
    }
}

export const studentRequestPassword = (body: any, navigate: any) => {
    return async (disptach: any) => {
        try {
            const { data } = await axios.post('student/resetpassword', body);
            disptach({ type: ActionTypes.REQUEST_RESET_PASSWORD, payload: data });
            disptach(setAlert({
                msg: 'Password Reset Successfull',
                status: 200,
                alertType: 'success'
            }))
            navigate('/login');
        } catch (error: any) {
            const message = error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong when reseting the password";
            disptach(setAlert({
                msg: message,
                status: error?.response?.status,
                alertType: 'danger'
            }))
        } finally {
            // navigate('/login');
        }
    }
}

export const changePassword = (body: any, navigate: any) => {
    return async (dispatch: any) => {
        try {
            const { data } = await axios.post('user/changePassword', body);
            dispatch({ type: ActionTypes.CHANGE_PASSWORD, payload: data });
            dispatch(setAlert({
                msg: 'Password Reset Successful',
                status: 200,
                alertType: 'success'
            }))
            navigate('/login');
        } catch (error: any) {
            const message = error?.response?.data ? error?.response?.data : 'Something went wrong when changing the password!';
            
            dispatch(setAlert({
                msg: message,
                status: error?.response?.status,
                alertType: 'danger'
            }))
        }
    }
}