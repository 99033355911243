import ActionTypes from "redux/actions/types";
const initialState = {
    studentEnrolmentSequences: [],
    singleStudentSequences: ''
};
export const studentEnrollmentSequence = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.ENROLLMENT_STUDENT_SEQUENCE_REGISTER_SUCCESS:
        case ActionTypes.ENROLLMENT_STUDENT_SEQUENCE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ActionTypes.GET_ENROLLMENT_STUDENT_SEQUENCES:
            return { ...state, studentEnrolmentSequences: [...action?.payload] }
        case ActionTypes.GET_ENROLLMENT_STUDENT_SEQUENCE:
            return { ...state, singleStudentSequences: { ...action?.payload } }
        case ActionTypes.DELETE_SEQUENCE:
            return {
                ...state,
                studentEnrolmentSequences: state?.studentEnrolmentSequences?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        default:
            return state
    }
}