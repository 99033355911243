import types from '../actions/types'
import { ISmsDeliveryReport, SmsDeliveryReportActions } from "redux/types/smsDeliveryReport";



const initialState = {
  smsDeliveryReportData: [],
  smsDeliveryReportDatabySMSTypes: [],
  smsDeliveryReportDatabySMSTypeData: [],
  totalCount : 0,
  communicationTypeTotalCount: 0,
  SMSTypeTotalCount: 0,
  isLoading: true
};

const smsDeliveryReducer = (state = initialState, action: SmsDeliveryReportActions) => {
  
  switch (action.type) {
    case types.GET_SMS_DELIVERY_REPORT:
      return {
        ...state,
        smsDeliveryReportData: action.payload.dumpData,
        isLoading : false,
        totalCount: action.payload.totalCount
      };
    case types.GET_SMS_DELIVERY_REPORT_BY_SMSTYPE:
      return {
        ...state,
        smsDeliveryReportDatabySMSTypes: action.payload?.[0]?.data,
        isLoading : false,
        SMSTypeTotalCount: action.payload?.[0]?.count?.[0]?.totalCount
      };
    case types.GET_SMS_DELIVERY_REPORT_BY_SMSTYPE_DATA:
        return {
          ...state,
          smsDeliveryReportDatabySMSTypeData: action.payload?.[0]?.data,
          isLoading : false,
          communicationTypeTotalCount: action.payload?.[0]?.count?.[0]?.totalCount
        };
    default:
      return state;
  }
};

export default smsDeliveryReducer;

