import types from "redux/actions/types";
import { IStaffAttendanceData, IStaffAttendance } from "redux/types/staffAttendance";

const initialState: IStaffAttendance = {
    loading: true,
    staffAttendance: {} as IStaffAttendanceData,
    staffAttendances: [],
    intialstaffData : [],
    staffAttendenceSummary: [],
    summaryLoading : false,
    totalSummary : {},
    totalCount: 0
};

const staffAttendanceReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.CREATE_STAFF_ATTENDANCE:
            return{
                ...state,
                loading: false,
                // staffAttendances: [...action.payload.data],
                // totalCount: action?.payload?.totalCount
            }
        case types.GET_STAFFATTENDANCE_STAFF:    
            return {
                ...state,
                loading: false,
                staffAttendances: action?.payload?.data,
                totalCount: action?.payload?.totalCount
            }
        case types.GET_STAFFATTENDANCE_DATA:            
            return {
                ...state,
                loading: false,
                intialstaffData : action?.payload,
                totalCount: action?.payload?.totalCount
            }
        case types.GET_STAFF_ATTENDENCE_SUMMARY_REQUEST:
                return {
                    ...state,
                    summaryLoading : true,
                };
        case types.GET_STAFF_ATTENDENCE_SUMMARY:
            return {
                ...state, summaryLoading : false, staffAttendenceSummary: [...action?.payload], totalSummary: action.totalSummary,
                summaryCount : action.summaryCount
            }
        default:
            return state;
    }
}

export default staffAttendanceReducer;
