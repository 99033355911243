import types from '../actions/types'
import { IStudentWiseDueReport,StudentWiseDueReportActions } from 'redux/types/studentWiseDueReport';

const initialState:IStudentWiseDueReport = {
    studentWiseDueReports :[],
    totalCount : 0,
    isLoading: true
}

const studentWiseDueReportReducer = (state=initialState,action:StudentWiseDueReportActions) =>{
    switch (action.type) {
      case types.GET_STUDENT_WISE_DUE_REPORT_REQUEST:
        return {
          ...state,
          isLoading: true
        }
        case types.GET_STUDENT_WISE_DUE_REPORT:
          return {
            ...state,
            studentWiseDueReports :action.payload.data,
            totalCount : action.payload.totalCount,
            isLoading: false
          };
        case types.GET_STUDENT_WISE_DUE_REPORT_FAILURE:
          return {
            ...state,
            totalCount : 0,
            isLoading: false
          };
        default:
          return state;
      }
}

export default studentWiseDueReportReducer;