import React from 'react';

const LowBandwidth = ({type}) => {
  return (
    <div>
      {type === "NETWORK" ? <h2>Lost Network Connection</h2> :<h2>Low Network Connection</h2>}
      <p>Please check your network connection and try again.</p>
    </div>
  );
};

export default LowBandwidth;