import ActionTypes from "redux/actions/types";

const initialState = {
    timelineData: [], 
    isEmpty: false,
    loading: true,
}
 const timeLineReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_ORG_TIMELINE:
            if (action.payload.scrolling)
                return {
                    ...state, timelineData: [...state.timelineData,...action.payload.data],
                    isEmpty : !action.payload.data?.length ,
                }
            return {
                    ...state, loading: false, timelineData: [...action.payload.data],
                    isEmpty : !action.payload.data?.length ,
            }
        case ActionTypes.GET_BRANCH_TIMELINE:
            if (action.payload.scrolling)
                return {
                    ...state, timelineData: [...state.timelineData,...action.payload.data],
                    isEmpty : !action.payload.data?.length
                }
            return {
                    ...state,loading: false, timelineData: [...action.payload.data],
                    isEmpty : !action.payload.data?.length
            }
        default:
            return state;
    }
}
export default timeLineReducer