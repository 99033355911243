import types from "redux/actions/types";
import {  DriverActions } from "../types/vehicleDriver";


const initialState = {
  loading: true,
  driver: {} ,
  drivers: [],
  totalCount: 0
};

const vehicleDriverReducer = (state = initialState, action: DriverActions) => {
  switch (action.type) {
    
    case types.CREATE_VEHICLE_DRIVER:
      return {
        ...state,
        loading: false,
      };
    case types.GET_VEHICLE_DRIVERS:

      return {
        ...state,
        loading: false,
        drivers: action?.payload,
        totalCount: action?.payload?.totalCount,
      };
    case types.DELETE_VEHICLE_DRIVER:
      return {
        ...state,
        drivers: state.drivers.filter(
          (driver) => driver._id !== action.payload
        ),
      };

    case types.UPDATE_VEHICLE_DRIVER:
      return {
        ...state,
        drivers: state.drivers.map((driver) =>
        driver._id === action.payload.id
            ? { ...action.payload.driver }
            : driver
        ),
      };
      
    default:
      return state;
  }
};
export default vehicleDriverReducer;
