import types from "redux/actions/types";
import { ExpenseCategoriesState, ExpenseCategoriesActions, IExpenseCategory } from "../types/expenseCategories";


const initialState: ExpenseCategoriesState = {
  loading: true,
  category: {} as IExpenseCategory,
  categories: [],
  totalCount: 0
};

const expenseCategoryReducer = (state = initialState, action: ExpenseCategoriesActions) => {
  switch (action.type) {
    case types.EXPENSE_CATEGORY_REGISTER_SUCCESS:
    case types.EXPENSE_CATEGORY_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // categories: [...state.categories, action.payload] as IExpenseCategory[],
      };
    case types.GET_EXPENSE_CATEGORIES:

      return {
        ...state,
        loading: false,
        categories: action?.payload?.data.data ,
        totalCount: action?.payload?.data.totalCount,
      };
    case types.DELETE_EXPENSE_CATEGORIES:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category._id !== action.payload
        ),
      };

    case types.UPDATE_EXPENSE_CATEGORIES:
      return {
        ...state,
        categories: state.categories.map((category) =>
        category._id === action.payload.id
            ? { ...action.payload.category }
            : category
        ),
      };
    case types.EXPENSE_CATEGORY_REGISTER_FAIL:

    default:
      return state;
  }
};
export default expenseCategoryReducer;
