import types from "redux/actions/types";
import {
    IStaffBulkUploadState,
    StaffBulkUploadActions,
} from "redux/types/staffbulkupload";

const initialState: IStaffBulkUploadState = {
    loading: true,
    staffbulkupload: [],
    staffbulkuploadvalidated: { status: "", count: [], message: [] },
};

const staffBulkUploadReducer = (
    state = initialState,
    action: StaffBulkUploadActions
): IStaffBulkUploadState => {
    switch (action.type) {
        case types.STAFF_BULK_UPLOAD:
            return {
                ...state,
                loading: false,
                staffbulkupload: action.payload,
            };
        case types.STAFF_BULK_UPLOAD_VALIDATION:
            return {
                loading: false,
                staffbulkupload: [],
                staffbulkuploadvalidated: {
                    status: action.payload.status,
                    count: action.payload.count,
                    message: action.payload.data,
                },
            };
        case types.STAFF_BULK_UPLOAD_FAILURE:
        default:
            return state;
    }
};

export default staffBulkUploadReducer;