import { trackPromise } from 'react-promise-tracker';
import axios from "utils/axios"
import { AnyMessageParams } from 'yup/lib/types';
import { setAlert } from "./alert"
import { setUserAuthToken } from "utils/headers";
import ActionTypes from "./types"
export const getOrgTimeline = (body: any, scrolling: boolean) => {
    return async (dispatch: any) => {
        try {
            const res: any = await (axios.post('timeline/getTimeLine', body))
            dispatch({ type: ActionTypes.GET_ORG_TIMELINE, payload: {data: res.data, scrolling : scrolling}})
        } catch (error: any) {
            // dispatch(
            //     setAlert({
            //         msg: error?.response?.data + " for fetching Timeline",
            //         status: error.status,
            //         alertType: "danger",
            //     })
            // )
        }
    }
}

export const getBranchTimeline = (body: any, scrolling: boolean) => {
    return async (dispatch: any) => {
        try {
            const res: any = await (axios.post('timeline/getTimeLine', body))
            dispatch({ type: ActionTypes.GET_BRANCH_TIMELINE, payload: {data: res.data, scrolling : scrolling}})
        } catch (error: any) {
            // dispatch(
            //     setAlert({
            //         msg: "Something went wrong",
            //         status: error?.response?.status,
            //         alertType: "danger",
            //     })
            // )
        }
    }
}

export const createTimeline = (body: any,) => {
    if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
    return async (dispatch: any) => {
        try {
            const res: any = await trackPromise(axios.post('timeline/create', body))
        } catch (error: any) {  
        }
    }
}