import types from "redux/actions/types";
import { IConcessionruleState, ConcessionruleActions } from "../types/concessionrule";
import { IConcessionrule } from "redux/types/concessionrule";

const initialState: IConcessionruleState = {
    loading: true,
    concessionrule: {} as IConcessionrule,
    concessionrules: [] as IConcessionrule[],
  totalCount:0

};

const concessionruleReducer = (state = initialState, action: ConcessionruleActions) => {
    switch (action.type) {
        case types.CONCESSIONRULE_REGISTER_SUCCESS:
        case types.CONCESSIONRULE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // concessionrules: [...state.concessionrules, action.payload] as IConcessionrule[],
            };
        case types.GET_CONCESSIONRULES:
            return {
                ...state,
                loading: false,
                concessionrules:action?.payload?.data as IConcessionrule [],
                totalCount:action?.payload?.totalCount   
                
            };
            case types.GET_CONCESSIONRULES_BRANCH:
                return {
                    ...state,
                    loading: false,
                    concessionrules:action?.payload as IConcessionrule [],
                    
                };
        case types.DELETE_CONCESSIONRULE:
            return {
                ...state,
                concessionrules: state.concessionrules.filter(
                    (concessionrule) => concessionrule._id !== action.payload
                ),
            };

        case types.UPDATE_CONCESSIONRULE:
            return {
                ...state,
                // concessionrules: state.concessionrules.map((concessionrule) =>
                //     concessionrule._id === action.payload.id
                //         ? { ...action.payload.concessionrule }
                //         : concessionrule
                // ),
            };
        case types.CONCESSIONRULE_REGISTER_FAIL:
        case types.CONCESSIONRULE_AUTH_ERROR:
        case types.BRANCHCONCESSIONRULE_SAVE:

        default:
            return state;
    }
};
export default concessionruleReducer;
