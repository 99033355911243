import ActionTypes from "redux/actions/types";

const initialState = {
  filteredStaff: [],
  totalCount : 0,
  loading: true,
};
export const FilterdStaff = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.GET_FILTERD_STAFF:     
      return {
        ...state,
        loading: false,
        filteredStaff: [...action.payload.staff],
        totalCount: action.payload.totalCount,
      };
    case ActionTypes.RESET_FILTERED_STUDENT:
        return{
          filteredStaff: [],
          approvals: [],
          totalCount: 0,
          loading:false
        }
    default:
      return state;
  }
};
