import types from "redux/actions/types";
import { AnnouncementActions, IAnnouncementState, IAnnouncementTypes, IAnnouncements } from "redux/types/announcements";

const initialState: IAnnouncementState = {
    loading: true,
    error: false,
    announcementTypesData : {data: [] as IAnnouncementTypes[], totalCount : 0} as any,
    announcementsData: {data: [] as IAnnouncements[], totalCount : 0} as any,
    singleAnnouncementData: {} as IAnnouncements,
};

const announcementReducer = (state = initialState, action: AnnouncementActions) => {
    switch (action.type) {
        case types.GET_ANNOUNCEMENTS:
            return {
                ...state,
                loading: false,
                error: false,
                announcementsData: {data: action.payload.data, totalCount: action.payload.totalCount} as any,
            }
        case types.CREATE_ANNOUNCEMENT:
            return {
                ...state,
                loading: false,
                error: false,
                singleAnnouncementData: action.payload,
            }

        case types.CREATE_ANNOUNCEMENT_FAILURE:
            return {
                ...state,
                error : true,
                announcementsData: [],
                loading: false,
            }
        case types.GET_ANNOUNCEMENT_TYPES:
            return {
                ...state,
                loading: false,
                error: false,
                announcementTypesData: {data: action.payload.data, totalCount: action.payload.totalCount} as any,
            }
        case types.CREATE_ANNOUNCEMENT_TYPE:
            return {
                ...state,
                loading: false,
                error: false,
            }
            
        case types.CREATE_ANNOUNCEMENT_TYPE_FAILURE:
            return {
                ...state,
                error : true,
                announcementTypesData: [],
                loading: false,
            }
            
        case types.UPDATE_ANNOUNCEMENT_TYPE:
        case types.DELETE_ANNOUNCEMENT_TYPE:
            return {
                    ...state,
                    }
        default : 
            return state;
    }
}

export default announcementReducer;
