import ActionTypes from "../actions/types";
import { AllGradeGroupScholasticActions } from "redux/types/gradeGroupScholastic";

const initialState = {
  loading: false,
  error: "",
  scholasticGradeGroups: [],
  totalCount: 0,
};

const gradeGroupScholasticReducer = (
  state = initialState,
  action: AllGradeGroupScholasticActions
) => {
  switch (action.type) {
    case ActionTypes.GET_GRADE_GROUP_SCHOLASTIC_REQUEST:
      return { ...state, loading: true, error: "" };

    case ActionTypes.GET_GRADE_GROUP_SCHOLASTIC_SUCCESS:
      return {
        ...state,
        scholasticGradeGroups: action?.payload?.data,
        totalCount: action.payload?.totalCount,
        loading: false,
      };

    case ActionTypes.GET_GRADE_GROUP_SCHOLASTIC_FAILURE:
      return { ...initialState, error: action.payload };

    case ActionTypes.CREATE_GRADE_GROUP_SCHOLASTIC_REQUEST:
      return { ...state, loading: true, error: "" };

    case ActionTypes.CREATE_GRADE_GROUP_SCHOLASTIC_SUCCESS:
      return {
        ...state,
        // scholasticGradeGroups: [
        //   ...state.scholasticGradeGroups,
        //   action?.payload?.data,
        // ],
        // totalCount: state?.totalCount + 1,
        error: "",
        loading: false,
      };

    case ActionTypes.CREATE_GRADE_GROUP_SCHOLASTIC_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ActionTypes.UPDATE_GRADE_GROUP_SCHOLASTIC_REQUEST:
      return { ...state, loading: true, error: "" };

    case ActionTypes.UPDATE_GRADE_GROUP_SCHOLASTIC_SUCCESS:
      return {
        ...state,
        scholasticGradeGroups: state.scholasticGradeGroups.map((gradeGroup) =>
          gradeGroup._id === action?.payload?.data?._id
            ? action?.payload?.data
            : gradeGroup
        ),
        loading: false,
      };

    case ActionTypes.UPDATE_GRADE_GROUP_SCHOLASTIC_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ActionTypes.DELETE_GRADE_GROUP_SCHOLASTIC_REQUEST:
      return { ...state, loading: true, error: "" };

    case ActionTypes.DELETE_GRADE_GROUP_SCHOLASTIC_SUCCESS:
      // return {
      //   ...state,
      //   scholasticGradeGroups: state?.scholasticGradeGroups?.filter(
      //     ({ _id }) => _id !== action?.payload
      //   ),
      //   loading: false,
      // };
      return state

    case ActionTypes.DELETE_GRADE_GROUP_SCHOLASTIC_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default gradeGroupScholasticReducer;
