import types from "redux/actions/types";
import { ISubjecttypeState, SubjecttypeActions } from "../types/subjecttypes";
import { ISubjecttype } from "redux/types/subjecttypes";

const initialState: ISubjecttypeState = {
    loading: true,
    subjecttype: {} as ISubjecttype,
    subjecttypes: [] as ISubjecttype[],
};

const subjecttypeReducer = (
    state = initialState,
    action: SubjecttypeActions
): ISubjecttypeState => {
    switch (action.type) {
        case types.SUBJECTTYPE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                subjecttypes: [...state.subjecttypes, action.payload] as ISubjecttype[],
            };
        case types.GET_SUBJECTTYPES:
            return {
                ...state,
                loading: false,
                subjecttypes: action.payload as ISubjecttype[],
            };

        case types.DELETE_SUBJECTTYPE:
            return {
                ...state,
                subjecttypes: state.subjecttypes.filter(
                    (subjecttype) => subjecttype._id !== action.payload
                ),
            };

        case types.UPDATE_SUBJECTTYPE:
            return {
                ...state,
                subjecttypes: state.subjecttypes.map((subjecttype) =>
                    subjecttype._id === action.payload.id
                        ? { ...action.payload.subjecttype }
                        : subjecttype
                ),
            };
        case types.SUBJECTTYPE_REGISTER_FAIL:
        case types.SUBJECTTYPE_AUTH_ERROR:

        default:
            return state;
    }
};

export default subjecttypeReducer;
