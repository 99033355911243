import StockItemReceipt from 'pages/Modules/Inventory/Stock_item_receipt/StockItemReceipt';
import types from '../actions/types'
import {IStockItemReceipt , IStockItemReciptData , StockItemReceiptActions } from "redux/types/inventoryStockItemReceipt";


const stockItemReceiptInitialState:IStockItemReceipt = {
    stockItemReceiptData: [] as IStockItemReciptData[],
    totalCount : 0,
    isLoading: false,
    error:"",
    stockItemReceiptDetail:{},
    itemSaleReceipt: [],
    itemSaleTotalCount : 0,
    stockItemByKitId:[],
    servicesByKitId: []
  };

  const stockItemReceiptReducer = (state = stockItemReceiptInitialState, action: StockItemReceiptActions) => {
    switch (action.type) {
      case types.GET_ALL_STOCK_ITEM_RECEIPT_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
        
      case types.GET_ALL_STOCK_ITEM_RECEIPT_SUCCESS:
        return {
          ...state,
          stockItemReceiptData: action.payload.data,
          totalCount: action.payload.totalCount,
          isLoading: false,
        };
      case types.GET_ALL_STOCK_ITEM_RECEIPT_FAILURE:
        return {
          ...state,
          stockItemReceiptData: [],
          totalCount: 0,
          isLoading: false,
        };
        case types.GET_ALL_ITEM_SALE_RECEIPT_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
        
      case types.GET_ALL_ITEM_SALE_RECEIPT_SUCCESS:
        return {
          ...state,
          itemSaleReceipt: action.payload.data,
          itemSaleTotalCount: action.payload.totalCount,
          isLoading: false,
        };
      case types.GET_ALL_ITEM_SALE_RECEIPT_FAILURE:
        return {
          ...state,
          itemSaleReceipt: [],
          totalCount: 0,
          isLoading: false,
        };
      case types.CREATE_STOCK_ITEM_RECEIPT_SUCCESS:
        return {
          ...state,
          // stockItemReceiptData: [...state.stockItemReceiptData, action.payload.data],
            stockItemReceiptDetail: action.payload
        };
    
      case types.CREATE_STOCK_ITEM_RECEIPT_FAILURE:
        return {
          ...state,
          stockItemReceiptData: [...state.stockItemReceiptData],
        };
      case types.GET_ALL_STOCK_ITEM_BY_KITID_REQUEST:
        return {
          ...state,
          stockItemByKitId : [],
          servicesByKitId: []
        };
      case types.GET_ALL_STOCK_ITEM_BY_KITID_FAILURE:
        return {
          ...state,
          stockItemByKitId : [...state.stockItemByKitId],
          servicesByKitId: []
        };
      case types.GET_ALL_STOCK_ITEM_BY_KITID_SUCCESS:
        return {
          ...state,
          stockItemByKitId : action.payload?.itemsData,
          servicesByKitId : action.payload?.serviceData
        };
    
      default:
        return state;
    }
  };
  

export default stockItemReceiptReducer;