import types from "redux/actions/types";
import { IStudentfeeState, StudentfeeActions } from "../types/studentfee";
import { IStudentfee, IStudentfeeRes } from "redux/types/studentfee";

const initialState: IStudentfeeState = {
    loading: true,
    studentfee: {} as IStudentfee,
    studentfees: [] as IStudentfee[],
    studentfeetransactions: [] as IStudentfee[],
    studentfeeSuccessResponse: [] as any[],
    studentfeerecept: {} as IStudentfee,
    totalCount: 0,
    onlinepayments:[]
};

const sudentFeeReducer = (
    state = initialState,
    action: StudentfeeActions
): IStudentfeeState => {
    switch (action.type) {
        case types.STUDENTFEE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // studentfeeSuccessResponse: action.payload as IStudentfeeRes,
            };
        case types.GET_STUDENTFEES:
            return {
                ...state,
                loading: false,
                studentfee: action.payload as IStudentfee,
            };

        case types.DELETE_STUDENTFEE:
            return {
                ...state,
                studentfees: state.studentfees.filter(
                    (studentfee: any) => studentfee._id !== action.payload
                ),
            };

        case types.UPDATE_STUDENTFEE:
            return {
                ...state,
                studentfees: state.studentfees.map((studentfee: any) =>
                    studentfee._id === action.payload.id
                        ? { ...action.payload.studentfee }
                        : studentfee
                ),
            };
        case types.STUDENTFEEPAYMENT_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                studentfeeSuccessResponse: action.payload,
            };
        case types.GET_STUDENTFEESRECEPT:
            return {
                ...state,
                loading: false,
                studentfeerecept: action.payload as IStudentfee,
            };
        case types.GET_STUDENTFEETRANSACTIONS:
            return {
                ...state,
                loading: false,
                studentfeetransactions: action?.payload?.data as IStudentfee[],
                totalCount: action?.payload?.totalCount

            };
            case types.GET_ONLINEPAYMENTS:
                return {
                    ...state,
                    loading: false,
                    onlinepayments: action?.payload as any[],
                };
        case types.STUDENTFEE_REGISTER_FAIL:
        case types.UNASSIGN_STUDENTFEE:
        case types.STUDENTFEE_AUTH_ERROR:
        
        default:
            return state;
    }
};

export default sudentFeeReducer;
