import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";



const IntegrationUrls: React.FC = (): JSX.Element => {
  return (
    <>
    
    <div className="c-container content-tabs mt-5">
      <div className="bg-section">
        <h3 className="f-24 frmed web-text5">Integrations URLs</h3>
   

        <Row>

          <Col md={4} className="pe-5 mb-3">
            <div className="section-small">
              <Form.Label>URLs Type</Form.Label>
              <Form.Select>
                <option>Web Enquiry API</option>
              </Form.Select>
            </div>
          </Col>
          <Col md={4} className="pe-5 mb-3">
            <div className="section-small">
              <Form.Label>Branch Name</Form.Label>
              <Form.Select>
                <option>Anish Group Of Institutions</option>
              </Form.Select>
            </div>
          </Col>

        </Row>
       
        </div>
    </div>
      </>


  )
}
export default React.memo(IntegrationUrls);
