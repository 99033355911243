import types from "redux/actions/types";
import { ILeavePlan, ILeavePlanState, LeavePlanActions } from "redux/types/leavePlan";

const initialState: ILeavePlanState = {
    loading: true,
    totalCount: 0,
    leavePlan: {} as ILeavePlan,
    leaveplans: [],
};
export const leavePlanReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.LEAVE_PLAN_REGISTER_SUCCESS:
        case types.LEAVE_PLAN_REGISTER_SUCCESS:
            return {
                ...state,
                leaveplans: [...state.leaveplans, action.payload.data],
                loading: false,
            };
        case types.GET_LEAVE_PLAN:
            return {
                ...state,
                loading: false,
                leaveplans: action?.payload,
                // totalCount: action?.payload?.length,
            };
        case types.DELETE_LEAVE_PLAN:
            return {
                ...state,
                leaveplans: state?.leaveplans?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        case types.UPDATE_LEAVE_PLAN:

            return {
                ...state,
                leaveplans: state.leaveplans.map((clientuser: any) =>
                    clientuser._id === action.payload._id ? { ...action.payload } : clientuser)
            }
        default:
            return state
    }
}
export default leavePlanReducer;