
import ActionTypes from "../actions/types";
import { AllStoreActions } from "redux/types/inventoryStore";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    store : {},
    stores : [],
    categoriesByStoreId : [],
    subCateGoriesByStoreId : [],
    categoryItems: [],
    subCategoryUnits: []
}

const Store = (state = initialState, action : AllStoreActions) => {
    switch(action.type){
        case ActionTypes.GET_ALL_STORES_REQUEST:
        case ActionTypes.CREATE_STORE_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_ALL_STORES_SUCCESS:
            return {
                ...state,
                loading : false,
                stores : action.payload.data,
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_ALL_STORES_FAILURE:
            return {
                ...state,
                loading : false,
                stores : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.CREATE_STORE_SUCCESS:
            return {
                ...state,
                loading : false,
                error : '',
            }
        case ActionTypes.CREATE_STORE_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
        case ActionTypes.GET_STORE_BY_ID:
            return {
                ...state, 
                loading : false,
                store: { ...action.payload.data }
            }
        case ActionTypes.UPDATE_STORE_SUCCESS:
            return {
                ...state,
                stores: state.stores.map((store) =>
                store._id === action.payload.id
                    ? { ...action.payload.store }
                    : store
                ),
            };
        case ActionTypes.DELETE_STORE:
            return {
                ...state,
                stores: state.stores.filter(
                (store) => store._id !== action.payload
                ),
            };
        case ActionTypes.GET_ALL_CATEGORIES_BY_STOREID:
                return {
                    ...state,
                    categoriesByStoreId : action?.payload?.Categories,
                    subCateGoriesByStoreId : action?.payload?.SubCategories
                };
        case ActionTypes.GET_ALL_ITEMS_BY_CATEGORY_SUBCATEGORY:
            return {
                ...state,
                categoryItems : action?.payload?.items,
                subCategoryUnits : action?.payload?.units
            }; 
        default : return state;
    }
}

export default Store;