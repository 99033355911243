import types from "redux/actions/types";
import { SearchedStudentsFilteredActions } from "redux/types/searchedStudentsFilter";

const initialState : any = {
    loading: true,
    searchedStudent: {} as any,
    searchedStudents: [] as Array<any>,
  };

const searchedStudentsFilterReducer = (state = initialState, action: SearchedStudentsFilteredActions): any => {
    switch (action.type) {
      case types.GET_SEARCHED_STUDENTS_FILTERED:
        return {
            ...state,
            loading: false,
            searchedStudents: action.payload as Array<any>,
          };
      default:
            return state;
    }
}
export default searchedStudentsFilterReducer;