import types from "redux/actions/types";
import { IRoleState, RoleActions } from "../types/role";
import { IRole } from "redux/types/role";

const initialState: IRoleState = {
  loading: true,
  role: {} as IRole,
  roles: {} as {role: IRole[], totalCount ?: number},
};

const roleReducer = (state = initialState, action: RoleActions): IRoleState => {
  switch (action.type) {
    case types.ROLE_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload as any,
      };
    case types.GET_ROLES:
      return {
        ...state,
        loading: false,
        roles: {role: action.payload.role, totalCount : action.payload.totalCount || 0},
      };

    case types.DELETE_ROLE:
      return {
        ...state,
        roles: {...state.roles, role: state.roles.role.filter((role) => role._id !== action.payload)},
      };

    case types.UPDATE_ROLE:
      return {
        ...state,
        loading: true,
        roles: {...state.roles, role: state.roles.role.map((role) =>
        role._id === action.payload.id ? { ...action.payload.user } : role
        )},
      };
    case types.ROLE_REGISTER_FAIL:
    case types.ROLE_AUTH_ERROR:
    default:
      return state;
  }
};

export default roleReducer;
