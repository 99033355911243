
import types from '../actions/types'
import { IItemSaleReport, IItemSaleReportData, ItemSaleReportActions } from 'redux/types/inventoryItemSaleReport';


const itemSaleReportInitialState:IItemSaleReportData = {
    itemSaleReportData: [] as IItemSaleReport[],
    totalCount : 0,
    isLoading: false,
    totalAmount:{}
  };

  const itemSaleReportReducer = (state = itemSaleReportInitialState, action: ItemSaleReportActions) => {
    switch (action.type) {
        case types.GET_ALL_ITEM_SALE_REPORT_REQUEST:
          return {
           ...state,
            isLoading: true,
          };

        case types.GET_ALL_ITEM_SALE_REPORT_SUCCESS:
          return {
           ...state,
            itemSaleReportData: action.payload.data,
            totalCount: action.payload.totalCount,
            totalAmount : action.payload.total,
            isLoading: false,
          };
        case types.GET_ALL_ITEM_SALE_REPORT_FAILURE:
          return {
           ...state,
            itemSaleReportData: [],
            totalCount: 0,
            isLoading: false,
          };
       
        default:
          return state;
    }
}

export default itemSaleReportReducer