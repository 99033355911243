import ActionTypes from "redux/actions/types";
import types from "redux/actionTypes/user";
import { IUserState, UserActions } from "../types/user";
import { IUser, IFeeplanUsers } from "redux/types/user";

const initialState: IUserState = {
  token: localStorage.getItem("user__token"),
  loading: true,
  isAuthenticated: null,
  user: {} as IUser,
  users: [] as IUser[],
  permissions: [],
  roleId: {},
  usersdetails: {} as IUser,
  searchusersdetails: [] as IUser[],
  isTempororyPassword: false,
  branchId: "",
  username: "",
  _id: "",
  email: "",
  student: {} as IUser,
  staffdetails: [] as IUser[],
  staffTotal: 0,
  organization: [],
  feeplanstudents: {} as IFeeplanUsers,
  feetypestudents: {loading: true, data: []},
  studentJourneyLoading : false,
  studentJourney : [],
  dashlets : [],
};

const userReducer = (state = initialState, action: UserActions): IUserState => {
  switch (action.type) {
    case types.USER_LOADED:
      if (action.payload.branchId) localStorage.setItem("branchId", action.payload.branchId);
      if (action.payload.orgId) localStorage.setItem("orgId", action.payload.orgId);
      if (action.payload?.roleId?._id) localStorage.setItem("roleId", action.payload?.roleId?._id);
      localStorage.setItem('use_role_level', localStorage.guestRoleId != undefined ? 'branch' : action?.payload?.roleId?.roleLevel)

      // if (action.payload.academicBatchId) localStorage.setItem("academicBatchId", action.payload.academicBatchId);
      // if (action.payload.branchAcademicBatchId) localStorage.setItem("branchAcademicBatchId", action.payload.branchAcademicBatchId);
      if (action.payload?.organization[0]?.s3_uniqId) localStorage.setItem("s3_uniqId", action.payload?.organization[0]?.s3_uniqId);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload.user,
        username: action.payload.username,
        permissions: action.payload.permissions,
        roleId: action.payload.roleId,
        branchId: action.payload.branchId,
        email: action.payload.email,
        isTempororyPassword: action.payload.isTempororyPassword,
        _id: action.payload._id,
        organization: action.payload.organization,
        dashlets: action.payload.dashlets,
      };
      case types.USER_PERMISSIONS_LOADED:
        return {
          ...state,
          permissions: action.payload.permissions,
          roleId: action.payload.roleId,
          dashlets: action.payload.dashlets,
        };
      
    case types.USER_CREATION_SUCCESS:
      return {
        ...state,
        loading: false,
        // usersdetails: [...state.usersdetails, action.payload] as IUser[],
      };
    case types.USER_REGISTER_SUCCESS:
    case types.USER_LOGIN_SUCCESS:
      if (action.payload?.user?.academicBatchId) localStorage.setItem("academicBatchId", action.payload?.user?.academicBatchId);
      if (action.payload?.user?.branchAcademicBatchId) localStorage.setItem("branchAcademicBatchId", action.payload?.user?.branchAcademicBatchId);
      if (action.payload?.user?._id) localStorage.setItem("userId", action.payload?.user?._id);

      localStorage.setItem("user__token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case types.GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case types.GET_STUDENT:
      return {
        ...state,
        student: action.payload,
      };
    case types.GET_STUDENT_JOURNEY_REQUEST:
        return {
          ...state,
          studentJourneyLoading: true,
        };
    case types.GET_STUDENT_JOURNEY:
        return {
            ...state,
            studentJourneyLoading: false,
            studentJourney : action.payload
          };
    case types.GET_STUDENT_JOURNEY_FAILURE:
        return {
          ...state,
          studentJourneyLoading: false,
        };
    case types.GET_USERDETAILS:
      return {
        ...state,
        loading: false,
        usersdetails: action.payload,
      };
    case types.SEARCH_USERDETAILS:
      return {
        ...state,
        loading: false,
        searchusersdetails: action.payload,
      };
    case types.GETFEEPLAN_STUDENTS:
      return {
        ...state,
        loading: false,
        feeplanstudents: action.payload,
      };
      case types.GETFEETYPE_STUDENTS:
      return {
        ...state,
        loading: false,
        feetypestudents: {loading:false, data: action.payload},
      };
    case types.GET_STAFFDETAILS:
    case types.FILTER_STAFFDETAILS:
      return {
        ...state,
        loading: false,
        staffdetails: action.payload.data,
        staffTotal: action.payload.totalCount,
      };
    case types.DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };

    case types.UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload.id ? { ...action.payload.user } : user
        ),
      };
    case types.UPDATE_STUDENTS_BULK:
      return {
        ...state,
      };
    case types.BULK_STUDENT_PASSWORD_GENERATION:
      return {
        ...state,
      };
    case types.BULK_USER_PASSWORD_GENERATION:
      return {
        ...state,
      };
    case types.UPDATE_STAFFDETAILS:
      return {
        ...state,
        staffdetails: state.staffdetails.map((e: any) =>
          e._id === action.payload.id ? { ...action.payload.staffdetail } : e
        ),
      };

    case types.USER_LOGIN_FAIL:
    case types.USER_AUTH_ERROR:
    case types.USER_LOGOUT:
      localStorage.removeItem("user__token");
      localStorage.clear();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: {} as IUser,
      };
    case types.USER_REGISTER_FAIL:
      return state;
    case ActionTypes.CHANGE_PASSWORD:
      return {...state, user : action.payload,
        isTempororyPassword: action.payload?.isTempororyPassword};
    default:
      return state;
  }
};

export default userReducer;
