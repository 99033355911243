import types from "redux/actions/types";
import { IGALLERY, ISchoolGalleryState, SchoolGalleryActions } from "redux/types/schoolGallery";

const initialState:ISchoolGalleryState={
    galleryLoading:true,
    categoryLoading:true,
    schoolGallery : [] as any,
    galleryCategories : [] as any,
}

const schoolGalleryReducer = (state = initialState,action:SchoolGalleryActions):ISchoolGalleryState  =>{
    switch (action.type){
        case types.GET_SCHOOL_GALLERY:
            return {
                ...state,
                galleryLoading:false,
                schoolGallery:action.payload
            }
        case types.GET_SCHOOL_GALLERY_FAILURE:
            return {
                ...state,
                galleryLoading: false,
            }
        case types.GET_SCHOOL_GALLERY_CATEGORIES:
            return {
                ...state,
                categoryLoading:false,
                galleryCategories:action.payload
            }
        case types.GET_SCHOOL_GALLERY_CATEGORIES_FAILURE:
            return {
                ...state,
                categoryLoading: false,
            }
        case types.CREATE_SCHOOL_GALLERY:
        case types.CREATE_SCHOOL_GALLERY_FAILURE:
        default:
            return state
    }
}

export default schoolGalleryReducer;