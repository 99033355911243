import { AllMarksApprovalActions } from "redux/types/marksApproval";
import ActionTypes from "../actions/types";

const initialState = {
  loading: false,
  error: "",
  marksApprovalData: [],
  studentsData: [],
  studentsDataLoading: false,
  studentDataError: "",
  totalCount: 0,
};

const marksApprovalReducer = (
  state = initialState,
  action: AllMarksApprovalActions
) => {
  switch (action.type) {
    case ActionTypes.GET_MARKS_APPROVAL_MARKS_REQUEST:
      return { ...state, totalCount: 0, loading: true, error: "" };

    case ActionTypes.GET_MARKS_APPROVAL_MARKS_SUCCESS:
      return {
        ...state,
        marksApprovalData: action?.payload?.data,
        totalCount: action?.payload?.totalCount,
        loading: false,
      };

    case ActionTypes.GET_MARKS_APPROVAL_MARKS_FAILURE:
      return { ...initialState, totalCount: 0, error: action.payload };

    case ActionTypes.GET_MARKS_APPROVAL_STUDENT_MARKS_REQUEST:
      return { ...state, studentsDataLoading: true, studentDataError: "" };

    case ActionTypes.GET_MARKS_APPROVAL_STUDENT_MARKS_SUCCESS:
      return {
        ...state,
        studentsData: action?.payload?.data,
        studentsDataLoading: false,
      };

    case ActionTypes.GET_MARKS_APPROVAL_STUDENT_MARKS_FAILURE:
      return {
        ...state,
        studentsDataLoading: false,
        studentDataError: action.payload,
      };

    default:
      return state;
  }
};

export default marksApprovalReducer;
