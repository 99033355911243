import React from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Ipblacklist from "./SSettings/Ipblacklist";
import Ipwhitelist from "./SSettings/Ipwhitelist";
import Macenabled from "./SSettings/Macenabled";
import Countryblacklist from "./SSettings/Countryblacklist";
import StudentparentAuthentication from "./SSettings/StudentparentAuthentication";
import AdminstafAuthentication from "./SSettings/AdminstafAuthentication";






const SecuritySettings: React.FC = (): JSX.Element => {
  return (
    <div className="c-container content-tabs mt-5">
      <Tabs defaultActiveKey="IP Whitelist" id="uncontrolled-tab-example">
        <Tab eventKey="IP Whitelist" title="IP Whitelist">
        <Ipwhitelist/>
        </Tab>

        <Tab eventKey="IP Blacklist" title="IP Blacklist">
        <Ipblacklist/>
        </Tab>

        <Tab eventKey="MAC Enabled" title="MAC Enabled">
            <Macenabled/>
        </Tab>
        <Tab eventKey="Country Blacklist" title="Country Blacklist">
          <Countryblacklist/>
        </Tab>

        <Tab eventKey="Student / Parent Two-Step Authentication" title="Student / Parent Two-Step Authentication">
          <StudentparentAuthentication/>
        </Tab>
        <Tab eventKey="Admin / Staff Two-Step Authentication" title="Admin / Staff Two-Step Authentication">
        <AdminstafAuthentication/>
        </Tab>



      </Tabs>
    </div>
  )
}
export default React.memo(SecuritySettings);
