import React from 'react';
import { Placeholder } from 'react-bootstrap';

interface Props {
    noOfCopies?: number;
    hasBtn ?: boolean;
}

const TablePlaceholder : React.FC<Props> = ({noOfCopies, hasBtn}) => {
    const copies = (noOfCopies || 10);
    const newArr =  [...Array(copies)];
    const placeholders = newArr.map((a,i) => (
                <Placeholder key={i} as="p" animation="glow" className='px-2'>
                    <Placeholder lg={2} xs={1} className="me-md-2"/> <Placeholder lg={2} xs={2} className="me-md-2"/> <Placeholder lg={2} xs={2} className="me-md-2"/> <Placeholder lg={2} xs={2} className="me-md-2"/> <Placeholder lg={2} xs={2} className="me-md-3" /> {hasBtn ?<Placeholder.Button xs={3} lg={1} size="xs" style={{width: "5%"}} className="ms-5 theme-tabular-action-btn" /> : null}
                </Placeholder>))
    return (<>
        <Placeholder as="p" animation="glow" className='px-2'>
            <Placeholder lg={2} xs={1} className="me-md-2"/> <Placeholder lg={2} xs={2} className="me-md-2"/> <Placeholder lg={2} xs={2} className="me-md-2"/> <Placeholder lg={2} xs={2} className="me-md-2"/> <Placeholder lg={2} xs={2} className="me-md-3" /> {hasBtn ? <Placeholder lg={2} xs={2} className="ms-5" style={{width: "5%"}}/> : null}
            {/* <Placeholder xs={12} size="lg" style={{backgroundColor : "#FFF" }} /> */}
        </Placeholder>
        {placeholders}
    </>)
}

export default TablePlaceholder;