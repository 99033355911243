import ActionTypes from "redux/actions/types";

const initialState = {
    approvalSettings: [],
    approvalSetting: {},
    totalCount: 0,
    approvals: [],
    approvalTotalCount:0
}

export const ApprovalSettings = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_APPROVAL_SETTINGS:
            return { ...state, approvalSettings: [...action.payload.data], totalCount: action.payload.totalCount }
        case ActionTypes.GET_APPROVALS:
            return { ...state, approvals: [...action.payload.data], approvalTotalCount: action.payload.totalCount }
        case ActionTypes.UPDATE_APPROVAL_SETTINGS:
            return { ...state, approvalSetting: { ...action.payload } }
        default:
            return state;
    }
}