import types from "redux/actions/types";
import { IMasterclassState, MasterclassActions } from "../types/masterclass";
import { IMasterclass } from "redux/types/masterclass";

const initialState: IMasterclassState = {
    loading: true,
    masterclass: {} as IMasterclass,
    masterclasses: [] as IMasterclass[],
};

const masterClassReducer = (
    state = initialState,
    action: MasterclassActions
): IMasterclassState => {
    switch (action.type) {
        case types.MASTERCLASS_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                masterclasses: [...state.masterclasses, action.payload] as IMasterclass[],
            };
        case types.GET_MASTERCLASSES:
            return {
                ...state,
                loading: false,
                masterclasses: action.payload as IMasterclass[],
            };

        case types.DELETE_MASTERCLASS:
            return {
                ...state,
                masterclasses: state.masterclasses.filter(
                    (masterclass) => masterclass._id !== action.payload
                ),
            };

        case types.UPDATE_MASTERCLASS:
            return {
                ...state,
                masterclasses: state.masterclasses.map((masterclass) =>
                    masterclass._id === action.payload.id
                        ? { ...action.payload.masterclass }
                        : masterclass
                ),
            };
        case types.MASTERCLASS_REGISTER_FAIL:
        case types.MASTERCLASS_AUTH_ERROR:

        default:
            return state;
    }
};

export default masterClassReducer;
