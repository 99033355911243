import types from "redux/actions/types";
import { IAdmissionsettingState, AdmissionsettingActions } from "../types/admissionsettings";
import { IAdmissionsettings } from "redux/types/admissionsettings";

const initialState: IAdmissionsettingState = {
  loading: true,
  admissionsetting: {} as IAdmissionsettings,
  admissionsettings: [] as IAdmissionsettings[],
};

const admissionSettingReducer = (state = initialState, action: AdmissionsettingActions) => {
  switch (action.type) {
    case types.ADMISSIONSETTING_REGISTER_SUCCESS:
    case types.ADMISSIONSETTING_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // admissionsettings: [...state.admissionsettings, action.payload] as IAdmissionsettings[],
      };
    case types.GET_ADMISSIONSETTINGS:
      return {
        ...state,
        loading: false,
        admissionsettings: action.payload as IAdmissionsettings[],
      };

    case types.DELETE_ADMISSIONSETTING:
      return {
        ...state,
        admissionsettings: state.admissionsettings.filter(
          (admissionsetting) => admissionsetting._id !== action.payload
        ),
      };

    case types.UPDATE_ADMISSIONSETTING:
      return {
        ...state,
        // admissionsettings: state.admissionsettings.map((admissionsetting) =>
        // admissionsetting._id === action.payload._id
        //     ? { ...action.payload.admissionsetting }
        //     : admissionsetting
        // ),
      };
    case types.ADMISSIONSETTING_REGISTER_FAIL:
    case types.ADMISSIONSETTING_AUTH_ERROR:

    default:
      return state;
  }
};
export default admissionSettingReducer;
