import types from "redux/actions/types";
import { IInfrastructurmanagementState, InfrastructurmanagementActions } from "../types/Infrastructurmanagement";
import { IInfrastructurmanagement } from "redux/types/Infrastructurmanagement";

const initialState: IInfrastructurmanagementState = {
  loading: true,
  Infrastructurmanagement: {} as IInfrastructurmanagement,
  Infrastructurmanagements: [] as IInfrastructurmanagement[],
  floorRooms:[]as any[],
  totalCount:0

};

const InfrastructurmanagementReducer= (state = initialState, action: InfrastructurmanagementActions) => {
  switch (action.type) {
    case types.INFRASTRUCTUREMANAGEMENT_REGISTER_SUCCESS:
    case types.INFRASTRUCTUREMANAGEMENT_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        Infrastructurmanagements: [...state.Infrastructurmanagements, action.payload] as IInfrastructurmanagement[],
      };
    case types.GET_INFRASTRUCTUREMANAGEMENTS:
      return {
        ...state,
        loading: false,
        Infrastructurmanagements: action?.payload as IInfrastructurmanagement,
      };
      case types.GET_FLOORROOMS:
        return {
          ...state,
          loading: false,
          floorRooms: action?.payload as any,
        };
    case types.DELETE_INFRASTRUCTUREMANAGEMENT:
      return {
        ...state,
        Infrastructurmanagements: state.Infrastructurmanagements.filter(
          (Infrastructurmanagement) => Infrastructurmanagement._id !== action.payload
        ),
      };

    case types.UPDATE_INFRASTRUCTUREMANAGEMENT:
      return {
        ...state,
        Infrastructurmanagements: state.Infrastructurmanagements.map((Infrastructurmanagement) =>
        Infrastructurmanagement._id === action.payload.id
            ? { ...action.payload.Infrastructurmanagement }
            : Infrastructurmanagement
        ),
      };
    case types.INFRASTRUCTUREMANAGEMENT_REGISTER_FAIL:
    case types.INFRASTRUCTUREMANAGEMENT_AUTH_ERROR:

    default:
      return state;
  }
};
export default InfrastructurmanagementReducer;
