import ActionTypes from "../actions/types";
import {AllCategoryActions } from "../types/inventoryCategory"; 

const initialState = {
  loading: false,
  error: '',
  totalCount: 0,
  category: [], 
};

const category = (state = initialState, action: AllCategoryActions) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_CATEGORY_REQUEST:
    case ActionTypes.CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ActionTypes.GET_ALL_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload.data, 
        totalCount: action.payload.totalCount,
        error: '',
      };
    case ActionTypes.GET_ALL_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        category: [], 
        totalCount: 0,
        error: action.payload,
      };
    case ActionTypes.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case ActionTypes.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.GET_CATEGORY_BY_ID:
      return {
        ...state,
        loading: false,
        category: [action.payload], 
      };
    case ActionTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: state.category.map((Category) =>
          Category._id === action.payload.id
            ? { ...action.payload.category }
            : Category
        ),
      };
    case ActionTypes.DELETE_CATEGORY:
      return {
        ...state,
        category: state.category.filter(
          (Category) => Category._id !== action
        ),
      };
    default:
      return state;
  }
};

export default category;