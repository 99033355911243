import ActionTypes from "../actions/types";
import { AllDynamicFormActions } from "../types/dynamicForm"; 

const initialState = {
  loading: false,
  error: '',
  dynamicForm: [], 
  formData: {}
};

const dynamicForm = (state = initialState, action: AllDynamicFormActions) => {
  switch (action.type) {
    case ActionTypes.GET_DYNAMIC_FORM_REQUEST:
    case ActionTypes.GET_DYNAMIC_FORM_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ActionTypes.GET_DYNAMIC_FORM_SUCCESS:
      //console.log(action.payload)
      return {
        ...state,
        loading: false,
        dynamicForm: action.payload.data, 
        error: '',
      };
    
    case ActionTypes.GET_DYNAMIC_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.GET_DYNAMIC_FORM_DATA_SUCCESS:
      //console.log(action.payload)
      return {
        ...state,
        loading: false,
        formData: action.payload.data, 
        error: '',
      };
    
    case ActionTypes.GET_DYNAMIC_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
        return state;
    }
};

export default dynamicForm;
