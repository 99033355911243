import { FilterdStudents } from "./feeDue";
import { FilterdStaff } from "./feeDueStaff";
import { Departments } from "./department";
import { Designation } from "./designation";
import { Favorates } from "./favorates";
import { FeeInstalments } from "./feeInstallment";
import { ApprovalSettings } from "./approvalSettings";
import { DltTemplate } from "./dltTemplate";
import { studentEnrollmentSequence } from "./enrollmentStudentSequence";
import { moduleReducer } from "./moduleReducer";
import timeLine from "./timeLine";
import { attendence } from "./attendence";
import { TimeTableReducer } from "./timeTable";
import { financeDashboard } from "./financeDashboard";
import { mainDashboard } from "./mainDashboard";
import hrDashboard from "./hrDashboard";
import { combineReducers } from "redux";
import alert from "./alert";
import user from "./user";
import role from "./role";
import branch from "./branch";
import createuser from "./createuser";
import branchmodule from "./branchmodule";
import classcategory from "./classcategory";
import boardname from "./boardname";
import masterclass from "./masterclass";
import orgclass from "./orgclass";
import branchclass from "./branchclass";
import section from "./section";
import subject from "./subject";
import branchsubject from "./branchsubject";
import mastersubject from "./mastersubject";
import subjecttype from "./subjecttypes";
import academicbatch from "./academicbatch";
import branchacademicbatch from "./branchacademicbatch";
import academic_class from "./academic_class";
import academicSection from "./academicSection";
import Organizationfeecategory from "./organizationfeecategory";
import MasterFeeCategory from "./MasterFeeCategory";
import feetype from "./feetype";
import feeplan from "./feeplan";
import bankaccount from "./bankaccount";
import cashflowaccount from "./cashflowaccount";
import branchcashflowaccount from "./branchcashflowaccount";
import feestructure from "./feestructure";
import enquiry from "./enquiry";
import enquirystage from "./enquirystage";
import admissionsetting from "./admissionsetting";
import admissiontype from "./admissiontype";
import applicant from "./applicant";
import applicationsettings from "./applicationsettings";
import headerstate from "./headerstate";
import dairy from "./dairy";
import studentfee from "./studentfee";
import concessiontype from "./concessiontype";
import concessionrule from "./concessionrule";
import concessionapprovalrule from "./concessionapprovalrule";
import branchpaymentmethod from "./branchpaymentmethod";
import orgpaymentmethod from "./orgpaymentmethods";
import logo from "./logosettings";
import organization from "./organization";
import feereports from "./feereports";
import classWiseDueReport from "./classWiseDueReport";
import finesetting from "./finesetting";
import profile from "./profile";
import { workingDays } from "./workingDays";
import { EventList } from "./event";
import { feeReciptSequences } from "./feeReciptSequence";
import CertificateSequenceNumber from "./certificateSequenceNumber";
import { staffEnrollmentSequence } from "./enrollmentStaffSequence";
import { resetPassword } from "./resetPassword";
import { TemplateSettings } from "./templateSettings";
import { StaffOnboarding } from "./enrollStaff";
import organizationLogo from "./organizationLogo";
import searchStudentsFiltered from "./searchedStudentsFilter";
import schoolBoards from "./schoolBoards";
import certificates from "./certificates";
import orgSources from "./orgSources";
import enquiryFollowups from "./enquiryfollowup";
import shuffleStudents from "./shuffleStudents";
import enrollStudent from "./enrollStudent";
import studentbulkupload from "./studentbulkupload";
import admissionReport from "./admissionReport";
import notificationsReducer from "./notifications";
import assignTeacher from "./assignTeacher";
import schoolGallery from "./schoolGallery";
import activestudent from "./activestudents";
import feeReceiptModification from './feeReceiptModification'
import credentials from './credentials'
import staffbulkuploads from "./staffbulkupload";
import staffshift from "./staffShifts";
import weeklyOff from "./weeklyOff";
import staffWorkload from "./staffWorkLoad";
import leaveType from "./leaveTypes";
import formFields from './formFields';
import templates from './templates';
import leavePlan from "./leavePlan";
import leaveApprovalMapping from "./leaveApprovalMapping";
import applystaffLeave from "./applystaffLeave";
import leaveCalculations from "./leaveCalculations";
import financebulkupload from "./financebulkupload";
import vehicledetails from "./vehicle_details";
import { DairyApprovals } from "./dairyApprovals";

import { staffTimeTable } from "./staffTimeTable";
import  staffAttendance  from "./staffAttendance";
import  staffAttendanceAllDate  from "./staffAttendanceAllDate";
import announcement from "./announcement";
import announcementApprovalSettings from "./announcementApprovalSettings";
import BranchLevelSettings from "./branchLevelSettings";
import scheduleJobs from "./scheduleJobs";
import smsRecharge  from "./smsRecharge";
import smsDeliveryReport  from "./smsDeliveryReport";

import AttendanceSettings from "./attendanceSettings";
import busroute from "./busroute";
import busstop from "./busstop";
import vehicleroute from "./vehicleroute";
import assignRoutetoStudent from "./assignRoutetoStudent";
import leavereportReducer from "./LeaveReport";
import expenseCategory from "./expenseCategories"
import allExpenses from "./allExpenses";
import expenseTransactionsReducer from "./expenseTransactions"
import expenseSequenceNumber from "./expenseSequence"


import ledgerBook from "./expenseLedgerBook"
import examStructure from "./exam_structure";
import examSchedule from "./examSchedule";
import examRuleSetUp from "./exam_rulesetup";
import scholasticMarks from "./scholasticMarksAllocation";
import staffSessionSettingsReducer from "./staffSessionSettings";
import staffHolidays from "./staffHolidays";
import manualStaffAttendanceReducer from "./manualStaffAttendance";
import AssessmentReducer from "./Assessments";
import SubskillsReducer from "./SubSkills";
import gradeGroupScholasticReducer from "./gradeGroupScholastic"
import gradeGroupCoScholasticReducer from "./gradeGroupCoScholastic"
import Infrastructurmanagement from "./Infrastructurmanagement";
import RoomAllocationgeneral from "./roomAllocationgeneral";
import Roomallocationexams from "./roomallocationexams";

import CoscholsticReducer from "./Coscholstic";
import coScholasticMarksAllocationReducer from "./coScholasticMarksAllocation";
import reportCardReducer from "./reportCard";
import studentsMarksReducer from "./studentsMarks";
import reportCardTemplate from "./reportCardTemplate";
import marksApprovalReducer from "./marksApproval";
import studentWiseDueReportReducer from "./studentWiseDueReport";

import dynamicForm from "./dynamicForm";

import inventoryCategory from "./inventoryCategory";
import inventoryUnit from "./inventoryUnit";
import inventorySubCategory from "./inventorySubCategory";

import vendorReducer from "./inventoryVendor";
import itemCreationReducer from "./itemCreation";
import inventoryKit from "./inventorykit";
import inventoryStore from "./inventoryStore";
import inventoryStock from "./inventoryStock";
import serviceCreationReducer from "./inventoryServiceCreation"
import stockItemReceiptReducer from "./inventoryStockItemReceipt";
import certificateApprovals from "./certificateApprovals";
import hrDashboardReducer from "./hrDashboard";

import vehicleDriver from "./vehicleDriver";
import vehicleRouteFee from "./vehicleRouteFee"
import itemSaleReportReducer from "./inventoryItemSaleReport";
import inventoryApprovalReducer from "./inventoryApproval";
import inventoryReceiptSequence from "./inventoryReceiptSequence";

import dashlets from "./dashlets";
import initialSetupReducer from "./initialSetup";


const rootReducer = combineReducers({
  alert,
  user,
  role,
  createuser,
  branch,
  branchmodule,
  classcategory,
  boardname,
  masterclass,
  orgclass,
  branchclass,
  section,
  subject,
  branchsubject,
  mastersubject,
  subjecttype,
  academicbatch,
  branchacademicbatch,
  academic_class,
  academicSection,
  Organizationfeecategory,
  MasterFeeCategory,
  feetype,
  feeplan,
  bankaccount,
  cashflowaccount,
  branchcashflowaccount,
  feestructure,
  enquiry,
  enquirystage,
  admissionsetting,
  admissiontype,
  applicant,
  applicationsettings,
  dairy,
  headerstate,
  studentfee,
  concessiontype,
  concessionrule,
  concessionapprovalrule,
  branchpaymentmethod,
  orgpaymentmethod,
  logo,
  organization,
  feereports,
  classWiseDueReport,
  finesetting,
  profile,
  mainDashboard,
  financeDashboard,
  workingDays,
  EventList,
  TimeTableReducer,
  attendence,
  timeLine,
  moduleReducer,
  feeReciptSequences,
  CertificateSequenceNumber,
  staffEnrollmentSequence,
  studentEnrollmentSequence,
  resetPassword,
  DltTemplate,
  TemplateSettings,
  ApprovalSettings,
  DairyApprovals,
  FeeInstalments,
  Favorates,
  Departments,
  Designation,
  StaffOnboarding,
  FilterdStudents,
  FilterdStaff,
  organizationLogo,
  searchStudentsFiltered,
  schoolBoards,
  certificates,
  orgSources,
  enquiryFollowups,
  shuffleStudents,
  enrollStudent,
  studentbulkupload,
  staffbulkuploads,
  financebulkupload,
  admissionReport,
  hrDashboard,
  notificationsReducer,
  assignTeacher,
  schoolGallery,
  activestudent,
  feeReceiptModification,
  credentials,
  staffshift,
  weeklyOff,
  staffWorkload,
  leaveType,
  formFields,
  templates,
  leavePlan,
  leaveApprovalMapping,
  applystaffLeave,
  leaveCalculations,
  staffTimeTable,
  announcement,
  announcementApprovalSettings,
  vehicledetails,
  BranchLevelSettings,
  staffAttendance,
  staffAttendanceAllDate,
  scheduleJobs,
  smsRecharge,
  AttendanceSettings,
  busroute,
  busstop,
  vehicleroute,
  assignRoutetoStudent,
  leavereportReducer,
  smsDeliveryReport,
  expenseCategory,
  allExpenses,
  expenseSequenceNumber,
  expenseTransactionsReducer,
  ledgerBook,
  staffSessionSettingsReducer,
  staffHolidays,
  manualStaffAttendanceReducer,
  AssessmentReducer,
  examStructure,
  examSchedule,
  examRuleSetUp,
  scholasticMarks,
  SubskillsReducer,
  Infrastructurmanagement,
  RoomAllocationgeneral,
  gradeGroupScholasticReducer,
  gradeGroupCoScholasticReducer,
  CoscholsticReducer,
  coScholasticMarksAllocationReducer,
  reportCardReducer,
  studentsMarksReducer,
  reportCardTemplate,
  marksApprovalReducer,
  Roomallocationexams,
  studentWiseDueReportReducer,
  dynamicForm,
  inventoryCategory,
  inventoryUnit,
  inventorySubCategory,
  vendorReducer,
  itemCreationReducer,
  inventoryKit,
  inventoryStore,
  inventoryStock,
  serviceCreationReducer,
  stockItemReceiptReducer,
  certificateApprovals,
  hrDashboardReducer,
  vehicleDriver, 
  vehicleRouteFee,
  itemSaleReportReducer,
  inventoryApprovalReducer,
  inventoryReceiptSequence,
  dashlets,
  initialSetupReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
