import ActionTypes from "redux/actions/types";

const initialState={
    modules:[]
}
export const moduleReducer=(state=initialState,action:any)=>{
    switch (action.type) {
        case ActionTypes.GET_MODULES:
            return{
                ...state,modules:[...action?.payload]
            };
    
        default:
            return state
    }
}