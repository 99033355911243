import types from "redux/actions/types";
import { IRoomSelectionexamsState, RoomSelectionexamsActions } from "../types/roomallocationExams";
import { IRoomSelectionexams } from "redux/types/roomallocationExams";

const initialState: IRoomSelectionexamsState = {
  loading: true,
  roomexam: {} as IRoomSelectionexams,
  roomexams: [] as IRoomSelectionexams[],
  totalCount:0

};

const roomSelectionexamsReducer= (state = initialState, action: RoomSelectionexamsActions) => {
  switch (action.type) {
    case types.ROOMSELECTIONEXAMS_REGISTER_SUCCESS:
    case types.ROOMSELECTIONEXAMS_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        roomexams: [...state.roomexams, action.payload] as IRoomSelectionexams[],
      };
    case types.GET_ROOMSELECTIONEXAMS:
      return {
        ...state,
        loading: false,
        roomexams: action?.payload as IRoomSelectionexams,
      };

    case types.DELETE_ROOMSELECTIONEXAMS:
      return {
        ...state,
        roomexams: state.roomexams.filter(
          (roomexam) => roomexam._id !== action.payload
        ),
      };

    case types.UPDATE_ROOMSELECTIONEXAMS:
      return {
        ...state,
        roomexams: state.roomexams.map((roomexam) =>
        roomexam._id === action.payload.id
            ? { ...action.payload.roomexams }
            : roomexam
        ),
      };
    case types.ROOMSELECTIONEXAMS_REGISTER_FAIL:
    case types.ROOMSELECTIONEXAMS_AUTH_ERROR:

    default:
      return state;
  }
};
export default roomSelectionexamsReducer;
