import types from "redux/actions/types";
import { IClassCategoryState, ClassCategoryActions } from "../types/classcategory";
import { IClassCategory } from "redux/types/classcategory";

const initialState: IClassCategoryState = {
  loading: true,
  classcategory: {} as IClassCategory,
  classcategorys: {} as IClassCategory,
  totalCount:0

};

const classcategoryReducer = (state = initialState, action: ClassCategoryActions) => {
  switch (action.type) {
    case types.CLASSCATOGORY_REGISTER_SUCCESS:
    case types.CLASSCATOGORY_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // classcategorys: [...state.classcategorys, action.payload] as IClassCategory[],
      };
    case types.GET_CLASSCATOGORYS:
      return {
        ...state,
        loading: false,
        classcategorys: action?.payload?.data as IClassCategory [],
        totalCount:action?.payload?.totalCount  
      };

    case types.DELETE_CLASSCATOGORY:
      return {
        ...state,
        classcategorys: state.classcategorys?.data.filter(
          (classcategory:any) => classcategory._id !== action.payload
        ),
      };

    case types.UPDATE_CLASSCATOGORY:
      return {
        ...state,
        classcategorys: state?.classcategorys?.data.map((classcategory:any) =>
        classcategory._id === action.payload.id
            ? { ...action.payload.classcategory }
            : classcategory
        ),
      };
    case types.CLASSCATOGORY_REGISTER_FAIL:
    case types.CLASSCATOGORY_AUTH_ERROR:

    default:
      return state;
  }
};
export default classcategoryReducer;
