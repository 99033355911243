import types from "redux/actions/types";
import { IBusStopState, BusStopActions } from "../types/busstop";
import { IBusstop } from "redux/types/busstop";

const initialState: IBusStopState = {
  loading: true,
  busstop: {} as IBusstop,
  busstops: [] as IBusstop[],
  totalCount:0

};

const busStopReducer= (state = initialState, action: BusStopActions) => {
  switch (action.type) {
    case types.BUSSTOP_REGISTER_SUCCESS:
    case types.BUSSTOP_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        IBusroute: [...state.busstops, action.payload] as IBusstop[],
      };
    case types.GET_BUSSTOPS:
      return {
        ...state,
        loading: false,
        busstops: action?.payload as IBusstop,
      };

    case types.DELETE_BUSSTOP:
      return {
        ...state,
        busstops: state.busstops.filter(
          (busstop) => busstop._id !== action.payload
        ),
      };

    case types.UPDATE_BUSSTOP:
      return {
        ...state,
        busstops: state.busstops.map((busstop) =>
        busstop._id === action.payload.id
            ? { ...action.payload.busstop }
            : busstop
        ),
      };
    case types.BUSSTOP_REGISTER_FAIL:
    case types.BUSSTOP_AUTH_ERROR:

    default:
      return state;
  }
};
export default busStopReducer;
