
import { Button, Card, Col, FloatingLabel, Form, ListGroup, Modal, Row, Tab, Table  } from "react-bootstrap";
import React, { useState } from "react";

const ReasonforInactivateStudents: React.FC = (): JSX.Element => {
    const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
 <>

    <div className="c-container content-tabs mt-5">
             <div className="section-bgwhite1">

             <div className="d-flex justify-content-end mb-3">             
              <Button variant="outline-primary outline-cbtn" onClick={handleShow}>Add Reason</Button>
            </div>


             <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Reason</th>  
              <th>Status</th> 
              <th>Create Date</th> 
              <th>Class Name</th> 
              <th>Section</th>                    
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>               
              <td><a href="#"> <img src="images/edit-icon.svg" /> </a></td>
            </tr>
          </tbody>
        </Table>
      </Card>

            </div>

    </div>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0">
          <Modal.Title>Add Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="section-small">
        <FloatingLabel controlId="floatingTextarea" label="Comments">
            <Form.Control as="textarea" placeholder="Reason" />
        </FloatingLabel>
            </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button className="btn-modal me-3" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn-modal">
          Add Reason
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
export default React.memo(ReasonforInactivateStudents);
