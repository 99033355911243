import types from "redux/actions/types";
import { IFeereportState, FeereportActions } from "../types/feereports";
import { IFeedaysheet } from "redux/types/feereports";

const initialState: IFeereportState = {
  loading: false,
  feedaysheet: {} as IFeedaysheet,
  feeduereport: [] as IFeedaysheet[],
};

const feeplanReducer = (state = initialState, action: FeereportActions) => {
  switch (action.type) {
  
    case types.GET_FEEDAYSHEETREPORT_REQUEST:
          return {
              ...state,
              loading : true,
          };

    case types.GET_FEEDAYSHEETREPORT:
      return {
        ...state,
        loading: false,
        feedaysheet: action.payload as IFeedaysheet,
      };
      case types.GET_FEEDUEREPORT:
        return {
          ...state,
          loading: false,
          feeduereport: action.payload as IFeedaysheet[],
        };
  
    default:
      return state;
  }
};
export default feeplanReducer;
