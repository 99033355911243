import ActionTypes from "../actions/types";
import { AllCoScholasticMarksAllocationActions } from "redux/types/coScholasticMarksAllocation";

const initialState = {
  loading: false,
  error: "",
  coScholasticStudents: [],
  totalCount: 0,
};

const coScholasticMarksAllocationReducer = (
  state = initialState,
  action: AllCoScholasticMarksAllocationActions
) => {
  switch (action.type) {
    case ActionTypes.GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_REQUEST:
      return { ...state, totalCount: 0, loading: true, error: "" };

    case ActionTypes.GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_SUCCESS:
      return {
        ...state,
        coScholasticStudents: action?.payload?.data,
        totalCount: action?.payload?.totalCount,
        loading: false,
      };

    case ActionTypes.GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_FAILURE:
      return { ...initialState, totalCount: 0, error: action.payload };

    // case ActionTypes.ASSIGN_COSCHOLASTIC_MARKS_REQUEST:
    //   return { ...state, loading: true, error: "" };

    // case ActionTypes.ASSIGN_COSCHOLASTIC_MARKS_SUCCESS:
    //   return {
    //     ...state,
    //     coScholasticStudents: action?.payload?.data,
    //     loading: false,
    //   };

    // case ActionTypes.ASSIGN_COSCHOLASTIC_MARKS_FAILURE:
    //   return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default coScholasticMarksAllocationReducer;
