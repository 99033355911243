import types from "redux/actions/types";
import { AssignTeacherActions, IAssignClassTeacher, IAssignSubjectTeacher, IAssignTeacherState } from "redux/types/assignTeacher";

const initialState: IAssignTeacherState = {
    loading: true,
    classTeacherAssignData: {} as IAssignClassTeacher,
    subjectTeacherAssignData: {} as IAssignSubjectTeacher,
};

const assignTeacherReducer = (state = initialState, action: AssignTeacherActions) => {
    switch (action.type) {
        case types.ASSIGN_CLASS_TEACHER:
            return {
                ...state,
                loading: false,
                classTeacherAssignData: action.payload,
            }
        case types.ASSIGN_CLASS_TEACHER_FAILURE:
            return {
                ...state,
                loading: false,
            }

        case types.ASSIGN_SUBJECT_TEACHER:
            return {
                ...state,
                loading: false,
                subjectTeacherAssignData: action.payload,
            }

        case types.ASSIGN_SUBJECT_TEACHER_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default : 
            return state;
    }
}

export default assignTeacherReducer;
