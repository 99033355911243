import moment from "moment";

const options:any = { year: 'numeric', month: 'short', day: '2-digit' };


export const offSetDate = (inpDate: string) => {
  const now = new Date(inpDate);

  const offset = -now.getTimezoneOffset();
  const timestampWithOffset = now.getTime() + offset * 60 * 1000;

  // Format the date in the desired timezone
  const formattedDate = new Date(timestampWithOffset).toLocaleString('en', {
    timeZone: 'UTC', // Change this to the desired timezone
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
  return formattedDate;
}


export const dateFormatter = (dateValue: string) => new Date(dateValue).toLocaleDateString("en-GB", options)
export const dateFormatterWithTime = (dateValue: string) => new Date(dateValue).toLocaleDateString("en-IN", {...options, hour: 'numeric', minute: 'numeric'})
export const formDateFormatter = (dateValue: string) => new Date(dateValue).toLocaleDateString("en-CA")

export const dateSetter = (fromDate: any, toDate ?: any) => {
    if(fromDate && toDate){
      const startDate = new Date(fromDate);
      startDate.setHours(0, 0, 0, 0);
      if(toDate){
        const endDate = new Date(toDate);
        endDate.setHours(23, 59, 59, 999);
        const localOffset = startDate.getTimezoneOffset() * 60000; // Offset in milliseconds
        const startDateLocal = new Date(startDate.getTime() - localOffset);
        const endDateLocal = new Date(endDate.getTime() - localOffset);

        return { fromDate: startDateLocal.toISOString(), toDate: endDateLocal.toISOString() };
      }
      const localOffset = startDate.getTimezoneOffset() * 60000; // Offset in milliseconds
      const startDateLocal = new Date(startDate.getTime() - localOffset);
      
      return { fromDate: startDateLocal.toISOString() };
  }
  else{
    return { fromDate: '', toDate: '' };
  }
}


export const isValidDate = (dateString) => {
  // Regular expression to match date format YYYY-MM-DD or ISO 8601
  const dateRegex = /^\d{4}-\d{2}-\d{2}([Tt](\d{2}:\d{2}:\d{2}(\.\d+)?([Zz]|([+-]\d{2}:\d{2})))?)?$/;

  // Check if the date string matches the regex
  if (!dateRegex.test(dateString)) {
    return false;
  }

  // Create a new date object
  const date = new Date(dateString);

  // Check if the date is valid
  return date instanceof Date && !isNaN(date.getTime());
};

// time in 12 hr format
export const convertTo12HourFormat = (time24HourFormat) => {
  const timeMoment = moment(new Date(time24HourFormat), 'HH:mm');
  return timeMoment.format('hh:mm A');
};

// get current time including seconds
export const getCurrentTimeWithSeconds = () => {
  const currentTimeLocal = moment().format('hh:mm:ss A');
  return currentTimeLocal;
};

export const getCurrentTime = () => {
  const currentTimeLocal = moment().format('hh:mm A');
  return currentTimeLocal;
};

export const getDateWithZeroTime = (dateString, type:  "withTime" | "withoutTime" = "withTime") => {
  const date = new Date(dateString);

  // Adjust the date to IST by adding 5 hours and 30 minutes
  date.setUTCHours(date.getUTCHours() + 5);
  date.setUTCMinutes(date.getUTCMinutes() + 30);

  // Set the time to 00:00:00
  date.setUTCHours(0, 0, 0, 0);
  if(type === "withTime"){
    return date?.toISOString();
  }else{
    return date.toISOString().split('T')[0];
  }
};

export  const calculateTimeDifference = (startTime, endTime) => {
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');

  const duration = moment.duration(end.diff(start));

  const hours = duration.hours();
  const minutes = duration.minutes();

  return `${hours ? hours + ' hours' : ''} ${minutes ? minutes + ' minutes' : ''}`;
};


export const adjustToUTC  = (dateString) => {
  let date = new Date(dateString);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  
  return date.toISOString();
};