import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";

const HeaderMain: React.FC = (): JSX.Element => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default React.memo(HeaderMain);
