import ActionTypes from "redux/actions/types";

const initialState = {
    favorates: []
}
export const Favorates = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_FAVORATES:
            return { ...state, favorates: [...action.payload] }
        default:
            return state
    }
}