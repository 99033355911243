import types from "redux/actions/types";

import { AdmissionFormFieldsActions, IFormFieldsState } from "redux/types/formFields";
const initialState: IFormFieldsState = {
    admissionFields: {data: [], error: false, loading: true, totalCount: 0},
};
const formFieldsReducer = (state = initialState, action: AdmissionFormFieldsActions) => {
    switch (action.type) {
        case types.GET_ADMISSION_FORM_FIELDS:
            return { ...state, admissionFields: {error: false,data: action.payload, loading: false, totalCount : action.payload.fields.length} }
        case types.GET_ADMISSION_FORM_FIELDS_FAILURE:
            return { ...state, admissionFields: {error: true,data: [], loading: false, totalCount : 0} }
        case types.UPDATE_ADMISSION_FORM_FIELD:
        default:
            return state
    }
}

export default formFieldsReducer;