import React from "react";
import { Button, Card, Col, Form, ListGroup, Modal, Row, Tab, Table  } from "react-bootstrap";


const EmailSettings: React.FC = (): JSX.Element => {
  return (
    <div className="c-container content-tabs mt-5">
             <div className="section-bgwhite1">
                      
             <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>Type</th>
              <th>Email ID</th>  
              <th>#</th> 
              <th>Signature Edition</th>                  
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>             
              <td><a href="#"> <img src="images/edit-icon.svg" /> </a></td>
            </tr>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>             
              <td><a href="#"> <img src="images/edit-icon.svg" /> </a></td>
            </tr>

          </tbody>
        </Table>
      </Card>

            </div>

    </div>
  )
}
export default React.memo(EmailSettings);
