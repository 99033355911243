import types from "redux/actions/types";
import { IAcademicclassState, AcademicclassActions } from "../types/academicClasses";
import { IAcademicclass } from "redux/types/academicClasses";

const initialState: IAcademicclassState = {
  loading: true,
  academicclass: {} as IAcademicclass,
  academicclasses:[],
  combinedClasses:[],
  combinedClassesByUser: []
};

const academicClassReducer = (state = initialState, action: AcademicclassActions) => {
  switch (action.type) {
    case types.ACADEMICCLASS_REGISTER_SUCCESS:
    case types.ACADEMICCLASS_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // academicclasses: [...state.academicclasses, action.payload] as IAcademicclass[],
      };
    case types.GET_ACADEMICCLASSES:
      return {
        ...state,
        loading: false,
        academicclasses: action.payload as IAcademicclass[],
      };
    case types.GET_COMBINED_CLASSESS:
      return {
        ...state,
        loading: false,
        combinedClasses: [...action.payload],
      };
    case types.GET_COMBINED_CLASSESS_BY_USER:
      return {
        ...state,
        loading: false,
        combinedClassesByUser: [...action.payload],
      };

    case types.DELETE_ACADEMICCLASS:
      return {
        ...state,
        academicclasses: state.academicclasses.filter(
          (academicclass:IAcademicclass) => academicclass._id !== action.payload
        ),
      };

    case types.UPDATE_ACADEMICCLASS:
      return {
        ...state,
        academicclasses: state.academicclasses.map((academicclass:IAcademicclass) =>
          academicclass._id === action.payload._id
            ? { ...action.payload.academicclass }
            : academicclass
        ),
      };
    case types.ACADEMICCLASS_REGISTER_FAIL:
    case types.ACADEMICCLASS_AUTH_ERROR:

    default:
      return state;
  }
};
export default academicClassReducer;
