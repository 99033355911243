import types from "redux/actions/types";
import { IMasterFeeCategoryState, MasterFeeCategoryActions } from "../types/MasterFeeCategory";
import { IMasterFeeCategory } from "redux/types/MasterFeeCategory";

const initialState: IMasterFeeCategoryState = {
    loading: true,
    masterfeecategory: {} as IMasterFeeCategory,
    masterfeecategories: [] as IMasterFeeCategory[],
};

const masterfeecategoryReducer = (
    state = initialState,
    action: MasterFeeCategoryActions
): IMasterFeeCategoryState => {
    switch (action.type) {
        case types.MASTERFEECATEGORY_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                masterfeecategories: [...state.masterfeecategories, action.payload] as IMasterFeeCategory[],
            };
        case types.GET_MASTERFEECATEGORIES:
            return {
                ...state,
                loading: false,
                masterfeecategories: action.payload as IMasterFeeCategory[],
            };

        case types.DELETE_MASTERFEECATEGORY:
            return {
                ...state,
                masterfeecategories: state.masterfeecategories.filter(
                    (masterfeecategory) => masterfeecategory._id !== action.payload
                ),
            };

        case types.UPDATE_MASTERFEECATEGORY:
            return {
                ...state,
                masterfeecategories: state.masterfeecategories.map((masterfeecategory) =>
                    masterfeecategory._id === action.payload.id
                        ? { ...action.payload.masterfeecategory }
                        : masterfeecategory
                ),
            };
        case types.MASTERFEECATEGORY_REGISTER_FAIL:
        case types.MASTERFEECATEGORY_AUTH_ERROR:

        default:
            return state;
    }
};

export default masterfeecategoryReducer;
