import types from "redux/actions/types";
import { RouteFeeActions } from "redux/types/vehicleRouteFee";


const initialState = {
  loading: true,
  routeFee: {} ,
  routeFees: [],
  totalCount: 0
};

const vehicleRouteFeeReducer = (state = initialState, action: RouteFeeActions) => {
  switch (action.type) {
    
    case types.CREATE_VEHICLE_ROUTE_FEE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_VEHICLE_ROUTE_FEE:

      return {
        ...state,
        loading: false,
        routeFees: action?.payload?.data,
        totalCount: action?.payload?.totalCount,
      };
    case types.DELETE_VEHICLE_ROUTE_FEE:
      return {
        ...state,
        routeFees: state.routeFees.filter(
          (routeFee) => routeFee._id !== action.payload
        ),
      };

    case types.UPDATE_VEHICLE_ROUTE_FEE:
      return {
        ...state,
        routeFees: state.routeFees.map((routeFee) =>
        routeFee._id === action.payload.id
            ? { ...action.payload.routeFee }
            : routeFee
        ),
      };
      
    default:
      return state;
  }
};
export default vehicleRouteFeeReducer;
