import ActionTypes from "redux/actions/types";

const initialState = {
    departments: []
}
export const Departments = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_DEPARTMENT:
            return { ...state, departments: [...action.payload] }
        default:
            return state;
    }
}

