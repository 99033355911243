import axios from 'axios';
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_NOTI,
  headers: { 'Content-Type': 'application/json' },
});
instance.interceptors.request.use(
  config => {
    config.headers['x-user-auth-token'] = `${localStorage.getItem('user__token')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)
export default instance;
// instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
