import types from "redux/actions/types";
import { IStaffWorkloadData, IStaffWorkload } from "redux/types/staffWorkload";

const initialState:IStaffWorkload = {
    loading: true,
    workLoad: {} as IStaffWorkloadData,
    workLoads: [],
    remainingWorkLoad: [],
    totalCount: 0
};

const staffWorkload = (state = initialState, action) => {
    switch (action.type) {
        case types.STAFF_WORK_LOAD:
            return {
                ...state,
                loading: false,
                workLoads: action?.payload.data,
                totalCount: action?.payload?.totalCount

            }
        case types.STAFF_REMAINING_WORK_LOAD:
            return {
                ...state,
                loading: false,
                remainingWorkLoad: action?.payload,

            }
        default:
            return state;
    }
}

export default staffWorkload;
