import types from "redux/actions/types";
import { IProfileState, ProfileActions } from "../types/profile";
import { IProfile } from "redux/types/profile";

const initialState: IProfileState = {
    loading: true,
    profile: {} as IProfile,
    profiles: [] as IProfile[],
};

const profileReducer = (
    state = initialState,
    action: ProfileActions
): IProfileState => {
    switch (action.type) {
        case types.PROFILE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // sections: [...state.sections, action.payload] as ISection[],
            };
        case types.LOGOUT_PROFILE:
            return {
                ...state,
                loading: true,
                profile: {} as IProfile
            };
        case types.GET_PROFILES:
            return {
                ...state,
                loading: false,
                profile: action.payload as IProfile,
            };

        case types.DELETE_PROFILE:
            return {
                ...state,
                profiles: state.profiles.filter(
                    (profile: any) => profile._id !== action.payload
                ),
            };

        case types.UPDATE_PROFILE:
            return {
                ...state,
                profiles: state.profiles.map((profile: any) =>
                    profile._id === action.payload.id
                        ? { ...action.payload.profile }
                        : profile
                ),
            };
        case types.PROFILE_REGISTER_FAIL:
        case types.PROFILE_AUTH_ERROR:

        default:
            return state;
    }
};

export default profileReducer;
