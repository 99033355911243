import React, { useEffect, useState } from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { getOrganizations } from "redux/actions/organization";
import { requestPassword, studentRequestPassword } from 'redux/actions/resetPassword';
import './auth_styles.css'
import { getlogo } from 'redux/actions/downloads';
import types from "redux/actions/types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IInitialValues {
    orgId: string;
    enrollmentCode: string;
    type: string;
    requestedPasswordType: string;
}
const validationSchema = Yup.object().shape({
    enrollmentCode:Yup.string().required('Enrollment Code is required'),
    
    requestedPasswordType:Yup.string().required('New Password Category Must be Provided ')
});

function ResetPasswordRequest() {
    const { register, handleSubmit, watch, reset, setValue, getValues, setError, formState: { errors }, formState } = useForm<IInitialValues>({
        resolver: yupResolver(validationSchema)
    });
    const [bgImage, setBgImage] = useState("");
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [passwordShown, setPasswordShown] = useState<boolean>(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState<boolean>(false);
    const { isPasswordReset } = useSelector((state: any) => state?.resetPassword)
    const {loading: orgLoading,organization} = useSelector((state:RootState)=>state.organization)
    const { logo, id } = useSelector((state: RootState) => state.organizationLogo)
    const { validatedDomain } = useSelector((state:RootState)=>state.organization);
    const navigate = useNavigate()
    
    const colors = ['#fff', '#fff', '#fff'];
    const [currentColorIndex, setCurrentColorIndex] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange: (current, next) => setCurrentColorIndex(next),
      };

    const onHandleSubmit = (values: IInitialValues) => {
        
        values.orgId = organization?._id
        if(values.type === "STAFF"){
            dispatch(requestPassword(values, navigate))
        }
        else if(values.type === "STUDENT"){
            dispatch(studentRequestPassword(values, navigate))
        }
        else{
            dispatch(requestPassword(values, navigate))
        }
        
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };

      const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmPasswordShown ? false : true);
      };

    useEffect(() => {
        dispatch(getOrganizations())
    }, [])
    
    const [bannerImages, setBannerImages] = useState<string[]>([]);
    const [videoData, setVideoData] = useState<any>("");
  
    const settingBackGroundData = async () => {
      setIsLoading(true)
      if ((organization as any)?.bannerImages?.length > 0) {
        
        let imagesPromises = (organization as any)?.bannerImages
          .filter((imagePath: string) => imagePath.endsWith('.png'))
          .map(async(imagePath: string) => await dispatch(getlogo(imagePath)));
  
          if(imagesPromises && imagesPromises.length){
        Promise.all(imagesPromises)
          .then((imagesData) => {
            setBannerImages(imagesData);
          })
          .catch((error) => {
            
          });
        }
        else{
          setBannerImages([]);
        }
        let videoPromise = (organization as any)?.bannerImages
        .find((imagePath: string) => imagePath.endsWith('.mp4'));
  
      const fetchVideoData = async () => {
        try {
            let videoData = await dispatch(getlogo(videoPromise));
            setVideoData(videoData);
          }
          catch (videoError) {
           
        }
      };
  
          if (videoPromise) {
            Promise.all([fetchVideoData()]);
          }
          else{
            setVideoData('');
          }
      }
      else{
     
        setBannerImages([]);
        setVideoData('')
      }
    }
  
    React.useEffect(() => {
        if (validatedDomain?.orgId && !orgLoading) {
            const data: any = dispatch(getlogo(organization?.logo_path))
            data.then((data: any) => {
              dispatch({type:types.UPDATE_ORGANIZATION_LOGO,payload:{logo:data,id:organization._id }})
            })
            settingBackGroundData()
            setIsLoading(false);
          } else if (id && !orgLoading){
            settingBackGroundData()
            setIsLoading(false);
          }
          else {
            setBannerImages([]);
             setVideoData('')
            setIsLoading(false);  
          }
        
    }, [organization, validatedDomain]);

    const navigateMobile = (type) => {
      if(organization?.[type]){
        window.open(organization?.[type], '_blank');
      }
      else{
        if(type === "playStoreLink"){
          window.open("https://play.google.com/store/apps/details?id=com.ctrlcampussmartapp", '_blank')
        }
        else{
          window.open("https://apps.apple.com/us/app/ctrlcampus-smartapp/id1640110690", '_blank')
        }
      }
    }


    const handleBackButton = () => {
        navigate("/login");
    }

      const [currentIndex, setCurrentIndex] = useState(0);
    
      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
        }, 3000); // Adjust the interval based on your preference (3000ms in this example)
    
        return () => clearInterval(interval);
      }, [bannerImages]);

    return (
        <div className='main-auth-background'>
            <div className="d-flex auth-main-screen">
                <div className={`m-0 p-0 screen-org ${isLoading ? 'align-items-center justify-content-center' : ''}`} style={{backgroundColor:'#fff'}}>
                
                {isLoading ? <div className="spinner-border text-primary mt-4 me-2" style={{width : "40px", height : "40px"}} role="status">
              <span className="sr-only">Loading...</span>
              </div> : bannerImages && bannerImages.length > 0 || videoData ? (
           
           <div className="custom-carousel-container">
           {videoData ? <div style = {{backgroundSize:'cover' , height: '100%'}}><video
                                // controls
                                autoPlay={true}
                                loop={true}
                                style={{ width: 'auto', height: '100%' }}
                                src={videoData}
                              /> </div> : 
                              <Slider {...settings} className="pt-0">
                              {bannerImages.map((image, index) => (
                                
                                <div>
                                    <img className="org-images-container" decoding="async" src={image} alt=""/>
                                </div>
                                
                              ))}
                            </Slider> }
           {bannerImages.length > 1 && !videoData ?
           <svg style={{ position: 'absolute', bottom: '10px', width:'100%' }}>
                   {currentColorIndex === 0 ? (
                     <rect
                       x={250} 
                       y={125}
                       width={45} 
                       height={10}
                       rx={6} 
                       ry={6}
                       fill={colors[0]}
                     />
                   ) : (
                     <circle
                       cx={250} 
                       cy={130}
                       r={5} 
                       fill={colors[0]}
                     />
                   )}
                   {bannerImages.length >= 2 && currentColorIndex === 1 ? (
                     <rect
                       x={263} 
                       y={125} 
                       width={45} 
                       height={10}
                       rx={6}
                       ry={6}
                       fill={colors[1]}
                     />
                   ) : (
                     <circle
                     cx={currentColorIndex === 0 ? 310 : 270}
                       cy={130} 
                       r={5} 
                       fill={colors[1]}
                     />
                   )}
                   {bannerImages.length > 2 && currentColorIndex === 2 ? (
                     <rect
                       x={285} 
                       y={125} 
                       width={45} 
                       height={10} 
                       rx={6} 
                       ry={6}
                       fill={colors[2]}
                     />
                   ) : (
                     <circle
                       cx={ currentColorIndex === 0 ? 330 : currentColorIndex === 1 ? 320 : 300} 
                       cy={130} 
                       r={5} 
                       fill={colors[2]}
                     />
                   )}
                 </svg> : <></> }
         </div>
         
          )
          : <>
              <Slider {...settings}>
                  <div>
                      <img className="default-img" decoding="async" src="../../../images/org-select-background.svg" alt="Image 1" />
                  </div>
                  <div>
                      <img className="default-img" decoding="async" src="../../../images/login-background.svg" alt="Image 2" />
                  </div>
                  <div>
                      <img className="default-img" decoding="async" src="../../../images/reset-pw-bg.svg" alt="Image 3" />
                  </div>
            </Slider>

          <div className="push-bottom">
          </div>
          <div className="wave">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 310"><path fill="#15447C" fill-opacity="1" d="M0,224L34.3,234.7C68.6,245,137,267,206,261.3C274.3,256,343,224,411,213.3C480,203,549,213,617,229.3C685.7,245,754,267,823,250.7C891.4,235,960,181,1029,176C1097.1,171,1166,213,1234,229.3C1302.9,245,1371,235,1406,229.3L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
                        
                             {currentColorIndex === 0 ? (
                        <rect
                            x={660} // Adjust the x-coordinate based on your design
                            y={290 - 10} // Adjust the y-coordinate based on your design
                            width={70}    // Adjust the width based on your design
                            height={20}   // Adjust the height based on your design
                            rx={10}             // Set rx equal to half of the width for a circular border radius
                            ry={10}
                            fill={colors[0]}
                        />
                        ) : (
                        <circle
                            cx={660}      // Adjust the cx-coordinate based on your design
                            cy={290}      // Adjust the cy-coordinate based on your design
                            r={10}        // Adjust the radius based on your design
                            fill={colors[0]}
                        />
                )}
                 {currentColorIndex === 1 ? (
                    <rect
                        x={690} 
                        y={290 - 10}  
                        width={70}  
                        height={20} 
                        rx={10}   
                        ry={10}
                        fill={colors[1]}
                    />
                    ) : (
                    <circle
                        cx={currentColorIndex === 0 ? 760 : 690} 
                        cy={290}     
                        r={10}        
                        fill={colors[1]}
                    />
                )}
                {currentColorIndex === 2 ? (
                        <rect
                            x={720} 
                            y={290 - 10} 
                            width={70}   
                            height={20}  
                            rx={10}             
                            ry={10}
                            fill={colors[2]}
                        />
                        ) : (
                        <circle
                            cx={ currentColorIndex === 1 || currentColorIndex === 0 ? 790 : 720} 
                            cy={290}      
                            r={10}        
                            fill={colors[2]}
                        />
                )}
                    </svg>  
                        <div className="main-auth-content text-white">
                        
                                <p className="text-center" style={{ fontWeight: "lighter",fontSize:'21px' }}>Campus Management Software</p>
                                <p className="f-14 text-center">With CtrlCampus, advanced ERP Software Systems <br /> can handle your Admission, Academics, <br />
                                and administration processes smartly.</p>
                        
                        </div>
                    </div>
          </>}
                </div>
                <div className="border-right-gray"></div>
                <div className="m-0 p-0 screen-2 d-flex flex-column align-items-center position-relative">
                <div className="d-flex flex-column h-100 align-items-center login-content-width">
                    <button className="theme-back-btn d-flex align-items-center gap-2" onClick={handleBackButton}><img src="/images/back-btn.svg"/> Back</button>
                    {/* <img className="mt-4" width={86} height={55} src="/images/login-ctrlcampus.svg" alt="" /> */}
               {isLoading ? <div className="spinner-border text-primary mt-4 me-2" style={{width : "40px", height : "40px"}} role="status">
              <span className="sr-only">Loading...</span>
              </div> : <p className="org-logo mb-0">
              <img src={logo} alt="Org Logo" />
            </p>}
            {/* <p className="fs-heading fw-bold theme-primary-color w-100 text-center">{organization.account_name}</p> */}
            <div className="d-flex flex-column align-items-center responsive-condition w-100">
                    <Form id='forgot-password-form' className="initial-page d-flex w-100 flex-column mt-2" onSubmit={handleSubmit(onHandleSubmit)} >
                         <div className="heading mb-2">
                            <h1 className="fs-large fw-500">Forgot Password</h1>
                             {/* <p className="fs-heading theme-login-secondary-color">Fill up the empty fields to reset your password.</p>  */}
                        </div>  
                                {/* <Form.Group className='mb-3'>
                                    <Form.Label className="style-2">Select Organization</Form.Label>
                                    <Form.Select className="login-input-control" required {...register('orgId')} aria-label="Default select example">
                                        <option selected value=''>Select Organization</option>
                                        {
                                            organizations?.map((item: any) => (
                                                <option value={item._id}>{item?.account_name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group className="mb-3" >
                                    {/* <Form.Label className="style-2">User Type</Form.Label> */}
                                    <Form.Select required {...register('type')} aria-label="Default select example" className='login-input-control' >
                                        <option value='STAFF'>Staff</option>
                                        <option value='STUDENT'>Student</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="username" >
                                    {/* <Form.Label className="style-2">Enrollment Code</Form.Label> */}
                                    <Form.Control className="login-input-control" type="text" placeholder="Enter Enrollment Code" required {...register('enrollmentCode')} />
                                </Form.Group>


                                <Form.Group className="mb-3" >
                                    {/* <Form.Label className="style-2">New Password Category</Form.Label> */}
                                    <Form.Select required {...register('requestedPasswordType')} aria-label="Default select example" className='login-input-control' >
                                        <option value="">Select Password Category</option>
                                        <option value='ENROLLMENT_CODE'>Enrollment Code</option>
                                        <option value='DATE_OF_BIRTH'>Date Of Birth</option>
                                        <option value='PHONE_NUMBER'>Mobile Number</option>
                                        {watch("type") === "STUDENT" ?
                                        <option value='ADMISSION_NUMBER'>Admission Number</option> : <></> }
                                    </Form.Select>
                                </Form.Group>

                   
                            <p id="submit-button w-100">
                                <button form='forgot-password-form' type="submit" className="org-btn f-14">Reset Password</button>
                            </p>

                    </Form>
                    </div>
                        <div className="customer-support-box w-100 mb-3">
                            <p className="d-flex justify-content-center gap-2">
                                <img className="cursor-pointer" src="/images/login-google-play.svg" onClick={() => navigateMobile("playStoreLink")}/>
                                <img className="cursor-pointer" src="/images/login-app-store.svg" onClick={() => navigateMobile("appStoreLink")}/>
                            </p>
                            <p className='d-flex align-items-center pt-3'>
                                <img className="me-16" src="/images/customer-support.svg" alt="" />
                                <span className="fs-small theme-login-secondary-color">Customer Support- +91-9293839833</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ResetPasswordRequest);
