import types from "redux/actions/types";
import { IAssignRouteState, AssignRouteActions } from "../types/assignRoutetoStudent";
import { IAssignRoute } from "redux/types/assignRoutetoStudent";

const initialState: IAssignRouteState = {
  loading: true,
  assignroute: {} as IAssignRoute,
  assignroutes: [] as IAssignRoute[],
  transportfeestudents: [],
  totalCount:0

};

const assignRouteReducer= (state = initialState, action: AssignRouteActions) => {
  switch (action.type) {
    case types.ASSIGNROUTETOSTUDENT_REGISTER_SUCCESS:
    case types.ASSIGNROUTETOSTUDENT_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // assignroutes: [...state.assignroutes, action.payload] as IAssignRoute[],
      };
    case types.GET_ASSIGNROUTETOSTUDENTS:
      return {
        ...state,
        loading: false,
        assignroutes: action?.payload?.assignedRoutes,
        totalCount: action?.payload?.totalCount
      };
    case types.GET_TRANSORTFEE_STUDENTS:
        return {
          ...state,
          loading: false,
          transportfeestudents: action.payload,
        };

    case types.DELETE_ASSIGNROUTETOSTUDENT:
      return {
        ...state,
        assignroutes: state.assignroutes.filter(
          (assignroute) => assignroute._id !== action.payload
        ),
      };

    case types.UPDATE_ASSIGNROUTETOSTUDENT:
      return {
        ...state,
        assignroutes: state.assignroutes.map((assignroute) =>
        assignroute._id === action.payload.id
            ? { ...action.payload.assignroute }
            : assignroute
        ),
      };
    case types.ASSIGNROUTETOSTUDENT_REGISTER_FAIL:
    case types.ASSIGNROUTETOSTUDENT_AUTH_ERROR:

    default:
      return state;
  }
};
export default assignRouteReducer;
