import React from 'react'

function emptyPlaceholder({ message, height, type }: { message?: string, height?: string, type?: string }) {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: height ?? "60vh" }}>
      <img src='/images/empty-screen-placeholder.svg' className='img img-fluid' style={{ height: '50%' }} />
      {message ? type === 'dashboard' ? <h5 className='fs-small theme-dark-color mt-16'>{message}</h5> :
        <h5 className='fs-header theme-dark-color mt-16'>{message}</h5> :
      <h5 className='fs-small theme-dark-color mt-16'>This page is empty enter the fields or search to get the values.</h5>}
    </div>
  )
}
export default emptyPlaceholder;
