
import ActionTypes from "../actions/types";
import { AllExamStructureActions } from "redux/types/examStructure";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    examstructure : {},
    examstructures : [],
}

const examStructure = (state = initialState, action : AllExamStructureActions) => {
    switch(action.type){
        case ActionTypes.GET_ALL_EXAM_STRUCTURE_REQUEST:
        case ActionTypes.CREATE_EXAM_STRUCTURE_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_ALL_EXAM_STRUCTURE_SUCCESS:
            return {
                ...state,
                loading : false,
                examstructures : action.payload.data,
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_ALL_EXAM_STRUCTURE_FAILURE:
            return {
                ...state,
                loading : false,
                examstructures : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.CREATE_EXAM_STRUCTURE_SUCCESS:
            return {
                ...state,
                loading : false,
                error : '',
            }
        case ActionTypes.CREATE_EXAM_STRUCTURE_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
        case ActionTypes.GET_EXAM_STRUCTURE_BY_ID:
            return {
                ...state, 
                loading : false,
                examstructure: { ...action.payload.data }
            }
        case ActionTypes.UPDATE_EXAM_STRUCTURE_SUCCESS:
            return {
                ...state,
                examstructures: state.examstructures.map((examstructure) =>
                examstructure._id === action.payload.id
                    ? { ...action.payload.examstructure }
                    : examstructure
                ),
            };
        case ActionTypes.DELETE_EXAM_STRUCTURE:
            return {
                ...state,
                examstructures: state.examstructures.filter(
                (examstructure) => examstructure._id !== action.payload
                ),
            };
        default : return state;
    }
}

export default examStructure;