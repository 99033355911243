import types from "redux/actions/types";
import { IAcademicBatchState, AcademicbatchActions } from "../types/academicbatch";
import { IAcademicbatch } from "redux/types/academicbatch";

const initialState: IAcademicBatchState = {
  loading: true,
  initial : false,
  academicbatch: {} as IAcademicbatch,
  academicbatchs: [] as IAcademicbatch[],
};

const academicbatchReducer = (state = initialState, action: AcademicbatchActions) => {
  switch (action.type) {
    case types.ACADEMICBATCH_INITIAL:
      return {
        ...state,
        initial : true,
      }
    case types.ACADEMICBATCH_NOT_INITIAL:
      return {
        ...state,
        initial : false,
      }
    case types.ACADEMICBATCH_REGISTER_SUCCESS:
    case types.ACADEMICBATCH_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        academicbatchs: [...state.academicbatchs, action.payload] as IAcademicbatch[],
      };
    case types.GET_ACADEMICBATCHS:
      
      return {
        ...state,
        loading: false,
        academicbatchs: action.payload as IAcademicbatch[],
      };

    case types.DELETE_ACADEMICBATCH:
      return {
        ...state,
        academicbatchs: state.academicbatchs.filter(
          (academicbatch) => academicbatch._id !== action.payload
        ),
      };

    case types.UPDATE_ACADEMICBATCH:
      return {
        ...state,
        academicbatchs: state.academicbatchs.map((academicbatch) =>
        academicbatch._id === action.payload.id
            ? { ...action.payload.academicbatch }
            : academicbatch
        ),
      };
    case types.ACADEMICBATCH_REGISTER_FAIL:
    case types.ACADEMICBATCH_AUTH_ERROR:

    default:
      return state;
  }
};
export default academicbatchReducer;
