import types from "redux/actions/types";
import { IVehicleState, VehicleActions } from "../types/vehicledetails";
import { IVehicle, IInsurance, IRepairesandbreakdowns, IRoadtax, IFueldetails, IPollution, IFitness, IServciehistory } from "redux/types/vehicledetails";

const initialState: IVehicleState = {
    loading: true,
    vehicle: {} as IVehicle,
    vehcleinsurance: {} as IInsurance,
    vehclefitness: {} as IFitness,
    vehclerepairesandbreakdowns: {} as IRepairesandbreakdowns,
    vehcleservicehistory: {} as IServciehistory,
    vehcleroadtax: {} as IRoadtax,
    vehclefueldetails: {} as IFueldetails,
    vehclepollution: {} as IPollution,
    vehicles: [],
};

const subjectReducer = (
    state = initialState,
    action: VehicleActions
): IVehicleState => {
    switch (action.type) {
        case types.CREATE_VEHICLE_DETAILS:
            return {
                ...state,
                loading: false,
            };
            case types.GETVEHICLEBYID:
                return {
                    ...state,
                    loading: false,
                    vehicle: action.payload as IVehicle,
                };
        case types.CREATE_VEHICLE_INSURANCE:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_VEHICLE_FITNESSDETAILS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_VEHICLE_REPAIRESANDBREAKDOWNDETAILS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_VEHICLE_SERVICEHISTORY:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_VEHICLE_ROADTAX:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_VEHICLE_FUELDETAILS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_VEHICLE_POLLUTION:
            return {
                ...state,
                loading: false,
            };
        case types.GET_VEHICLE_DETAILS:
            return {
                ...state,
                loading: false,
                vehicles: action.payload?.data?.buses,
            };
        case types.DELETE_VEHICLE:
            return {
                ...state,
                vehicles: state.vehicles.filter(
                    (vehicle: any) => vehicle._id !== action.payload
                ),
            };
            case types.UPDATEVEHICLE:
                return {
                    ...state,
                    vehicles: state.vehicles.map((vehicle:any) =>
                    vehicle._id === action.payload.id
                            ? { ...action.payload.vehicle }
                            : vehicle
                    ),
                };
        case types.GET_VEHICLE_DETAILS_FIALED:
        case types.VEHICLE_AUTH_ERROR:

        default:
            return state;
    }
};

export default subjectReducer;
