import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import '@fortawesome/fontawesome-free/css/all.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import App from 'App';
import 'theme.css';
import 'index.css';
import 'resoinsive.css';
import Store from 'redux/store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { datadogRum } from '@datadog/browser-rum';

// Check if the app is running on localhost
const isLocalhost =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

// Initialize Datadog RUM only if not running on localhost
if (!isLocalhost) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_APPID as string,
    clientToken: process.env.REACT_APP_CLIENT_ID as string,
    site: process.env.REACT_APP_SITE,
    service: process.env.REACT_APP_SERVICE,
    env: process.env.REACT_APP_ENVIRONMENT || 'development',
    version: '1.7.5',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: false, // optional, default to false
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body"
        nonce: undefined, // optional, default undefined
      }}
    >
      <Provider store={Store}>
        <App />
      </Provider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
