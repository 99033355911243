import ActionTypes from "redux/actions/types";

const initialState = {
  filteredStudents: [],
  approvals: [],
  totalCount: 0,
  loading: true,
};
export const FilterdStudents = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.GET_FILTERD_STUDENTS:      
      return {
        ...state,
        loading: false,
        filteredStudents: [...action.payload.students],
        totalCount: action.payload.totalCount,
      };
    case ActionTypes.GET_AWAIT_APPROVALS:      
      return {
        ...state,
        approvals: [...action.payload.data],
        totalCount: action.payload.totalCount,
      };
    case ActionTypes.GET_SEND_FOR_APPROVALS:      
        return {
          ...state,
          approvals: [...action.payload.data],
          totalCount: action.payload.totalCount,
        };
    case ActionTypes.RESET_FILTERED_STUDENT:
        return{
          filteredStudents: [],
          approvals: [],
          totalCount: 0,
          loading:false
        }
    default:
      return state;
  }
};
