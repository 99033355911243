import ActionTypes from "redux/actions/types";
const initialState = {
    workingDays: []
}
export const workingDays = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_WORKING_DAYS:
            return {
                ...state, workingDays: [...action.payload]
            }
        default:
            return state;
    }
}