import { IStaffShift, IStaffShiftsState, StaffShiftActions } from "redux/types/staffShifts";
import types from "redux/actions/types";
import StaffShifts from "pages/Modules/Hr/StaffShifts";

const initialState: IStaffShiftsState = {
    staffshift: {} as IStaffShift,
    staffshifts: [],
    loading: false,
    totalCount: 0
};
export const staffShiftsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.STAFF_SHIFTS_REGISTER_SUCCESS:
        case types.STAFF_SHIFTS_REGISTER_SUCCESS:
            return {
                ...state,
                staffshifts: [...state.staffshifts, action.payload.data],
                loading: false,
                
            };
        case types.GET_STAFF_SHIFTS:
            return {
                ...state,
                loading: false,
                staffshifts: action?.payload,
                // totalCount: action?.payload?.length,
            };
        case types.DELETE_STAFF_SHIFTS:
            return {
                ...state,
                staffshifts: state?.staffshifts?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        case types.UPDATE_STAFF_SHIFTS:
            return {
                ...state,
                staffshifts: state.staffshifts.map((clientuser: any) =>
                    clientuser._id === action.payload._id ? { ...action.payload.staffshift } : clientuser)
            }

        default:
            return state
    }
}
export default staffShiftsReducer;