import types from "redux/actions/types";
import { ApplyLeavesettingActions, IApplyLeave, IApplyLeaveState, IApproveLeave, IApproveLeaveDetails, IGetApplyLeaveTypes } from "redux/types/applystaffLeave";

const initialState: IApplyLeaveState = {
    loading: true,
    leaveStats : {
        error: '',
        loading : false,
        data: [],
    },
    applyleaves: {} as IApplyLeave,
    getleavetypes: {} as IGetApplyLeaveTypes,
    approveleaves: {} as IApproveLeave,
    leavetypeDetails: {} as IApproveLeaveDetails,
    leaveSubstituteSlots: []
};
export const leaveApplyReducer = (state = initialState, action: ApplyLeavesettingActions | any) => {
    switch (action.type) {
        case types.GET_STAFF_LEAVE_AVAILABLE_LEAVES:
            return {
                ...state,
                loading: false,
                leaveStats : {
                    ...state.leaveStats,
                    loading : false,
                    error : ''
                }
            };
        case types.GET_STAFF_LEAVE_AVAILABLE_LEAVES_SUCCESS:
            return {
                ...state,
                loading: false,
                leaveStats : {
                    ...state.leaveStats,
                    data : action.payload
                }
            };
        case types.GET_STAFF_LEAVE_AVAILABLE_LEAVES_FAILURE:
            return {
                ...state,
                leaveStats : {
                    ...state.leaveStats,
                    loading : false,
                    error : action.payload
                }
            };
        case types.APPLY_LEAVE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_SUBSTITUTES:
            return {
                ...state,
                loading: false,
            };
        case types.GET_STAFF_LEAVE_TYPES:
            return {
                ...state,
                loading: false,
                getleavetypes: action?.payload,
                // totalCount: action?.payload?.length,
            };
        case types.GET_STAFF_LEAVE_SLOTS:
            return {
                ...state,
                loading: false,
                leaveSubstituteSlots: action?.payload,
            };
        case types.GET_STAFF_LEAVE_APPORVAL:
            return {
                ...state,
                loading: false,
                approveleaves: action?.payload,
                // totalCount: action?.payload?.length,
            };
        case types.GET_STAFF_LEAVE_APPORVAL_DETAILS :
            return {
                ...state,
                loading: false,
                leavetypeDetails : action?.payload,
            }
        case types.UPDATE_LEAVE_APPROVAL_SUCCESS:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}
export default leaveApplyReducer;