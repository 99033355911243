import types from "redux/actions/types";
import { IApplicationsettingState, ApplicationsettingActions } from "../types/applicationsettings";
import { IApplicationsettings } from "redux/types/applicationsettings";

const initialState: IApplicationsettingState = {
  loading: true,
  applicationsetting: {} as IApplicationsettings,
  applicationsettings: [] as IApplicationsettings[],
};

const admissionSettingReducer = (state = initialState, action: ApplicationsettingActions) => {
  switch (action.type) {
    case types.APPLICATIONSETTING_REGISTER_SUCCESS:
    case types.APPLICATIONSETTING_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // applicationsettings: [...state.applicationsettings, action.payload] as IApplicationsettings[],
      };
    case types.GET_APPLICATIONSETTINGS:
      return {
        ...state,
        loading: false,
        applicationsettings: action.payload as IApplicationsettings[],
      };

    case types.DELETE_APPLICATIONSETTING:
      return {
        ...state,
        applicationsettings: state.applicationsettings.filter(
          (applicationsetting) => applicationsetting._id !== action.payload
        ),
      };

    case types.UPDATE_APPLICATIONSETTING:
      return {
        ...state,
        // applicationsettings: state.applicationsettings.map((applicationsetting) =>
        // applicationsetting._id === action.payload._id
        //     ? { ...action.payload.applicationsetting }
        //     : applicationsetting
        // ),
      };
    case types.APPLICATIONSETTING_REGISTER_FAIL:
    case types.APPLICATIONSETTING_AUTH_ERROR:

    default:
      return state;
  }
};
export default admissionSettingReducer;
