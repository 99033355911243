import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "redux/reducers";

export default function SubDomainCheck({ children }: { children: JSX.Element }) {
    const location = useLocation();
    const { validatedDomain } = useSelector((state:RootState)=>state.organization)
    const { id: selectedOrgId } = useSelector((state:RootState)=>state.organizationLogo)
    const [subdomain] = window.location.hostname.split('.');
    // if (subdomain === "dev" || subdomain === "app" || subdomain === "localhost" || subdomain === "ctrlcampus"){
    //     if (window.location.pathname === "/login" && !selectedOrgId)
    //         return <Navigate to="/" state={{ from: location }} />;
    //     return children;
    // }
    if(validatedDomain?.subdomainExists){
        if (window.location.pathname === "/login")
            return children;
        return <Navigate to="/login" state={{ from: location }} />;
    } else {
        if (validatedDomain?.subdomainExists === false && subdomain !== "localhost"){
            const url = window.location.href;
            const newUrl = url.replace(subdomain,"app");
            window.location.href = newUrl;
        }
        if (window.location.pathname === "/login" && !selectedOrgId)
            return <Navigate to="/" state={{ from: location }} />;
        return children;
    }
}   