import types from "redux/actions/types";
import { IEnquiryState, EnquiryActions } from "../types/enquiry";
import { IEnquiry } from "redux/types/enquiry";

const initialState: IEnquiryState = {
  loading: true,
  enquiry: {} as IEnquiry,
  enquiryResponse : {} as IEnquiry,
  enquiries: [] as IEnquiry[],
  totalCount:0
};

const enquiryReducer = (state = initialState, action: EnquiryActions) => {
  switch (action.type) {
    case types.ENQUIRY_REGISTER_SUCCESS:
      return {
        ...state,
        enquiry: action.payload?.data as IEnquiry,
        enquiryResponse : action.payload?.data,
        loading: false,
      };
    case types.GET_ENQUIRIES:
      return {
        ...state,
        loading: false,
        enquiries: action?.payload?.enquiries as IEnquiry[],
        totalCount: action?.payload?.totalCount ,

      };
      case types.SEARCH_ENQUIRIES:
        return {
          ...state,
          loading: false,
          enquiries: action?.payload as IEnquiry[],
        };
    case types.DELETE_ENQUIRY:
      return {
        ...state,
        enquiries: state.enquiries.filter(
          (enquiry) => enquiry._id !== action.payload
        ),
      };

    case types.UPDATE_ENQUIRY:
      return {
        ...state,
        enquiry: action.payload?.enquiry as IEnquiry,
        enquiries: state.enquiries.map((enquiry) =>
        enquiry._id === action.payload.id
            ? { ...action.payload.enquiry }
            : enquiry
        ),
      };
    case types.ENQUIRY_REGISTER_FAIL:
      return state;
    case types.ENQUIRY_AUTH_ERROR:

    default:
      return state;
  }
};
export default enquiryReducer;
