import types from "redux/actions/types";
import { IBusRouteState, BusRouteActions } from "../types/busroutes";
import { IBusroute } from "redux/types/busroutes";

const initialState: IBusRouteState = {
  loading: true,
  busroute: {} as IBusroute,
  busroutes: [] as IBusroute[],
  totalCount:0

};

const busRouteReducer = (state = initialState, action: BusRouteActions) => {
  switch (action.type) {
    case types.BUSROUTE_REGISTER_SUCCESS:
    case types.BUSROUTE_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        IBusroute: [...state.busroutes, action.payload] as IBusroute[],
      };
    case types.GET_BUSROUTES:
      return {
        ...state,
        loading: false,
        busroutes: action?.payload as IBusroute,
      };

    case types.DELETE_BUSROUTE:
      return {
        ...state,
        busroutes: state.busroutes.filter(
          (busroute) => busroute._id !== action.payload
        ),
      };

    case types.UPDATE_BUSROUTE:
      return {
        ...state,
        busroutes: state.busroutes.map((busroute) =>
        busroute._id === action.payload.id
            ? { ...action.payload.busroute }
            : busroute
        ),
      };
    case types.BUSROUTE_REGISTER_FAIL:
    case types.BUSROUTE_AUTH_ERROR:

    default:
      return state;
  }
};
export default busRouteReducer;
