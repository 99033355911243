import types from "redux/actions/types";
import { IOrganizationLogo,IUpdateOrganizationLogo } from "redux/types/organizationLogo";

const initialState:IOrganizationLogo={
    logo:'',
    id: ''
}

const organizationLogoReducer = (state=initialState,action:IUpdateOrganizationLogo)=>{
    switch (action.type){
        case types.UPDATE_ORGANIZATION_LOGO:
            return {...state,logo:action.payload.logo,id:action.payload.id}
        default:
            return {...state}
    }
}

export default organizationLogoReducer;