import types from "redux/actions/types";
import { IOrganizationState, OrganizationActions, ISubDomainCheck } from "../types/organization";
import { IOrganization } from "redux/types/organization";

const initialState: IOrganizationState = {
    loading: true,
    organization: {} as IOrganization,
    organizations: [] as IOrganization[],
    validatedDomain:{} as ISubDomainCheck,
};

const masterClassReducer = (
    state = initialState,
    action: OrganizationActions
): IOrganizationState => {
    switch (action.type) {
        case types.ORGANIZATION_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                organizations: [...state.organizations, action.payload] as IOrganization[],
            };
        case types.GET_ORGANIZATIONS:
            return {
                ...state,
                loading: false,
                organization: action.payload as IOrganization,
            };
        case types.GET_ALL_ORGANIZATIONS:
            return {
                ...state,
                loading: false,
                organizations: action.payload as IOrganization[],
            };
        case types.DELETE_ORGANIZATION:
            return {
                ...state,
                organizations: state.organizations.filter(
                    (organization) => organization._id !== action.payload
                ),
            };
        case types.VALIDATE_ORGANIZATION_BY_SUBDOMAIN:
            return {
                ...state,
                validatedDomain: action.payload,
            };
            
        case types.SET_ORGANIZATION_BACKGROUND_IMAGE:
            return {
                ...state,
            };
        case types.LOGOUT_ORGANIZATION :
            return {
                ...state,
                loading: true,
                organization : {} as IOrganization
            }
        case types.UPDATE_ORGANIZATION:
            return {
                ...state,
                organizations: state.organizations.map((organization) =>
                    organization._id === action.payload.id
                        ? { ...action.payload.organization }
                        : organization
                ),
            };
        case types.ORGANIZATION_REGISTER_FAIL:
        case types.ORGANIZATION_AUTH_ERROR:

        default:
            return state;
    }
};

export default masterClassReducer;
