import types from "redux/actions/types";
import { ILeaveRoleMapping, ILeaveRoleMappingState, LeaveRoleMappingActions } from "redux/types/leaveApprovalMapping";

const initialState: ILeaveRoleMappingState = {
    loading: true,
    totalCount: 0,
    leaverole: {} as ILeaveRoleMapping,
    leaveroles: [],
};
export const leaveRoleMappingReducer = (state = initialState, action: LeaveRoleMappingActions) => {
    switch (action.type) {
        case types.LEAVE_ROLE_MAPPING_REGISTER_SUCCESS:
        case types.LEAVE_ROLE_MAPPING_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state
    }
}
export default leaveRoleMappingReducer;