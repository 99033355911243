import ActionTypes from "../actions/types";
import { AllInputFieldActions } from "../types/inventoryUnit"; 

const initialState = {
  loading: false,
  error: '',
  totalCount: 0,
  Units: [], 
};

const inputField = (state = initialState, action: AllInputFieldActions) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_INPUT_FIELD_REQUEST:
    case ActionTypes.CREATE_INPUT_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ActionTypes.GET_ALL_INPUT_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        Units: action.payload.data, 
        totalCount: action.payload.totalCount,
        error: '',
      };
    case ActionTypes.GET_ALL_INPUT_FIELD_FAILURE:
      return {
        ...state,
        loading: false,
        Units: [], 
        totalCount: 0,
        error: action.payload,
      };
    case ActionTypes.CREATE_INPUT_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case ActionTypes.CREATE_INPUT_FIELD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.GET_INPUT_FIELD_BY_ID:
      return {
        ...state,
        loading: false,
        Units: [action.payload], 
      };
    case ActionTypes.UPDATE_INPUT_FIELD_SUCCESS:
      return {
        ...state,
        Units: state.Units.map((unit) =>
          unit._id === action.payload.id
            ? { ...action.payload.unit }
            : unit
        ),
      };
    case ActionTypes.DELETE_INPUT_FIELD:
      return {
        ...state,
        Units: state.Units.filter(
          (unit) => unit._id !== action
        ),
      };
    default:
      return state;
  }
};

export default inputField;
