import React from 'react';
import { Card, Form, Table } from 'react-bootstrap';
const AcademicYearStartDate: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Form.Select className="outline-select">
          <option>2021-2022</option>
        </Form.Select>
      </div>
      <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Location</th>
              <th>Branch</th>
              <th>Class</th>
              <th>Start Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>001</td>
              <td>All</td>
              <td>branch</td>
              <td>2</td>
              <td>01-01-21</td>
              <td>
                <a href="#">
                  <img src="images/edit-icon.svg" />
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </>
  );
};
export default React.memo(AcademicYearStartDate);
