import { Dispatch } from "redux";
import axios from "utils/axios";
import types from "./types";
import { setAlert } from "./alert";
import { BranchAcademicbatchActions } from "redux/types/branchacademicbatch";
import { AlertActions } from "redux/types/alert";

// REGISTER ACADEMICBATCH
let orgId = localStorage.getItem("orgId")
export const registerBranchacademicbatch =
    (body: any, history: any) =>
        async (dispatch: Dispatch<BranchAcademicbatchActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.post(`branchacademicbatch/create`, body, config);
                dispatch({
                    type: types.BRANCHACADEMICBATCH_REGISTER_SUCCESS,
                    payload: data,
                });
                dispatch<any>(
                    setAlert({
                        msg: "BranchAcademicbatch Register successful!",
                        status: 200,
                        alertType: "success",
                    })
                );
                dispatch<any>(getBranchacademicbatchs({ orgId: orgId }));
            } catch (error: any) {
                dispatch({ type: types.BRANCHACADEMICBATCH_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: error?.response?.data,
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };
// GET ACADEMICBATCHs

export const getBranchacademicbatchs =
    (body: any, setIsLoading?:any, initial ?: boolean) => async (dispatch: Dispatch<BranchAcademicbatchActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            if(initial){
                dispatch({type : types.BRANCHACADEMICBATCH_INITIAL})
            } else {
                dispatch({type : types.BRANCHACADEMICBATCH_NOT_INITIAL})
            }
            const { data } = await axios.post(`branchacademicbatch/getBranchAcademicBatches`, body, config);
            dispatch({ type: types.GET_BRANCHACADEMICBATCHS, payload: data });
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when fetching the Branchacademicbatchs!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        } finally {
            if (setIsLoading)
                setIsLoading(false);
        }
    };

// UPDATE ACADEMICBATCH DATA
export const updateBranchacademicbatchs =
    (body: any, id: any, history: any) =>
        async (dispatch: Dispatch<BranchAcademicbatchActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.put(
                    `branchacademicbatch/update/${id}`,
                    body,
                    config
                );
                dispatch({
                    type: types.UPDATE_BRANCHACADEMICBATCH,
                    payload: data,
                });

                dispatch<any>(
                    setAlert({
                        msg: "BranchAcademicbatch Data Updated!",
                        status: 200,
                        alertType: "success",
                    })
                );
                dispatch<any>(getBranchacademicbatchs({ orgId: orgId }));

            } catch (error: any) {
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when updating the BranchAcademicbatch!",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            } finally {
                // setSubmitting(false);
            }
        };

// DELETE ACADEMICBATCH
export const deleteBranchacademicbatch =
    (id: number) => async (dispatch: Dispatch<BranchAcademicbatchActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            await axios.delete(`branchacademicbatch/delete/${id}`, config);
            dispatch({ type: types.DELETE_BRANCHACADEMICBATCH, payload: id });
            dispatch<any>(
                setAlert({
                    msg: "Branchacademicbatch has been deleted!",
                    status: 200,
                    alertType: "success",
                })
            );
            dispatch<any>(getBranchacademicbatchs({ orgId: orgId }));
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when deleting the BranchAcademicbatch!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };
// UPDATE ACADEMICBATCH DATA
export const updateBranchacademicbatchsStatus =
    (body: any, history: any) =>
        async (dispatch: Dispatch<BranchAcademicbatchActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.post(
                    `branchacademicbatch/updateAcademicBatch`,
                    body,
                    config
                );
                dispatch({
                    type: types.UPDATE_BRANCHACADEMICBATCHSTATUS,
                    payload: data,
                });

                dispatch<any>(
                    setAlert({
                        msg: "BranchAcademicbatch Data Updated!",
                        status: 200,
                        alertType: "success",
                    })
                );
            } catch (error: any) {
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when updating the BranchAcademicbatch!",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            } finally {
                // setSubmitting(false);
            }
        };