
import types from '../actions/types'
import {IServiceCreation,IServiceCreationData,ServiceCreationActions } from "redux/types/inventoryServiceCreation";
import { I } from '@fullcalendar/core/internal-common';


const serviceInitialState:IServiceCreation = {
    serviceData: [] as IServiceCreationData[],
    totalCount : 0,
    isLoading: false
  };

  const serviceCreationReducer = (state = serviceInitialState, action: ServiceCreationActions) => {
    switch (action.type) {
      case types.GET_SERVICE_CREATION_SERVICES_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
        
      case types.GET_SERVICE_CREATION_SERVICES_SUCCESS:
        return {
          ...state,
          serviceData: action.payload.data,
          totalCount: action.payload.totalCount,
          isLoading: false,
        };
      case types.GET_SERVICE_CREATION_SERVICES_FAILURE:
        return {
          ...state,
          serviceData: [],
          totalCount: 0,
          isLoading: false,
        };
      case types.CREATE_SERVICE_CREATION_SERVICE_SUCCESS:
        return {
          ...state,
          serviceData: [...state.serviceData, action.payload.data],
        };
      case types.UPDATE_SERVICE_CREATION_SERVICE_SUCCESS:
        return {
          ...state,
          serviceData: state.serviceData.map((item:any) =>
            item.id === action.payload.id ? action.payload   : item
          ),
        };
      case types.DELETE_SERVICE_CREATION_SERVICE:
        return {
          ...state,
          serviceData: state.serviceData.filter(
            (item:any) => item.id !== action.payload.id
          ),
        };
      default:
        return state;
    }
  };
  

export default serviceCreationReducer;