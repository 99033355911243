import React from "react";
import { Button, Card, Col, Form, ListGroup, Modal, Row, Tab, Table  } from "react-bootstrap";


const ItcertificateFeeSettings: React.FC = (): JSX.Element => {
  return (
    <div className="c-container content-tabs mt-5">
             <div className="section-bgwhite1">

             <div className="d-flex justify-content-end mb-3"> 
              <Form.Select className="outline-select me-3">
                <option>2021-2022</option>
              </Form.Select>
              <Button variant="outline-primary outline-cbtn">Add </Button>
            </div>


             <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Location</th>  
              <th>Branch</th> 
              <th>Academic Year</th> 
              <th>Fee Type</th> 
              <th>Fee Head Name</th>                    
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>               
              <td><a href="#"> <img src="images/edit-icon.svg" /> </a></td>
            </tr>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>               
              <td><a href="#"> <img src="images/edit-icon.svg" /> </a></td>
            </tr>

          </tbody>
        </Table>
      </Card>

            </div>

    </div>
  )
}
export default React.memo(ItcertificateFeeSettings);
