import React from "react";
import { Button, Card, Col, Form, ListGroup, Modal, Row, Tab, Table  } from "react-bootstrap";


const CreateStudentSegment: React.FC = (): JSX.Element => {
  return (
    <div className="c-container content-tabs mt-5">
             <div className="section-bgwhite1">
                      
             <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Segment Name</th>  
              <th>Segment Description</th> 
              <th>Status</th> 
              <th>Is RTE/EWS</th> 
              <th>New Admission Allowed</th>                    
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>               
              <td><a href="#"> <img src="images/edit-icon.svg" /> </a></td>
            </tr>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>               
              <td><a href="#"> <img src="images/edit-icon.svg" /> </a></td>
            </tr>

          </tbody>
        </Table>
      </Card>

            </div>

    </div>
  )
}
export default React.memo(CreateStudentSegment);
