import types from "redux/actions/types";
import { IScheduleJob, ScheduleJobActions, ScheduleJobState } from "redux/types/scheduleJobs";

const initialState: ScheduleJobState = {
  loading: true,
  schedulejob: {} as IScheduleJob,
  scheduleJobs: {loading: true, data: []},
  scheduleTypes: {loading: true, data: []},
};

const ScheduleJobReducer = (state = initialState, action: ScheduleJobActions) => {
  switch (action.type) {
    case types.SCHEDULEJOB_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // scheduleJobs: [...state.scheduleJobs, action.payload] as IScheduleJob[],
      };
    case types.GET_SCHEDULEJOBS:
      return {
        ...state,
        loading: false,
        scheduleJobs: {loading:false, data: action.payload.data},
      };
      case types.GET_SCHEDULETYPES:
        return {
          ...state,
          loading: false,
          scheduleTypes: {loading:false, data: action.payload.data},
        };
    case types.UPDATE_SCHEDULEJOBS:
      return {
        ...state,
        scheduleJobs: state.scheduleJobs.map((schedulejob) =>
        schedulejob._id === action.payload.id
            ? { ...action.payload.schedulejob }
            : schedulejob
        ),
      };
      
    case types.DELETE_SCHEDULEJOBS:
      return {
        ...state,
        scheduleJobs: state.scheduleJobs.filter(
          (schedulejob) => schedulejob._id !== action.payload
        ),
      };
      
    case types.SCHEDULEJOB_REGISTER_FAIL:
    case types.SCHEDULEJOB_AUTH_ERROR:
    

    default:
      return state;
  }
};
export default ScheduleJobReducer;
