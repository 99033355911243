import types from "redux/actions/types";
import { IFinesettingState, FinesettingActions } from "../types/finesettings";
import { IFinesetting } from "redux/types/finesettings";

const initialState: IFinesettingState = {
    loading: true,
    finesetting: {} as IFinesetting,
    finesettings: [] as IFinesetting[],
};

const finesettingReducer = (state = initialState, action: FinesettingActions) => {
    switch (action.type) {
        case types.FINESETTING_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // finesettings: [...state.finesettings, action.payload] as IFinesetting[],
            };
        case types.GET_FINESETTINGS:
            return {
                ...state,
                loading: false,
                finesettings: action.payload as IFinesetting[],
            };

        case types.DELETE_FINESETTING:
            return {
                ...state,
                finesettings: state.finesettings.filter(
                    (finesetting) => finesetting._id !== action.payload
                ),
            };

        case types.UPDATE_FINESETTING:
            return {
                ...state,
            };
        case types.FINESETTING_REGISTER_FAIL:
        case types.FINESETTING_AUTH_ERROR:

        default:
            return state;
    }
};
export default finesettingReducer;
