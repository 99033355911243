import types from "redux/actions/types";
import { IApplicantState, ApplicantActions } from "../types/application";
import { IApplicant } from "redux/types/application";

const initialState: IApplicantState = {
  loading: true,
  applicant: {} as IApplicant,
  applicants: [] as IApplicant[],
  applicantResponse : {} as IApplicant,
  searchapplicants:[] as IApplicant[],
  totalCount:0
};

const applicantReducer = (state = initialState, action: ApplicantActions) => {
  switch (action.type) {
    case types.APPLICANT_REGISTER_SUCCESS:
    case types.APPLICANT_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        applicantResponse : action.payload,
        // applicants: {...state.applicants, action.payload} as IApplicant,
      };
    case types.GET_APPLICANTS:
      return {
        ...state,
        loading: false,
        applicants: action?.payload?.data as IApplicant[],
        totalCount:action?.payload?.totalCount
      };
      case types.SEARCH_APPLICANTS:
        return {
          ...state,
          loading: false,
          applicants: action?.payload as IApplicant[],
        };
    case types.DELETE_APPLICANT:
      return {
        ...state,
        applicants: state?.applicants?.filter(
          (applicant:any) => applicant._id !== action.payload
        ),
      };

    case types.UPDATE_APPLICANT:
      return {
        ...state,
        applicants: state?.applicants?.map((applicant:any) =>
        applicant._id === action.payload._id
            ? { ...action.payload.applicant }
            : applicant
        ),
      };
    case types.APPLICANT_REGISTER_FAIL:
    case types.APPLICANT_AUTH_ERROR:

    default:
      return state;
  }
};
export default applicantReducer;
