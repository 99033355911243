import ActionTypes from "redux/actions/types";
import {IInitialSetup , IInitialSetupActions} from "../types/initialSetup";

const initialState: IInitialSetup = {
    
    initialSetupShow: false,
    initialSetupBannerShow: false
    
};

const initialSetupReducer = (state = initialState, action: IInitialSetupActions) => {
    switch (action.type) {
        case ActionTypes.GET_INITIAL_SETUP:
            return { ...state, initialSetupShow: action?.payload?.initialSetupShow, initialSetupBannerShow: action?.payload?.initialSetupBannerShow }
        default:
            return state
    }
}

export default initialSetupReducer