import types from "redux/actions/types";
import { IBranchmoduleState, BranchmoduleActions } from "../types/branchmodules";
import { IBranchModule } from "redux/types/branchmodules";

const initialState: IBranchmoduleState = {
  loading: true,
  premisiionsloading:true,
  branchmodule: {} as IBranchModule,
  branchmodules: [] as IBranchModule[],
  branchmodulepremissionlist: [] as IBranchModule[],
};

const branchmodule = (state = initialState, action: BranchmoduleActions) => {
  switch (action.type) {
    case types.BRANCHMODULE_REGISTER_SUCCESS:
    case types.GET_BRANCHMODULES:
      return {
        ...state,
        loading: false,
        branchmodules: action.payload as IBranchModule[],
      };
      case types.GET_BRANCHMODULEPREMISSIONSLIST:
        return {
          ...state,
          premisiionsloading:false,
          branchmodulepremissionlist: action.payload as IBranchModule[],
        };
    case types.DELETE_BRANCHMODULES:
      return {
        ...state,
        branchmodules: state.branchmodules.filter(
          (branchmodule) => branchmodule._id !== action.payload
        ),
      };

    case types.UPDATE_BRANCHMODULES:
      return {
        ...state,
        branchmodules: state.branchmodules.map((branchmodule) =>
        branchmodule._id === action.payload.id
            ? { ...action.payload.organization }
            : branchmodule
        ),
      };
    case types.BRANCHMODULE_REGISTER_FAIL:
    case types.BRANCHMODULES_AUTH_ERROR:

    default:
      return state;
  }
};
export default branchmodule;
