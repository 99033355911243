import types from "redux/actions/types";
import { IVehicleRouteState, VehicleRouteActions } from "../types/vehicleroutes";
import { IVehicleRoute } from "redux/types/vehicleroutes";

const initialState: IVehicleRouteState = {
  loading: true,
  vehicleroute: {} as IVehicleRoute,
  vehicleroutes: [],
  pickUpPoints: [],
  dropPoints: [],
  totalCount:0

};

const vehicleRouteReducer= (state = initialState, action: VehicleRouteActions) => {
  switch (action.type) {
    case types.VEHICLEROUTE_REGISTER_SUCCESS:
    case types.VEHICLEROUTE_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicleroute: [...state.vehicleroutes, action.payload] as IVehicleRoute[],
      };
    case types.GET_VEHICLEROUTES:
      return {
        ...state,
        loading: false,
        vehicleroutes: action?.payload,
      };

    case types.GET_ROUTEPOINTS_BY_ID:
      return {
        ...state,
        loading: false,
        pickUpPoints: action?.payload?.pickUpPoints,
        dropPoints: action?.payload?.dropPoints,
      };
    case types.DELETE_VEHICLEROUTE:
      return {
        ...state,
        vehicleroutes: state.vehicleroutes.filter(
          (busstop) => busstop._id !== action.payload
        ),
      };

    case types.UPDATE_VEHICLEROUTE:
      return {
        ...state,
        vehicleroutes: state.vehicleroutes.map((vehicleroute) =>
        vehicleroute._id === action.payload.id
            ? { ...action.payload.vehicleroute }
            : vehicleroute
        ),
      };
    case types.VEHICLEROUTE_REGISTER_FAIL:
    case types.VEHICLEROUTE_AUTH_ERROR:

    default:
      return state;
  }
};
export default vehicleRouteReducer;
