
import ActionTypes from "../actions/types";
import { AllStockActions } from "redux/types/inventoryStock";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    stock : {},
    stocks : [],
}

const Stock = (state = initialState, action : AllStockActions) => {
    switch(action.type){
        case ActionTypes.GET_ALL_STOCKS_REQUEST:
        case ActionTypes.CREATE_STOCK_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_ALL_STOCKS_SUCCESS:
            return {
                ...state,
                loading : false,
                stocks : action.payload.data,
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_ALL_STOCKS_FAILURE:
            return {
                ...state,
                loading : false,
                stocks : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.CREATE_STOCK_SUCCESS:
            return {
                ...state,
                loading : false,
                error : '',
            }
        case ActionTypes.CREATE_STOCK_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
            
        case ActionTypes.UPDATE_STOCK_SUCCESS:
            return {
                ...state,
                stocks: state.stocks.map((stock) =>
                stock._id === action.payload.id
                    ? { ...action.payload.stock }
                    : stock
                ),
            };
        case ActionTypes.DELETE_STOCK:
            return {
                ...state,
                stocks: state.stocks.filter(
                (stock) => stock._id !== action.payload
                ),
            };
        
        default : return state;
    }
}

export default Stock;