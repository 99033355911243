import ActionTypes from "redux/actions/types";

const initialState = {
    approvalSettings: [],
    loading: true,
    approvalSetting: {},
    totalCount: 0,
    approvals: [],
    approvalTotalCount:0
}

const ApprovalSettings = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_CERTIFICATE_APPROVAL_SETTINGS:
            return { ...state, approvalSettings: [...action.payload.data], totalCount: action.payload.totalCount }
        case ActionTypes.CREATE_CERTIFICATE_APPROVAL_SETTINGS:
            return { ...state , loading: false}
        case ActionTypes.UPDATE_CERTIFICATE_APPROVAL_SETTINGS:
            return { ...state, approvalSetting: { ...action.payload } }
        case ActionTypes.GET_CERTIFICATE_APPROVALS:
            return { ...state, approvals: [...action?.payload], approvalTotalCount: action?.totalCount }
        
        default:
            return state;
    }
}

export default ApprovalSettings;