import ActionTypes from "redux/actions/types";

const initialState = {
    studentAttendence: [],
    summaryLoading : false,
    studentAttendenceSummary: [],
    totalSummary: {},
    summaryCount: 0,
    classSessions: [],
    totalCount: 10,
    studentAttendenceReports: [],
}
export const attendence = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_STUDENT_ATTENDENCE:
            return {
                ...state, studentAttendence: [...action?.payload?.data], totalCount: action?.payload?.totalCount
            }
        case ActionTypes.GET_STUDENT_ATTENDENCE_SUMMARY_REQUEST:
                return {
                    ...state,
                    summaryLoading : true,
                };
        case ActionTypes.GET_STUDENT_ATTENDENCE_SUMMARY:
            return {
                ...state, summaryLoading : false, studentAttendenceSummary: [...action?.payload], totalSummary: action.totalSummary,
                summaryCount : action.summaryCount
            }
        case ActionTypes.GET_STUDENT_ATTENDENCE_REPORTS:
            return {
                ...state,  studentAttendenceReports: [...action?.payload]
            }
        case ActionTypes.GET_SESSIONS:
            return {
                ...state, classSessions: [...action.payload],
            }
        default:
            return state
    }
}