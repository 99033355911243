import ActionTypes from "redux/actions/types";

const initialState = {
    timeTables: [],
    validation : [],
    timeTable: {},
    totalCount:10
}
export const TimeTableReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_TIME_TABLES:
            return {
                ...state, timeTables: [...action.payload.data],totalCount:action.payload?.totalCount?action.payload?.totalCount:state.totalCount
            }
        case ActionTypes.VALIDATE_TIME_TABLE:
                return {
                    ...state, 
                    validation: [...action.payload.data]
                    // ,totalCount:action.payload?.totalCount?action.payload?.totalCount:state.totalCount
                }
        case ActionTypes.GET_TIME_TABLES_BY_ID:
            return {
                ...state, timeTable: { ...action.payload }
            }
        default:
            return state;
    }

}