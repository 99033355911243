import types from "redux/actions/types";
import { IHeaderstateState, HeaderStageActions } from "../types/headerstate";
import { IHeaderstate } from "redux/types/headerstate";

const initialState: IHeaderstateState = {
    loading: true,
    headerstate: {} as IHeaderstate,
};

const heaerStateReducer = (
    state = initialState,
    action: HeaderStageActions
): IHeaderstateState => {
    switch (action.type) {
        case types.HEADERSTATE_UPDATE:
             
            return {
                ...state,
                headerstate: action.payload as IHeaderstate,
            };
        default:
            return state;
    }
};

export default heaerStateReducer;
