import types from '../actions/types'
import {VendorsActions,IInventoryvendor  } from "redux/types/inventoryVendor";


const vendorsInitialState:IInventoryvendor = {
    vendorsData: [],
    totalCount : 0,
    isLoading: false
  };

  const vendorReducer = (state = vendorsInitialState, action: VendorsActions) => {
    switch (action.type) {
      case types.GET_INVENTORY_VENDORS:
        return {
          ...state,
          vendorsData: action.payload.data,
          totalCount: action.payload.totalCount,
          isLoading: false,
        };
      case types.GET_INVENTORY_VENDORS_FAILURE:
        return {
          ...state,
          vendorsData: [],
          totalCount: 0,
          isLoading: false,
        };
      case types.CREATE_INVENTORY_VENDOR:
        return {
          ...state,
          vendorsData: [...state.vendorsData, action.payload.data],
        };
      case types.UPDATE_INVENTORY_VENDOR:
        return {
          ...state,
          vendorsData: state.vendorsData.map((vendor:any) =>
            vendor.id === action.payload.id ? action.payload   : vendor
          ),
        };
      case types.DELETE_INVENTORY_VENDOR:
        return {
          ...state,
          vendorsData: state.vendorsData.filter(
            (vendor:any) => vendor.id !== action.payload.id
          ),
        };
      default:
        return state;
    }
  };
  

export default vendorReducer;