import React, { useState } from "react";
import { Button, Col, Form,  Row, Tab, Table  } from "react-bootstrap";


const PasswordSettings: React.FC = (): JSX.Element => {
 

    return (
       <>
                <div className="c-container">
                   <section className="bg-section mt-3">
                   <div className="d-flex justify-content-between mb-4">
                                    <h3 className="f-24 frmed web-text5">Create & View Password Settings</h3>
                                  
                                    <div>
                                    <Button className="outline-assign me-3"> <img src="images/refresh-icon.svg" /> Reset All Settings</Button>
                                    <Button className="btn-save">+ Create New Settings</Button></div>
                                    </div>

                
                        <div className="mb-4">
                                   <div className="section-small">  
                                   <Form.Control type="search" className="search w-auto" placeholder="Search" />

                                   </div>
                                    
                                </div>
                                <div className="inner-section">
                                  
                                  <Table responsive hover  className="c-table" >
                                    <thead>
                                        <tr>
                                        <th>S.No</th>
                                        <th>User Type Name</th>
                                        <th>AccessLevel</th>
                                        <th>Min Password Length</th>
                                        <th>Max Password Length</th>
                                        <th>Password Validity (InDays)</th>
                                        <th>Type of Complexity</th>
                                        <th>Actions</th>
                                                                        
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td className="bg-td">
                                       1
                                      </td>
                                        <td>
                                        Academic Incharge (Corporate)
                                        </td>

                                        <td className="bg-td">
                                        Organisation
                                        </td>
                                        <td>
                                          5
                                          </td>

                                          <td className="bg-td">
                                          8
                                          </td>
                                        
                                          <td>
                                          365
                                          </td>

                                          <td className="bg-td">
                                            Alphabets and Numerics
                                          </td>

                                        <td>
                                        <a href="#" className="me-3"><img src="images/edit-icon.svg" /></a>
                                           <a href="#"><img src="images/delete-icon.svg" /></a>
                                        </td>
 
                                        </tr>


                                    </tbody>
                                    </Table>                                    
                                </div>
                   </section>
                 </div>
       


       </>
            


       
       
    )
}
export default PasswordSettings;
