import types from "redux/actions/types";
import { IWeeklyOffData, IWeeklyOff } from "redux/types/weeklyOff";

const initialState: IWeeklyOff = {
    loading: true,
    weeklyOff: {} as IWeeklyOffData,
    weeklyOffs: [],
    totalCount: 0
};

const weeklyOffReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_WEEKLY_OFF:
            return {
                ...state,
                loading: false,
            }
        case types.GET_WEEKLY_OFF:
            return {
                ...state,
                loading: false,
                weeklyOffs: action?.payload.data,
                totalCount: action?.payload?.totalCount

            }
        case types.UPDATE_WEEKLY_OFF:

            return {
                ...state,
                weeklyOffs: state.weeklyOffs.map((val: any) =>
                    val._id === action.payload._id ? { ...action.payload.weeklyOff } : val)
            }
        case types.DELETE_STAFF_SHIFTS:
            return {
                ...state,
                weeklyOffs: state?.weeklyOffs?.filter(
                    (deletedId: any) => deletedId._id !== action.payload
                ),
            };
        default:
            return state;
    }
}

export default weeklyOffReducer;
