import types from "redux/actions/types";
import { IAssessments, IAssessmentstats } from "redux/types/Assessments";
const initialState: IAssessmentstats = {
    loading: true,
    totalCount: 0,
    assessment: {} as IAssessments,
    assessments: [],
};
export const AssessmentReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.ASSESSMENT_REGISTER_SUCCESS:
        case types .ASSESSMENT_REGISTER_SUCCESS:
            return {
                ...state,
                assessments: [...state.assessments, action.payload.data.data],
                loading: false,
            };
        case types.GET_ASSESSMENTS:
            return {
                ...state,
                loading: false,
                assessments : action?.payload,
            
            };
        case types.DELETE_ASSESSMENTS:
            return {
                ...state,
                assessments: state?.assessments?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        case types.UPDATE_ASSESSMENTS:

            return {
                ...state,
                assessments: state.assessments.map((clientuser: any) =>
                    clientuser._id === action.payload._id ? { ...action.payload } : clientuser)
            }
        default:
            return state
    }
}
export default AssessmentReducer;