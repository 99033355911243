import axios from "utils/axios";

// API : v1/dashboard/getUserLoginStats
export async function getUserLoginStatus(body) {
    try {
        return await axios.post(`dashboard/getUserLoginStats`, body)

    } catch (error) {
        return error
    }
};

// API : v1/userloginhistory/getUserLoginReport
export async function getLoginReport(body) {
    try {
        return await axios.post(`userloginhistory/getUserLoginReport`, body)

    } catch (error) {
        return error
    }
};
// API : v1/user/auth/google
export async function googleAuth(body) {
    try {
        return await axios.post(`user/auth/google`, body)
    } catch (error) {
        return error
    }
};

// API: v1/userloginhistory/getUserLogs
export async function getUserLogs(data) {
    try {
        return await axios.post(`userloginhistory/getUserLogs`, data);
    } catch (error) {
        return error;
    }
}

// API: v1/userloginhistory/getStudentLogs
export async function getStudentLogs(data) {
    try {
        return await axios.post(`userloginhistory/getStudentLogs`, data);
    } catch (error) {
        return error;
    }
}

// API: v1/user/update/${id}
export async function updateUser(data: any, id?: any) {
    try {
        return await axios.put(`user/update/${id}`, data);
    } catch (error) {
        return error?.response;
    }
}

// API: v1/user/getOrgUsers
export async function getOrgUsers(data: any) {
    try {
        return await axios.post(`user/getOrgUsers`, data);
    } catch (error) {
        return error?.response;
    }
}

// API : v1/userloginhistory/getNeverLoggedReport
export async function getNeverLoggedReport(body) {
    try {
        return await axios.post(`userloginhistory/getNeverLoggedReport`, body)

    } catch (error) {
        return error
    }
};

// API : v1/dashboard/getNeverLoginStats
export async function getNeverLoggedStatus(body) {
    try {
        return await axios.post(`dashboard/getNeverLoginStats`, body)

    } catch (error) {
        return error
    }
};

// API : v1/socialpost/auth/socialMedia
export async function socialMediaAuth(body) {
    try {
        return await axios.post(`socialpost/auth/socialMedia`, body)
    } catch (error) {
        return error
    }
};
