import types from "redux/actions/types";
import { ICredentialsState, CredentialActions } from "redux/types/credentials";
const initialState: ICredentialsState = {
    loading: true,
    totalCount: 0,
    orgCredential : {},
    orgCredentials: [],
};
const credentialsReducer = (state = initialState, action: CredentialActions) => {
    switch (action.type) {
        case types.GET_CREDENTIALS:
            return { ...state, loading: false, orgCredentials: [...action?.payload?.credentials], totalCount: action?.payload?.totalCount }
        case types.CREATE_CREDENTIALS:
            return { ...state, orgCredential: {...action.payload} }
        case types.UPDATE_CREDENTIALS:
            return { ...state, orgCredential: {...action.payload} }
        case types.GET_CREDENTIALS_FAILURE:
            return { ...state, orgCredential : {}, totalCount: 0, loading: false }
        default:
            return state
    }
}

export default credentialsReducer;