import types from "redux/actions/types";
import { ISubSkills, ISubSkillsstats } from "redux/types/SubSkills";
const initialState: ISubSkillsstats = {
    loading: true,
    totalCount: 0,
    subskill: {} as ISubSkills,
    subskills: [],
};
export const SubskillsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.SUBSKILLS_REGISTER_SUCCESS:
        case types .SUBSKILLS_REGISTER_SUCCESS:
            return {
                ...state,
                // subskills: [...state.subskills, action.payload],
                loading: false,
            };
        case types.GET_SUBSKILLS:
            return {
                ...state,
                loading: false,
                subskills : action?.payload?.data.data,
                totalCount : action?.payload?.data?.totalCount,
            
            };
        case types.DELETE_SUBSKILLS:
            return {
                ...state,
                subskills: state?.subskills?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        case types.UPDATE_SUBSKILLS:

            return {
                ...state,
                subskills: state.subskills.map((clientuser: any) =>
                    clientuser._id === action.payload._id ? { ...action.payload } : clientuser)
            }
        default:
            return state
    }
}
export default SubskillsReducer;