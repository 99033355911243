import types from "redux/actions/types";
import { IAcademicsectionState, AcademicsectionActions } from "../types/academicSection";
import { IAcademicsection } from "redux/types/academicSection";

const initialState: IAcademicsectionState = {
  loading: true,
  academicsection: {} as IAcademicsection,
  academicsections: [] as IAcademicsection[],
};

const academicSectionReducer = (state = initialState, action: AcademicsectionActions) => {
  switch (action.type) {
    case types.ACADEMICSECTION_REGISTER_SUCCESS:
    case types.ACADEMICSECTION_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        academicsections: [...state.academicsections, action.payload] as IAcademicsection[],
      };
    case types.GET_ACADEMICSECTIONS:
      return {
        ...state,
        loading: false,
        academicsections: action.payload as IAcademicsection[],
      };

    case types.DELETE_ACADEMICSECTION:
      return {
        ...state,
        academicsections: state.academicsections.filter(
          (academicsection) => academicsection._id !== action.payload
        ),
      };

    case types.UPDATE_ACADEMICSECTION:
      return {
        ...state,
        academicsections: state.academicsections.map((academicsection) =>
        academicsection._id === action.payload._id
            ? { ...action.payload.academicsection }
            : academicsection
        ),
      };
    case types.ACADEMICSECTION_REGISTER_FAIL:
    case types.ACADEMICSECTION_AUTH_ERROR:

    default:
      return state;
  }
};
export default academicSectionReducer;
