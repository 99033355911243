import types from '../actions/types'
import {ReportCardActions,IReportCard  } from "redux/types/reportCard";


const reportCardInitialState:IReportCard = {
    reportCardData: [],
    totalCount : 0,
    isLoading: true,
    overallDetails : {},
    coScholasticMarksDetails : {},
    remarks: ""
  };

const reportCardReducer = (state = reportCardInitialState, action:ReportCardActions) => {
  
  switch (action.type) {
    case types.GET_REPORT_CARD_SUCCESS:
      return {
        ...state,
        reportCardData: action.payload.data,
        isLoading : false,
        totalCount: action.payload.totalCount,
        overallDetails : action.payload.overAllDetails,
        coScholasticMarksDetails : action.payload.coScholasticMarksDetails,
        remarks: action.payload.remark
      };
      case types.GET_REPORT_CARD_FAILURE:
      return {
        ...state,
        reportCardData: [],
        remarks : "",
        overallDetails : {},
        coScholasticMarksDetails : {},
        isLoading : false,
      };
    default:
      return state;
  }
};

export default reportCardReducer;