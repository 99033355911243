import types from "redux/actions/types";
import { IOrgSourceState, IOrgSource,OrgSourceActions } from "../types/orgSources";

const initialState: IOrgSourceState = {
    loading: true,
    orgSource: { } as IOrgSource,
    orgSources: [] as IOrgSource[],
    totalCount: 0

};

const orgSourceReducer = (state = initialState, action: OrgSourceActions) => {
    switch (action.type) {
        case types.SOURCE_REGISTER_SUCCESS:
        case types.SOURCE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                orgSources: [...state.orgSources, action.payload] as IOrgSource[],
            };
        case types.GET_SOURCES:
            return {
                ...state,
                loading: false,
                orgSources: action?.payload as IOrgSource[],
                totalCount: action?.payload?.length,
            };

        case types.DELETE_SOURCE:
            return {
                ...state,
                orgSources: state?.orgSources?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };

        case types.UPDATE_SOURCE:
            return {
                ...state,
                orgSources: state.orgSources.map((cla:any) =>
                cla._id === action.payload.id
                    ? { ...action.payload }
                    : cla
                ),
            };
        case types.SOURCE_REGISTER_FAIL:
            return state;
        case types.SOURCE_AUTH_ERROR:

        default:
            return state;
    }
};
export default orgSourceReducer;
