import { Dispatch } from "redux";
import axios from "utils/axios";
import types from "./types";
import { setAlert } from "./alert";
import { HeaderStageActions } from "redux/types/headerstate";
import { AlertActions } from "redux/types/alert";

// UPDATESTATE
export const registerHeaderstate =
    (body: any) =>
        async (dispatch: Dispatch<HeaderStageActions>) => {
        
            try {
                dispatch({
                    type: types.HEADERSTATE_UPDATE,
                    payload: body,
                });
            } catch (error: any) {
                
            }
    
        };
