import { Dispatch } from "redux";
import axios from "utils/axiosnoti";
import types from "./types";
import { setAlert } from "./alert";
import { NotificationsActions } from "redux/types/notifications";
import { AlertActions } from "redux/types/alert";

export const getNotifications =
    (body: any) => async (dispatch: Dispatch<NotificationsActions | AlertActions>) => {
        
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(`notificationDump/getnotifications`, body, config)                     
            dispatch({ type: types.GET_NOTIFICATIONS, payload: data?.data });
        } catch (error: any) {
            // dispatch<any>(
            //     setAlert({
            //         msg: "Something went wrong when fetching the Notification!",
            //         status: error?.response?.status,
            //         alertType: "danger",
            //     })
            // );
        }
    };

export const updateReadNotificationCount =
    (body: any) => async (dispatch: Dispatch<NotificationsActions | AlertActions>) => {
        
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            await axios.put(`notificationDump/updatenotificationcount`, body, config) 
            dispatch({ type: types.UPDATE_READ_NOTIFICATIONS });
        } catch (error: any) {
            
        }
    };
export const updateOpenNotificationCount =
    (body: {notificationId : string}, userId) => async (dispatch: Dispatch<NotificationsActions | AlertActions>) => {
        
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            await axios.put(`notificationDump/updatenotification`, body, config)                     
        } catch (error: any) {
        } finally {
            dispatch<any>(getNotifications({userId}));
        }
    };

