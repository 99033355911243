import React from 'react';
import { Button, Form, Table } from 'react-bootstrap';
const AcademicyearMapping: React.FC = (): JSX.Element => {
  return (
    <div className="c-container">
      <section className="bg-section mt-3">
        <div className="d-flex justify-content-between mb-4">
          <h3 className="f-24 frmed web-text5">Academic Year Mapping</h3>
          <Button className="btn-save">Save & Update</Button>
        </div>
        <div className="note mb-4">
          <h5 className="frbold">Note:</h5>
          <p>
            Use this screen to hide / show academic years, you cannot hide the
            current running academic year.
          </p>
        </div>
        <div className="inner-section">
          <Table responsive hover  className="c-table">
            <thead>
              <tr>
                <th>#</th>
                <th>AcademicYear</th>
                <th>Is PreAdmission AcademicYear</th>
                <th>Is Current AcademicYear</th>
                <th>Is Disable Parent Login</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Form.Check inline name="group" className="me-0" />
                </td>
                <td>2018 - 2019</td>
                <td>
                  <Form.Check inline name="group" className="me-0" />
                </td>
                <td>
                  <Form.Check inline name="group" className="me-0" />
                </td>
                <td>
                  <Form.Check inline name="group" className="me-0" />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </section>
    </div>
  );
};
export default React.memo(AcademicyearMapping);
