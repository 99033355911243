import types from "redux/actions/types";
import { ICreateuserState, CreateuserActions } from "../types/createuser";
import { ICreateuser } from "redux/types/createuser";

const initialState: ICreateuserState = {
    loading: true,
    createuser: {} as ICreateuser,
    createusers: [] as ICreateuser[],
    totalCount:0

};

const createuserReducer = (state = initialState, action: CreateuserActions): ICreateuserState => {
    switch (action.type) {
        case types.CREATEUSER_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // feeplans: [...state.feeplans, action.payload] as IFeeplan[],
              };
        case types.GET_CREATEUSERS:
            return {
                ...state,
                loading: false,
                createusers: action?.payload?.data as ICreateuser[],
                totalCount: action?.payload?.totalCount as any,
            };

        case types.DELETE_CREATEUSER:
            return {
                ...state,
                createusers: state.createusers.filter((user) => user._id !== action.payload),
            };

        case types.UPDATE_CREATEUSER:
            return {
                ...state,
                createusers: state.createusers.map((clientuser:any) =>
                    clientuser._id === action.payload.id ? { ...action.payload.createuser } : clientuser
                ),
            };
        case types.CREATEUSER_REGISTER_FAIL:
            return {
                ...state,
                loading: false,
                createuser: {} as ICreateuser,
            };
        case types.CREATEUSER_LOGIN_FAIL:
        case types.CREATEUSER_AUTH_ERROR:
            localStorage.removeItem("user__token");
            return {
                ...state,
                loading: false,
                createuser: {} as ICreateuser,
            };

        default:
            return state;
    }
};

export default createuserReducer;
