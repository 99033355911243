
import ActionTypes from "../actions/types";
import { AllExamScheduleActions } from "redux/types/examschedule";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    examschedule : {},
    examschedules : [],
    halltickets : [],
    students : [],
    examtypes : [],
    terms : [],
}

const examSchedule = (state = initialState, action : AllExamScheduleActions) => {
    switch(action.type){
        case ActionTypes.GET_ALL_EXAM_SCHEDULE_REQUEST:
        case ActionTypes.CREATE_EXAM_SCHEDULE_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_ALL_EXAM_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading : false,
                examschedules : action.payload.data,
                // examschedule : action.payload.data[0],
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_ALL_EXAM_TYPES:
            return {
                ...state,
                loading : false,
                examtypes : action.payload?.data?.examTypes,
                terms : action.payload?.data?.terms,
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_ALL_EXAM_SCHEDULE_FAILURE:
            return {
                ...state,
                loading : false,
                examschedules : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.CREATE_EXAM_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading : false,
                students : action.payload.data,
                examschedule: action.payload.schedule,
                error : '',
            }
        case ActionTypes.CREATE_EXAM_SCHEDULE_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
        case ActionTypes.GET_EXAM_SCHEDULE_BY_ID:
            return {
                ...state, 
                loading : false,
                examschedule: action.payload.data
            }
        case ActionTypes.UPDATE_EXAM_SCHEDULE_SUCCESS:
            return {
                ...state,
                examschedules: state.examschedules.map((examschedule) =>
                examschedule._id === action.payload.id
                    ? { ...action.payload.examschedule }
                    : examschedule
                ),
            };
        case ActionTypes.DELETE_EXAM_SCHEDULE:
            return {
                ...state,
                examschedules: state.examschedules.filter(
                (examschedule) => examschedule._id !== action.payload
                ),
            };
        default : return state;
    }
}

export default examSchedule;