import React, { useState, useEffect } from 'react'
import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createFavorates, removeFavorates } from 'redux/actions/favorates';
import { RootState } from 'redux/reducers';
import ToolTip from 'components/shared/ToolTip'

function Favorites() {
    const userModules: any = useSelector((state: RootState) => state?.user?.permissions[0]?.BMRP);
    const [favourite, setFavourite] = useState<any>([])
    const [subModule,setSubModule] = useState<any>([])
    const dispatch = useDispatch();
    const userId = localStorage.userId;
    const navigate = useNavigate()
    const { favorates } = useSelector((state: any) => state.Favorates)

    useEffect(()=>{
        setFavourite(userModules)
    },[])
    useEffect(()=>{
        if(userModules && userModules.length){
            getSubModules("Module")
        }else{
            setFavourite(userModules)           
        }
    },[userModules])
    const setPaths = (rp:any,module:any)=>{
        let _id = rp.branchmodule._id                            
        let route = "/modules/" + module.route_path + "/" + rp.branchmodule.module.route_path;
        let image = "/images/modules/submoduleslist/" + module.route_path + "/" + rp.branchmodule.module.route_path;
        let title = rp.branchmodule.module.name;
        if (rp.branchmodule.module.alias_name) title = rp.branchmodule.module.alias_name;
        else if (rp.branchmodule.alias_name) title = rp.branchmodule.alias_name;
        return { _id,title, route, image}
    }
    const getSubModules = (moduleName: any) => {
        const BMRP = userModules
        let [tempData,modules, submodules, submodulesArr] = [[],[], [], []];
        if(moduleName === "Module"){
            for (let rp of BMRP){
                if (rp.branchmodule.module.type === "module"){
                    modules.push({_id : rp.branchmodule.module._id, 
                        name: rp.branchmodule.module.name, 
                        route_path : rp.branchmodule.module.route_path, 
                        subModule : []});
                }
                if (rp.branchmodule.module.type === "submodule") submodules.push(rp.branchmodule.module);
            }
            for (let submod of submodules){
                for (let module of modules){ 
                    if(submod.parentId === module._id) module.subModule.push(submod._id);
                }
            }            
            for (let rp of BMRP){
                if (rp.branchmodule.module.type === "submoduleroute"){
                    const parentModuleId = rp.branchmodule.module.parentId;
                    for (let module of modules){
                        if (module.subModule.includes(parentModuleId)) tempData.push(setPaths(rp,module));
                    }
                }        
            }setFavourite(tempData)
            setSubModule([])
        }else{
            for (let rp of BMRP){
                if (rp.branchmodule.module.type === "module" && rp.branchmodule.module.name===moduleName){
                    modules.push({_id : rp.branchmodule.module._id, 
                        name: rp.branchmodule.module.name, 
                        route_path : rp.branchmodule.module.route_path, 
                        subModule : []});
                }
                if (rp.branchmodule.module.type === "submodule") submodules.push(rp.branchmodule.module); 
            }
            for (let submod of submodules){
                if(submod.parentId === modules[0]?._id){
                    modules[0]?.subModule.push(submod._id);
                    submodulesArr.push({_id:submod._id,name:submod.name,route_path : modules[0].route_path,})
                }
            }
            setSubModule(submodulesArr)
            for (let rp of BMRP){
                if (rp.branchmodule.module.type === "submoduleroute"){
                    const parentModuleId = rp.branchmodule.module.parentId;
                    if (modules[0]?.subModule.includes(parentModuleId)){
                        setPaths(rp,modules[0])
                        tempData.push(setPaths(rp,modules[0]));
                    }
                }        
            }setFavourite(tempData)
        }
    }
    const setSubModulesPages = (moduleId)=>{
        let module = {route_path:""}
        subModule.forEach((item)=>{
            if(item._id===moduleId){
                module = item
                return
            }
        })
        let subModulePagesArr = []
        for (let rp of userModules){
            if (rp.branchmodule.module.type === "submoduleroute"){
                const parentModuleId = rp.branchmodule.module.parentId;
                if (moduleId===parentModuleId) subModulePagesArr.push(setPaths(rp,module));
            }        
        }setFavourite(subModulePagesArr)
    }
    const createFavorate = (id) => {
        dispatch(createFavorates([{ "userId": userId, "branchmoduleId": id }]))
    }
    const removeFavorate = (id: any) => {
        dispatch(removeFavorates([{ "_id": id, "userId": userId, }]))
    }
    const onChangeFavorate = (item: any) => {
        let isExist = false;
        favorates.map(fav => {
            if (fav?.branchmoduleId == item?._id) {
                removeFavorate(fav?._id);
                isExist = true;
                return;
            }
        })
        !isExist && createFavorate(item?._id);
    }
    return (
        <div className='theme-content-container'>
            <div className='theme-content-heading'>
                <h6>Favorites</h6>
                <ToolTip info='Favorites'/>
            </div>
            <div className="theme-content-filters theme-content-filter-justify-start">
                <Form.Select className="theme-input-control width-filter" onChange={(e: any) => { getSubModules(e.target.value) }}>
                        <option>Module</option>
                        {
                            userModules?.map((module: any) => (
                                module?.branchmodule?.module?.type == 'module' && (
                                        <option value={module?.branchmodule?.module?.name}>{module?.branchmodule?.module?.name}</option>
                                )))
                        }
                </Form.Select>
                <Form.Select className="theme-input-control width-filter"  disabled={!subModule.length?true:false} onChange={(e: any) => { setSubModulesPages(e.target.value) }}>
                        <option>Sub Module</option>
                        {
                            subModule?.map((module: any) => (
                                    <option value={module._id}>{module?.name}</option>
                                ))
                        }
                </Form.Select>
                <div>
                    <InputGroup className="mb-0">
                            <FormControl
                                placeholder="Search Here"
                                aria-label="Search Here"
                                aria-describedby="basic-addon2"
                                className="theme-input-control search-control"
                            />
                    </InputGroup>
                </div>
            </div>
            <Row className='w-100 px-2'>
                {
                    favourite?.map((item: any) => (
                        <Col md={3}>
                            <div className='fav-card p-2 mt-3'>
                                <div className='d-flex justify-content-between align-items-center' onClick={(e: any) => { onChangeFavorate(item) }}>
                                    <div className='d-flex align-items-center'>
                                    <img
                                        src={`${item?.image}.svg`}
                                        className="me-2 pe-1 pb-1"
                                    />
                                    <small className='fst-normal fw-bold' style={{marginLeft:"-0.5rem"}}>{item?.title}</small>
                                    </div>
                                    <img src={`/images/${favorates.some(fav => fav?.branchmoduleId == item?._id)?"favourites-star":"favouriteStar"}.svg`} className="favourite-star-icon"/>
                                </div>
                                <div className='text-right mt-3'>
                                    <small className='f-11 text-primary-col' onClick={() => { navigate(item?.route) }}>View Page</small>
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}

export default Favorites