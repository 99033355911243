import types from '../actions/types'
import {IItemCreation,IItemCreationData,ItemCreationActions } from "redux/types/itemCreation";


const itemInitialState:IItemCreation = {
    itemsData: [] as IItemCreationData[],
    totalCount : 0,
    isLoading: false,
    stockitems:[],
    storeItems: []
  };

  const itemCreationReducer = (state = itemInitialState, action: ItemCreationActions) => {
    switch (action.type) {
      case types.GET_ITEM_CREATION_ITEMS_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
        
      case types.GET_ITEM_CREATION_ITEMS:
        return {
          ...state,
          itemsData: action.payload.data,
          totalCount: action.payload.totalCount,
          isLoading: false,
        };
      case types.GET_ITEM_CREATION_ITEMS_FAILURE:
        return {
          ...state,
          itemsData: [],
          totalCount: 0,
          isLoading: false,
        };
      case types.CREATE_ITEM_CREATION_ITEM:
        return {
          ...state,
          itemsData: [...state.itemsData, action.payload.data],
        };
      case types.UPDATE_ITEM_CREATION_ITEM:
        return {
          ...state,
          itemsData: state.itemsData.map((item:any) =>
            item.id === action.payload.id ? action.payload   : item
          ),
        };
      case types.DELETE_ITEM_CREATION_ITEM:
        return {
          ...state,
          itemsData: state.itemsData.filter(
            (item:any) => item.id !== action.payload.id
          ),
        };
        case types.GET_STOCK_ITEMS:
          return {
            ...state,
            stockitems: action.payload,
            isLoading: false,
          };
        case types.GET_ITEMS_BY_STOREID:
          return {
            ...state,
            storeItems: action.payload,
            isLoading: false,
          };

      default:
        return state;
    }
  };
  

export default itemCreationReducer;