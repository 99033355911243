import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";

const StudentparentAuthentication: React.FC = (): JSX.Element => {
  const [checked, setChecked] = React.useState(true);
  return (
    <>
      
      <div className="note mb-4">
                <h5 className="frbold">Note:</h5>
                  <p>If you want to restrict any user to work from a dedicated Desktop / Laptop, you can define that desktop / laptop MAC against that user, so that the user access will be opened only from that desktop/laptop. Best suited for Accountants for fee collections.</p>
                </div>

                <h3 className="f-24 frmed web-text5 mb-4">Second level login authentication settings for Students and Parents:</h3>
           

                                  <div className="d-flex align-items-start justify-content-start mb-3">                                           
                                        <div className="checkbox">                                           
                                        <input type="checkbox" id="checkbox2" defaultChecked={checked}
                                            onChange={() => setChecked(!checked)} />
                                        <label htmlFor="checkbox2"></label>
                                        </div>
                                        
                                        <div className="ms-3">
                                        <h4 className="frmed web-text mb-0">Email OTP (Recommended)</h4>
                                        <p className="web-text7 mb-0">(Each time, when Parent / Student logins in, a 6 digit OTP will be sent to registered email address for authentication)</p>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-start justify-content-start mb-3">                                           
                                        <div className="checkbox">                                           
                                        <input type="checkbox" id="checkbox3"  />
                                        <label htmlFor="checkbox3"></label>
                                        </div>
                                        
                                        <div className="ms-3">
                                        <h4 className="frmed web-text6 mb-0">MPIN</h4>
                                        <p className="web-text7 mb-0">(Each time, when Parent / Student logins in, 4 digit MPIN needs to be entered which will be valid for web and smart school app)</p>
                                        </div>
                                    </div>


                                    <div className="d-flex align-items-start justify-content-start mb-3">                                           
                                        <div className="checkbox">                                           
                                        <input type="checkbox" id="checkbox3"  />
                                        <label htmlFor="checkbox3"></label>
                                        </div>
                                        
                                        <div className="ms-3">
                                        <h4 className="frmed web-text6 mb-0">No OTP (Not recommended)</h4>
                                        <p className="web-text7 mb-0">(No additional authentication except the login credentials) </p>
                                        </div>
                                    </div>






    </>
  )
}
export default React.memo(StudentparentAuthentication);
