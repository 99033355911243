import { Dispatch } from "redux";
import axios from "utils/axios";
import types from "./types";
import { setAlert } from "./alert";
import { OrganizationActions } from "redux/types/organization";
import { AlertActions } from "redux/types/alert";

// REGISTER Module
export const getOrgbyId =
    (id: any) =>
        async (dispatch: Dispatch<OrganizationActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.get(`organization/getOrganizationDetails/${id}`, config);
                dispatch({
                    type: types.GET_ORGANIZATIONS,
                    payload: data,
                });

            } catch (error: any) {
                dispatch({ type: types.ORGANIZATION_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: error?.response?.data,
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };
// GET Modules
export const getOrgForLogin = (id: any) =>
    async (dispatch: Dispatch<OrganizationActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.get(`organization/getOrgDetailsForLogin/${id}`, config);
            dispatch({
                type: types.GET_ORGANIZATIONS,
                payload: data,
            });

        } catch (error: any) {
            dispatch({ type: types.ORGANIZATION_REGISTER_FAIL });
            dispatch<any>(
                setAlert({
                    msg: error?.response?.data,
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
        // finally {
        //   setSubmitting(false);
        // }
    };

export const updateOrgInfo =
    (body: any, id: any, history: any, setIsLoading?: any) =>
        async (dispatch: Dispatch<OrganizationActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.patch(
                    `organization/patchOrganization/${id}`,
                    body,
                    config
                );
                dispatch({
                    type: types.UPDATE_ORGANIZATION,
                    payload: data,
                });

                dispatch<any>(
                    setAlert({
                        msg: "Organization Information Updated!",
                        status: 200,
                        alertType: "success",
                    })
                );
                dispatch<any>(getOrgbyId(id));
            } catch (error: any) {
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when updating the Organization Info!",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            } finally {
                if (setIsLoading) {
                    setIsLoading(false);
                }

            }
        };

export const getOrganizations = () => {
   return async (dispatch:any) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            }
        };
        try {
            const { data } = await axios.get(`organization/getAllOrganizations`, config);
            dispatch({
                type: types.GET_ALL_ORGANIZATIONS,
                payload: data,
            });
        } catch (error: any) {
            dispatch(
                setAlert({
                    msg: error?.response?.data,
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };
}
export const validateDomain = (body: {subdomain: string}) => {
   return async (dispatch:any) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            }
        };
        try {
            const { data } = await axios.post(`organization/validateDomain`, body, config);
            dispatch({
                type: types.VALIDATE_ORGANIZATION_BY_SUBDOMAIN,
                payload: data.data,
            });
        } catch (error: any) {
            dispatch(
                setAlert({
                    msg: error?.response?.data,
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
   };
}

    export const uploadBackgroundImage = (body: {orgId: string,imageArray: string[]}) => {
        return async (dispatch:any) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                }
            };
            try {
                const { data } = await axios.post(`organization/setBannerImages`, body, config);
                dispatch({
                    type: types.SET_ORGANIZATION_BACKGROUND_IMAGE,
                    payload: data,
                });
                dispatch(
                    setAlert({
                        msg: "Background Image / Video uploaded successfully",
                        status: 200,
                        alertType: "success",
                    })
                );
            } catch (error: any) {
                dispatch(
                    setAlert({
                        msg: "Something went wrong when uploading Background Image",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
        };
}
