import axios from "utils/axios";

// API : v1/initialsetup/get
export async function getInitialSetup(data) {
    try {
        return await axios.post(`initialsetup/getInitialSetupData`, data);

    } catch (error) {
        return error
    }
}


// API : v1/initialsetup/skip
export async function skipInitialSetup(data) {
    try {
        return await axios.post(`initialsetup/skip`, data);

    } catch (error) {
        return error
    }
}
