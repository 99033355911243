import React from "react";

type Props = {
    dimentions : {"width" : number, "height" : number, "radius" ?: number, fontSize ?: number};
    text : {firstName: string, lastName: string};
    shape : "rounded" | "squared";
    classes ?: string;
}

const DefaultProfileImage: React.FC<Props> = ({dimentions, text, shape, classes}): JSX.Element => {

  const [color,setColor] = React.useState("#866666");
    React.useEffect(() => {
      let color = "#2B4378"
      setColor(color);
    }, [])
    return (
        <>
        {shape === "rounded" &&
      <div style={{width : dimentions.width + "px", height: dimentions.height + "px", fontSize : dimentions.fontSize ? dimentions.fontSize + "px" : dimentions.width/2 + "px", backgroundColor : color}} className={`rounded-circle d-flex align-item-center justify-content-center + ${classes ? classes : ""}`} >
        <p className=" mb-0 text-light text-decoration-none text-center d-flex align-self-center"><span className="align-self-center">{text.firstName[0].toUpperCase()}</span><span className="align-self-center">{text.lastName[0].toUpperCase()}</span></p>
      </div>
        }
        {shape === "squared" && 
      <div style={{width : dimentions.width + "px", height: dimentions.height + "px", borderRadius: dimentions.radius ? dimentions.radius : "15" + "px", fontSize : dimentions.fontSize ? dimentions.fontSize + "px" : dimentions.width/2 + "px", backgroundColor : color}} className={`d-flex align-item-center justify-content-center  + ${classes ? classes : ""}`}>
        <p className=" mb-0 text-light text-decoration-none text-center d-flex align-self-center"><span className="align-self-center">{text.firstName[0].toUpperCase()}</span><span className="align-self-center">{text.lastName[0].toUpperCase()}</span></p>
      </div>
        }
        </>
    );
  };
  
export default React.memo(DefaultProfileImage);