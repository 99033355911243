
import React, { useState, useEffect } from 'react';
import { NavDropdown } from "react-bootstrap";

import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useNavigate, useLocation } from 'react-router-dom';

const SubheaderList: React.FC<any> = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation()
  let paths = location.pathname.split("/");

  const _user: any = useSelector((state: RootState) => state.user);
  const [subModuleRoutes, setSubModuleRoutes] = useState<any>([]);
  const [subModules, setSubmodules] = useState([])

  const getSubModuleRoutes = (parentId: any) => {
    if (!_user.loading && _user.permissions.length > 0) {
      let sModuleRoutes = _user.permissions[0].BMRP.filter((mainModule: any) => {
        if (mainModule?.branchmodule?.module?.type == "submoduleroute" && mainModule?.branchmodule?.module?.parentId == parentId)
          return mainModule
      })
      return sModuleRoutes
    }
  }

  useEffect(() => {
    let id = _user.permissions[0]?.BMRP?.filter((mainModule: any) => mainModule && mainModule?.branchmodule?.module?.route_path?.toLowerCase() == paths[2]?.toLowerCase())[0]?.branchmodule?.module?._id
    let data: [] = _user.permissions[0]?.BMRP?.filter((item: any) => item?.branchmodule?.module?.type == 'submodule' && item?.branchmodule?.module?.parentId == id)
    data?.sort((a: any, b: any) => a?.branchmodule?.module?.sequenceNumber - b?.branchmodule?.module?.sequenceNumber)
    setSubmodules(data)
  }, [_user, location])

  return (
    <>
      {
        subModules && subModules?.map((item) => (
          <NavDropdown onClick={() => setSubModuleRoutes([...getSubModuleRoutes(item?.branchmodule?.module._id)])}
            key={item?._id}
            title={<><img style={{ marginBottom: "1px" }} className='me-1' src={`/images/modules/submoduleslist/${paths[2]}/${item?.branchmodule?.module?.route_path}.svg`} alt="" /> <span className='fs-text me-1'>{item?.branchmodule?.alias_name}</span><img className='rotate-180' src="/images/down-arrow-white.svg" /></>}
            id="basic-nav-dropdown">
            {subModuleRoutes
              .sort((a, b) => a?.branchmodule?.module?.sequenceNumber - b?.branchmodule?.module?.sequenceNumber)
              .map((smr: any) => (
                <NavDropdown.Item key={smr._id} onClick={() => navigate(`${paths[1]}/${paths[2]}/${smr.branchmodule.module.route_path}`)} ><img className='me-2' src={`/images/modules/submoduleslist/${paths[2]}/${smr.branchmodule.module.route_path}.svg`} alt="" /> <span className="fs-text">{(smr.branchmodule.alias_name || (smr.branchmodule?.module?.name || ""))}</span></NavDropdown.Item>
              ))}
          </NavDropdown >
        ))
      }
    </>
  )
}
export default React.memo(SubheaderList);

<img src="/images/down-arrow.svg" className='rotate-180' />
