import ActionTypes from "redux/actions/types";
const initialState = {
    staffEnrolmentSequences: [],
    singleStaffSequences: ''
};
export const staffEnrollmentSequence = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.ENROLLMENT_STAFF_SEQUENCE_REGISTER_SUCCESS:
        case ActionTypes.ENROLLMENT_STAFF_SEQUENCE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ActionTypes.GET_ENROLLMENT_STAFF_SEQUENCES:
            return { ...state, staffEnrolmentSequences: [...action?.payload] }
        case ActionTypes.GET_ENROLLMENT_STAFF_SEQUENCE:
            return { ...state, singleStaffSequences: { ...action?.payload } }
        case ActionTypes.DELETE_SEQUENCE:
            return {
                ...state,
                staffEnrollmentSequence: state?.staffEnrolmentSequences?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        default:
            return state
    }
}