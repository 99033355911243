import * as React from 'react';
import {
    Routes,
    Route,
} from 'react-router-dom';
import { totalRoutes } from "pages/Home/routes";
import HeaderMain from './HeaderMain';
import PrivateRoute from 'components/routing/PrivateRoute';
import AppLoader from 'layouts/AppLoader';
import AuthPrivateRoute from 'components/routing/AuthPrivateRoute';
import SubDomainCheck from 'components/routing/SubDomainCheck';
import Footer from './Footer';

export default React.memo(function Home() {
    return (<>
        <Routes >
            <Route element={<PrivateRoute><><HeaderMain /> <Footer /></></PrivateRoute>}>
                {totalRoutes.map(({ component: Component, path, auth }) => {
                    return (auth == true ? (<Route key={path} path={path} element={
                        <PrivateRoute>
                            <React.Suspense fallback={<AppLoader />}>
                                <Component />
                            </React.Suspense>
                        </PrivateRoute>
                    } />) : null)
                })}
            </Route>
            <Route >
                {totalRoutes.map(({ component: Component, path, auth, subDomainCheck }) => {
                    if(subDomainCheck === true && auth === false){
                        return (<Route key={path} path={path} element={
                        <AuthPrivateRoute>
                            <SubDomainCheck>
                                <React.Suspense fallback={<AppLoader />}>
                                    <Component />
                                </React.Suspense>
                            </SubDomainCheck>
                        </AuthPrivateRoute>
                        } />)}
                    return (auth == false ? (<Route key={path} path={path} element={
                        <AuthPrivateRoute>
                            <React.Suspense fallback={<AppLoader />}>
                                <Component />
                            </React.Suspense>
                        </AuthPrivateRoute>
                    } />) : null)
                })}
            </Route>
        </Routes>

    </>
    );
})
