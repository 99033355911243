import types from "redux/actions/types";
import { ILogoState, LogoActions } from "../types/logosettings";
import { ILogo } from "redux/types/logosettings";

const initialState: ILogoState = {
    loading: true,
    logo: {} as ILogo,
    logos: [] as ILogo[],
};

const logoReducer = (
    state = initialState,
    action: LogoActions
): ILogoState => {
    switch (action.type) {
        case types.LOGO_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                logo: action.payload as ILogo,
            };
        case types.ORGLOGO_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.BRANCHLOGO_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.GET_LOGOS:
            return {
                ...state,
                loading: false,
                logos: action.payload as ILogo[],
            };

        case types.DELETE_LOGO:
            return {
                ...state,
                logos: state.logos.filter(
                    (logo) => logo._id !== action.payload
                ),
            };

        case types.UPDATE_LOGO:
            return {
                ...state,
                logos: state.logos.map((logo) =>
                    logo._id === action.payload.id
                        ? { ...action.payload.logo }
                        : logo
                ),
            };
        case types.LOGO_REGISTER_FAIL:
        case types.LOGO_AUTH_ERROR:

        default:
            return state;
    }
};

export default logoReducer;
