import React, { useState } from "react";
import { Button, Col, Form,  Row, Tab, Table  } from "react-bootstrap";


const UsertypePreferences: React.FC = (): JSX.Element => {
    const [checked, setChecked] = React.useState(true);

    return (
       <>
                <div className="c-container">
                   <section className="bg-section mt-3">
                   <div className="d-flex justify-content-between mb-4">
                                    <h3 className="f-24 frmed web-text5">User Type Preferences (Parent Edit Information)</h3>
                                    <Button className="btn-save">+ Create New Settings</Button>
                                    </div>

                
                        <div className="mb-4">
                                   <div className="section-small">  
                                   <Form.Label>Status</Form.Label>                                
                                    <Form.Select className="w-auto">
                                        <option>Active</option>
                                        <option>InActive</option>
                                    </Form.Select>

                                   </div>
                                    
                                </div>
                                <div className="inner-section">
                                  
                                  <Table responsive hover  className="c-table" >
                                    <thead>
                                        <tr>
                                        <th>S.No</th>
                                        <th>Date Range</th>
                                        <th>Branch Name</th>
                                        <th>Status</th>
                                                                        
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>  
                                       1
                                            </td>
                                        <td className="web-text4">
                                        15 Dec 2021 to 15 Feb 2022
                                        </td>

                                        <td>
                                        AIMS Group of institution's
                                        </td>

                                        <td>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className="me-2">Active</span>
                                        <div className="checkbox">                                           
                                        <input type="checkbox" id="checkbox2" defaultChecked={checked}
                                            onChange={() => setChecked(!checked)} />
                                        <label htmlFor="checkbox2"></label>
                                        </div>
                                        </div>
                                        </td>
 
                                        </tr>


                                        <tr>
                                        <td>  
                                       1
                                            </td>
                                        <td className="web-text4">
                                        25 Nov 2021 to 25 Jan 2022
                                        </td>

                                        <td>
                                        ACE Engineering College
                                        </td>

                                        <td>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className="me-2">InActive</span>
                                        <div className="checkbox">                                           
                                        <input type="checkbox" id="checkbox3"/>
                                        <label htmlFor="checkbox3"></label>
                                        </div>
                                        </div>
                                        </td>
 
                                        </tr>

                                    </tbody>
                                    </Table>                                    
                                </div>
                   </section>
                 </div>
       


       </>
            


       
       
    )
}
export default UsertypePreferences;
