import { setAlert } from 'redux/actions/alert';
import axios from "utils/axios"
import ActionTypes from './types';

export const createFavorates = (body: any) => {
    return async (dispatch: any) => {
        try {
            const res = await axios.post('favorites/create', body);
            dispatch(getFavorates({userId:localStorage.userId}))
        } catch (error: any) {
            dispatch(setAlert({
                msg: "Something went wrong!",
                status: error?.response?.status,
                alertType: "danger",
            }
            ))
        }
    }
}

export const getFavorates = (body: any) => {
    return async (dispatch: any) => {
        try {
            const { data } = await axios.post('favorites/getFavorites', body);
            dispatch({ type: ActionTypes.GET_FAVORATES, payload: data.data });
        }
        catch (error: any) {
            dispatch(setAlert({
                msg: "Something went wrong!",
                status: error?.response?.status,
                alertType: "danger",
            }
            ))
        }
    }
}

export const removeFavorates = (body: any) => {
    return async (dispatch: any) => {
        try {
            const res = await axios.post('favorites/remove', body);
            dispatch(getFavorates({userId:localStorage.userId}))
        } catch (error: any) {
            dispatch(setAlert({
                msg: "Something went wrong!",
                status: error?.response?.status,
                alertType: "danger",
            }
            ))
        }
    }
}