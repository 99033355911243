import React from "react";
import { Button, Col, Form, ListGroup, Row, Tab, Table  } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'

const AssignSection: React.FC = (): JSX.Element => {
    return (
       <>
                <div className="c-container">
                   <section className="bg-section mt-3">
                       

                    <Row>
                        
                        <Col sm={4}>

                        <div className="section-small">
                                <div className="mb-3">
                                    <Form.Label>Locations</Form.Label>
                                    <Form.Select>
                                        <option>All Schools</option>
                                    </Form.Select>
                                </div>
                                <div className="mb-3">
                                    <Form.Label>Academic Year</Form.Label>
                                    <Form.Select>
                                        <option>2020 - 2021</option>
                                    </Form.Select>
                                </div>


                                <Form.Label>Branches List</Form.Label>
                          <div className="inner-section-small">
                          <Tabs defaultActiveKey="Schools" id="uncontrolled-tab-example" className="custom-dlex-tabs">
                            <Tab eventKey="Schools" title="Schools">
                                 
                            <ListGroup >
                                <ListGroup.Item 
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                     <a href="#">
                                    <div className="me-auto">
                                    <div className="fw-title">Bhashyam Techno School</div>
                                    Hyderabad
                                    </div>
                                    </a>
                                </ListGroup.Item>
                                <ListGroup.Item 
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <a href="#">
                                    <div className="me-auto">
                                    <div className="fw-title">Pragati High School</div>
                                    Hyderabad
                                    </div>
                                    </a>
                                   
                                </ListGroup.Item>
                                <ListGroup.Item 
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <a href="#">
                                    <div className="me-auto">
                                    <div className="fw-title">St'Johns High School</div>
                                    Hyderabad
                                    </div>
                                    </a>
                                </ListGroup.Item>
                                </ListGroup>



                            </Tab>

                            <Tab eventKey="Colleges" title="Colleges">
                            2
                            </Tab>
                            <Tab eventKey="Academy's" title="Academy's">
                           3
                            </Tab>
                        </Tabs>

                          </div>

                          </div>
                        </Col>

                        <Col sm={8}>
                                <div className="inner-section">
                                    <div className="d-flex justify-content-between mb-4">
                                    <h3 className="f-24 frmed web-text5">Classes and Sections List</h3>
                                    <Button className="btn-save">Save</Button>
                                    </div>

                                
                                  <Table responsive hover  className="c-table" >
                                    <thead>
                                        <tr>
                                        <th>Class</th>
                                        <th>Sections</th>
                                        <th>Actions</th>                                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td className="bg-td">  
                                        <div className="check-label">
                                            <Form.Check
                                                inline
                                                label="Class - V"
                                                name="group"    
                                                className="me-0"                                    
                                            />
                                            </div>
                                            </td>
                                        <td>
                                        <div className="check-label">
                                            <Form.Check
                                                inline
                                                label="Section - A"
                                                name="group1"   className="pe-4 me-0"                                         
                                            />
                                            <Form.Check
                                                inline
                                                label="Section - B"
                                                name="group1"     className="pe-4 me-0"                                          
                                            />
                                            <Form.Check
                                                inline                                              
                                                label="Section - C"
                                                name="group1"     
                                                className="pe-4 me-0"                                        
                                            />
                                             <Form.Check
                                                inline                                              
                                                label="Section - D"
                                                name="group1"     
                                                className="pe-4 me-0"                                        
                                            />
                                            </div>

                                        </td>
                                        <td className="bg-td"><Button className="outline-assign" variant="outline-light">Assign</Button></td>
                                   
                                        </tr>

                                        <tr>
                                        <td className="bg-td">  
                                        <div className="check-label">
                                            <Form.Check
                                                inline
                                                label="Class - VI"
                                                name="group2"    
                                                className="me-0"                                    
                                            />
                                            </div>
                                            </td>
                                        <td>
                                        <div className="check-label">
                                            <Form.Check
                                                inline
                                                label="Section - A"
                                                name="group3"   className="pe-4 me-0"                                         
                                            />
                                            <Form.Check
                                                inline
                                                label="Section - B"
                                                name="group3"     className="pe-4 me-0"                                          
                                            />
                                            <Form.Check
                                                inline                                              
                                                label="Section - C"
                                                name="group3"     
                                                className="pe-4 me-0"                                        
                                            />
                                             <Form.Check
                                                inline                                              
                                                label="Section - D"
                                                name="group3"     
                                                className="pe-4 me-0"                                        
                                            />
                                            </div>

                                        </td>
                                        <td className="bg-td"><Button className="outline-assign" variant="outline-light">Assign</Button></td>
                                   
                                        </tr>
                                    
                                    </tbody>
                                    </Table>


                                    
                                </div>

                        </Col>
                    </Row>

                   </section>

                 </div>
       
       
       </>
            


       
       
    )
}
export default AssignSection;
