import types from "redux/actions/types";
import { IBranchpaymentmethodState, BranchpaymentmethodActions } from "../types/branchpaymentmethods";
import { IBranchpaymentmethod } from "redux/types/branchpaymentmethods";

const initialState: IBranchpaymentmethodState = {
  loading: true,
  branchpaymentmethod: {} as IBranchpaymentmethod,
  branchpaymentmethods: [] as IBranchpaymentmethod[],
};

const branchpaymentmthodReducer = (state = initialState, action: BranchpaymentmethodActions) => {
  switch (action.type) {
    case types.BRANCHPAYMENTMETHOD_REGISTER_SUCCESS:
    case types.BRANCHPAYMENTMETHOD_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        branchpaymentmethods: [...state.branchpaymentmethods, action.payload] as IBranchpaymentmethod[],
      };
    case types.GET_BRANCHPAYMENTMETHODS:
      return {
        ...state,
        loading: false,
        branchpaymentmethods: action.payload as IBranchpaymentmethod[],
      };

    case types.DELETE_BRANCHPAYMENTMETHOD:
      return {
        ...state,
        branchpaymentmethods: state.branchpaymentmethods.filter(
          (branchpaymentmethod) => branchpaymentmethod._id !== action.payload
        ),
      };

    case types.UPDATE_BRANCHPAYMENTMETHOD:
      return {
        ...state,
        branchpaymentmethods: state.branchpaymentmethods.map((branchpaymentmethod) =>
        branchpaymentmethod._id === action.payload.id
            ? { ...action.payload.branchpaymentmethod }
            : branchpaymentmethod
        ),
      };
    case types.BRANCHPAYMENTMETHOD_REGISTER_FAIL:
    case types.BRANCHPAYMENTMETHOD_AUTH_ERROR:

    default:
      return state;
  }
};
export default branchpaymentmthodReducer;
