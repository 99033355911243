import types from "redux/actions/types";
import { EnrollStudentActions, IEnrolledStudent,IEnrolledStudentState } from "../types/enrollStudent";


const initialState: IEnrolledStudentState = {
    loading: true,
    enrolledStudent:{} as IEnrolledStudent,
    enrolledStudents:[] as IEnrolledStudent[],
    totalCount:0
};

const enrollStudentReducer = (state = initialState, action: EnrollStudentActions) => {
    switch (action.type) {
        case types.ENROLL_STUDENT_SUCCESS:
        case types.ENROLL_STUDENT_SUCCESS:
            return {
                ...state,
                loading: false,
                enrolledStudents: [...state.enrolledStudents] as IEnrolledStudent[],
            };
        case types.GET_ENROLLED_STUDENTS:
            return {
                ...state,
                loading: false,
                enrolledStudents: action?.payload?.data as IEnrolledStudent[],
                totalCount:action?.payload?.totalCount
            };

        case types.DELETE_ENROLLED_STUDENT:
            return {
                ...state,
                enrolledStudents: state.enrolledStudents.filter(
                    (enrolledStudent:IEnrolledStudent) => enrolledStudent.userId !== action?.payload
                ),
            };

        case types.UPDATE_ENROLLED_STUDENT:
            return {
                ...state,
                enrolledStudents: state.enrolledStudents.map((enrolledStudent:IEnrolledStudent) =>{
                    if (enrolledStudent?.userId === action?.payload?.id){
                        enrolledStudent.user = action?.payload?.data 
                        return 
                    }
                        
                    else return enrolledStudent
                        }
                ),
            };
        case types.ENROLL_STUDENT_FAIL:

        default:
            return state;
    }
};
export default enrollStudentReducer
