import types from "redux/actions/types";
import { IBranchsubjectState, BranchsubjectActions } from "../types/branchsubject";
import { IBranchsubject } from "redux/types/branchsubject";

const initialState: IBranchsubjectState = {
    loading: true,
    totalCount : 0,
    branchsubject: {} as IBranchsubject,
    branchsubjects: [] as IBranchsubject[],
};

const branchsubjectReducer = (
    state = initialState,
    action: BranchsubjectActions
): IBranchsubjectState => {
    switch (action.type) {
        case types.BRANCHSUBJECT_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // branchsubjects: [...state.branchsubjects, action.payload] as IBranchsubject[],
            };
        case types.GET_BRANCHSUBJECTS:
            return {
                ...state,
                loading: false,
                totalCount : action.payload.totalCount,
                branchsubjects: action.payload.branchSubjects as IBranchsubject[],
            };
        case types.GET_BRANCHSUBJECTS_REQUEST:
            return {
                ...state,
                loading: true,
                totalCount : 0,
            };

        case types.DELETE_BRANCHSUBJECT:
            return {
                ...state,
                branchsubjects: state.branchsubjects.filter(
                    (subject:any) => subject._id !== action.payload
                ),
            };

        case types.UPDATE_BRANCHSUBJECT:
            return {
                ...state,
                branchsubjects: state.branchsubjects.map((branchsubject:any) =>
                branchsubject._id === action.payload.id
                        ? { ...action.payload.branchsubject }
                        : branchsubject
                ),
            };
        case types.BRANCHSUBJECT_REGISTER_FAIL:
        case types.BRANCHSUBJECT_AUTH_ERROR:

        default:
            return state;
    }
};

export default branchsubjectReducer;
