import types from "redux/actions/types";
import { IBranchCashflowaccountState, BranchCashflowaccountActions } from "../types/branchcashflowAccount";
import { IBranchCashflowaccount } from "redux/types/branchcashflowAccount";

const initialState: IBranchCashflowaccountState = {
  loading: true,
  branchcashflowaccount: {} as IBranchCashflowaccount,
  branchcashflowaccounts: [] as IBranchCashflowaccount[],
  totalCount:0
};

const branchcashflowaccountReducer = (state = initialState, action: BranchCashflowaccountActions) => {
  switch (action.type) {
    case types.BRANCHCASHFLOWACCOUNT_REGISTER_SUCCESS:
    case types.BRANCHCASHFLOWACCOUNT_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // branchcashflowaccounts: [...state.branchcashflowaccounts, action.payload] as IBranchCashflowaccount[],
      };
    case types.GET_BRANCHCASHFLOWACCOUNTS:
      return {
        ...state,
        loading: false,
        branchcashflowaccounts: action?.payload?.data as IBranchCashflowaccount[],
        totalCount: action?.payload?.totalCount as Number,

      };

    case types.DELETE_BRANCHCASHFLOWACCOUNT:
      return {
        ...state,
        branchcashflowaccounts: state.branchcashflowaccounts.filter(
          (branchcashflowaccount) => branchcashflowaccount._id !== action.payload
        ),
      };

    case types.UPDATE_BRANCHCASHFLOWACCOUNT:
      return {
        ...state,
        branchcashflowaccounts: state.branchcashflowaccounts.map((branchcashflowaccount) =>
        branchcashflowaccount._id === action.payload.id
            ? { ...action.payload.branchcashflowaccount }
            : branchcashflowaccount
        ),
      };
    case types.BRANCHCASHFLOWACCOUNT_REGISTER_FAIL:
    case types.BRANCHCASHFLOWACCOUNT_AUTH_ERROR:

    default:
      return state;
  }
};
export default branchcashflowaccountReducer;
