import types from "redux/actions/types";
import { IOrganizationfeeCategoryState, OrganizationfeeCategoryActions } from "../types/organizationfeecategory";
import { IOrganizationfeeCategory } from "redux/types/organizationfeecategory";

const initialState: IOrganizationfeeCategoryState = {
  loading: true,
  organizationfeecategory: {} as IOrganizationfeeCategory,
  organizationfeecategorios: [] as IOrganizationfeeCategory[],
  totalCount:0
};

const organizationfeefeecategoryReducer = (state = initialState, action: OrganizationfeeCategoryActions) => {
  switch (action.type) {
    case types.ORIGANIZATIONFEECATEGORY_REGISTER_SUCCESS:
    case types.ORIGANIZATIONFEECATEGORY_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // organizationfeecategorios: [...state.organizationfeecategorios, action.payload] as IOrganizationfeeCategory[],
      };
    case types.GET_ORIGANIZATIONFEECATEGORIOS:
      return {
        ...state,
        loading: false,
        organizationfeecategorios: action?.payload?.data as IOrganizationfeeCategory [],
        totalCount: action?.payload?.totalCount,

      };

    case types.DELETE_ORIGANIZATIONFEECATEGORY:
      return {
        ...state,
        organizationfeecategorios: state.organizationfeecategorios.filter(
          (organizationfeefeecategory) => organizationfeefeecategory._id !== action.payload
        ),
      };

    case types.UPDATE_ORIGANIZATIONFEECATEGORY:
      return {
        ...state,
        organizationfeecategorios: state.organizationfeecategorios.map((organizationfeefeecategory) =>
        organizationfeefeecategory._id === action.payload.id
            ? { ...action.payload.organizationfeecategory }
            : organizationfeefeecategory
        ),
      };
    case types.ORIGANIZATIONFEECATEGORY_REGISTER_FAIL:
    case types.ORIGANIZATIONFEECATEGORY_AUTH_ERROR:

    default:
      return state;
  }
};
export default organizationfeefeecategoryReducer;
