import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Confirmation, { IConfirmationBoxData } from "components/shared/Confirmation";
import useApi from "components/hooks/useApi";
import * as initialSetupService from "services/initialSetup"
import "./InitialSetup.css";



const InitialSetupBanner: React.FC = (): JSX.Element => {

    const orgId = localStorage.getItem("orgId");
    let moduleId = localStorage.getItem("routeId");
    
    const dispatch = useDispatch();

    const { action: skipInitialSetup, error: skipInitialSetupError } = useApi(initialSetupService.skipInitialSetup, { error: true });

    const [confirmationBoxData, setConfirmationBoxData] = useState<IConfirmationBoxData>({
        show: false,
        data: null,
    });

    const handleModalShow = () => {
        dispatch({ type: 'GET_INITIAL_SETUP', payload: { initialSetupShow: true, initialSetupBannerShow: false } })
    };

    const handleCloseBtn = () => {
        setConfirmationBoxData({
            show: true,
            data: { success: "disabled", data: true },
        });
    };

    const handleConformation = (data) => {
        skipInitialSetup({
            orgId: orgId,
            moduleId: moduleId,
            markModuleAsCompleted: data,
        })
        dispatch({ type: 'GET_INITIAL_SETUP', payload: { initialSetupShow: false, initialSetupBannerShow: false } })
    };

    return (
        <div className="" style={{ backgroundColor: "#FFFFFF", height: '40px' }}>
            <div className='d-flex align-items-center initial-setup-margin-spacing pt-2'>
                <img src="/images/initial-setup-banner-icon.svg" alt="initial-setup-banner-icon" width={24} height={24} />
                <div className='ms-2 fs-small fw-normal'>{'Your initial setup is not finish, finish it now to make your experience seamless and easy!'}</div>
                <button
                    type="button"
                    className="initial-setup-banner-btn me-16 ms-3"
                    onClick={handleModalShow}
                >
                    Finish Set Up Now
                </button>
                <button
                    type="button"
                    className='initial-setup-banner-close-btn'
                    onClick={handleCloseBtn}
                >
                    <img src="/images/close-icon.svg" alt="close-icon" />
                </button>

                <Confirmation
                    cancelHandler={setConfirmationBoxData}
                    info={"Are you sure you want to End Initial Setup?"}
                    actionHandler={() => {
                        handleConformation(confirmationBoxData?.data?.data)
                     
                    }}
                    showData={confirmationBoxData}
                />
            </div>
        </div>
    )
}

export default InitialSetupBanner