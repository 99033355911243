import types from "redux/actions/types";
import { IFeetypeState, FeetypeActions } from "../types/feetype";
import { IFeetype } from "redux/types/feetype";

const initialState: IFeetypeState = {
  loading: true,
  feetype: {} as IFeetype,
  feetypes: [] as IFeetype[],
  feePlanTypes:[],
  totalCount:0
};

const feetypeReducer = (state = initialState, action: FeetypeActions) => {
  switch (action.type) {
    case types.FEETYPE_REGISTER_SUCCESS:
    case types.FEETYPE_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        feetypes: [...state.feetypes, action.payload] as IFeetype[],
      };
    case types.GET_FEETYPES:
      return {
        ...state,
        loading: false,
        feetypes: action?.payload?.data as IFeetype[],
        totalCount: action?.payload?.totalCount as Number,

      };
      case types.GET_FEEPLAN_FEETYPES:
      return {
        ...state,
        feePlanTypes:action.payload
      };

    case types.DELETE_FEETYPE:
      return {
        ...state,
        feetypes: state.feetypes.filter(
          (feetype) => feetype._id !== action.payload
        ),
      };

    case types.UPDATE_FEETYPE:
      return {
        ...state,
        feetypes: state.feetypes.map((feetype) =>
        feetype._id === action.payload.id
            ? { ...action.payload.feetype }
            : feetype
        ),
      };
    case types.FEETYPE_REGISTER_FAIL:
    case types.FEETYPE_AUTH_ERROR:

    default:
      return state;
  }
};
export default feetypeReducer;
