import types from "redux/actions/types";
import { AnnouncementApprovalSettingsActions, IAnnouncementApprovalSettings, IAnnouncementApprovalSettingsState, IAnnouncementApprovals } from "redux/types/announcementApprovalSettings";

const initialState: IAnnouncementApprovalSettingsState = {
    approvalData : {data: [] as IAnnouncementApprovalSettings[], totalCount : 0, loading : true, error : false} as any,
    approvalSettingsData: {data: [] as IAnnouncementApprovals[], totalCount : 0, loading : true, error : false} as any,
};

const announcementReducer = (state = initialState, action: AnnouncementApprovalSettingsActions) => {
    switch (action.type) {
        case types.GET_ANNOUNCEMENT_APPROVAL_SETTINGS:
            return {
                ...state,
                approvalSettingsData: {data: action.payload.data, totalCount: action.payload.totalCount, loading: false, error: false} as any,
            }
        case types.CREATE_ANNOUNCEMENT_SETTING:
            return {
                ...state,
                loading: false,
                error: false,
                approvalSettingsData : {...state.approvalSettingsData, loading: false, error: false,}
            }
        case types.CREATE_ANNOUNCEMENT_SETTING_FAILURE:
            return {
                ...state,
                approvalSettingsData : {...state.approvalSettingsData, data: [], loading: false, error: true,}
            }
            
        case types.GET_ANNOUNCEMENT_APPROVALS:
            return {
                ...state,
                approvalData: {data: action.payload.data, totalCount: action.payload.totalCount, loading: false, error: false,} as any,
            }
        case types.GET_ANNOUNCEMENT_APPROVALS_FAILURE:
            return {
                ...state,
                approvalData: {data: [], totalCount: 0, loading: false, error: true,} as any,
            }
        case types.ACCEPT_REJECT_ANNOUNCEMENT_APPROVAL:
            return {
                ...state,
                approvalData : {...state.approvalData, loading: false, error: false,}
            }
            
        case types.UPDATE_ANNOUNCEMENT_SETTING:
        case types.DELETE_ANNOUNCEMENT_SETTING:
            return {
                    ...state,
                    approvalSettingsData : {...state.approvalSettingsData, loading: false, error: false,}

                    }
        default : 
            return state;
    }
}

export default announcementReducer;
