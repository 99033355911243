import types from '../actions/types'
import { IManualStaffAttendance, ManualStaffAttendanceActions } from "redux/types/manualStaffAttendance";



const initialState:IManualStaffAttendance = {
  manualStaffAttendanceData: [],
  totalCount : 0,
  isLoading: false
};

const manualStaffAttendanceReducer = (state = initialState, action: ManualStaffAttendanceActions) => {
  
  switch (action.type) {
    case types.CREATE_MANUAL_STAFF_ATTENDANCE:
      return {
        ...state
      }

    case types.GET_MANUAL_STAFF_ATTENDANCE:
      return {
        ...state,
        manualStaffAttendanceData: action.payload.data,
        isLoading : false,
        totalCount: action.payload.totalCount
      };
    default:
      return state;
  }
};

export default manualStaffAttendanceReducer;