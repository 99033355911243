import React, { useState } from "react";
import { Button, Col, Form, ListGroup, Modal, Row, Tab, Table  } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'

const CreateclassGroups: React.FC = (): JSX.Element => {
 const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
       <>
                <div className="c-container">
                   <section className="bg-section mt-3">
                   <div className="d-flex justify-content-between mb-4">
                                    <h3 className="f-24 frmed web-text5">Create Class Groups</h3>
                                    <Button className="btn-save" onClick={handleShow}>+ Add New Class Group</Button>
                                    </div>

                
                        <div className="d-flex justify-content-end mb-4">
                                   <div className="section-small">                                  
                                    <Form.Select>
                                        <option>All</option>
                                    </Form.Select>

                                   </div>
                                    
                                </div>
                                <div className="inner-section">
                                  
                                  <Table responsive hover  className="c-table" >
                                    <thead>
                                        <tr>
                                        <th>S.No</th>
                                        <th>Class Group Name</th>
                                        <th>Class Group Type</th>
                                        <th>Classes</th>
                                        <th>Actions</th>                                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>  
                                       1
                                            </td>
                                        <td>
                                          IIT - JEE
                                        </td>

                                        <td>
                                        Academics
                                        </td>

                                        <td>
                                        Jr.Inter, Sr.Inter
                                        </td>

                                        <td>
                                        <a href="#"><img src="images/edit-icon.svg" /></a>
                                        </td>
                                   
                                        </tr>

                                        <tr>
                                        <td>  
                                        2
                                            </td>
                                        <td>
                                          IIT - JEE
                                        </td>

                                        <td>
                                        Academics
                                        </td>

                                        <td>
                                        Jr.Inter, Sr.Inter
                                        </td>

                                        <td>
                                        <a href="#"><img src="images/edit-icon.svg" /></a>
                                        </td>
                                   
                                        </tr>

                                       
                                    </tbody>
                                    </Table>                                    
                                </div>
                   </section>
                 </div>
       



       
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0">
          <Modal.Title>Create Class Groups</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="section-small">
                                <div className="mb-3">
                                    <Form.Label>Class Group Type</Form.Label>
                                    <Form.Select>
                                        <option>Select Here</option>
                                    </Form.Select>
                                </div>
                                <div className="mb-3">
                                    <Form.Label>Class</Form.Label>
                                    <Form.Select>
                                        <option>Select Here</option>
                                    </Form.Select>
                                </div>
                                <div>
                                    <Form.Label>Class Group Name</Form.Label>
                                    <Form.Control type="text" placeholder="Eg: Jr.Inter - IIT" /> 
                                </div>

            </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button className="btn-modal me-3" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn-modal">
          Create
          </Button>
        </Modal.Footer>
      </Modal>
       
       </>
            


       
       
    )
}
export default CreateclassGroups;
