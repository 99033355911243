import { ILeaveType, ILeaveTypeState, LeaveTypeActions } from "redux/types/leaveTypes";
import types from "redux/actions/types";

const initialState: ILeaveTypeState = {
    loading: true,
    leaveType: {} as ILeaveType,
    leavetypes: [] as ILeaveType[],
    totalCount: 0
};
export const leaveTypeReducer = (state = initialState, action: LeaveTypeActions) => {
    switch (action.type) {
        case types.LEAVE_TYPE_REGISTER_SUCCESS:
        case types.LEAVE_TYPE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.GET_LEAVE_TYPE:
            return {
                ...state,
                loading: false,
                leavetypes: action?.payload,
                // totalCount: action?.payload?.length,
            };
        case types.DELETE_LEAVE_TYPE:
            return {
                ...state,
                leavetypes: state?.leavetypes?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        case types.UPDATE_LEAVE_TYPE:

            return {
                ...state,
                leavetypes: state.leavetypes.map((clientuser: any) =>
                    clientuser._id === action.payload._id ? { ...action.payload.leaveType } : clientuser)
            }

        default:
            return state
    }
}
export default leaveTypeReducer;