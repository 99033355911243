import React from "react";
import { Button, Card, Col, Form, ListGroup, Modal, Row, Tab, Table  } from "react-bootstrap";


const StudentPromotion: React.FC = (): JSX.Element => {
  return (
    <div className="c-container content-tabs mt-5">
             <div className="section-bgwhite1">

             <div className="d-flex justify-content-end mb-3"> 
              <Form.Select className="outline-select me-3">
                <option>Financial Promotion</option>
              </Form.Select>
              <Form.Select className="outline-select me-3">
                <option>Branch Name</option>
              </Form.Select>
              <Form.Select className="outline-select">
                <option>2021-2022</option>
              </Form.Select>
             
            </div>


             <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Class Name</th>  
              <th>Section</th> 
              <th>Strength</th> 
              <th>Class Name</th> 
              <th>Section</th>                    
              <th>Section</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>
              <td>test</td>
              <td>test</td>               
              <td>-</td>
            </tr>
         

          </tbody>
        </Table>
      </Card>

            </div>

    </div>
  )
}
export default React.memo(StudentPromotion);
