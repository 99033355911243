import types from "redux/actions/types";
import { IDairyState, DairyActions } from "../types/dairy";
import { IDairy } from "redux/types/dairy";

const initialState: IDairyState = {
  loading: true,
  dairy: {} as IDairy,
  dairies: [] as IDairy[],
  generalDairies: {loading: true, data: []},
};

const dairyReducer = (state = initialState, action: DairyActions) => {
  switch (action.type) {
    case types.DAIRY_REGISTER_SUCCESS:
    case types.DAIRY_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // dairies: [...state.dairies, action.payload] as IDairy[],
      };
    case types.GET_DAIRIES:
      return {
        ...state,
        loading: false,
        dairies: action.payload as IDairy[],
      };
    case types.GET_GENERAL_DAIRIES:
      return {
        ...state,
        loading: false,
        generalDairies: {loading:false, data: action.payload},
      };
    case types.DELETE_DAIRY:
      return {
        ...state,
        dairies: state.dairies.filter(
          (dairy) => dairy._id !== action.payload
        ),
      };

    case types.UPDATE_DAIRY:
      return {
        ...state,
        dairies: state.dairies.map((dairy) =>
        dairy._id === action.payload.id
            ? { ...action.payload.dairy }
            : dairy
        ),
      };
    case types.DAIRY_REGISTER_FAIL:
    case types.DAIRY_AUTH_ERROR:

    default:
      return state;
  }
};
export default dairyReducer;
