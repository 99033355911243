import ActionTypes from "redux/actions/types";

const initialState = {
    templates: [],
    totalCount: 0,
    template: {}
}

export const TemplateSettings = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_TEMPLATES:
            return { ...state, templates: [...action.payload.data], totalCount: action.payload.totalCount }
        case ActionTypes.GET_SINGLE_TEMPLATE:
            return { ...state, template: { ...action.payload } }
        default:
            return state;
    }
}