import React from "react";

type Props = {

};

const currencySymbol: React.FC<Props> = ({ }): JSX.Element => {
  return (
    <>
      {'\u20B9'}
      {/* {'&#8377;'} */}

    </>
  )
};

export default currencySymbol;