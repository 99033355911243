import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";

const Ipwhitelist: React.FC = (): JSX.Element => {
  return (
    <>
      
     <div className="note mb-4">
         <h5 className="frbold">Note:</h5>
           <p>Specify your dedicated IP (preferably static IP) from which you want to access your MCB portal. If you define the IP addresses, you can only access from the defined IP addresses only. However Parent / students can access from anywhere.</p>
     </div>


      <Form className="w-100 mw-100">
        <Row>

          <Col md={4} className="pe-5 mb-3">
                              <div className="section-small">                                        
                                            <Form.Label>Access level</Form.Label>
                                            <Form.Select>
                                                <option>Select Here</option>
                                            </Form.Select>                                      
                                </div>
          </Col>
          <Col md={4} className="pe-5 mb-3">
                               <div className="section-small">                                      
                                            <Form.Label>Branch</Form.Label>
                                            <Form.Select>
                                                <option>Select Here</option>
                                            </Form.Select>                                      
                                </div>
          </Col>

       
        
        </Row>
        <Button className="btn-save">Fetch Details</Button>  
      </Form>



    </>
  )
}
export default React.memo(Ipwhitelist);
