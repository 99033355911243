import ActionTypes from "redux/actions/types";
import { IStaffTimeTable, IstaffTimeTabletypeState } from "redux/types/staffTimeTable";
const initialState : IstaffTimeTabletypeState = {
    loading : true,
    staffworkload: {} as IStaffTimeTable,
    staffworkloads: [],
    totalcount : 0,
};

export const staffTimeTable = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_STAFF_TIME_TABLES:
            return {
                ...state,
                loading: false,
                staffworkloads : action?.payload,
                // totalCount: action?.payload?.length,
            };
        default:
            return state;
    }

}