import ActionTypes from "redux/actions/types";
import { CertificateSequenceNumbersAction, ICertificateSequenceNumbersState } from "redux/types/certificateSequenceNumber";
const initialState : ICertificateSequenceNumbersState = {
    loading: true,
    error: false,
    singleCertificateSequence : "",
    certificateSequenceData: [],
};
const CertificateSequenceNumberReducer = (state = initialState, action: CertificateSequenceNumbersAction) => {
    switch (action.type) {
        case ActionTypes.GET_CERTIFICATE_SEQUENCES:
            return { ...state, certificateSequenceData: [...action?.payload], loading : false, error: false }
        case ActionTypes.GET_CERTIFICATE_SEQUENCES_FAILURE:
            return { ...state, feeReciptcertificateSequenceData: [], loading : false, error: true }
        case ActionTypes.CREATE_CERTIFICATE_SEQUENCE:
            return { ...state, singleCertificateData : action?.payload, loading : false, error: false }
        case ActionTypes.UPDATE_CERTIFICATE_SEQUENCE:
            return { ...state, singleCertificateData : action?.payload, loading : false, error: false }
        default:
            return state
    }
}

export default CertificateSequenceNumberReducer