import axiosnoti from "utils/axiosnoti";

export async function updateReadNotification(data) {
   
    try {
        return await axiosnoti.put(`notificationDump/updatenotification`, data);

    } catch (error) {
        return error
    }
}

// notificationDump/updateallnotifications

export async function updateAllReadNotification(data) {
   
    try {
        return await axiosnoti.put(`notificationDump/updateallnotifications`, data);

    } catch (error) {
        return error
    }
}

// notificationDump/getnotifications

export async function getNotifications(data) {
   
    try {
        return await axiosnoti.post(`notificationDump/getnotifications`, data);

    } catch (error) {
        return error
    }
}