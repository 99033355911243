import React from "react";
import { Button, Col, Form, ListGroup, Row, Tab, Table  } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'

const AssignclassGroup: React.FC = (): JSX.Element => {
    return (
       <>
                <div className="c-container">
                   <section className="bg-section mt-3">
                   <div className="d-flex justify-content-between mb-4">
                                    <h3 className="f-24 frmed web-text5">Assign Class Groups To Branches</h3>
                                    <Button className="btn-save">+ Create New Class Group</Button>
                                    </div>

                    <Row>
                        
                        <Col sm={4}>
                        <div className="section-small">   
                                <Form.Label>Branches List</Form.Label>
                          <div className="inner-section-small">
                          <Tabs defaultActiveKey="Schools" id="uncontrolled-tab-example" className="custom-dlex-tabs">
                            <Tab eventKey="Schools" title="Schools">
                                 
                            <ListGroup >
                                <ListGroup.Item 
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                     <a href="#">
                                    <div className="me-auto">
                                    <div className="fw-title">Bhashyam Techno School</div>
                                    Hyderabad
                                    </div>
                                    </a>
                                </ListGroup.Item>
                                <ListGroup.Item 
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <a href="#">
                                    <div className="me-auto">
                                    <div className="fw-title">Pragati High School</div>
                                    Hyderabad
                                    </div>
                                    </a>
                                   
                                </ListGroup.Item>
                                <ListGroup.Item 
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <a href="#">
                                    <div className="me-auto">
                                    <div className="fw-title">St'Johns High School</div>
                                    Hyderabad
                                    </div>
                                    </a>
                                </ListGroup.Item>
                                </ListGroup>

                            </Tab>

                            <Tab eventKey="Colleges" title="Colleges">
                            2
                            </Tab>
                            <Tab eventKey="Academy's" title="Academy's">
                           3
                            </Tab>
                        </Tabs>

                          </div>

                          </div>
                        </Col>

                        <Col sm={8}>
                        <div className="d-flex justify-content-between mb-4">
                                   <div className="section-small d-flex"> 
                                   
                                    <div className="me-4">
                                            <Form.Label>Class Group Type</Form.Label>
                                            <Form.Select>
                                                <option>All</option>
                                            </Form.Select>
                                    </div>
                                    <div className="mt-32">  <Form.Control type="search" className="search" placeholder="Search" /> </div>
                                   </div>

                                   <div className="section-small">                                  
                                    <Form.Select className="mt-32">
                                        <option>All</option>
                                    </Form.Select>
                                   </div>
                                    
                                </div>
                                <div className="inner-section">
                                  
                                  <Table responsive hover  className="c-table" >
                                    <thead>
                                        <tr>
                                        <th>#</th>
                                        <th>Class Group Name</th>
                                        <th>Class Group Type</th>
                                        <th>Applicable Classes</th>
                                        <th>Actions</th>                                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td className="bg-td">  
                                        <div className="check-label">
                                            <Form.Check
                                                inline                                             
                                                name="group"    
                                                className="me-0"                                    
                                            />
                                            </div>
                                            </td>
                                        <td>
                                          IIT - JEE
                                        </td>

                                        <td className="bg-td">
                                        Academics
                                        </td>

                                        <td>
                                        Jr.Inter, Sr.Inter
                                        </td>

                                        <td className="bg-td">
                                        <a href="#"><img src="images/edit-icon.svg" /></a>
                                        </td>
                                   
                                        </tr>

                                        <tr>
                                        <td className="bg-td">  
                                        <div className="check-label">
                                            <Form.Check
                                                inline                                             
                                                name="group"    
                                                className="me-0"                                    
                                            />
                                            </div>
                                            </td>
                                        <td>
                                          IIT - JEE
                                        </td>

                                        <td className="bg-td">
                                        Academics
                                        </td>

                                        <td>
                                        Jr.Inter, Sr.Inter
                                        </td>

                                        <td className="bg-td">
                                        <a href="#"><img src="images/edit-icon.svg" /></a>
                                        </td>
                                   
                                        </tr>

                                       
                                    </tbody>
                                    </Table>


                                    
                                </div>

                        </Col>
                    </Row>

                   </section>

                 </div>
       
       
       </>
            


       
       
    )
}
export default AssignclassGroup;
