
import types from '../actions/types'
import { IInventoryApproval, IInventoryApprovalData , InventoryApprovalActions } from 'redux/types/inventoryApproval';


const inventoryApprovalInitialState:IInventoryApprovalData = {
    inventoryApprovalData: [] as IInventoryApproval[],
    totalCount : 0,
    isLoading: false,
   
  };

  const inventoryApprovalReducer = (state = inventoryApprovalInitialState, action: InventoryApprovalActions) => {
    switch (action.type) {
        case types.GET_ALL_INVENTORY_APPROVAL_REQUEST:
          return {
           ...state,
            isLoading: true,
          };

        case types.GET_ALL_INVENTORY_APPROVAL_SUCCESS:
            return {
                ...state,
                inventoryApprovalData: action.payload.data,
                totalCount: action.payload.totalCount,
                isLoading: false,
            };
        case types.GET_ALL_INVENTORY_APPROVAL_FAILURE:
            return {
                ...state,
                inventoryApprovalData: [],
                totalCount: 0,
                isLoading: false,
            };
        
        default:
          return state;
    }
}

export default inventoryApprovalReducer