import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

export default function ButtonLoader({color, size} : {color ?: string, size ?: string}) {
  return (
    <ThreeDots 
    height="30"
    width={size ? size : "30"} 
    radius="9"
    color={color ? color : "#2B4378"}
    ariaLabel="three-dots-loading"
    wrapperClass='justify-content-center'
    visible={true}
    />
  )
}
