import React from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
const AcademicCourses: React.FC = (): JSX.Element => {
  return (
    <div className="c-container">
      <section className="bg-section mt-3">
        <div className="d-flex justify-content-between mb-4">
          <h3 className="f-24 frmed web-text5">Academic Courses</h3>
          <Button className="btn-save">+ Create New Course</Button>
        </div>
        <Form className="w-100 mw-100 mb-3">
          <Row>
            <Col md={4} className="pe-5 mb-3">
              <div className="section-small">
                <Form.Label>Access level</Form.Label>
                <Form.Select>
                  <option>Select Here</option>
                </Form.Select>
              </div>
            </Col>
            <Col md={4} className="pe-5 mb-3">
              <div className="section-small mt-32">
                <Form.Control
                  type="search"
                  className="search"
                  placeholder="Search"
                />
              </div>
            </Col>
          </Row>
          <Button className="btn-save">Fetch Details</Button>
        </Form>
        <div className="inner-section">
          <Table responsive hover  className="c-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Course</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Is Alumini</th>
                <th>Is Dayscholar</th>
                <th>Is Hosteler</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={8} className="bg-blue">
                  <span className="text-white"> Graduation ( 2020-2024 )</span>
                </td>
              </tr>
              <tr>
                <td className="bg-td">1</td>
                <td>Graduation - I Year - 1 Semester</td>
                <td className="bg-td">01-Jan-2021</td>
                <td>30-Jun-2021</td>
                <td className="bg-td">No</td>
                <td>Yes</td>
                <td className="bg-td">Yes</td>
                <td>
                  <a href="#" className="me-3">
                    <img src="images/edit-icon.svg" />
                  </a>
                  <a href="#">
                    <img src="images/delete-icon.svg" />
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </section>
    </div>
  );
};
export default React.memo(AcademicCourses);
