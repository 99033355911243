import ActionTypes from "redux/actions/types";

const initialState = {
    events: [],
    eventTypes:[],
    totalCount:0,
}
export const EventList = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_EVENTS:
            return {
                ...state, events: [...action.payload.data] ,totalCount:action?.payload?.totalCount?action?.payload?.totalCount:state?.totalCount
            };
        case ActionTypes.GET_EVENT_TYPES:
            return {
                ...state, eventTypes: [...action.payload.data],totalCount:action?.payload?.totalCount?action?.payload?.totalCount:state?.totalCount
            };
        default:
            return state;
    }
}