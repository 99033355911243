import * as React from 'react';
import { Nav, Navbar } from "react-bootstrap";
import SubheaderList from "./SubheaderList";
const Subheader: React.FC<any> = (): JSX.Element => {

  return (<>
    <Navbar expand="lg">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <SubheaderList />
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  </>)
}
export default React.memo(Subheader);