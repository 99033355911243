import React from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Branches from "./Integrations/Branches";






const ApiIntegrations: React.FC = (): JSX.Element => {
  return (
    <div className="c-container content-tabs mt-5">
      <Tabs defaultActiveKey="Branches" id="uncontrolled-tab-example">
        <Tab eventKey="Branches" title="Branches">
              <Branches />
        </Tab>

     
      



      </Tabs>
    </div>
  )
}
export default React.memo(ApiIntegrations);
