import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "redux/reducers";

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  let userToken = localStorage.getItem("user__token");
  let user = useSelector((state: RootState) => state.user);
  let location = useLocation();
  if ((!user.loading && !user.isAuthenticated) || !userToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} />;
  }
  else if(user?.isTempororyPassword){
    return <Navigate to="/reset_password" state={{ from: location }} />;
  }
  else{
    return children;
  }
}

// const RouteWrapper = ({Component, ...props }) => {
//   const auth = checkAuth();

//   let role = getUserRole(),
//       access = checkAccess(role, props.roles);

//   if (!auth) {
//       logout();
//   } else if (auth && !access) {
//       return <Redirect to="/error/401" />;
//   }

//   return (
//       <Route
//           {...props}
//           render={routeProps => <Component {...routeProps} />}
//       />
//   );
// };