import { AllExpenseTransactionsActions, } from "redux/types/expenseTransactions";
import ActionTypes from "../actions/types";

const initialState = {
    loading : false,
    error : '',
    transactions : [],
    overallTotalAmount:0,
    paymentMethod:[]
}


const expenseTransactionsReducer=(state=initialState,action:AllExpenseTransactionsActions)=>{
    switch(action.type){
        case ActionTypes.GET_EXPENSE_TRANSACTIONS_REQUEST:
            return {...state,loading:true,error:""}

        case ActionTypes.GET_EXPENSE_TRANSACTIONS_SUCCESS:
            return {...state,transactions:action.payload.data,overallTotalAmount:action.payload.overallTotalAmount,paymentMethod:action.payload.paymentMethod,loading:false}
        
        case ActionTypes.GET_EXPENSE_TRANSACTIONS_FAILURE:
            return {...initialState,error:action.payload}    
        default:
            return state
    }
}

export default expenseTransactionsReducer