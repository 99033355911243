import { Dispatch } from "redux";
import axios from "utils/axios";
import types from "./types";
import { setAlert } from "./alert";
// import { OrganizationActions } from "redux/types/organization";
import { AlertActions } from "redux/types/alert";
import { IBranchesState, BranchesActions } from "redux/types/branch";

// REGISTER Branch
let orgId = localStorage.getItem("orgId")
export const registerBranch =
  (body: any, history: any) =>
    async (dispatch: Dispatch<BranchesActions | AlertActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`branch/create`, body, config);
        
        dispatch({
          type: types.BRANCHES_REGISTER_SUCCESS,
          payload: data.branch,
        });
        dispatch<any>(
          setAlert({
            msg: "Organization Register successful!",
            status: 200,
            alertType: "success",
          })
        );
        // dispatch<any>(getBranches());
        history.push("/branches");
      } catch (error: any) {
        dispatch({ type: types.BRANCHES_REGISTER_FAIL });
        dispatch<any>(
          setAlert({
            msg: error?.response?.data,
            status: error?.response?.status,
            alertType: "danger",
          })
        );
      }
      // finally {
      //   setSubmitting(false);
      // }
    };
// GET Branches
export const getBranches =
  () => async (dispatch: Dispatch<BranchesActions | AlertActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.get(`branch/branches`, config);
      dispatch({ type: types.GET_BRANCHES, payload: data["branch"] });
    } catch (error: any) {
      dispatch<any>(
        setAlert({
          msg: "Something went wrong when fetching the branches!",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    }
  };

// UPDATE organizations DATA
export const updateBranches =
  (body: any, id: any, history: any) =>
    async (dispatch: Dispatch<BranchesActions | AlertActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.patch(
          `branch/updateBranch/${id}`,
          body,
          config
        );
        dispatch({
          type: types.UPDATE_BRANCHES,
          payload: data,
        });

        dispatch<any>(
          setAlert({
            msg: "Branche Data Updated!",
            status: 200,
            alertType: "success",
          })
        );
        dispatch<any>(getBranches());
        history.push("/branches");
      } catch (error: any) {
        dispatch<any>(
          setAlert({
            msg: "Something went wrong when updating the Branche!",
            status: error?.response?.status,
            alertType: "danger",
          })
        );
      }
    };

// DELETE organization
export const deleteBranch =
  (id: number) =>
    async (dispatch: Dispatch<BranchesActions | AlertActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        await axios.delete(`branch/deletebranch/${id}`, config);
        dispatch({ type: types.DELETE_BRANCHES, payload: id });
        dispatch<any>(
          setAlert({
            msg: "Branch has been deleted!",
            status: 200,
            alertType: "success",
          })
        );
        // dispatch<any>(getBranchesByOrgId({}));
      } catch (error: any) {
        dispatch<any>(
          setAlert({
            msg: "Something went wrong when deleting the Branch!",
            status: error?.response?.status,
            alertType: "danger",
          })
        );
      }
    };
// CREATE BRANCH MODULE
export const createBranchmodule =
  (body: any, history: any) =>
    async (dispatch: Dispatch<BranchesActions | AlertActions>) => {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        const { data } = await axios.post(`branchmodule/create`, body, config);
        dispatch({
          type: types.BRANCHMODULE_REGISTER_SUCCESS,
          payload: data,
        });
        dispatch<any>(
          setAlert({
            msg: "BranchModule creation sucess",
            status: 200,
            alertType: "success",
          })
        );
        // dispatch<any>(getBranchesByOrgId({}));
      } catch (error: any) {
        dispatch({ type: types.BRANCHES_REGISTER_FAIL });
        dispatch<any>(
          setAlert({
            msg: error?.response["data"],
            status: error?.response?.status,
            alertType: "danger",
          })
        );
      }
      // finally {
      //   setSubmitting(false);
      // }
    };
export const getBranchesById = (id: any) => async (dispatch: Dispatch<BranchesActions>) => {
  const config: any = {
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.get(`branch/getBranchesByOrgId/${id}`, config);
    dispatch({ type: types.GET_BRANCHES, payload: data["branch"] });
  } catch (error) { }
};
export const getBranchById = (id: any) => async (dispatch: Dispatch<BranchesActions>) => {
  const config: any = {
    header: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axios.get(`branch/getBranch/${id}`, config);
    dispatch({ type: types.GET_BRANCH, payload: data });
  } catch (error) { }
};

// export const getBranchModulesByBranchId = (id:any) => async (dispatch: Dispatch<BranchesActions>) => {
//   const config: any = {
//     header: {
//       "Content-Type": "application/json",
//     },
//   };

//   try {
//     const { data } = await axios.get(`branch/branchmodulesBybranchId/${id}`, config);
//     dispatch({  type: types.GET_BRANCHES, payload: data["branch"] });
//   } catch (error) {}
// };


export const getBranchesByOrgId =
  (body: any, setIsLoading?:any) => async (dispatch: Dispatch<BranchesActions | AlertActions>) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      let moduleId = localStorage.getItem("routeId");
      const { data } = await axios.post(`branch/getbranches`, {...body, moduleId}, config);
      dispatch({ type: types.GET_BRANCHES, payload: data });
    } catch (error: any) {
      dispatch<any>(
        setAlert({
          msg: "Something went wrong when fetching the branches!",
          status: error?.response?.status,
          alertType: "danger",
        })
      );
    } finally {
      if (setIsLoading)
        setIsLoading(false);
    }
  };