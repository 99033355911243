import types from "redux/actions/types";
import { ISchoolBoardState, SchoolBoardActions,ISchoolBoard } from "redux/types/schoolBoards";

const initialState:ISchoolBoardState={
    loading:true,
    board : {} as ISchoolBoard,
    boards: [] as ISchoolBoard[]
}

const schoolBoardReducer = (state = initialState,action:SchoolBoardActions):ISchoolBoardState  =>{
    switch (action.type){
        case types.GET_SCHOOL_BOARDS:
            return {
                ...state,
                loading:false,
                boards:action.payload
            }
        default:
            return state
    }
}

export default schoolBoardReducer