import useLocalStorage from "components/hooks/useLocalStorage";
import React, { useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setAlert } from "redux/actions/alert";
import { registerHeaderstate } from "redux/actions/headerstate";
import { RootState } from "redux/reducers";

function AcademicYearSelector() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let use_role_level = localStorage.getItem("use_role_level");
  let guestRole = localStorage.getItem("guestRoleId");

  let [branchAcademicBatchId, setBranchAcademicBatchId] = useLocalStorage("branchAcademicBatchId", "");
  let [academicBatchId, setAcademicBatchId] = useLocalStorage("academicBatchId", "");

  const { branchacademicbatchs : branchAcademicBatches } =
  useSelector((state: RootState) => state.branchacademicbatch);

  const {academicbatchs : academicBatches } = useSelector(
    (state: RootState) => state.academicbatch
  );

  const currentYearData : Record<string, any> = useMemo(() => {
    if(use_role_level === "organization" && academicBatches?.length){
        const currentYear = academicBatches.filter((data) => data.isCurrentYear == true)?.[0];
        return {...currentYear, isAcademicYear : true};
    } else if (use_role_level === "branch" && branchAcademicBatches?.length) {
        const currentYear = branchAcademicBatches.filter((data) => data.isCurrentAcademicYear == true)?.[0];
        return {...currentYear, isBranchAcademicYear : true};
    }
  }, [use_role_level, academicBatches, branchAcademicBatches]);

  const [currentYear, setCurrentYear] = React.useState<string>("");

  useEffect(() => {
    if(currentYearData){
        if(currentYearData?.isAcademicYear && currentYearData?._id) {
            if(!academicBatchId){
                setAcademicBatchId(currentYearData?._id);
            }
            dispatch(registerHeaderstate({ academicBatch: currentYearData?._id }));
            
        } else if (currentYearData?.isBranchAcademicYear && currentYearData?._id) {
            if(!branchAcademicBatchId){
                setBranchAcademicBatchId(currentYearData?._id);
            }
            dispatch(registerHeaderstate({ branchAcademicBatchId: currentYearData?._id }));
        }
        if(academicBatchId && currentYearData?.isAcademicYear){
            setCurrentYear(academicBatchId);
        } else if (branchAcademicBatchId && currentYearData?.isBranchAcademicYear) {
            setCurrentYear(branchAcademicBatchId);
        } else {
            setCurrentYear(currentYearData?._id);
        }
    }

  }, [currentYearData, academicBatchId, branchAcademicBatchId]);

  const _onSelectBranchAcademicBatch = (e: any) => {
    if (e.target.value == "undefined") {
      dispatch(
        setAlert({
          msg: "Please Select Valid Academic Year",
          status: 200,
          alertType: "warning",
        })
      );
      return;
    } else {
      setBranchAcademicBatchId(e.target.value);
      dispatch(registerHeaderstate({ branchAcademicBatchId: e.target.value }));
      navigate("/dashboard");
    }
  };

  const _onSelectAcademicBatch = (e: any) => {
    if (e.target.value == "undefined") {
      dispatch(
        setAlert({
          msg: "Please Select Valid Academic Year",
          status: 200,
          alertType: "warning",
        })
      );
      return;
    } else {
      setAcademicBatchId(e.target.value);
      dispatch(registerHeaderstate({ academicBatch: e.target.value }));
      navigate("/dashboard");
    }
  };

  return (
    <>
      {use_role_level == "branch" ||
      (use_role_level == "branch" && guestRole) ? (
        <div
          className="h-dwn header ms-0"
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Form.Select
            onChange={_onSelectBranchAcademicBatch}
            aria-label="Default select example"
            value={currentYear}
            // defaultValue={currentYear ? currentYear : ""}
            className="theme-input-control academic-year-header-light"
            style={{ border: "1px solid #DDDDDD !important" }}
          >
            <option value={"undefined"}>Select Current Year</option>
            {branchAcademicBatches && branchAcademicBatches.length ? (
              branchAcademicBatches.map((e: any, key: any) => {
                return (
                  <option key={key} value={e._id}>
                    {" "}
                    {e.name}
                  </option>
                );
              })
            ) : (
              <option value={"undefined"}>No AcademicYear</option>
            )}
          </Form.Select>
        </div>
      ) : null}
      {use_role_level == "organization" && !guestRole ? (
        <div
          className="h-dwn header ms-0"
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Form.Select
            onChange={_onSelectAcademicBatch}
            aria-label="Default select example"
            value={currentYear}
            className="theme-input-control academic-year-header-light"
            style={{ border: "1px solid #DDDDDD !important" }}
            // defaultValue={currentYear ? currentYear : ""}
          >
            <option value={"undefined"}>Select Current Year</option>
            {academicBatches && academicBatches.length ? (
              academicBatches.map((e: any, key: any) => {
                return (
                  <option key={key} value={e._id}>
                    {" "}
                    {e.name}
                  </option>
                );
              })
            ) : (
              <option value={"undefined"}>No AcademicYear</option>
            )}
          </Form.Select>
        </div>
      ) : null}
    </>
  );
}

export default AcademicYearSelector;
