import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import NotFound from 'components/not-found/NotFound';


const logErrorToDatadog = (error, info) => {
  const renderingError = new Error(error.message);
  renderingError.name = 'ReactRenderingError';
  renderingError.stack = info.componentStack || error.stack;

  datadogRum.addError(renderingError, {
    errorSource: 'rendering',
    componentStack: info.componentStack || '',
  });

  console.error("Error caught in ErrorBoundary: ", error, info);
};

const ErrorBoundary: React.FC = ({ children }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={NotFound}
      onError={logErrorToDatadog}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
