import types from "redux/actions/types";
import { IRoomSelectiongeneralState, RoomSelectiongeneralActions } from "../types/roomAllocationgeneral";
import { IRoomSelectiongeneral } from "redux/types/roomAllocationgeneral";

const initialState: IRoomSelectiongeneralState = {
  loading: true,
  roomgeneral: {} as IRoomSelectiongeneral,
  roomgenerals: [] as IRoomSelectiongeneral[],
  totalCount:0

};

const roomSelectiongeneralReducer= (state = initialState, action: RoomSelectiongeneralActions) => {
  switch (action.type) {
    case types.ROOMSELECTIONGENERAL_REGISTER_SUCCESS:
    case types.ROOMSELECTIONGENERAL_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        roomgeneral: [...state.roomgenerals, action.payload] as IRoomSelectiongeneral[],
      };
    case types.GET_ROOMSELECTIONGENERALS:
      return {
        ...state,
        loading: false,
        roomgenerals: action?.payload as IRoomSelectiongeneral,
      };

    case types.DELETE_ROOMSELECTIONGENERAL:
      return {
        ...state,
        roomgenerals: state.roomgenerals.filter(
          (roomgeneral) => roomgeneral._id !== action.payload
        ),
      };

    case types.UPDATE_ROOMSELECTIONGENERAL:
      return {
        ...state,
        roomgenerals: state.roomgenerals.map((roomgeneral) =>
        roomgeneral._id === action.payload.id
            ? { ...action.payload.roomgeneral }
            : roomgeneral
        ),
      };
    case types.ROOMSELECTIONGENERAL_REGISTER_FAIL:
    case types.ROOMSELECTIONGENERAL_AUTH_ERROR:

    default:
      return state;
  }
};
export default roomSelectiongeneralReducer;
