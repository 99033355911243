import types from "redux/actions/types";
import { IFeeplanState, FeeplanActions } from "../types/feeplan";
import { IFeeplan } from "redux/types/feeplan";

const initialState: IFeeplanState = {
  loading: true,
  feeplan: {} as IFeeplan,
  feeplans: [] as IFeeplan[],
  classBasedFeePlanes: [],
  totalCount: 0
};

const feeplanReducer = (state = initialState, action: FeeplanActions) => {
  switch (action.type) {
    case types.FEEPLAN_REGISTER_SUCCESS:
    case types.FEEPLAN_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // feeplans: [...state.feeplans, action.payload] as IFeeplan[],
      };
    case types.GET_FEEPLANS:

      return {
        ...state,
        loading: false,
        feeplans: action?.payload?.data as IFeeplan[],
        totalCount: action?.payload?.totalCount,
      };
      case types.GET_FEEPLANSBYCLASS:
        return {
          ...state,
          loading: false,
          feeplans: action?.payload as IFeeplan[],
        };
    case types.GET_CLASSBASED_FEEPLANS:
      return {
        ...state,
        classBasedFeePlanes: action.payload,
      };

    case types.DELETE_FEEPLAN:
      return {
        ...state,
        feeplans: state.feeplans.filter(
          (feeplan) => feeplan._id !== action.payload
        ),
      };

    case types.UPDATE_FEEPLAN:
      return {
        ...state,
        feeplans: state.feeplans.map((feeplan) =>
          feeplan._id === action.payload.id
            ? { ...action.payload.feeplan }
            : feeplan
        ),
      };
    case types.FEEPLAN_REGISTER_FAIL:
    case types.FEEPLAN_AUTH_ERROR:

    default:
      return state;
  }
};
export default feeplanReducer;
