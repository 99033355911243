import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";

const Branches: React.FC = (): JSX.Element => {
  return (
    <>

      <div className="p-0">
                <Table responsive hover  className="c-table" >
                                    <thead>
                                        <tr>
                                        <th colSpan={3} className="text-start">App URL: <span className="web-text4">https://api.myclassboard.com/swagger</span></th>                                         
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>
                                        <span className="web-text4">GET</span>  
                                        </td>
                                        <td>
                                        <span className="web-text4">  http://api.BranchData/Get_BranchInfrastructure</span> 
                                      
                                        </td>
                                        <td>
                                        This API is to get Branch Infrastructure
                                        </td>
                                        </tr>

                                    </tbody>
                                    </Table>   
     

      </div>

    </>
  )
}
export default React.memo(Branches);
