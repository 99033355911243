import React from "react";
import { Tooltip, Overlay } from "react-bootstrap";

interface Props {
  info: string;
  width ?: string,
  height ?: string
}

const ToolTip: React.FC<Props> = ({ info, width, height }) => {
  const triggerHandler = () => setShow((prev) => !prev);
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  return (
    <>
      <img
        src="/images/tooltip.svg"
        width={width}
        height={height}
        ref={target}
        onMouseOver={triggerHandler}
        onMouseLeave={triggerHandler}
      />
      <Overlay target={target.current} show={show} placement="bottom-start">
        {(props) => (
          <Tooltip id="overlay-example" className="theme-tooltip" {...props}>
            {info}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ToolTip;
