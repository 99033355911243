import ActionTypes from "../actions/types";

const initialState = {
    loading: true,
    error: "",
    dashletsData: [],
    totalCount: 0,
  };

const dashletsReducer = (
    state = initialState,
    action: any,
) => {
    switch (action.type) {
    case ActionTypes.GET_DASHLETS_REQUEST:
    case ActionTypes.UPDATE_DASHLETS_REQUEST:
        return { ...state, totalCount: 0, loading: true, error: "" };
  
    case ActionTypes.GET_DASHLETS_SUCCESS:
        return {
          ...state,
          dashletsData: action?.payload,
          loading: false,
        };
    case ActionTypes.GET_DASHLETS_FAILURE:
        return { ...initialState, totalCount: 0, error: action.payload };
    default:
        return state;
    }
}
export default dashletsReducer;
