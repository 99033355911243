import types from "redux/actions/types";
import { IStaffAttendanceData, IStaffALLDATEAttendance } from "redux/types/staffAttendanceAllDate";

const initialState: IStaffALLDATEAttendance = {
    loading: true,
    branchLeaveLoading:true,
    OrgLeaveLoading:true,
    allDatesAttendance: {} as IStaffAttendanceData,
    allDatesAttendances:[],
    staffAttendancesBranchLeave:[],
    staffAttendancesOrgLeave:[],
    totalCount: 0
};

const staffAttendanceAllDateReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_DATE_STAFFATTENDANCE:            
            return {
                ...state,
                loading: false,
                allDatesAttendances: action?.payload,
                totalCount: action?.payload?.totalCount
            }
        case types.GET_BRANCH_LEAVE_STATS:   
            return {
                ...state,
                branchLeaveLoading:false,
                staffAttendancesBranchLeave: action?.payload,
            }
        case types.GET_ORG_LEAVE_STATS:   
            return {
                ...state,
                OrgLeaveLoading:false,
                staffAttendancesOrgLeave: action?.payload,
            }
        default:
            return state;
    }
}

export default staffAttendanceAllDateReducer;
