import React from "react";

const RenderHTMLWithStyles = (htmlContent) => {
    // Sanitize the HTML content (optional but recommended for security)
    const sanitizedHTML = sanitizeHTML(htmlContent);
  
    // Set the sanitized HTML using dangerouslySetInnerHTML
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
  };
  
  // Sanitization function (using DOMParser)
  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.innerHTML;
  };

  export default RenderHTMLWithStyles;