import { AllExpensesActions } from "redux/types/allExpenses";
import ActionTypes from "../actions/types";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    expenses : [],
}

const allExpensesReducer = (state = initialState, action : AllExpensesActions) => {
    switch(action.type){
        case ActionTypes.GET_ALL_EXPENSES_REQUEST:
        case ActionTypes.CREATE_EXPENSE_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_ALL_EXPENSES_SUCCESS:
            return {
                ...state,
                loading : false,
                expenses : action.payload.data,
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_ALL_EXPENSES_FAILURE:
            return {
                ...state,
                loading : false,
                expenses : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.CREATE_EXPENSE_SUCCESS:
            return {
                ...state,
                loading : false,
                error : '',
            }
        case ActionTypes.CREATE_EXPENSE_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
        default : return state;
    }
}

export default allExpensesReducer;