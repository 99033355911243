import types from "redux/actions/types";
import { IConcessionapprovalruleState, ConcessionapprovalruleActions } from "../types/concessionapprovalrule";
import { IConcessionapprovalrule } from "redux/types/concessionapprovalrule";

const initialState: IConcessionapprovalruleState = {
    loading: true,
    concessionapprovalrule: {} as IConcessionapprovalrule,
    viewconcessionapprovalrequest: {} as IConcessionapprovalrule,

    concessionapprovalrules: [] as IConcessionapprovalrule[],
    concessionapprovalrequests: [] as IConcessionapprovalrule[],
    totalCount: 0

};

const concessionapprovalruleReducer = (state = initialState, action: ConcessionapprovalruleActions) => {
    switch (action.type) {
        case types.CONCESSIONAPPROVALRULE_REGISTER_SUCCESS:
        case types.CONCESSIONAPPROVALRULE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                concessionapprovalrules: [...state.concessionapprovalrules, action.payload] as IConcessionapprovalrule[],
            };
            case types.CONCESSIONAPPROVEDREQUEST_REGISTER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                };
        case types.GET_CONCESSIONAPPROVALRULES:
            return {
                ...state,
                loading: false,
                concessionapprovalrules: action.payload as IConcessionapprovalrule[],
            };
            case types.GET_CONCESSIONAPPROVALREQUEST:
                return {
                    ...state,
                    loading: false,
                    concessionapprovalrequests: action?.payload?.data,
                    totalCount: action?.payload?.totalCount
                };
    
                case types.GET_VIEWCONCESSIONAPPROVALREQUEST:
                    return {
                        ...state,
                        loading: false,
                        viewconcessionapprovalrequest: action.payload as IConcessionapprovalrule,
                    };
        
        case types.DELETE_CONCESSIONAPPROVALRULE:
            return {
                ...state,
                concessionapprovalrules: state.concessionapprovalrules.filter(
                    (concessionapprovalrule) => concessionapprovalrule._id !== action.payload
                ),
            };

        case types.UPDATE_CONCESSIONAPPROVALRULE:
            return {
                ...state,
                concessionapprovalrules: state.concessionapprovalrules.map((concessionapprovalrule) =>
                    concessionapprovalrule._id === action.payload.id
                        ? { ...action.payload.concessionapprovalrule }
                        : concessionapprovalrule
                ),
            };
        case types.CONCESSIONAPPROVALRULE_REGISTER_FAIL:
        case types.CONCESSIONAPPROVALRULE_AUTH_ERROR:

        default:
            return state;
    }
};
export default concessionapprovalruleReducer;
