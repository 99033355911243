import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import { RootState } from "redux/reducers";


function BreadcrumbComp() {

    const location = useLocation()
    const _user: any = useSelector((state: RootState) => state.user);
    let paths = location.pathname.split("/").filter((el) => { return el != '' && el != 'modules' && el != 'dashboard' });
    // console.log(location.pathname)
    // console.log(paths)

    const displayName = (path) => {
        if (!_user.loading && _user.permissions.length > 0) {
            // console.log(_user.permissions[0].BMRP);
            // console.log(_user.permissions[0].BMRP?.findIndex((mainModule: any) => mainModule?.branchmodule?.module?.route_path == path));
            let module = _user.permissions[0]?.BMRP?.filter((mainModule: any) => mainModule?.branchmodule?.module?.route_path == path)
            if (module[0]?.branchmodule?.alias_name) {
                // console.log(module[0]?.branchmodule?.alias_name)
                return module[0]?.branchmodule?.alias_name;
            }
            else if (module[0]?.branchmodule) {
                // console.log(module[0]?.branchmodule)
                return module[0]?.branchmodule;
            }
            else if (module[0]) {
                // console.log(module[0])
                return module[0];
            }
            else {
                // console.log(module)
                return path;
            }
        }
        else {
            return path;
        }
    }
    const pullPath = (path, i) => {
        if (i == 0)
            return 'modules/' + path
        if (i == 1)
            return `modules/${paths[i - 1]}/` + path
    }
    let absolutePaths = []
    paths.forEach((item) => {
        if (item.includes("_")) {
            absolutePaths.push(item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))
        } else {
            absolutePaths.push(item)
        }
    })
    return (
        <Breadcrumb className='m-0 mt-1 mb-1'>
            {paths.length > 0 && <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/dashboard' }} >Dashboard</Breadcrumb.Item>}
            {paths.length > 0 && paths.map((item, i) => {
                return (<React.Fragment key={i}>
                    {paths?.length == (i + 1) ? (
                        <Breadcrumb.Item  >
                            {displayName(absolutePaths[i])}
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${pullPath(item, i)}` }}>
                            {displayName(absolutePaths[i])}
                        </Breadcrumb.Item>
                    )}

                </React.Fragment>)
            })}
        </Breadcrumb>
    );
}

export default BreadcrumbComp;