enum ActionTypes {
  // USER 
  USER_LOADED = "USER_LOADED",
  USER_PERMISSIONS_LOADED = "USER_PERMISSIONS_LOADED",
  USER_AUTH_ERROR = "USER_AUTH_ERROR",
  USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL = "USER_REGISTER_FAIL",
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL = "USER_LOGIN_FAIL",
  USER_LOGOUT = "USER_LOGOUT",
  GET_USERS = "GET_USERS",
  GET_STUDENT = "GET_STUDENT",
  GET_STUDENT_JOURNEY_REQUEST = "GET_STUDENT_JOURNEY_REQUEST",
  GET_STUDENT_JOURNEY = "GET_STUDENT_JOURNEY",
  GET_STUDENT_JOURNEY_FAILURE = "GET_STUDENT_JOURNEY_FAILURE",
  GET_USERDETAILS = "GET_USERDETAILS",
  SEARCH_USERDETAILS = "SEARCH_USERDETAILS",
  GETFEEPLAN_STUDENTS = "GETFEEPLAN_STUDENTS",
  GETFEETYPE_STUDENTS = "GETFEETYPE_STUDENTS",

  UPDATE_USER = "UPDATE_USER",
  UPDATE_STUDENTS_BULK = "UPDATE_STUDENTS_BULK",
  BULK_STUDENT_PASSWORD_GENERATION = "BULK_STUDENT_PASSWORD_GENERATION",
  BULK_USER_PASSWORD_GENERATION = "BULK_USER_PASSWORD_GENERATION",
  DELETE_USER = "DELETE_USER",
  USER_CREATION_SUCCESS = "USER_CREATION_SUCCESS",
  GET_STAFFDETAILS = "GET_STAFFDETAILS",
  FILTER_STAFFDETAILS = "FILTER_STAFFDETAILS",
  UPDATE_STAFFDETAILS = "UPDATE_STAFFDETAILS",
  UPDATE_STAFFDETAILS_BULK = "UPDATE_STAFFDETAILS_BULK"
}

export default ActionTypes;
