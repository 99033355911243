import React from "react";
import { Button, Col, Form, ListGroup, Modal, Row, Tab, Table  } from "react-bootstrap";


const MobileappSettings: React.FC = (): JSX.Element => {
  return (
    <div className="c-container content-tabs mt-5">
        <div className="bg-section">
             <h3 className="f-24 frmed web-text5">Mobile App Advanced Settings</h3>

             <div className="section-bgwhite">
                      <div className="section-small">
                                <div className="mb-3">
                                    <Form.Label>School Name</Form.Label>
                                    <Form.Control type="text" placeholder="" /> 
                                </div>
                                <div className="mb-3">
                                    <Form.Label>App Name</Form.Label>
                                    <Form.Control type="text" placeholder="" /> 
                                </div>
                                <div className="mb-3">
                                    <Form.Label>Android App URL</Form.Label>
                                    <Form.Control type="text" placeholder="" /> 
                                </div>

                                <div className="mb-3">
                                    <Form.Label>IOS App URL</Form.Label>
                                    <Form.Control type="text" placeholder="" /> 
                                </div>
                                <div className="mb-3">
                                    <Form.Label>Upload App Logo</Form.Label>
                                    <Form.Control type="file" placeholder="" /> 
                                </div>

                                <div className="text-end">
                                <Button className="btn-save">
                                Save & Update
                                  </Button>
                                  </div>

                                  </div>

            </div>


        </div>
      
    </div>
  )
}
export default React.memo(MobileappSettings);
