import types from 'redux/actions/types';
const initialState = {
    staffData:{},
    branches: [],
    feeCollection:[],
    statusRibbon: {},
    leavesData: {loading: true, data: {}},
    feeData: {loading: true, data: {}},
    approvalsData: {loading: true, data: []},
    classWiseStudents: {loading: true, data: []},
    orgStudentBirthdays: {loading: true, data: []},
    staffWorkAnniversaries : {loading: true, data: []},
    smsData: {loading: true, data: {}},
    peopleData: {loading: true, data: []},
    strengthData: {loading: true, data: {}},
    birthdaysData: {loading: true, data: {}},
    branchAnnouncementsData: {loading: true, data: {}},
    branchEventsData: {loading: true, data: {}},
    monthWiseFeeCollection: {loading: true, data: []},
}
export const mainDashboard = (state = initialState, action: any) => {
    switch (action.type) {
        case types.STAFF_DATA:
            return {
                ...state,
                staffData: {...state.staffData,...action.payload}
            }
            case types.FEE_COLLECTION:
                return{
                    ...state,
                    feeCollection:[...action.payload]
                }
            case types.STATUS_RIBBON:
                return{
                    ...state,
                    statusRibbon: {...state.statusRibbon, ...action.payload}
                }
            case types.ORG_LEAVES_DATA:
                return{
                    ...state,
                    leavesData: {loading:false, data: {...action.payload}}
                }
            case types.BRANCH_LEAVES_DATA:
                return{
                    ...state,
                    leavesData: {loading:false, data: {...action.payload}}
                }
            case types.ORG_APPROVALS_DATA:
                return{
                    ...state,
                    approvalsData: {loading:false, data: [...action.payload]}
                }
            case types.BRANCH_APPROVALS_DATA:
                return{
                    ...state,
                    approvalsData: {loading:false, data: [...action.payload]}
                }
            case types.ORG_SMS_DATA:
                return{
                    ...state,
                    smsData: {loading:false, data: {...action.payload}}
                }
            case types.BRANCH_SMS_DATA:
                return{
                    ...state,
                    smsData: {loading:false, data: {...action.payload}}
                }
            case types.BRANCH_SMS_DATA_FAIL:
                return{
                    ...state,
                    smsData: {loading:false, data: {}}
                }
            case types.TOTAL_PEOPLE_DATA:
                return{
                    ...state,
                    peopleData: {loading:false, data: [...action.payload]}
                }
            case types.ORG_STRENGTH:
                return{
                    ...state,
                    strengthData: {loading:false, data: {...action.payload}}
                }
            case types.BRANCH_STRENGTH:
                return{
                    ...state,
                    strengthData: {loading:false, data: {...action.payload}}
                }
            case types.BRANCH_BIRTH_DAYS:
                return{
                    ...state,
                    birthdaysData: {loading:false, data: {...action.payload}}
                }
            case types.BRANCH_ANNOUNCEMENTS:
                return{
                    ...state,
                    branchAnnouncementsData: {loading:false, data: {...action.payload}}
                }
            case types.BRANCH_EVENTS:
                return{
                    ...state,
                    branchEventsData: {loading:false, data: {...action.payload}}
                }
            case types.FEE_SUMMARY_DATA:
                return{
                    ...state,
                    feeData: {loading:false, data: {...action.payload}} 
                }
            case types.FEE_SUMMARY_DATA_FAIL:
                    return{
                        ...state,
                        feeData: {loading:false, data: {}} 
                    }
            case types.CLASS_WISE_STUDENT_STRENGTH:
               return{
                    ...state,
                    classWiseStudents: {loading:false, data: [...action.payload]}
                }
            case types.GET_MONTH_WISE_FEE:
                return{
                        ...state,
                        monthWiseFeeCollection: {loading:false, data: [...action.payload]}
                    }
            case types.GET_STUDENT_BIRTHDAYS:
                return{
                        ...state,
                        orgStudentBirthdays: {loading:false, data: [...action.payload]}
                    }
            case types.GET_STAFF_WORK_ANNIVERSARY:
                return{
                        ...state,
                        staffWorkAnniversaries: {loading:false, data: [...action.payload]}
                    }
        default:
            return state
    }
}