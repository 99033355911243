import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";

const Countryblacklist: React.FC = (): JSX.Element => {
  return (
    <>
      

      <h3 className="f-24 frmed web-text5">Check to block the countries</h3>

      <div className="note mb-4">
                <h5 className="frbold">Note:</h5>
                  <p> If you select any country from the list, if anyone is accessing from the country will be blocked automatically by the system.</p>
                </div>


           


      <div className="inner-section p-0 mt-4">
          <div className="bg-section">

             <div className=" d-flex align-items-center justify-content-between pb-3 border-b">
             <Form.Check type="checkbox" inline
              label="Select All"
              name="group1" className="maclabel" />
                <Button className="btn-save">Save & Update</Button>                 
               </div> 


              <ul className="ullist mt-3">                  
                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Afghanistan`}
                        />                    
                      </div>                   
                    </li>

                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Aland Islands`}
                        />                    
                      </div>
                    </li>
                   
                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Antigua and Barbuda`}
                        />                    
                      </div>
                    </li>

                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Aland Islands`}
                        />                    
                      </div>
                    </li>
                   
                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Antigua and Barbuda`}
                        />                    
                      </div>
                    </li>


                    
                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Aland Islands`}
                        />                    
                      </div>
                    </li>
                   
                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Antigua and Barbuda`}
                        />                    
                      </div>
                    </li>
                    
                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Aland Islands`}
                        />                    
                      </div>
                    </li>
                   
                    <li>  
                      <div  className="mb-3">
                        <Form.Check                      
                          label={`Antigua and Barbuda`}
                        />                    
                      </div>
                    </li>

              </ul>

               
          </div>
      </div>



    </>
  )
}
export default React.memo(Countryblacklist);
