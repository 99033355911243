import { ICertificate, ICertificateState, CertificateActions } from "redux/types/certificates"
import types from "redux/actions/types";

const initialState:ICertificateState = {
    loading:true,
    certificate: {} as ICertificate,
    certificates: [] as ICertificate[],
    certifiedStudents:[],
    totalCount:0
}

const printCertificate = async (certificateHTML: string) => {
    var pri = (document.getElementById("ifmcontentstoprint") as HTMLIFrameElement).contentWindow;
    pri.document.open();
    pri.document.write(certificateHTML);
    pri.document.close();
    pri.focus();
    pri.print();
}

const certificateReducer=(state=initialState,action:CertificateActions)=>{
    switch (action.type){
        case types.ISSUE_CERTIFICATE_SUCCESS:
            printCertificate(action?.payload?.htmlString);
            return {
                ...state,
                loading:false,
                certificate:{...action.payload} as ICertificate
            }
        case types.ISSUE_CERTIFICATE_FAIL:
            return {
                ...state,loading:true
            }
        case types.REPRINT_CERTIFICATE_SUCCESS :
            printCertificate(action?.payload?.htmlString);
            return {
                ...state,
                loading:false,
                certificate:{...action.payload} as ICertificate
            }
        case types.ISSUE_TRANSFER_CERTIFICATE_SUCCESS:
            printCertificate(action?.payload?.htmlString);
            return {
                ...state, loading: false, certificate: { ...action.payload } as ICertificate
            }
            case types.REPRINT_TRANSFER_CERTIFICATE_SUCCESS : 
                printCertificate(action?.payload?.htmlString);
                return {
                    ...state, loading: false, certificate: { ...action.payload } as ICertificate
                }
        case types.GET_CERTIFIED_STUDENTS:
            return {
                ...state,loading:false,certifiedStudents:action?.payload?.data,
                totalCount:action?.payload?.totalCount
            }
        case types.GET_TRANSFERRED_STUDENTS:
            return {
                ...state, loading: false, certifiedStudents: action?.payload?.data,
                totalCount: action?.payload?.totalCount
            }
        default:
            return {...state}
    }
}

export default certificateReducer