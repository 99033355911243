import types from "redux/actions/types";
import { IBranchesState, BranchesActions } from "../types/branch";
import { IBranches } from "redux/types/branch";

const initialState: IBranchesState = {
  loading: true,
  branche: {} as IBranches,
  branches: [] as IBranches[],
};

const branchReducer = (state = initialState, action: BranchesActions) => {
  switch (action.type) {
    case types.BRANCHES_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        branches: [...state.branches, action.payload] as IBranches[],
      };
    case types.GET_BRANCHES:
      return {
        ...state,
        loading: false,
        branches: action.payload as IBranches[],
      };
      case types.GET_BRANCH:
        return {
          ...state,
          loading: false,
          branche: action.payload as IBranches,
        };
      case types.LOGOUT_BRANCH:
        return {
          ...state,
          loading: true,
          branche: {} as IBranches
        }
    case types.DELETE_BRANCHES:
      return {
        ...state,
        branches: state.branches.filter(
          (branche) => branche._id !== action.payload
        ),
      };

    case types.UPDATE_BRANCHES:
      return {
        ...state,
        branches: state.branches.map((branche) =>
        branche._id === action.payload.id
        ? { ...action.payload.branch }
        : branche
        ),
      };
      case types.BRANCHES_REGISTER_FAIL:
      case types.BRANCHES_AUTH_ERROR:
      case types.BRANCHMODULE_REGISTER_SUCCESS:

    default:
      return state;
  }
};
export default branchReducer;
