import ActionTypes from "redux/actions/types";

const initialState = {
    isPasswordReset: false
}

export const resetPassword = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.REQUEST_RESET_PASSWORD:
            return { ...state, isPasswordReset: true }
        default:
            return state
    }
}