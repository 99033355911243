import types from "redux/actions/types";
import { IBranchAcademicBatchState, BranchAcademicbatchActions } from "../types/branchacademicbatch";
import { IBranchAcademicbatch } from "redux/types/branchacademicbatch";

const initialState: IBranchAcademicBatchState = {
  loading: true,
  initial : false,
  branchacademicbatch: {} as IBranchAcademicbatch,
  branchacademicbatchs: [] as IBranchAcademicbatch[],
};

const branchacademicbatchReducer = (state = initialState, action: BranchAcademicbatchActions) => {
  switch (action.type) {
    case types.BRANCHACADEMICBATCH_INITIAL:
      return {
        ...state,
        initial : true,
      }
    case types.BRANCHACADEMICBATCH_NOT_INITIAL:
      return {
        ...state,
        initial : false,
      }

    case types.BRANCHACADEMICBATCH_REGISTER_SUCCESS:
    case types.BRANCHACADEMICBATCH_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        branchacademicbatchs: [...state.branchacademicbatchs, action.payload] as IBranchAcademicbatch[],
      };
      case types.UPDATE_BRANCHACADEMICBATCHSTATUS:
        return {
          ...state,
          loading: false,
          branchacademicbatchs:state.branchacademicbatchs.concat(action.payload)
        };
    case types.GET_BRANCHACADEMICBATCHS:
      return {
        ...state,
        loading: false,
        branchacademicbatchs: action.payload as IBranchAcademicbatch[],
      };

    case types.DELETE_BRANCHACADEMICBATCH:
      return {
        ...state,
        branchacademicbatchs: state.branchacademicbatchs.filter(
          (branchacademicbatch) => branchacademicbatch._id !== action.payload
        ),
      };

    case types.UPDATE_BRANCHACADEMICBATCH:
      return {
        ...state,
        loading: false,
        branchacademicbatchs: state.branchacademicbatchs.map((branchacademicbatch) =>
        branchacademicbatch._id === action.payload._id
            ? { ...action.payload.branchacademicbatch }
            : branchacademicbatch
        ),
      };
    case types.BRANCHACADEMICBATCH_REGISTER_FAIL:
    case types.BRANCHACADEMICBATCH_AUTH_ERROR:

    default:
      return state;
  }
};
export default branchacademicbatchReducer;
