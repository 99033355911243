import { AxiosRequestConfig } from "axios";
import axios from "utils/axiosifs";

export async function uploadAttachment(data, _, opt) {
    try {

        const config: AxiosRequestConfig<any> = {
            onUploadProgress(progressEvent) {
                if(opt?.handleUploadInProgress){
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    opt.handleUploadInProgress(progress);
                }
            },
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return await axios.post(`media/upload`, data, config);

    } catch (error) {
        return error;
    }
}

export async function getAttachment(id: any) {
    try {
        return await axios.get(`media/file?path=${id}`);

    } catch (error) {
        return error;
    }
}

export async function getImageAsBase64(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
  }