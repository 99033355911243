import React from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as Yup from "yup";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from 'redux/actions/resetPassword';
import ResetComponent from 'components/resetPasswordComponent';
interface IInitialValues {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}
const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .required('username is required'),
    password: Yup.string()
        .required('password is required').min(6, 'Password must be at 3 char long'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords does not match'),

});

function ResetPassword() {
    return (
        <ResetComponent />
    )
}

export default React.memo(ResetPassword)