import { Dispatch } from "redux";
import axios from "utils/axios";
import types from "./types";
import { setAlert } from "./alert";
import { StudentfeeActions } from "redux/types/studentfee";
import { AlertActions } from "redux/types/alert";
import { setUserAuthToken } from "../../utils/headers";

// REGISTER Module
export const registerStudentfee =
    (body: any) =>
        async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
            if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.post(`studentfee/assign`, body, config);
                dispatch({
                    type: types.STUDENTFEE_REGISTER_SUCCESS,
                    payload: data,
                });
                dispatch<any>(
                    setAlert({
                        msg: "Studentfee Register successful!",
                        status: 200,
                        alertType: "success",
                    })
                );

                // history.push("/boardnames");
            } catch (error: any) {
                
                dispatch({ type: types.STUDENTFEE_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: error?.response?.data,
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };

// GET Studentfees
export const getStudentfees =
    (body: any, setIsLoading?:any) => async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(`studentfee/getStudentFees`, body, config);
            dispatch({ type: types.GET_STUDENTFEES, payload: data });
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when fetching the Studentfees!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        } finally {
            if(setIsLoading)
                setIsLoading(false);
        }
    };

// UPDATE Modules DATA
export const updateStudentfees =
    (body: any, id: any, history: any) =>
        async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.put(
                    `studentfee/update/${id}`,
                    body,
                    config
                );
                dispatch({
                    type: types.UPDATE_STUDENTFEE,
                    payload: data,
                });

                dispatch<any>(
                    setAlert({
                        msg: "Studentfee Data Updated!",
                        status: 200,
                        alertType: "success",
                    })
                );
                // history.push("/boardnames");
            } catch (error: any) {
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when updating the Studentfee!",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            } finally {
                // setSubmitting(false);
            }
        };

// DELETE Module
export const deleteStudentfee =
    (id: number) => async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            await axios.delete(`studentfee/delete/${id}`, config);
            dispatch({ type: types.DELETE_STUDENTFEE, payload: id });
            dispatch<any>(
                setAlert({
                    msg: "studentfee has been deleted!",
                    status: 200,
                    alertType: "success",
                })
            );
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when deleting the Studentfee!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };
export const StudentFeepayment =
    (body: any) =>
        async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
            if (localStorage.user__token) setUserAuthToken(localStorage.user__token, localStorage.use_role_level);

            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.post(`instalmentpayment/create`, body, config);
                let valid = true;
                for (let item of data){
                    if(item.error === true){
                        valid = false
                        dispatch<any>(
                            setAlert({
                                msg: (item.message ?? "Receipt number settings for one of the payments is not found!"),
                                status: 400,
                                alertType: "danger",
                            })
                        );
                        dispatch({
                            type: types.STUDENTFEEPAYMENT_REGISTER_SUCCESS,
                            payload: [item],
                        });
                        return [item];
                    }
                }
                if (valid){
                    dispatch({
                        type: types.STUDENTFEEPAYMENT_REGISTER_SUCCESS,
                        payload: data,
                    });
                    dispatch<any>(
                        setAlert({
                            msg: "Student Fee payment successful!",
                            status: 200,
                            alertType: "success",
                        })
                    );
                }
                return data;
            } catch (error: any) {
                dispatch({ type: types.STUDENTFEE_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: error?.response?.data,
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };
export const StudentFeerecept =
    (id: any) =>
        async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.get(`feereceipt/getPaymentReceipt/${id}`, config);
                dispatch({
                    type: types.GET_STUDENTFEESRECEPT,
                    payload: data,
                });
            } catch (error: any) {
                
                dispatch({ type: types.STUDENTFEE_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: error?.response?.data,
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };

export const StudentFeetransactions =
    (body: any, setIsLoader ?: React.Dispatch<React.SetStateAction<boolean>>) => async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            if(setIsLoader){
                setIsLoader(true);
            }
            const { data } = await axios.post(`feereceipt/getPaymentReceipts`, body, config);
            dispatch({ type: types.GET_STUDENTFEETRANSACTIONS, payload: data });
        } catch (error: any) {
            
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when fetching the Studentfees!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        } finally {
            if(setIsLoader){
                setIsLoader(false);
            }
        }
    };
export const unAssignStudentFee =
    (body: any) => async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(`studentfee/unassign`, body, config);
            if (data.err === true){
                dispatch<any>(
                    setAlert({
                        msg: "Cannot unassign the fee. Partial/Full amount is paid for the selected fee.",
                        status: 400,
                        alertType: "danger",
                    })
                );
            }
            dispatch({ type: types.UNASSIGN_STUDENTFEE, payload: data });
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when unassigning the student's fee!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };
    export const onlinePayments =
    () => async (dispatch: Dispatch<StudentfeeActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.get(`onlinepaymodes/getPayModes`);
            dispatch({ type: types.GET_ONLINEPAYMENTS, payload: data });
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when fetching the onlinepayments!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };
    // localhost:5002/api/v1/onlinepaymodes/getPayModes