import ActionTypes from "redux/actions/types";
const initialState = {
    feeReciptSequences: [],
    manualReceiptSetting : {
        data : [],
        loading : false,
        error : ''
    },
    singleReciptSequence:''
};
export const feeReciptSequences = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_FEE_RECIPT_SEQUENCES:
            return { ...state, feeReciptSequences: [...action?.payload] }
        case ActionTypes.GET_FEE_RECIPT_SEQUENCE:
            return { ...state, singleReciptSequence: {...action?.payload} }
        case ActionTypes.GET_MANUAL_RECEIPT_SETTINGS:
            return { ...state, manualReceiptSetting: {data : [...action?.payload], loading : false, error : ''} }
        default:
            return state
    }
}