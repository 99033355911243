import types from "redux/actions/types";
import { IEnquirystageState, EnquirystageActions } from "../types/enquirystage";
import { IEnquirystage } from "redux/types/enquirystage";

const initialState: IEnquirystageState = {
  loading: true,
  enquirystage: {} as IEnquirystage,
  enquirystages: [] as IEnquirystage[],
};

const enquirystageReducer = (state = initialState, action: EnquirystageActions) => {
  switch (action.type) {
    case types.ENQUIRYSTAGE_REGISTER_SUCCESS:
    case types.ENQUIRYSTAGE_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        enquirystages: [...state.enquirystages, action.payload] as IEnquirystage[],
      };
    case types.GET_ENQUIRYSTAGES:
      return {
        ...state,
        loading: false,
        enquirystages: action.payload as IEnquirystage[],
      };

    case types.DELETE_ENQUIRYSTAGE:
      return {
        ...state,
        enquirystages: state.enquirystages.filter(
          (enquirystage) => enquirystage._id !== action.payload
        ),
      };

    case types.UPDATE_ENQUIRYSTAGE:
      return {
        ...state,
        enquirystages: state.enquirystages.map((enquirystage) =>
        enquirystage._id === action.payload.id
            ? { ...action.payload.enquirystage }
            : enquirystage
        ),
      };
    case types.ENQUIRYSTAGE_REGISTER_FAIL:
    case types.ENQUIRYSTAGE_AUTH_ERROR:

    default:
      return state;
  }
};
export default enquirystageReducer;
