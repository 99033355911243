import React, { useState, useEffect } from "react";
import { getUserPermissions, logOutUser } from "redux/actions/user";
import useApi from "components/hooks/useApi";
import * as organizationService from 'services/organization'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setAlert } from "redux/actions/alert";
import { RootState } from "redux/reducers";
import { Form } from "react-bootstrap";

const HeaderRoles: React.FC = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let use_role_level: any = localStorage.getItem("use_role_level");
    const [initialLevel, setInitialLevel] = useState<any>("");
    const [currentRoleItem, setCurrentRoleItem] = useState<any>(null);
    const [currentRole, setCurrentRole] = useState<string>("");
    const {action : getUserRoles, data : userRolesData, error : userRolesError } = useApi(organizationService.getUserRoles);
    const _user = useSelector((state: RootState) => state.user);

 const _onSelectUserRole = (e: any) => {
    if (e.target.value === "undefined") {
      dispatch(
        setAlert({
          msg: "Please select any role",
          status: 200,
          alertType: "warning",
        })
      );
      return;
    } else {
      const [selectedId, selectedIsDefaultRole] = e.target.value.split(',');
      const newRoleItem = userRolesData?.data?.filter((item) => item._id === selectedId);
      const previousRole = currentRole;
      const previousRoleItem = currentRoleItem;
      setCurrentRoleItem(newRoleItem);
      setCurrentRole(e.target.value);
      navigate("/dashboard")
      dispatch(getUserPermissions({ roleId: selectedId, isDefaultRole: selectedIsDefaultRole }, () => {setCurrentRole(previousRole); setCurrentRoleItem(previousRoleItem);}));
      
    }
  };

  useEffect(() => {
    if(currentRole){
      localStorage.setItem("roleId", currentRole.split(",")[0]);
    }
  },[currentRole]) 
  useEffect(() => {
    if(currentRoleItem?.[0]?.roleLevel){
      localStorage.setItem("use_role_level", currentRoleItem?.[0]?.roleLevel);
    }
  },[currentRoleItem]) 

  useEffect(() => {
    if (initialLevel === "branch") {
      getUserRoles()
    }
  }, [initialLevel]);

  useEffect(() => {
    if (use_role_level === "branch" && currentRole === "" && _user?.roleId?._id) {
      setInitialLevel(_user?.roleId?.roleLevel);
      setCurrentRole(`${_user?.roleId?._id},${true}` || '')
    }
  }, [use_role_level, _user?.roleId, currentRole]);
  return (
    <>
    {((initialLevel === "branch") && !userRolesError && userRolesData?.data && userRolesData?.data.length > 1) ?
      <div className="d-flex align-items-center" style={{paddingBlock : "5px"}}>
        <Form.Select
          onChange={_onSelectUserRole}
          aria-label="Default select example"
          value={currentRole}
          defaultValue={_user && _user?.roleId ? _user?.roleId?._id : ""}
          className="theme-input-control-white"
          style={{border: "1px solid #DDDDDD !important"}}
        >
          <option value={"undefined"}>Select Role</option>
          {userRolesData?.data && userRolesData?.data.length ? (
            userRolesData?.data.map((e: any, key: any) => {
              return (
                <option key={key} value={`${e?._id},${e?.isDefaultRole}`}>
                  {e.name}
                </option>
              );
            })
          ) : (
            <option value={"undefined"}>No Roles</option>
          )}
        </Form.Select>
      </div> 
    :null}
    </>
      
  )
}

export default React.memo(HeaderRoles);