import types from "redux/actions/types";
import { ITemplatesState, TemplatesActions } from "redux/types/templates";

const initialState: ITemplatesState  = {
    template: {} as any,
    templates: [],
    loading: true,
    error: false,
};
const TemplatesReducer = (state = initialState, action: TemplatesActions) => {
    switch (action.type) {
        case types.GET_ALL_TEMPLATES:
            return {
                ...state,
                loading: false,
                error : false,
                templates: action.payload
            };
        case types.GET_ALL_TEMPLATES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                templates : [],
                template: {},
            };
        case types.SELECT_TEMPLATE:
            return {
                ...state,
                loading: false,
                error:false,
                template: action?.payload,
            };

        default:
            return state
    }
}
export default TemplatesReducer;