import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";



const BranchAcademicYearMonth: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Form.Select className="outline-select">
          <option>2021-2022</option>
        </Form.Select>
      </div>

      <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>Location</th>
              <th>Branch</th>  
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>All Location</td>
              <td>January</td> 
            </tr>
          </tbody>
        </Table>
      </Card>
    </>
  )
}
export default React.memo(BranchAcademicYearMonth);

