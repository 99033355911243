import ActionTypes from "redux/actions/types";

const initialState = {
    eduFiles: [],
    expFiles: [],
    staff: []
}

export const StaffOnboarding = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.UPLOAD_EDUCATION_FILES:
            return { ...state, eduFiles: [...state.eduFiles, {...action.payload}] }
        case ActionTypes.UPLOAD_EXPERIANCE_FILES:
            return { ...state, expFiles: [...state.expFiles, {...action.payload}] }
        default:
            return state;
    }
}