import ActionTypes from "redux/actions/types";

const initialState = {
    DltTemplates: [],
    comunicationTypes: [],
    templateVariables: []
}

export const DltTemplate = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_DLT_TEMPLATES:
            return { ...state, DltTemplates: [...action.payload] }
        case ActionTypes.GET_COMMUNICATION_TYPES:
            return { ...state, comunicationTypes: [...action.payload] }
        case ActionTypes.GET_TEMPLATE_VARIABLES:
            return { ...state, templateVariables: [...action.payload] }
        default:
            return state
    }
}
