import types from "redux/actions/types";
import {
  IStudentBulkUploadState,
  StudentBulkUploadActions,
} from "redux/types/studentbulkupload";

const initialState: IStudentBulkUploadState = {
  loading: true,
  studentbulkupload: [],
  studentbulkuploadvalidated: { status: "", count: [], message: [] },
};

const studentBulkUploadReducer = (
  state = initialState,
  action: StudentBulkUploadActions
): IStudentBulkUploadState => {
  switch (action.type) {
    case types.STUDENT_BULK_UPLOAD:
      return {
        ...state,
        loading: false,
        studentbulkupload: action.payload,
      };
    case types.STUDENT_BULK_UPLOAD_VALIDATION:
      return {
        loading: false,
        studentbulkupload: [],
        studentbulkuploadvalidated: {
          status: action.payload.status,
          count: action.payload.count,
          message: action.payload.data,
        },
      };
    case types.STUDENT_BULK_UPLOAD_FAILURE:
    default:
      return state;
  }
};

export default studentBulkUploadReducer;