import React, {useState,useEffect} from 'react'
import { useNavigate ,useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as Yup from "yup";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from 'redux/actions/resetPassword';
import { getlogo } from "redux/actions/downloads";
import { RootState } from 'redux/reducers';
import '../pages/auth/auth_styles.css'
import types from 'redux/actions/types';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IInitialValues {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}
const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .required('username is required'),
    password: Yup.string()
        .required('password is required').min(6, 'Password must be at 6 char long'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords does not match'),

});
function ResetComponent() {
    const { register, handleSubmit, watch, setValue, getValues, setError, formState: { errors }, formState } = useForm<IInitialValues>({
        resolver: yupResolver(validationSchema)
    });
    const { _id } = useSelector((state: any) => state.user);
    const { logo, id } = useSelector((state: RootState) => state.organizationLogo)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [bgImage, setBgImage] = useState("");
    const [oldPasswordShown, setOldPasswordShown] = React.useState<boolean>(false);
    const [passwordShown, setPasswordShown] = React.useState<boolean>(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = React.useState<boolean>(false);
    const { validatedDomain } = useSelector((state:RootState)=>state.organization);
    const {loading: orgLoading,organization} = useSelector((state:RootState)=>state.organization)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onHandleSubmit = (values: any) => {
        let obj = {
            userId: _id,
            password: values.password,
            currentPassword: values.oldPassword
        }
        dispatch(changePassword(obj, navigate))
    }
    const colors = ['#fff', '#fff', '#fff'];
    const [currentColorIndex, setCurrentColorIndex] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange: (current, next) => setCurrentColorIndex(next),
      };

      const [bannerImages, setBannerImages] = useState<string[]>([]);
      const [videoData, setVideoData] = useState<any>("");
    
      const settingBackGroundData = async () => {
        setIsLoading(true)
        if ((organization as any)?.bannerImages?.length > 0) {
          
          let imagesPromises = (organization as any)?.bannerImages
            .filter((imagePath: string) => imagePath.endsWith('.png'))
            .map(async(imagePath: string) => await dispatch(getlogo(imagePath)));
    
            if(imagesPromises && imagesPromises.length){
          Promise.all(imagesPromises)
            .then((imagesData) => {
              setBannerImages(imagesData);
            })
            .catch((error) => {
              
            });
          }
          else{
            setBannerImages([]);
          }
          let videoPromise = (organization as any)?.bannerImages
          .find((imagePath: string) => imagePath.endsWith('.mp4'));
    
        const fetchVideoData = async () => {
          try {
              let videoData = await dispatch(getlogo(videoPromise));
              setVideoData(videoData);
            }
            catch (videoError) {
             
          }
        };
    
            if (videoPromise) {
              Promise.all([fetchVideoData()]);
            }
            else{
              setVideoData('');
            }
        }
        else{
       
          setBannerImages([]);
          setVideoData('')
        }
      }
    
      React.useEffect(() => {
          if (validatedDomain?.orgId && !orgLoading) {
              const data: any = dispatch(getlogo(organization?.logo_path))
              data.then((data: any) => {
                dispatch({type:types.UPDATE_ORGANIZATION_LOGO,payload:{logo:data,id:organization._id }})
              })
              settingBackGroundData()
              setIsLoading(false);
            } else if (id && !orgLoading){
              settingBackGroundData()
              setIsLoading(false);
            }
            else {
              setBannerImages([]);
               setVideoData('')
              setIsLoading(false);  
            }
          
      }, [organization, validatedDomain]);
    

      const navigateMobile = (type) => {
        if(organization?.[type]){
          window.open(organization?.[type], '_blank');
        }
        else{
          if(type === "playStoreLink"){
            window.open("https://play.google.com/store/apps/details?id=com.ctrlcampussmartapp", '_blank')
          }
          else{
            window.open("https://apps.apple.com/us/app/ctrlcampus-smartapp/id1640110690", '_blank')
          }
        }
      }

    const toggleOldPasswordVisiblity = () => {
        setOldPasswordShown(oldPasswordShown ? false : true);
      };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };

      const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmPasswordShown ? false : true);
      };
 

    const handleBackButton = () => {
        navigate("/login");
    }
    
      const [currentIndex, setCurrentIndex] = useState(0);
    
      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
        }, 3000); // Adjust the interval based on your preference (3000ms in this example)
    
        return () => clearInterval(interval);
      }, [bannerImages]);

    return (
        <div className='main-auth-background'>
            <div className="d-flex auth-main-screen">
                <div className={`m-0 p-0 screen-org ${isLoading ? 'align-items-center justify-content-center' : ''}`} style={{backgroundColor:'#fff'}}>
                {isLoading ? <div className="spinner-border text-primary mt-4 me-2" style={{width : "40px", height : "40px"}} role="status">
              <span className="sr-only">Loading...</span>
              </div> : bannerImages && bannerImages.length > 0 || videoData ? (
           
           <div className="custom-carousel-container">
           {videoData ? <div style = {{backgroundSize:'cover' , height: '100%'}}><video
                                // controls
                                autoPlay={true}
                                loop={true}
                                style={{ width: 'auto', height: '100%' }}
                                src={videoData}
                              /> </div> : 
                              <Slider {...settings} className="pt-0">
                              {bannerImages.map((image, index) => (
                                
                                <div>
                                    <img className="org-images-container" decoding="async" src={image} alt=""/>
                                </div>
                               
                              ))}
                            </Slider> }
           {bannerImages.length > 1 && !videoData ?
           <svg style={{ position: 'absolute', bottom: '10px', width:'100%' }}>
                   {currentColorIndex === 0 ? (
                     <rect
                       x={250} 
                       y={125}
                       width={45} 
                       height={10}
                       rx={6} 
                       ry={6}
                       fill={colors[0]}
                     />
                   ) : (
                     <circle
                       cx={250} 
                       cy={130}
                       r={5} 
                       fill={colors[0]}
                     />
                   )}
                   {bannerImages.length >= 2 && currentColorIndex === 1 ? (
                     <rect
                       x={263} 
                       y={125} 
                       width={45} 
                       height={10}
                       rx={6}
                       ry={6}
                       fill={colors[1]}
                     />
                   ) : (
                     <circle
                     cx={currentColorIndex === 0 ? 310 : 270}
                       cy={130} 
                       r={5} 
                       fill={colors[1]}
                     />
                   )}
                   {bannerImages.length > 2 && currentColorIndex === 2 ? (
                     <rect
                       x={285} 
                       y={125} 
                       width={45} 
                       height={10} 
                       rx={6} 
                       ry={6}
                       fill={colors[2]}
                     />
                   ) : (
                     <circle
                       cx={ currentColorIndex === 0 ? 330 : currentColorIndex === 1 ? 320 : 300} 
                       cy={130} 
                       r={5} 
                       fill={colors[2]}
                     />
                   )}
                 </svg> : <></> }
         </div>
         
          )
          : <>
              <Slider {...settings}>
                  <div>
                      <img className="default-img" decoding="async" src="../../../images/org-select-background.svg" alt="Image 1" />
                  </div>
                  <div>
                      <img className="default-img" decoding="async" src="../../../images/login-background.svg" alt="Image 2" />
                  </div>
                  <div>
                      <img  className="default-img" decoding="async" src="../../../images/reset-pw-bg.svg" alt="Image 3" />
                  </div>
            </Slider>

          <div className="push-bottom">
          </div>
          <div className="wave">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 310"><path fill="#15447C" fill-opacity="1" d="M0,224L34.3,234.7C68.6,245,137,267,206,261.3C274.3,256,343,224,411,213.3C480,203,549,213,617,229.3C685.7,245,754,267,823,250.7C891.4,235,960,181,1029,176C1097.1,171,1166,213,1234,229.3C1302.9,245,1371,235,1406,229.3L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
                        
                             {currentColorIndex === 0 ? (
                        <rect
                            x={660} // Adjust the x-coordinate based on your design
                            y={290 - 10} // Adjust the y-coordinate based on your design
                            width={70}    // Adjust the width based on your design
                            height={20}   // Adjust the height based on your design
                            rx={10}             // Set rx equal to half of the width for a circular border radius
                            ry={10}
                            fill={colors[0]}
                        />
                        ) : (
                        <circle
                            cx={660}      // Adjust the cx-coordinate based on your design
                            cy={290}      // Adjust the cy-coordinate based on your design
                            r={10}        // Adjust the radius based on your design
                            fill={colors[0]}
                        />
                )}
                 {currentColorIndex === 1 ? (
                    <rect
                        x={690} 
                        y={290 - 10}  
                        width={70}  
                        height={20} 
                        rx={10}   
                        ry={10}
                        fill={colors[1]}
                    />
                    ) : (
                    <circle
                        cx={currentColorIndex === 0 ? 760 : 690} 
                        cy={290}     
                        r={10}        
                        fill={colors[1]}
                    />
                )}
                {currentColorIndex === 2 ? (
                        <rect
                            x={720} 
                            y={290 - 10} 
                            width={70}   
                            height={20}  
                            rx={10}             
                            ry={10}
                            fill={colors[2]}
                        />
                        ) : (
                        <circle
                            cx={ currentColorIndex === 1 || currentColorIndex === 0 ? 790 : 720} 
                            cy={290}      
                            r={10}        
                            fill={colors[2]}
                        />
                )}
                    </svg>  
                        <div className="main-auth-content text-white">
                        
                                <p className="text-center" style={{ fontWeight: "lighter",fontSize:'21px' }}>Campus Management Software</p>
                                <p className="f-14 text-center">With CtrlCampus, advanced ERP Software Systems <br /> can handle your Admission, Academics, <br />
                                and administration processes smartly.</p>
                        
                        </div>
                    </div>
          </>}
                </div>
                <div className="m-0 p-0 screen-2 d-flex flex-column align-items-center position-relative">
                    <div className="d-flex flex-column h-100 align-items-center login-content-width" >
                    {isLoading ? <div className="spinner-border text-primary mt-4 me-2" style={{width : "40px", height : "40px"}} role="status">
                        <span className="sr-only">Loading...</span>
                        </div> : <p className="org-logo mb-0">
                        <img src={logo} alt="" />
                    </p>}
                    {/* <p className="fs-larger fw-bold theme-primary-color w-100 text-center">{organization.account_name}</p> */}
                    <div className="d-flex flex-column align-items-center responsive-condition w-100">
                        <Form id="reset-pwd-form" className="initial-page d-flex w-100 flex-column mt-4" onSubmit={handleSubmit(onHandleSubmit)} >
                            <div className="heading mb-2">
                                <h1 className="fs-large fw-500">Reset Password</h1>
                                {/* <p className="fs-heading theme-login-secondary-color">Fill the input boxes to change the password and enter dashboards.</p> */}
                            </div>

                            <Form.Group className="mb-3 position-relative" controlId="password" >
                                {/* <Form.Label className="style-2 ">Enter Old Password</Form.Label> */}
                                <Form.Control className="login-input-control" type={oldPasswordShown ? "text" : "password"} placeholder="Enter old password"  required {...register('oldPassword')} isInvalid={!!errors.oldPassword} />
                                <span className="password-toggle" onClick={toggleOldPasswordVisiblity} style={{marginLeft:"-2rem",cursor:"pointer"}}><img src="/images/password-toggle.svg"/></span>
                                <div className="invalid-feedback">{errors.oldPassword?.message}</div>
                            </Form.Group>

                            <Form.Group className="mb-3 position-relative" controlId="password">
                                {/* <Form.Label className="style-2 ">Enter New Password</Form.Label> */}
                                <Form.Control className="login-input-control" type={passwordShown ? "text" : "password"} placeholder="Enter new password" required {...register('password')} isInvalid={!!errors.password} />
                                <span className="password-toggle" onClick={togglePasswordVisiblity} style={{marginLeft:"-2rem",cursor:"pointer"}}><img src="/images/password-toggle.svg"/></span>
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </Form.Group>
                            <Form.Group className="mb-3 position-relative" controlId="password">
                                {/* <Form.Label className="style-2 ">Confirm New Password</Form.Label> */}
                                <Form.Control className="login-input-control" type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm new password" required {...register('confirmPassword')} isInvalid={!!errors.confirmPassword} />
                                <span className="password-toggle" onClick={toggleConfirmPasswordVisiblity} style={{marginLeft:"-2rem",cursor:"pointer"}}><img src="/images/password-toggle.svg"/></span>
                                <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                            </Form.Group>
                            <p id="submit-button w-100">
                                <button form="reset-pwd-form" type="submit" className="org-btn f-14">Reset</button>
                            </p>
                        </Form>
                    </div>
                    <div className="customer-support-box w-100">
                        <p className="d-flex justify-content-center gap-2 pb-3 mb-0">
                            <img className="cursor-pointer" src="/images/login-google-play.svg" onClick={() => navigateMobile("playStoreLink")}/>
                            <img className="cursor-pointer" src="/images/login-app-store.svg" onClick={() => navigateMobile("appStoreLink")}/>
                        </p>
                        <p className='d-flex align-items-center'>
                            <img className="me-16" src="/images/customer-support.svg" alt="" />
                            <span className="fs-small theme-login-secondary-color">Customer Support- +91-9293839833</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    )
}

export default ResetComponent
