import ActionTypes from "redux/actions/types";
const initialState = {
    loading: false,
    actionLoading : false,
    actionError: "",
    error: "",
    totalCount : 0,
    expenseSequences: [],
};
const ExpenseSequenceNumberReducer = (state = initialState, action ) => {
    switch (action.type) {
        case ActionTypes.EXPENSE_SEQUENCE_FETCH:
            return { ...state, loading : true, error: "" }
        case ActionTypes.GET_EXPENSE_SEQUENCE_SUCCESS:
            return { ...state, expenseSequences : [...action.payload.data], totalCount : action.payload.totalCount, loading : false, error: "" }
        case ActionTypes.GET_EXPENSE_SEQUENCE_FAILURE:
            return { ...state, expenseSequences : [], loading : false, error: action.payload }
        
        case ActionTypes.EXPENSE_SEQUENCE_ACTION:
            return { ...state, actionLoading : true, actionError: "" }
        case ActionTypes.EXPENSE_SEQUENCE_CREATE_SUCCESS:
            return { ...state, actionError: "", actionLoading : false }
        case ActionTypes.EXPENSE_SEQUENCE_CREATE_FAILURE:
            return { ...state, actionError: action.payload, actionLoading : false }
        case ActionTypes.EXPENSE_SEQUENCE_UPDATE_SUCCESS:
            return { ...state, actionError: "", actionLoading : false }
        case ActionTypes.EXPENSE_SEQUENCE_UPDATE_FAILURE:
            return { ...state, actionError: action.payload, actionLoading : false }
        default:
            return state
    }
}

export default ExpenseSequenceNumberReducer