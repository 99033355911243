import React, { useState } from 'react'
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { setAlert } from 'redux/actions/alert';

interface APIResponse {
    // Define the structure of your API response data
    // Need to work on it.
} 
  
// Return Type
export interface UseApiReturn {
// Any Available Data or null.
    data: any | null;
    loading: boolean;
    success: boolean;
    error: boolean;
// The function that is formatted to do api calls
    action: (body?: any | null, id?: string, opts ?: Record<string, any>) => Promise<any>,
// Additional Data
    errorMessage : string;
// Actions to clear certain states in the hook.
    clearActions: {
        clearError: () => void;
        clearSuccess: () => void;
        clearLoading: () => void;
        clearData: () => void;
        clearAll: () => void;
    }
}

type UseApiHook = (
    // action : The API Service that needs to be called.
        action: (data: any, id?: string, options ?: any) => Promise<AxiosResponse> | AxiosError | Error, 
    // options : if you need success message or error message you can configure here or any other options.
        options?: {
            success ?: boolean, 
            error ?: boolean, 
        // Custom Success or Error Message.
            successMessage ?: string, 
            errorMessage ?: string,

        // to create named error message / other places that require name of the action.
            actionName ?: string,

        // to store value in data state or no.
            noStore ?: boolean, 

        // if needed for initial loading set this as true
            initialLoad ?: boolean

        // when error occurs this function runs.
            onError ?: (msg ?:string, error ?: boolean) => void;
        // when data is populated this function runs.
            onComplete ?: (data ?: any, error ?: boolean) => void;
        }
    ) => UseApiReturn

const useApi : UseApiHook = (action, options) : UseApiReturn => {
    // Data, Loader, Error, Success states.
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(() => {
        return !!options?.initialLoad
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useDispatch();

// Fetcher 
    const fetchData = async (body?: any | null, id?: string, opt ?: Record<string, any>) => {
        try {
        // Resets
            setLoading(true);
            setSuccess(false);
            clearError();

            if(!body){
                body = {};
            }
            const res = await action(body, id, opt);
            
        // Error Check
            if(res instanceof Error){
                throw res;
            }

        // Success Handle
            return handleSuccess(res);
        } catch (error){
        // Catch Errors
            handleError(error);
        } finally {
            setLoading(false);
        }
    }

// Success Handling
    const handleSuccess = (res: AxiosResponse<any>) => {
        if (options?.success) {
            const message : string =
                typeof res.data === "string"
                ? res.data
                : res.data?.message ??
                    options.successMessage ??
                    `${options.actionName ? options.actionName : "Action"} Successful!`;
            dispatch<any>(
                setAlert({
                msg: message,
                status: res.status,
                alertType: "success",
                })
            );
        }
        if (!options?.noStore) {
            setData(res.data);
        }
    // Completion Handler
        setSuccess(true);
        if(options?.onComplete){
            options.onComplete(res.data, false);
        }
        return res.data;
    };

// Error Handling
    const extractErrorMessage = (err: AxiosError | Error) : string => {
        if(axios.isAxiosError(err)){
            if(err?.response){
                if(err?.response?.status === 404){
                    return "Requested Resource Not Found!";
                }
                if (err.response?.data?.message) {
                    return err.response.data.message;
                } else if (typeof err?.response?.data === "string"){
                    return err?.response?.data;
                }
            }
        } else if (err instanceof Error) {
            return err.message;
        } else {
            return "Unknown error occurred.";
        }
    };
    const handleError = (err : Error) => {
        setError(true);

        const errorText = extractErrorMessage(err);
        setErrorMessage(errorText);
        console.error(new Error(errorText));

        if(options?.error){
            dispatch(
                setAlert({
                    msg : errorText,
                    status : 500,
                    alertType : "danger"
                })
            )
        }
    // Error Handler
        if(options?.onError){
            options.onError(errorText, true);
        }
    } 

// Clear Actions
    const clearError = () => {
        setError(false);
        setErrorMessage("");
    }
    const clearSuccess = () => {
        setSuccess(false);
        setErrorMessage("");
    }
    const clearLoading = () => {
        setLoading(false);
    }
    const clearData = () => {
        setData(null);
    }
    const clearAll = () => {
        setError(false);
        setLoading(false);
        setData(null);
    }

    return { data, loading, success, error, action : fetchData, errorMessage, clearActions : {clearError, clearSuccess, clearLoading, clearData, clearAll} };
}

export default useApi;