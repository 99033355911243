import React, { useEffect } from 'react'
import { usePromiseTracker } from "react-promise-tracker";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ThreeDots } from 'react-loader-spinner'

function LoaderComponent(props: any) {
    const { promiseInProgress } = usePromiseTracker({
        area: props.area,
        delay: 0
    });
    // useEffect(() => {
    //     console.log(promiseInProgress)
    // }, [promiseInProgress])
    return (
        <>
            {
                promiseInProgress ? (
                    <div className="spinner">
                        <ThreeDots color="#2B4378" height="100" width="100" />
                    </div>
                ) : ''
            }
        </>
    )
}

export default LoaderComponent