import ActionTypes from "redux/actions/types";
const initialState = {
    inventoryReceiptSequences: [],
    totalCount: 0
};

const inventoryReceiptSequence = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.GET_INVENTORY_RECEIPT_SEQUENCES:
            return { ...state, inventoryReceiptSequences: action?.payload?.data, totalCount: action?.payload?.totalCount }
        default:
            return state
    }
}

export default inventoryReceiptSequence