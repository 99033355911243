import React from "react";
import { ThreeDots } from 'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const AppLoader: React.FC = (): JSX.Element => {

  return (
    <div className="spinner">
      <ThreeDots color="#2B4378" height="100" width="100" />
    </div>
  );
};

export default AppLoader;
