import types from "redux/actions/types";
import { ICoscholstic, ICoscholsticstats } from "redux/types/Coscholstic";
const initialState: ICoscholsticstats = {
    loading: true,
    totalCount: 0,
    coscholstic: {} as ICoscholstic,
    coscholstics: [],
    terms: []
};
export const CoscholsticReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.COSCHOLSTIC_REGISTER_SUCCESS:
        case types.COSCHOLSTIC_REGISTER_SUCCESS:
            return {
                ...state,
                coscholstics: [...state.coscholstics, action.payload.data.data],
                loading: false,
            };
        case types.GET_COSCHOLSTIC:
            return {
                ...state,
                loading: false,
                coscholstics : action?.payload,
            
            };
            case types.GET_TERMS:
                return {
                    ...state,
                    loading: false,
                    terms : action?.payload,
                
                };
        case types.DELETE_COSCHOLSTIC:
            return {
                ...state,
                coscholstics: state?.coscholstics?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        case types.UPDATE_COSCHOLSTIC:

            return {
                ...state,
                coscholstics: state.coscholstics.map((clientuser: any) =>
                    clientuser._id === action.payload._id ? { ...action.payload } : clientuser)
            }
        default:
            return state
    }
}
export default CoscholsticReducer;