import types from '../actions/types'
import {ReportCardActions,IStudentsMarks  } from "redux/types/reportCard";

const studentsMarksInitialState:IStudentsMarks = {
    studentsMarksData: [],
    totalCount : 0,
    isLoading: false
  };

const studentsMarksReducer = (state = studentsMarksInitialState, action:ReportCardActions) => {
  
    switch (action.type) {
      case types.GET_STUDENTS_MARKS:
        return {
          ...state,
          studentsMarksData: action.payload.data,
          isLoading : false,
          totalCount: action.payload.totalCount
        };
      default:
        return state;
    }
  };
  
export default studentsMarksReducer;