import ActionTypes from "redux/actions/types";

const initialState = {
  approvals: [],
  approvalsActions: {},
  totalCount: 0,
  loading: true,
};
export const DairyApprovals = (state = initialState, action: any) => {
  switch (action.type) {
    
    case ActionTypes.GET_DAIRY_SEND_APPORVAL:      
      return {
        ...state,
        loading: false,
        approvalsActions: {...action.payload.data}
      };
    case ActionTypes.GET_DAIRY_APPORVAL:      
        return {
          ...state,
          loading: false,
          approvals: action.payload,
          totalCount: action.payload.totalCount,
        };
    
    default:
      return state;
  }
};
