import types from 'redux/actions/types';
import {HrDashboardActions} from 'redux/types/hrDashboard';


const InitialState = {
    staffGenderData : {loading: true, data: {male : 0, female : 0}},
    staffExperienceData : {loading: true, data: {}},
    staffAgeData : {loading: true, data: {}},
}

const hrDashboardReducer = (state = InitialState, action: HrDashboardActions) => {
    switch (action.type) {
        case types.HR_GENDER_PERCENTAGE:
            return {
                ...state,
                staffGenderData: {loading: false, data: {male : action.payload.malePercentage,
                female : action.payload.femalePercentage}}
            }
            case types.HR_STAFF_AGE:
                return{
                    ...state,
                    staffAgeData:{loading: false, data: {...action.payload}}
                }
            case types.HR_STAFF_EXPERIENCE:
                return{
                    ...state,
                    staffExperienceData: {loading: false, data: {...action.payload}}
                }
        default:
            return state
    }
}

export default hrDashboardReducer;