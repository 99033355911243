import types from "redux/actions/types";
import { FeeReceiptModificationAction, IFeeReceiptModificationState } from "redux/types/feeReceiptModification";
const initialState: IFeeReceiptModificationState = {
    loading: true,
    feeReciptModificationData: [],
    totalCount : 0,
    approvedModificationRequest: {},
    cancelledFeeReceiptData: {},
};
const feeReceiptModificationReducer = (state = initialState, action: FeeReceiptModificationAction) => {
    switch (action.type) {
        case types.GET_FEE_RECEIPT_MODIFICATION_REQUESTS:
            return { ...state, loading: false, feeReciptModificationData: [...action?.payload?.data], totalCount : action?.payload?.totalCount }
        case types.APPROVE_FEE_RECEIPT_MODIFICATION:
            return { ...state, approvedModificationRequest: {...action?.payload} }
        case types.CANCEL_FEE_RECEIPT:
            return { ...state, cancelledFeeReceiptData: {...action?.payload} }
        default:
            return state
    }
}

export default feeReceiptModificationReducer;