import types from "redux/actions/types";
import { AdmissionReportActions, IAdmissionReport, IAdmissionReportState } from "redux/types/admissionReport";

const initialState: IAdmissionReportState = {
    loading: true,
    admissionReport: {} as IAdmissionReport,
    admissionReports: {totalCount: 0, reports : []} ,
  };

const admissionReportReducer = (state = initialState, action: AdmissionReportActions) => {
    switch (action.type) {
        case types.GET_ADMISSIONREPORTS:
            return {
                ...state,
                loading: false,
                admissionReports: {reports: [...action.payload.data], 
                totalCount : action.payload.totalCount}
            };
        default:
            return state;
    }
};

export default admissionReportReducer;