import React from "react";

const NotFound: React.FC = (): JSX.Element => {

    function refreshPage() {
        window.location.reload();
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="error-template">
                        <h1>
                            Oops!</h1>
                        <h2>
                            session expired</h2>
                        <div className="error-details">
                            Please Reload the page
                            <button onClick={refreshPage}>reload</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(NotFound);
