
import { Button, Card, Col, FloatingLabel, Form, ListGroup, Modal, Row, Tab, Table  } from "react-bootstrap";
import React, { useState } from "react";

const EmergencyExitDetails: React.FC = (): JSX.Element => {

  return (
 <>

    <div className="c-container content-tabs mt-5">
             <div className="section-bgwhite1">

             <div className="d-flex justify-content-end mb-3">             
             <Form.Select className="outline-select">
                <option>Branch Name</option>
                </Form.Select>
            </div>


             <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Emergency Exit</th>  
              <th>Emergency Details</th> 
              <th>Class</th> 
              <th>Section</th> 
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>General</td>
              <td>test</td>  
              <td>test</td>
              <td>test</td>
              <td>test</td>       
            </tr>
          </tbody>
        </Table>
      </Card>

            </div>

    </div>

   
    </>
  )
}
export default React.memo(EmergencyExitDetails);
