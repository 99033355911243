
import ActionTypes from "../actions/types";
import { AllKitActions } from "redux/types/inventorykit";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    kit : {},
    kits : [],
}

const kit = (state = initialState, action : AllKitActions) => {
    switch(action.type){
        case ActionTypes.GET_ALL_KITS_REQUEST:
        case ActionTypes.CREATE_KIT_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_ALL_KITS_SUCCESS:
            return {
                ...state,
                loading : false,
                kits : action.payload.data,
                totalCount : action.payload.totalCount,
                error : '',
            };
        case ActionTypes.GET_ALL_KITS_FAILURE:
            return {
                ...state,
                loading : false,
                kits : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.CREATE_KIT_SUCCESS:
            return {
                ...state,
                loading : false,
                error : '',
            }
        case ActionTypes.CREATE_KIT_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
        case ActionTypes.GET_KIT_BY_ID:
            return {
                ...state, 
                loading : false,
                kit: { ...action.payload.data }
            }
        case ActionTypes.UPDATE_KIT_SUCCESS:
            return {
                ...state,
                kits: state.kits.map((kit) =>
                kit._id === action.payload.id
                    ? { ...action.payload.kit }
                    : kit
                ),
            };
        case ActionTypes.DELETE_KIT:
            return {
                ...state,
                kits: state.kits.filter(
                (kit) => kit._id !== action.payload
                ),
            };
        default : return state;
    }
}

export default kit;