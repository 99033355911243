import { ISMSRECHARGE } from "redux/types/smsRecharge";
import GET_SMS_RECHARGE  from "redux/actions/types";

const initialState: ISMSRECHARGE = {
  branchName: "",
  smsAccountName: "",
  senderId: "",
  planDetails: "",
  balanceSms: 0,
  status: "",
};

const smsRechargeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SMS_RECHARGE:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default smsRechargeReducer;

