import { AllGradeGroupCoScholasticActions } from "redux/types/gradeGroupCoScholastic";
import ActionTypes from "../actions/types";

const initialState = {
  loading: false,
  error: "",
  coScholasticGradeGroups: [],
  totalCount: 0,
};

const gradeGroupCoScholasticReducer = (
  state = initialState,
  action: AllGradeGroupCoScholasticActions
) => {
  switch (action.type) {
    case ActionTypes.GET_GRADE_GROUP_CO_SCHOLASTIC_REQUEST:
      return { ...state, loading: true, error: "" };

    case ActionTypes.GET_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS:
      return {
        ...state,
        coScholasticGradeGroups: action?.payload?.data,
        totalCount: action.payload?.totalCount,
        loading: false,
      };

    case ActionTypes.GET_GRADE_GROUP_CO_SCHOLASTIC_FAILURE:
      return { ...initialState, error: action.payload };

    case ActionTypes.CREATE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST:
      return { ...state, loading: true, error: "" };

    case ActionTypes.CREATE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS:
      return {
        ...state,
        coScholasticGradeGroups: action?.payload?.data,
        totalCount: state?.totalCount + 1,
        loading: false,
      };

    case ActionTypes.CREATE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ActionTypes.UPDATE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST:
      return { ...state, loading: true, error: "" };

    case ActionTypes.UPDATE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS:
      return {
        ...state,
        coScholasticGradeGroups: [action?.payload?.data],
        loading: false,
      };

    case ActionTypes.UPDATE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ActionTypes.DELETE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST:
      return { ...state, loading: true, error: "" };

    case ActionTypes.DELETE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS:
      return {
        ...state,
        coScholasticGradeGroups: action?.payload?.data,
        loading: false,
      };

    case ActionTypes.DELETE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default gradeGroupCoScholasticReducer;
