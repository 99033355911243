import ActionTypes from "redux/actions/types";
import { DesignationActions, IDesignation, IdesignationState } from "redux/types/designation";
import types from "redux/actions/types";

const initialState: IdesignationState = {
    loading: true,
    designation: {} as IDesignation,
    designations: [] as IDesignation[],
    totalCount: 0

}
export const Designation = (state = initialState, action: DesignationActions) => {
    switch (action.type) {
        case types.DESIGNATION_REGISTER_SUCCESS:
        case types.DESIGNATION_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // designations: [...state.designations, action.payload] as IDesignation[],
            };
        case types.GET_DESIGNATION:
            return {
                ...state,
                loading: false,
                designations: action?.payload,
                // totalCount: action?.payload?.length,
            };
        case ActionTypes.DELETE_DESIGNATION:
            return {
                ...state,
                designations: state?.designations?.filter(
                    (cla: any) => cla._id !== action.payload
                ),
            };
        case ActionTypes.UPDATE_DESIGNATION:

            return {
                ...state,
                designations: state.designations.map((clientuser: any) =>
                    clientuser._id === action.payload._id ? { ...action.payload.designation } : clientuser)
            }

        default:
            return state;
    }
}

