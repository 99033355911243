import types from 'redux/actions/types';
const initialState = {
    branchWiseFeeDue: [],
    feeTypeWiseDue: [],
    classWiseFeeDue: []
}

export const financeDashboard = (state = initialState, action: any) => {
    switch (action.type) {
        case types.BRANCH_FEE_DUES:
            return {
                ...state,
                branchWiseFeeDue: [...action.payload]
            }
        case types.FEE_TYPE_DUES:
            return {
                ...state,
                feeTypeWiseDue: [...action.payload]
            }
        case types.CLASS_FEE_DUES:
            return {
                ...state,
                classWiseFeeDue: [...action.payload]
            }
        default:
            return state
    }
}