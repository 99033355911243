import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function AuthPrivateRoute({ children }: { children: JSX.Element }) {
  let location = useLocation();
  let userToken = localStorage.getItem("user__token");

  if(userToken && (location.pathname === "/" || location.pathname === "/login") && !location.pathname.includes("/forms")){
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }
  else{
    return children;
  }
}