import types from "redux/actions/types";
import { IMastersubjectState, MastersubjectActions } from "../types/mastersubject";
import { IMastersubject } from "redux/types/mastersubject";

const initialState: IMastersubjectState = {
    loading: true,
    mastersubject: {} as IMastersubject,
    mastersubjects: [] as IMastersubject[],
};

const mastersubjectReducer = (
    state = initialState,
    action: MastersubjectActions
): IMastersubjectState => {
    switch (action.type) {
        case types.MASTERSUBJECT_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                // mastersubjects: [...state.mastersubjects, action.payload] as IMastersubject[],
            };
        case types.GET_MASTERSUBJECTS:
            return {
                ...state,
                loading: false,
                mastersubjects: action.payload as IMastersubject[],
            };

        case types.DELETE_MASTERSUBJECT:
            return {
                ...state,
                mastersubjects: state.mastersubjects.filter(
                    (subject:any) => subject._id !== action.payload
                ),
            };

        case types.UPDATE_MASTERSUBJECT:
            return {
                ...state,
                mastersubjects: state.mastersubjects.map((mastersubject:any) =>
                mastersubject._id === action.payload.id
                        ? { ...action.payload.mastersubject }
                        : mastersubject
                ),
            };
        case types.MASTERSUBJECT_REGISTER_FAIL:
        case types.MASTERSUBJECT_AUTH_ERROR:

        default:
            return state;
    }
};

export default mastersubjectReducer;
