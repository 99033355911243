import React from "react";

import { Button, Card, CardGroup, Col, Container, Dropdown, Figure, Form, FormControl, InputGroup, Nav, NavDropdown, Offcanvas, Overlay, Popover, Row, Tab, Table, Tabs } from "react-bootstrap";



const CommonAcademicyearMonth: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Form.Select className="outline-select">
          <option>2021-2022</option>
        </Form.Select>
      </div>

      <Card className="customcard">
        <Table responsive hover >
          <thead>
            <tr>
              <th>Year</th>
              <th>Month</th>             
              <th>Action</th>


            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2021</td>
              <td>January</td>            
              <td><a href="#"> <img src="images/edit-icon.svg" /> </a></td>

            </tr>

          </tbody>
        </Table>
      </Card>
    </>
  )
}
export default React.memo(CommonAcademicyearMonth);

