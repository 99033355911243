
import ActionTypes from "../actions/types";
import { AllScholasticMarksActions } from "redux/types/scholasticsMarksAllocation";


const initialState = {
    loading : false,
    error : '',
    totalCount : 0,
    studentMark : {},
    studentMarks : [],
    scholasticSubjects: [],
    unpaginatedData : []
}

const scholasticMarks = (state = initialState, action : AllScholasticMarksActions) => {
    switch(action.type){
        case ActionTypes.GET_SCHOLASTIC_MARKS_STUDENTS_REQUEST:
        case ActionTypes.ASSIGN_SCHOLASTIC_MARKS_REQUEST:
            return {
                ...state,
                loading : true,
                error : '',
            };
        case ActionTypes.GET_SCHOLASTIC_MARKS_STUDENTS_SUCCESS:
            return {
                ...state,
                loading : false,
                studentMarks : action.payload.data,
                totalCount : action.payload.totalCount,
                unpaginatedData : action.payload.unpaginatedData,
                error : '',
            };
            case ActionTypes.GET_SCHOLASTIC_SUBJECTS:
                return {
                    ...state,
                    loading : false,
                    scholasticSubjects : action.payload.data,
                    error : '',
                };
        case ActionTypes.GET_SCHOLASTIC_MARKS_STUDENTS_FAILURE:
            return {
                ...state,
                loading : false,
                studentMarks : [],
                totalCount : 0,
                error : action.payload,
            };
        case ActionTypes.ASSIGN_SCHOLASTIC_MARKS_SUCCESS:
            return {
                ...state,
                loading : false,
                error : '',
            }
        case ActionTypes.ASSIGN_SCHOLASTIC_MARKS_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload,
            };
        default : return state;
    }
}

export default scholasticMarks;