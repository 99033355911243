import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBranchesByOrgId } from 'redux/actions/branch';
import { getModules } from 'redux/actions/modules';
import { getBranchTimeline, getOrgTimeline } from 'redux/actions/timeLine';
import { getstaffDetails } from 'redux/actions/user';
import InfiniteScroll from "react-infinite-scroll-component";
import { RootState } from 'redux/reducers';
import ToolTip from 'components/shared/ToolTip'
import { Form } from "react-bootstrap";
import { setAlert } from "../../redux/actions/alert";
import './timeline.css'
import RemoveHtmlTags from "utils/removeHtmlTags";
import { dateSetter } from 'utils/date';
import ButtonLoader from "components/shared/Buttons/ButtonLoader";
import EmptyPlaceholder from "components/shared/emptyPlaceholder";
import TablePlaceholder from "components/shared/TablePlaceholder";


const Timeline = () => {
    const userModules: any = useSelector((state: RootState) => state?.user?.permissions[0]?.BMRP);
    const [startDate, setStartDate] = useState<any>(new Date(new Date().setDate(new Date().getDate() - 1)))
    const [endDate, setEndDate] = useState(new Date())
    const [module, setModule] = useState('');
    const [branch, setBranch] = useState('');
    const [user, setUser] = useState('');
    let { orgId, use_role_level, academicBatchId, branchId, branchAcademicBatchId } = localStorage
    const dispatch = useDispatch();
    const [role, setRole] = useState(use_role_level)
    const { timelineData: timelinedata, isEmpty,} = useSelector((state: RootState) => state?.timeLine);
    const { branche } = useSelector((state: RootState) => state.branch);
    const [timelineData, setTimelineData] = useState<any>(timelinedata);
    const [timeLineDataArray, setTimelineDataArray] = useState([])
    const [datePicker, setDatePicker] = React.useState<string>("today");
    const [isLoading, setIsLoading] = useState(true)
    const [payload, setpayload] = React.useState<any>({
        fromDate: new Date().toLocaleDateString("en-CA"),
        toDate: new Date().toLocaleDateString("en-CA"),
        orgId: orgId,
        branchId: branchId,
        // showPDC: false,
        dateType: "SYSTEM",
        reportType: "",
        academicBatchId: academicBatchId,
        branchAcademicBatchId: branchAcademicBatchId,
        paymentMethodId: "",
        branchPaymentMethodId: "",
    });
    const { organization } = useSelector(
        (state: RootState) => state.organization
    );
    const [count, setCount] = useState(0)
    let obj: any = {}

    useEffect(() => {
        setTimelineData(timelinedata);
        getTimeLineDataArray()
    }, [timelinedata])
    const getTimeLineDataArray = () => {
        let timeLineArray = []
        let prev = ''
        let subTimeLineArr = []
        timelineData.map((item, index) => {
            let itemDate = moment(item?.date).format('DD')
            if (index === 0) {
                subTimeLineArr.push(item)
            } else {
                if (prev === itemDate) {
                    subTimeLineArr.push(item)
                } else {
                    timeLineArray.push(subTimeLineArr)
                    subTimeLineArr = []
                    subTimeLineArr.push(item)
                }
            }
            prev = itemDate
        })
        timeLineArray.push(subTimeLineArr)
        setTimelineDataArray(timeLineArray)
    }

    // const getTimelineData = (skip: any, scrolling: any) => {
    //     if (role == 'organization') {
    //         let data = {
    //             "orgId": orgId,
    //             "academicBatchId": academicBatchId,
    //             "fromDate": startDate.toISOString(),
    //             "toDate": endDate,
    //             "limit": 10,
    //             "branchId": branch,
    //             "moduleId": module,
    //             "createdBy": user,
    //             "skip": skip
    //         }
    //         dispatch(getOrgTimeline(data, scrolling))
    //     } else {
    //         let data = {
    //             "branchId": branchId,
    //             "branchAcademicBatchId": branchAcademicBatchId,
    //             "fromDate": startDate.toISOString(),
    //             "toDate": endDate,
    //             "limit": 10,
    //             "moduleId": module,
    //             "createdBy": user,
    //             "skip": skip
    //         }
    //         dispatch(getBranchTimeline(data, scrolling))
    //     }
    // }
    const getTimelineData = async(skip: any, scrolling: any) => {
        const {fromDate, toDate} = dateSetter(payload?.fromDate, payload?.toDate);
        !scrolling && setIsLoading(true)
        if (role == 'organization') {
            let data = {
                "orgId": orgId,
                "academicBatchId": academicBatchId,
                "fromDate": fromDate,
                "toDate": toDate,
                "branchId": branch,
                "moduleId": module,
                "createdBy": user,
                "skip": skip,
                "limit" : 10
            }
            await dispatch(getOrgTimeline(data, scrolling))
        } else {
            let data = {
                "branchId": branchId,
                "branchAcademicBatchId": branchAcademicBatchId,
                "fromDate": fromDate,
                "toDate": toDate,
                "moduleId": module,
                "createdBy": user,
                "skip": skip,
                "limit" : 10
            }
            !scrolling && setIsLoading(true)
            await dispatch(getBranchTimeline(data, scrolling))
        }
        setIsLoading(false)
    }

    const getInitialData = () => {
        if (role == 'organization') {
            obj = {
                "orgId": localStorage?.orgId,
                "academicBatchId": academicBatchId,
                "fromDate": startDate.toISOString(),
                "toDate": endDate,
                "skip": 0,
                "limit" : 10
            }
            dispatch(getModules({ orgId: orgId }))
            dispatch(getOrgTimeline(obj, false))
            dispatch(getBranchesByOrgId({ orgId: orgId }))
        }
        else {
            obj = {
                "branchId": branchId,
                "branchAcademicBatchId": branchAcademicBatchId,
                "fromDate": startDate.toISOString(),
                "toDate": endDate,
                "skip": 0,
                "limit" : 10
            }
            dispatch(getModules({ branchId: branchId }))
            dispatch(getstaffDetails({ branchId: localStorage.branchId }))
            dispatch(getBranchTimeline(obj, false))
        }
    }

    const fetchMoreData = () => {
        setCount(count + timelineData.length);
        setTimeout(() => {
            getTimelineData(count + timelineData.length, true)
            getTimeLineDataArray()
        }, 100);
    }
    useEffect(() => {
        if (startDate && endDate) {
            getTimelineData(0, false)
        }
    }, [module])
    useEffect(() => {
        getTimeLineDataArray()
    }, [timelineData])


    return (
        <div className='theme-content-container'>
            <div className='theme-content-heading'>
                <h6>Timeline</h6>
                <ToolTip info='enrollment Code Settings' />
            </div>
            <div className="theme-content-filters">
                <Form.Select className="theme-input-control width-filter" onChange={(e: any) => { setModule(e.target.value) }}>
                    <option value="">Select Category</option>
                    {
                        userModules?.map((module: any) => (
                            module?.branchmodule?.module?.type == 'module' && (
                                <option value={module?.branchmodule?.module?._id}>{module?.branchmodule?.module?.name}</option>
                            )))
                    }
                </Form.Select>
                <Form.Select
                    className="theme-input-control width-filter"
                    aria-label="Default select example"
                    value={datePicker}
                    onChange={(e) => {
                        let obj: any = { ...payload, fromDate: "", toDate: "" };
                        if (e.target.value === "undefined") {
                            setpayload(obj);
                            setDatePicker("");
                        } else {
                            if (e.target.value === "today") {
                                let obj: any = {
                                    ...payload,
                                    fromDate: new Date().toLocaleDateString("en-CA"),
                                    toDate: new Date().toLocaleDateString("en-CA"),
                                };
                                setpayload(obj);
                            }
                            else if (e.target.value === "between")
                                setpayload(obj);
                            else if (e.target.value === "7days") {
                                const curDate = new Date();
                                const sevenDays = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
                                let obj: any = {
                                    ...payload,
                                    fromDate: sevenDays.toLocaleDateString('en-CA'),
                                    toDate: curDate.toLocaleDateString('en-CA'),
                                }
                                setpayload(obj);
                            }
                            else if (e.target.value === "30days") {
                                const curDate = new Date();
                                const thirthyDays = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
                                let obj: any = {
                                    ...payload,
                                    fromDate: thirthyDays.toLocaleDateString('en-CA'),
                                    toDate: curDate.toLocaleDateString('en-CA'),
                                }
                                setpayload(obj);
                            }
                            else if (e.target.value === "90days") {
                                const curDate = new Date();
                                const ninetyDays = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
                                let obj: any = {
                                    ...payload,
                                    fromDate: ninetyDays.toLocaleDateString('en-CA'),
                                    toDate: curDate.toLocaleDateString('en-CA'),
                                }
                                setpayload(obj);
                            }
                            else if (e.target.value === "till") {
                                let obj: any = {
                                    ...payload,
                                    fromDate: new Date((use_role_level === "organization" ? organization as any : branche as any)?.createdAt).toLocaleDateString('en-CA'),
                                    toDate: new Date(),
                                }
                                setpayload(obj);
                            }
                            setDatePicker(e.target.value);
                        }
                    }}
                >
                    <option value={"undefined"}>Date</option>
                    <option value={"today"}>Today</option>
                    <option value={"7days"}>Last 7 Days</option>
                    <option value={"30days"}>Last 30 Days</option>
                    <option value={"90days"}>Last 90 Days</option>
                    <option value={"till"}>Till Date</option>
                    <option value={"between"}>Between Dates</option>
                </Form.Select>
                {datePicker === "between" && (
                    <Form.Group
                        controlId="fromdate"
                        className="d-flex align-items-center gap-3"
                    >
                        <div>
                            <Form.Control
                                className="theme-input-control width-filter"
                                type="date"
                                placeholder="From date"
                                defaultValue={payload.fromDate}
                                max={new Date().toLocaleDateString("en-CA")}
                                onChange={(e: any) => {
                                    let obj: any = { ...payload, fromDate: e.target.value };
                                    setpayload(obj);
                                }}
                            />
                        </div>
                        <div>
                            <Form.Control
                                className="theme-input-control width-filter"
                                type="date"
                                placeholder="To date"
                                defaultValue={payload.toDate}
                                max={new Date().toLocaleDateString("en-CA")}
                                onChange={(e: any) => {
                                    let obj: any = {
                                        ...payload,
                                        toDate: new Date(e.target.value).toLocaleDateString(
                                            "en-CA"
                                        ),
                                    };
                                    setpayload(obj);
                                }}
                            />
                        </div>
                    </Form.Group>
                )}
                {/* <div className="invalid-feedback"></div>
                <input type="date" value={moment(startDate).format('YYYY-MM-DD')} onChange={(e: any) => {setStartDate(new Date(e.target.value))}} className='theme-input-control width-filter' />
                <input type="date" value={moment(endDate).format('YYYY-MM-DD')} onChange={(e: any) => { setEndDate(new Date(e.target.value)) }} className='theme-input-control width-filter' /> */}
                <div className='me-auto'>
                    <button className='theme-btn theme-action-btn d-flex align-items-center' onClick={() => getTimelineData(0, false)}>Get data</button>
                </div>
            </div>

            <section id='cd-timeline' className='timeline-section'>
                <InfiniteScroll
                    dataLength={timeLineDataArray?.length}
                    next={() => fetchMoreData()}
                    hasMore={!isEmpty ? true : false}
                    loader={(!isLoading && !isEmpty) ? <ButtonLoader size='90' /> : ''}
                    scrollableTarget="cd-timeline"
                >
                    {isLoading ? <TablePlaceholder noOfCopies={10} /> :
                        (timeLineDataArray && timeLineDataArray?.[0] && timeLineDataArray?.[0].length > 0) ? timeLineDataArray.map((item: any, index: any) => (
                            <div className='timeline'>
                                <div className="border-bottom ">
                                    <h3 className="f-28 frmed web-text timeline-date">{moment(item[0]?.date).format('DD MMM YYYY')}</h3>
                                </div>
                                {
                                    item.map((timeLine, timeLineIndex) => (
                                        <div className={`d-flex justify-content-between align-items-center ${(timeLineIndex !== timeLine.length - 1) ? "border-bottom" : ""} ${timeLineIndex !== 0 ? "border-top" : ""} sub-timeline`}>
                                            <div className='d-flex align-items-center gap-3 sub-timeline-first-part'>
                                                <p className='theme-primary-color sub-timeline-time pe-2'>{moment(timeLine?.date).format('hh:mm a')}</p>
                                                <div className='sub-timeline-icon'>
                                                    <div className='sub-timeline-icon-top'></div>
                                                    <div className='timeline-icon-div'>
                                                        <img src={`/images/modules/menu/${timeLine?.moduleId?.route_path}.svg`} alt="" />
                                                    </div>
                                                    <div className='sub-timeline-icon-bottom'></div>
                                                </div>
                                                <div className='timeline-role'>
                                                    <p className='fw-bold fs-6'>{timeLine?.moduleId?.name}</p>
                                                    <div className='d-flex gap-2'>
                                                        <p>{timeLine?.roleId && timeLine?.roleId?.name}</p>
                                                        {/* <p>{timeLine?.createdBy && timeLine?.createdBy.firstName ? (timeLine?.createdBy?.firstName + " " + timeLine?.createdBy?.lastName) : (timeLine?.createdBy?.username)}</p> */}
                                                        <p className="theme-primary-color">{RemoveHtmlTags(timeLine?.description)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>{new Date(moment(timeLine?.date).format('DD MMM YYYY')).toLocaleDateString("en-US", { weekday: 'long' })}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                        :  <EmptyPlaceholder />
                    }


                </InfiniteScroll>
            </section>
        </div>
    );
}

export default Timeline