import types from "redux/actions/types";
import { IFeestructureState, FeestructureActions } from "../types/feestructure";
import { IFeestructure } from "redux/types/feestructure";

const initialState: IFeestructureState = {
  loading: true,
  feestructure: {} as IFeestructure,
  feestructures: [] as IFeestructure[],
};

const feestructureReducer = (state = initialState, action: FeestructureActions) => {
  switch (action.type) {
    case types.FEESTRUCTURE_REGISTER_SUCCESS:
    case types.FEESTRUCTURE_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        feestructures: [...state.feestructures, action.payload] as IFeestructure[],
      };
    case types.GET_FEESTRUCTURES:
      return {
        ...state,
        loading: false,
        feestructures: action.payload as IFeestructure[],
      };

    case types.DELETE_FEESTRUCTURE:
      return {
        ...state,
        feestructures: state.feestructures.filter(
          (feestructure) => feestructure._id !== action.payload
        ),
      };

    case types.UPDATE_FEESTRUCTURE:
      return {
        ...state,
        feestructures: state.feestructures.map((feestructure) =>
        feestructure._id === action.payload.id
            ? { ...action.payload.feestructure }
            : feestructure
        ),
      };
    case types.FEESTRUCTURE_REGISTER_FAIL:
    case types.FEESTRUCTURE_AUTH_ERROR:

    default:
      return state;
  }
};
export default feestructureReducer;
