import types from "redux/actions/types";
import { IBoardNameState, BoardNameActions } from "../types/boardnames";
import { IBoardName } from "redux/types/boardnames";

const initialState: IBoardNameState = {
    loading: true,
    boardname: {} as IBoardName,
    boardnames: [] as IBoardName[],
};

const boardnameReducer = (
    state = initialState,
    action: BoardNameActions
): IBoardNameState => {
    switch (action.type) {
        case types.BOARDNAME_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                boardnames: [...state.boardnames, action.payload] as IBoardName[],
            };
        case types.GET_BOARDNAMES:
            return {
                ...state,
                loading: false,
                boardnames: action.payload as IBoardName[],
            };

        case types.DELETE_BOARDNAME:
            return {
                ...state,
                boardnames: state.boardnames.filter(
                    (boardname:any) => boardname._id !== action.payload
                ),
            };

        case types.UPDATE_BOARDNAME:
            return {
                ...state,
                boardnames: state.boardnames.map((boardname:any) =>
                    boardname._id === action.payload.id
                        ? { ...action.payload.boardname }
                        : boardname
                ),
            };
        case types.BOARDNAME_REGISTER_FAIL:
        case types.BOARDNAME_AUTH_ERROR:

        default:
            return state;
    }
};

export default boardnameReducer;
