import types from "redux/actions/types";
import { IConcessiontypeState, ConcessiontypeActions } from "../types/concessiontype";
import { IConcessiontype } from "redux/types/concessiontype";

const initialState: IConcessiontypeState = {
    loading: true,
    concessiontype: {} as IConcessiontype,
    concessiontypes: [] as IConcessiontype[],
};

const concessiontypeReducer = (state = initialState, action: ConcessiontypeActions) => {
    switch (action.type) {
        case types.CONCESSIONTYPE_REGISTER_SUCCESS:
        case types.CONCESSIONTYPE_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                concessiontypes: [...state.concessiontypes, action.payload] as IConcessiontype[],
            };
        case types.GET_CONCESSIONTYPES:
            return {
                ...state,
                loading: false,
                concessiontypes: action.payload as IConcessiontype[],
            };

        case types.DELETE_CONCESSIONTYPE:
            return {
                ...state,
                concessiontypes: state.concessiontypes.filter(
                    (concessiontype) => concessiontype._id !== action.payload
                ),
            };

        case types.UPDATE_CONCESSIONTYPE:
            return {
                ...state,
                concessiontypes: state.concessiontypes.map((concessiontype) =>
                    concessiontype._id === action.payload.id
                        ? { ...action.payload.concessiontype }
                        : concessiontype
                ),
            };
        case types.CONCESSIONTYPE_REGISTER_FAIL:
        case types.CONCESSIONTYPE_AUTH_ERROR:

        default:
            return state;
    }
};
export default concessiontypeReducer;
