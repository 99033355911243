import types from "redux/actions/types";
import { IActivestudentState, ActiveStudentsActions, IActivestudentsCard } from "../types/activestudents";
import { IActivestudents } from "redux/types/activestudents";

const initialState: IActivestudentState = {
    loading: true,
    activestudent: {} as IActivestudents,
    activestudents: [] as IActivestudents[],
    activestudentscard: {} as IActivestudentsCard,

};

const activestudentReducer = (state = initialState, action: ActiveStudentsActions) => {
    switch (action.type) {

        case types.GET_ACTIVESTUDENTS_DATA:
            return {
                ...state,
                loading: false,
                activestudents: action.payload,
            }

        case types.GET_ACTIVESTUDENTS_CARDS_DATA:
            return {
                ...state,
                loading: false,
                activestudentscard: action.payload,
            }
        default:
            return state;
    }
};
export default activestudentReducer;