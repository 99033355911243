import React from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CommonAcademicyearMonth from "./AcademicYearSettings/CommonAcademicyearMonth";
import BranchAcademicYearMonth from "./AcademicYearSettings/BranchAcademicYearMonth";
import AcademicYearStartDate from "./AcademicYearSettings/AcademicYearStartDate";


const AcademicYearSettings: React.FC = (): JSX.Element => {
  return (
    <div className="c-container content-tabs mt-5">
      <Tabs defaultActiveKey="Common Academic Year Months" id="uncontrolled-tab-example" className="custom-dlex-tabs">
        <Tab eventKey="Common Academic Year Months" title="Common Academic Year Months">
         <CommonAcademicyearMonth/>
        </Tab>

        <Tab eventKey="Branch Academic Year Month" title="Branch Academic Year Month">
         <BranchAcademicYearMonth/>
        </Tab>

        <Tab eventKey="Academic Year Start Date" title="Academic Year Start Date">
         <AcademicYearStartDate/>
         </Tab>

      </Tabs>
    </div>
  )
}
export default React.memo(AcademicYearSettings);

