import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ButtonLoader from './Buttons/ButtonLoader';
export interface IConfirmationBoxData {
    show: boolean;
    data: any;
}

type Props = {
    info: any;
    showData: IConfirmationBoxData;
    actionHandler: Function;
    cancelHandler: Function;
    loader?: boolean;
};
const Confirmation: React.FC<Props> = ({ actionHandler, cancelHandler, info, showData, loader }): JSX.Element => {

    function debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }
    function finalCall() {
        actionHandler()
    }
    const handelMultipleClicks = debounce(() => finalCall());
    // Create a debounced version of the function to control its execution frequency
    // Adjust the delay (200ms in this example)
    return (
        <>
            <Modal className='theme-confirmation-modal' show={showData.show} onHide={() => cancelHandler({ show: false, data: "" })} centered backdrop="static" aria-labelledby="modal-for-conformation">
                <Modal.Body>
                    <div>
                        <h6>Warning <img className='pb-1 ms-1' src="/images/warning.svg" /></h6>
                        <span>{info}</span>
                    </div>
                    <div className='mt-3 pt-1 d-flex align-items-center justify-content-center'>
                        <button className='theme-modal-btn theme-action-btn me-16' disabled={loader} onClick={(e) => { handelMultipleClicks() }} onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handelMultipleClicks()
                            }
                        }}>
                            {loader ? <ButtonLoader color={"#fff"}/> : `Yes, I am sure`}
                        </button>
                        <button className="theme-modal-btn theme-secondary-btn" onClick={() => cancelHandler({ show: false, data: "" })}>
                            {`No, Take me back`}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Confirmation