import types from "redux/actions/types";
import { BranchLevelSettingsActions, IBranchLevelSettingsState, INotificationSettings, IWeekOffSettings } from "redux/types/branchLevelSettings";

const initialState: IBranchLevelSettingsState = {
    notificationSettings: {loading: true,error : false, totalCount: 0, data : []  as INotificationSettings[]},
    weekOffSettings: {loading: true,error : false, totalCount: 0, data : []  as IWeekOffSettings[]},
};

const branchLevelSettingsReducer = (state = initialState, action: BranchLevelSettingsActions) => {
    switch (action.type) {
        case types.GET_NOTIFICATION_SETTINGS:
            return {
                ...state,
                notificationSettings : {...state.notificationSettings, data: action.payload.data, error : false, loading : false, totalCount : action.payload.totalCount}
            }
        case types.UPDATE_NOTIFICATION_SETTINGS:
            return {
                ...state,
                notificationSettings : {...state.notificationSettings, error : false, loading : false}
            }

        case types.UPDATE_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                notificationSettings : {...state.notificationSettings, error : true, loading : false}
            }
        case types.GET_WEEKOFF_SETTINGS:
            return {
                ...state,
                weekOffSettings : {...state.weekOffSettings, data: action.payload.data, error : false, loading : false, totalCount : action.payload.totalCount}
            }
        case types.UPDATE_WEEKOFF_SETTINGS:
            return {
                ...state,
                weekOffSettings : {...state.weekOffSettings, error : false, loading : false}
            }

        case types.UPDATE_WEEKOFF_SETTINGS_FAILURE:
            return {
                ...state,
                weekOffSettings : {...state.weekOffSettings, error : true, loading : false}
            }
        default : 
            return state;
    }
}

export default branchLevelSettingsReducer;