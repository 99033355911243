enum ActionTypes {
  // ROLES
  ROLE_AUTH_ERROR = "ROLE_AUTH_ERROR",
  ROLE_REGISTER_SUCCESS = "ROLE_REGISTER_SUCCESS",
  ROLE_REGISTER_FAIL = "ROLE_REGISTER_FAIL",
  GET_ROLES = "GET_ROLES",
  UPDATE_ROLE = "UPDATE_ROLE",
  DELETE_ROLE = "DELETE_ROLE",
  // BRANCH
  BRANCHES_AUTH_ERROR = "BRANCHES_AUTH_ERROR",
  BRANCHES_REGISTER_SUCCESS = "BRANCHES_REGISTER_SUCCESS",
  LOGOUT_BRANCH = "LOGOUT_BRANCH",
  BRANCHMODULE_REGISTER_SUCCESS = "BRANCHMODULE_REGISTER_SUCCESS",
  BRANCHES_REGISTER_FAIL = "BRANCHES_REGISTER_FAIL",
  GET_BRANCHES = "GET_BRANCHES",
  GET_BRANCH = "GET_BRANCH",

  UPDATE_BRANCHES = "UPDATE_BRANCHES",
  DELETE_BRANCHES = "DELETE_BRANCHES",
  // CREATE USER
  CREATEUSER_LOADED = "CREATEUSER_LOADED",
  CREATEUSER_AUTH_ERROR = "CREATEUSER_AUTH_ERROR",
  CREATEUSER_REGISTER_SUCCESS = "CREATEUSER_REGISTER_SUCCESS",
  CREATEUSER_REGISTER_FAIL = "CREATEUSER_REGISTER_FAIL",
  CREATEUSER_LOGIN_SUCCESS = "CREATEUSER_LOGIN_SUCCESS",
  CREATEUSER_LOGIN_FAIL = "CREATEUSER_LOGIN_FAIL",
  CREATEUSER_LOGOUT = "CREATEUSER_LOGOUT",
  GET_CREATEUSERS = "GET_CREATEUSERS",
  UPDATE_CREATEUSER = "UPDATE_CREATEUSER",
  DELETE_CREATEUSER = "DELETE_CREATEUSER",
  // branchesmodules
  BRANCHMODULES_AUTH_ERROR = "BRANCHMODULES_AUTH_ERROR",
  BRANCHMODULES_REGISTER_SUCCESS = "BRANCHMODULES_REGISTER_SUCCESS",
  BRANCHMODULE1_REGISTER_SUCCESS = "BRANCHMODULE1_REGISTER_SUCCESS",
  BRANCHMODULE_REGISTER_FAIL = "BRANCHMODULES_REGISTER_FAIL",
  GET_BRANCHMODULES = "GET_BRANCHMODULES",
  GET_BRANCHMODULEPREMISSIONSLIST = "GET_BRANCHMODULEPREMISSIONSLIST",
  UPDATE_BRANCHMODULES = "UPDATE_BRANCHMODULES",
  DELETE_BRANCHMODULES = "DELETE_BRANCHMODULES",

  // classCatogory
  CLASSCATOGORY_AUTH_ERROR = "CLASSCATOGORY_AUTH_ERROR",
  CLASSCATOGORY_REGISTER_SUCCESS = "CLASSCATOGORY_REGISTER_SUCCESS",

  CLASSCATOGORY_REGISTER_FAIL = "CLASSCATOGORY_REGISTER_FAIL",
  GET_CLASSCATOGORYS = "GET_CLASSCATOGORYS",
  GET_CLASSCATOGORYPREMISSIONSLIST = "GET_CLASSCATOGORYPREMISSIONSLIST",
  UPDATE_CLASSCATOGORY = "UPDATE_CLASSCATOGORY",
  DELETE_CLASSCATOGORY = "DELETE_CLASSCATOGORY",
  // boardnames
  BOARDNAME_AUTH_ERROR = "BOARDNAME_AUTH_ERROR",
  BOARDNAME_REGISTER_SUCCESS = "BOARDNAME_REGISTER_SUCCESS",

  BOARDNAME_REGISTER_FAIL = "BOARDNAME_REGISTER_FAIL",
  GET_BOARDNAMES = "GET_BOARDNAMES",
  GET_BOARDNAMEPREMISSIONSLIST = "GET_BOARDNAMEPREMISSIONSLIST",
  UPDATE_BOARDNAME = "UPDATE_BOARDNAME",
  DELETE_BOARDNAME = "DELETE_BOARDNAME",
  //MASTERCLASS
  MASTERCLASS_AUTH_ERROR = "MASTERCLASS_AUTH_ERROR",
  MASTERCLASS_REGISTER_SUCCESS = "MASTERCLASS_REGISTER_SUCCESS",
  MASTERCLASS_REGISTER_FAIL = "MASTERCLASS_REGISTER_FAIL",
  GET_MASTERCLASSES = "GET_MASTERCLASSES",
  UPDATE_MASTERCLASS = "UPDATE_MASTERCLASS",
  DELETE_MASTERCLASS = "DELETE_MASTERCLASS",
  //CLASS
  CLASS_AUTH_ERROR = "CLASS_AUTH_ERROR",
  CLASS_REGISTER_SUCCESS = "CLASS_REGISTER_SUCCESS",
  CLASS_REGISTER_FAIL = "CLASS_REGISTER_FAIL",
  GET_CLASSES = "GET_CLASSES",
  UPDATE_CLASS = "UPDATE_CLASS",
  DELETE_CLASS = "DELETE_CLASS",
  //BRANCHCLASS
  BRANCHCLASS_AUTH_ERROR = "BRANCHCLASS_AUTH_ERROR",
  BRANCHCLASS_REGISTER_SUCCESS = "BRANCHCLASS_REGISTER_SUCCESS",
  BRANCHCLASS_REGISTER_FAIL = "BRANCHCLASS_REGISTER_FAIL",
  GET_BRANCHCLASSES = "GET_BRANCHCLASSES",
  UPDATE_BRANCHCLASS = "UPDATE_BRANCHCLASS",
  DELETE_BRANCHCLASS = "DELETE_BRANCHCLASS",
  //PROFILE
  SECTION_AUTH_ERROR = "SECTION_AUTH_ERROR",
  SECTION_REGISTER_SUCCESS = "SECTION_REGISTER_SUCCESS",
  SECTION_REGISTER_FAIL = "SECTION_REGISTER_FAIL",
  GET_SECTIONS = "GET_SECTIONS",
  UPDATE_SECTION = "UPDATE_SECTION",
  DELETE_SECTION = "DELETE_SECTION",
  //SUBJECT
  SUBJECT_AUTH_ERROR = "SUBJECT_AUTH_ERROR",
  SUBJECT_REGISTER_SUCCESS = "SUBJECT_REGISTER_SUCCESS",
  SUBJECT_REGISTER_FAIL = "SUBJECT_REGISTER_FAIL",
  GET_SUBJECTS = "GET_SUBJECTS",
  GET_SUBJECTS_REQUEST = "GET_SUBJECTS_REQUEST",
  UPDATE_SUBJECT = "UPDATE_SUBJECT",
  DELETE_SUBJECT = "DELETE_SUBJECT",
  //BRANCHSUBJECT
  BRANCHSUBJECT_AUTH_ERROR = "BRANCHSUBJECT_AUTH_ERROR",
  BRANCHSUBJECT_REGISTER_SUCCESS = "BRANCHSUBJECT_REGISTER_SUCCESS",
  BRANCHSUBJECT_REGISTER_FAIL = "BRANCHSUBJECT_REGISTER_FAIL",
  GET_BRANCHSUBJECTS_REQUEST = "GET_BRANCHSUBJECTS_REQUEST",
  GET_BRANCHSUBJECTS = "GET_BRANCHSUBJECTS",
  UPDATE_BRANCHSUBJECT = "UPDATE_BRANCHSUBJECT",
  DELETE_BRANCHSUBJECT = "DELETE_BRANCHSUBJECT",
  //MASTERSUBJECT
  MASTERSUBJECT_AUTH_ERROR = "MASTERSUBJECT_AUTH_ERROR",
  MASTERSUBJECT_REGISTER_SUCCESS = "MASTERSUBJECT_REGISTER_SUCCESS",
  MASTERSUBJECT_REGISTER_FAIL = "MASTERSUBJECT_REGISTER_FAIL",
  GET_MASTERSUBJECTS = "GET_MASTERSUBJECTS",
  UPDATE_MASTERSUBJECT = "UPDATE_MASTERSUBJECT",
  DELETE_MASTERSUBJECT = "DELETE_MASTERSUBJECT",
  // SUBJECTTYPE
  SUBJECTTYPE_AUTH_ERROR = "SUBJECTTYPE_AUTH_ERROR",
  SUBJECTTYPE_REGISTER_SUCCESS = "SUBJECTTYPE_REGISTER_SUCCESS",
  SUBJECTTYPE_REGISTER_FAIL = "SUBJECTTYPE_REGISTER_FAIL",
  GET_SUBJECTTYPES = "GET_SUBJECTTYPES",
  UPDATE_SUBJECTTYPE = "UPDATE_SUBJECTTYPE",
  DELETE_SUBJECTTYPE = "DELETE_SUBJECTTYPE",
  // ACADEMICBATCH
  ACADEMICBATCH_INITIAL = "ACADEMICBATCH_INITIAL",
  ACADEMICBATCH_NOT_INITIAL = "ACADEMICBATCH_NOT_INITIAL",
  ACADEMICBATCH_AUTH_ERROR = "ACADEMICBATCH_AUTH_ERROR",
  ACADEMICBATCH_REGISTER_SUCCESS = "ACADEMICBATCH_REGISTER_SUCCESS",
  ACADEMICBATCH_REGISTER_FAIL = "ACADEMICBATCH_REGISTER_FAIL",
  GET_ACADEMICBATCHS = "GET_ACADEMICBATCHS",
  UPDATE_ACADEMICBATCH = "UPDATE_ACADEMICBATCH",
  DELETE_ACADEMICBATCH = "DELETE_ACADEMICBATCH",
  // BRANCHACADEMICBATCH
  BRANCHACADEMICBATCH_INITIAL = "BRANCHACADEMICBATCH_INITIAL",
  BRANCHACADEMICBATCH_NOT_INITIAL = "BRANCHACADEMICBATCH_NOT_INITIAL",
  BRANCHACADEMICBATCH_AUTH_ERROR = "BRANCHACADEMICBATCH_AUTH_ERROR",
  BRANCHACADEMICBATCH_REGISTER_SUCCESS = "BRANCHACADEMICBATCH_REGISTER_SUCCESS",
  BRANCHACADEMICBATCH_REGISTER_FAIL = "BRANCHACADEMICBATCH_REGISTER_FAIL",
  GET_BRANCHACADEMICBATCHS = "GET_BRANCHACADEMICBATCHS",
  UPDATE_BRANCHACADEMICBATCH = "UPDATE_BRANCHACADEMICBATCH",
  DELETE_BRANCHACADEMICBATCH = "DELETE_BRANCHACADEMICBATCH",
  UPDATE_BRANCHACADEMICBATCHSTATUS = "UPDATE_BRANCHACADEMICBATCHSTATUS",
  // ACADEMICCLASS
  ACADEMICCLASS_AUTH_ERROR = "ACADEMICCLASS_AUTH_ERROR",
  ACADEMICCLASS_REGISTER_SUCCESS = "ACADEMICCLASS_REGISTER_SUCCESS",
  ACADEMICCLASS_REGISTER_FAIL = "ACADEMICCLASS_REGISTER_FAIL",
  GET_ACADEMICCLASSES = "GET_ACADEMICCLASSES",
  UPDATE_ACADEMICCLASS = "UPDATE_ACADEMICCLASS",
  DELETE_ACADEMICCLASS = "DELETE_ACADEMICCLASS",
  GET_COMBINED_CLASSESS = 'GET_COMBINED_CLASSESS',
  GET_COMBINED_CLASSESS_BY_USER = 'GET_COMBINED_CLASSESS_BY_USER',
  // ACADEMICSECTION
  ACADEMICSECTION_AUTH_ERROR = "ACADEMICSECTION_AUTH_ERROR",
  ACADEMICSECTION_REGISTER_SUCCESS = "ACADEMICSECTION_REGISTER_SUCCESS",
  ACADEMICSECTION_REGISTER_FAIL = "ACADEMICSECTION_REGISTER_FAIL",
  GET_ACADEMICSECTIONS = "GET_ACADEMICSECTIONS",
  UPDATE_ACADEMICSECTION = "UPDATE_ACADEMICSECTION",
  DELETE_ACADEMICSECTION = "DELETE_ACADEMICSECTION",

  // ACADEMICSECTION
  ORIGANIZATIONFEECATEGORY_AUTH_ERROR = "ORIGANIZATIONFEECATEGORY_AUTH_ERROR",
  ORIGANIZATIONFEECATEGORY_REGISTER_SUCCESS = "ORIGANIZATIONFEECATEGORY_REGISTER_SUCCESS",
  ORIGANIZATIONFEECATEGORY_REGISTER_FAIL = "ORIGANIZATIONFEECATEGORY_REGISTER_FAIL",
  GET_ORIGANIZATIONFEECATEGORIOS = "GET_ORIGANIZATIONFEECATEGORIOS",
  UPDATE_ORIGANIZATIONFEECATEGORY = "UPDATE_ORIGANIZATIONFEECATEGORY",
  DELETE_ORIGANIZATIONFEECATEGORY = "DELETE_ORIGANIZATIONFEECATEGORY",
  // MASTERFEECATEGORY
  MASTERFEECATEGORY_AUTH_ERROR = "MASTERFEECATEGORY_AUTH_ERROR",
  MASTERFEECATEGORY_REGISTER_SUCCESS = "MASTERFEECATEGORY_REGISTER_SUCCESS",
  MASTERFEECATEGORY_REGISTER_FAIL = "MASTERFEECATEGORY_REGISTER_FAIL",
  GET_MASTERFEECATEGORIES = "GET_MASTERFEECATEGORIES",
  UPDATE_MASTERFEECATEGORY = "UPDATE_MASTERFEECATEGORY",
  DELETE_MASTERFEECATEGORY = "DELETE_MASTERFEECATEGORY",
  // FEETYPE
  FEETYPE_AUTH_ERROR = "FEETYPE_AUTH_ERROR",
  FEETYPE_REGISTER_SUCCESS = "FEETYPE_REGISTER_SUCCESS",
  FEETYPE_REGISTER_FAIL = "FEETYPE_REGISTER_FAIL",
  GET_FEETYPES = "GET_FEETYPES",
  UPDATE_FEETYPE = "UPDATE_FEETYPE",
  DELETE_FEETYPE = "DELETE_FEETYPE",
  // FEEPLAN
  FEEPLAN_AUTH_ERROR = "FEEPLAN_AUTH_ERROR",
  FEEPLAN_REGISTER_SUCCESS = "FEEPLAN_REGISTER_SUCCESS",
  FEEPLAN_REGISTER_FAIL = "FEEPLAN_REGISTER_FAIL",
  GET_FEEPLANS = "GET_FEEPLANS",
  GET_FEEPLANSBYCLASS = "GET_FEEPLANSBYCLASS",
  UPDATE_FEEPLAN = "UPDATE_FEEPLAN",
  DELETE_FEEPLAN = "DELETE_FEEPLAN",
  GET_CLASSBASED_FEEPLANS = 'GET_CLASSBASED_FEEPLANS',
  // FEEPLANFEETYPE
  FEEPLANFEETYPE_AUTH_ERROR = "FEEPLAN_AUTH_ERROR",
  FEEPLANFEETYPE_REGISTER_SUCCESS = "FEEPLANFEETYPE_REGISTER_SUCCESS",
  FEEPLANFEETYPE_REGISTER_FAIL = "FEEPLANFEETYPE_REGISTER_FAIL",
  GET_FEEPLAN_FEETYPES = "GET_FEEPLAN_FEETYPES",
  UPDATE_FEEPLANFEETYPE = "UPDATE_FEEPLANFEETYPE",
  DELETE_FEEPLANFEETYPE = "DELETE_FEEPLANFEETYPE",
  // BANKACCOUNT
  BANKACCOUNT_AUTH_ERROR = "BANKACCOUNT_AUTH_ERROR",
  BANKACCOUNT_REGISTER_SUCCESS = "BANKACCOUNT_REGISTER_SUCCESS",
  BANKACCOUNT_REGISTER_FAIL = "BANKACCOUNT_REGISTER_FAIL",
  GET_BANKACCOUNTS = "GET_BANKACCOUNTS",
  UPDATE_BANKACCOUNT = "UPDATE_BANKACCOUNT",
  DELETE_BANKACCOUNT = "DELETE_BANKACCOUNT",
  // CASHFLOWACCOUNT
  CASHFLOWACCOUNT_AUTH_ERROR = "CASHFLOWACCOUNT_AUTH_ERROR",
  CASHFLOWACCOUNT_REGISTER_SUCCESS = "CASHFLOWACCOUNT_REGISTER_SUCCESS",
  CASHFLOWACCOUNT_REGISTER_FAIL = "CASHFLOWACCOUNT_REGISTER_FAIL",
  GET_CASHFLOWACCOUNTS = "GET_CASHFLOWACCOUNTS",
  UPDATE_CASHFLOWACCOUNT = "UPDATE_CASHFLOWACCOUNT",
  DELETE_CASHFLOWACCOUNT = "DELETE_CASHFLOWACCOUNT",
  // BRANCHCASHFLOWACCOUNT
  BRANCHCASHFLOWACCOUNT_AUTH_ERROR = "BRANCHCASHFLOWACCOUNT_AUTH_ERROR",
  BRANCHCASHFLOWACCOUNT_REGISTER_SUCCESS = "BRANCHCASHFLOWACCOUNT_REGISTER_SUCCESS",
  BRANCHCASHFLOWACCOUNT_REGISTER_FAIL = "BRANCHCASHFLOWACCOUNT_REGISTER_FAIL",
  GET_BRANCHCASHFLOWACCOUNTS = "GET_BRANCHCASHFLOWACCOUNTS",
  UPDATE_BRANCHCASHFLOWACCOUNT = "UPDATE_BRANCHCASHFLOWACCOUNT",
  DELETE_BRANCHCASHFLOWACCOUNT = "DELETE_BRANCHCASHFLOWACCOUNT",
  // FEESTRUCTURE
  FEESTRUCTURE_AUTH_ERROR = "FEESTRUCTURE_AUTH_ERROR",
  FEESTRUCTURE_REGISTER_SUCCESS = "FEESTRUCTURE_REGISTER_SUCCESS",
  FEESTRUCTURE_REGISTER_FAIL = "FEESTRUCTURE_REGISTER_FAIL",
  GET_FEESTRUCTURES = "GET_FEESTRUCTURES",
  UPDATE_FEESTRUCTURE = "UPDATE_FEESTRUCTURE",
  DELETE_FEESTRUCTURE = "DELETE_FEESTRUCTURE",
  // ENQUIRYSTAGE
  ENQUIRYSTAGE_AUTH_ERROR = "ENQUIRYSTAGE_AUTH_ERROR",
  ENQUIRYSTAGE_REGISTER_SUCCESS = "ENQUIRYSTAGE_REGISTER_SUCCESS",
  ENQUIRYSTAGE_REGISTER_FAIL = "ENQUIRYSTAGE_REGISTER_FAIL",
  GET_ENQUIRYSTAGES = "GET_ENQUIRYSTAGES",
  UPDATE_ENQUIRYSTAGE = "UPDATE_ENQUIRYSTAGE",
  DELETE_ENQUIRYSTAGE = "DELETE_ENQUIRYSTAGE",
  DELETE_HISTORY_ENQUIRY_FOLLOWUP = "DELETE_HISTORY_ENQUIRY_FOLLOWUP",
  // ENQUIRY
  ENQUIRY_AUTH_ERROR = "ENQUIRY_AUTH_ERROR",
  ENQUIRY_REGISTER_SUCCESS = "ENQUIRY_REGISTER_SUCCESS",
  ENQUIRY_REGISTER_FAIL = "ENQUIRY_REGISTER_FAIL",
  GET_ENQUIRIES = "GET_ENQUIRIES",
  SEARCH_ENQUIRIES = "SEARCH_ENQUIRIES",
  UPDATE_ENQUIRY = "UPDATE_ENQUIRY",
  DELETE_ENQUIRY = "DELETE_ENQUIRY",
  // ENQUIRYFOLLOWUP
  ENQUIRYFOLLOWUP_AUTH_ERROR = "ENQUIRYFOLLOWUP_AUTH_ERROR",
  ENQUIRYFOLLOWUP_REGISTER_SUCCESS = "ENQUIRYFOLLOWUP_REGISTER_SUCCESS",
  ENQUIRYFOLLOWUP_REGISTER_FAIL = "ENQUIRYFOLLOWUP_REGISTER_FAIL",
  GET_ENQUIRYFOLLOWUPS = "GET_ENQUIRYFOLLOWUPS",
  UPDATE_ENQUIRYFOLLOWUP = "UPDATE_ENQUIRYFOLLOWUP",
  DELETE_ENQUIRYFOLLOWUP = "DELETE_ENQUIRYFOLLOWUP",
  HISTORY_ENQUIRYFOLLOWUP = "HISTORY_ENQUIRYFOLLOWUP",
  // ENROLL_STUDENTS
  ENROLL_STUDENT_SUCCESS = "ENROLL_STUDENT_SUCCESS",
  ENROLL_STUDENT_FAIL = "ENROLL_STUDENT_FAIL",
  GET_ENROLLED_STUDENTS = "GET_ENROLLED_STUDENTS",
  UPDATE_ENROLLED_STUDENT = "UPDATE_ENROLLED_STUDENT",
  DELETE_ENROLLED_STUDENT = "DELETE_ENROLLED_STUDENT",
  // ADMISSIONSETTINGS
  ADMISSIONSETTING_AUTH_ERROR = "ADMISSIONSETTING_AUTH_ERROR",
  ADMISSIONSETTING_REGISTER_SUCCESS = "ADMISSIONSETTING_REGISTER_SUCCESS",
  ADMISSIONSETTING_REGISTER_FAIL = "ADMISSIONSETTING_REGISTER_FAIL",
  GET_ADMISSIONSETTINGS = "GET_ADMISSIONSETTINGS",
  UPDATE_ADMISSIONSETTING = "UPDATE_ADMISSIONSETTING",
  DELETE_ADMISSIONSETTING = "DELETE_ADMISSIONSETTING",
  // ADMISSIONTYPES
  ADMISSIONTYPE_AUTH_ERROR = "ADMISSIONTYPE_AUTH_ERROR",
  ADMISSIONTYPE_REGISTER_SUCCESS = "ADMISSIONTYPE_REGISTER_SUCCESS",
  ADMISSIONTYPE_REGISTER_FAIL = "ADMISSIONTYPE_REGISTER_FAIL",
  GET_ADMISSIONTYPES = "GET_ADMISSIONTYPES",
  UPDATE_ADMISSIONTYPE = "UPDATE_ADMISSIONTYPE",
  DELETE_ADMISSIONTYPE = "DELETE_ADMISSIONTYPE",
  // ADMISSIONREPORTS
  GET_ADMISSIONREPORTS = "GET_ADMISSIONREPORTS",
  // APPLICANT
  APPLICANT_AUTH_ERROR = "APPLICANT_AUTH_ERROR",
  APPLICANT_REGISTER_SUCCESS = "APPLICANT_REGISTER_SUCCESS",
  APPLICANT_REGISTER_FAIL = "APPLICANT_REGISTER_FAIL",
  GET_APPLICANTS = "GET_APPLICANTS",
  SEARCH_APPLICANTS = "SEARCH_APPLICANTS",
  UPDATE_APPLICANT = "UPDATE_APPLICANT",
  DELETE_APPLICANT = "DELETE_APPLICANT",
  // APPLICATIONSETTING
  APPLICATIONSETTING_AUTH_ERROR = "APPLICATIONSETTING_AUTH_ERROR",
  APPLICATIONSETTING_REGISTER_SUCCESS = "APPLICATIONSETTING_REGISTER_SUCCESS",
  APPLICATIONSETTING_REGISTER_FAIL = "APPLICATIONSETTING_REGISTER_FAIL",
  GET_APPLICATIONSETTINGS = "GET_APPLICATIONSETTINGS",
  UPDATE_APPLICATIONSETTING = "UPDATE_APPLICATIONSETTING",
  DELETE_APPLICATIONSETTING = "DELETE_APPLICATIONSETTING",
  // DAIRY
  DAIRY_AUTH_ERROR = "DAIRY_AUTH_ERROR",
  DAIRY_REGISTER_SUCCESS = "DAIRY_REGISTER_SUCCESS",
  DAIRY_REGISTER_FAIL = "DAIRY_REGISTER_FAIL",
  GET_DAIRIES = "GET_DAIRIES",
  GET_GENERAL_DAIRIES = "GET_GENERAL_DAIRIES",
  UPDATE_DAIRY = "UPDATE_DAIRY",
  DELETE_DAIRY = "DELETE_DAIRY",
  //DAIRY APPROVALS
  GET_DAIRY_SEND_APPORVAL = "GET_DAIRY_SEND_APPORVAL",
  GET_DAIRY_APPORVAL = "GET_DAIRY_APPORVAL",
  // HEADERSTATE
  HEADERSTATE_UPDATE = "HEADERSTATE_UPDATE",
  // STUDENTFEE
  STUDENTFEE_AUTH_ERROR = "STUDENTFEE_AUTH_ERROR",
  STUDENTFEE_REGISTER_SUCCESS = "STUDENTFEE_REGISTER_SUCCESS",
  STUDENTFEE_REGISTER_FAIL = "STUDENTFEE_REGISTER_FAIL",
  GET_STUDENTFEES = "GET_STUDENTFEES",
  UPDATE_STUDENTFEE = "UPDATE_STUDENTFEE",
  DELETE_STUDENTFEE = "DELETE_STUDENTFEE",
  STUDENTFEEPAYMENT_REGISTER_SUCCESS = "STUDENTFEEPAYMENT_REGISTER_SUCCESS",
  GET_STUDENTFEESRECEPT = "GET_STUDENTFEESRECEPT",
  GET_STUDENTFEETRANSACTIONS = "GET_STUDENTFEETRANSACTIONS",
  UNASSIGN_STUDENTFEE = "UNASSIGN_STUDENTFEE",
  GET_ONLINEPAYMENTS = "ONLINE_PAYMENTS",
  // FINESETTING
  FINESETTING_AUTH_ERROR = "FINESETTING_AUTH_ERROR",
  FINESETTING_REGISTER_SUCCESS = "FINESETTING_REGISTER_SUCCESS",
  FINESETTING_REGISTER_FAIL = "FINESETTING_REGISTER_FAIL",
  GET_FINESETTINGS = "GET_FINESETTINGS",
  UPDATE_FINESETTING = "UPDATE_FINESETTING",
  DELETE_FINESETTING = "DELETE_FINESETTING",
  // CONCESSIONTYPE
  CONCESSIONTYPE_AUTH_ERROR = "CONCESSIONTYPE_AUTH_ERROR",
  CONCESSIONTYPE_REGISTER_SUCCESS = "CONCESSIONTYPE_REGISTER_SUCCESS",
  CONCESSIONTYPE_REGISTER_FAIL = "CONCESSIONTYPE_REGISTER_FAIL",
  GET_CONCESSIONTYPES = "GET_CONCESSIONTYPES",
  UPDATE_CONCESSIONTYPE = "UPDATE_CONCESSIONTYPE",
  DELETE_CONCESSIONTYPE = "DELETE_CONCESSIONTYPE",
  // CONCESSIONRULE
  CONCESSIONRULE_AUTH_ERROR = "CONCESSIONRULE_AUTH_ERROR",
  CONCESSIONRULE_REGISTER_SUCCESS = "CONCESSIONRULE_REGISTER_SUCCESS",
  BRANCHCONCESSIONRULE_SAVE = "BRANCHCONCESSIONRULE_SAVE",

  CONCESSIONRULE_REGISTER_FAIL = "CONCESSIONRULE_REGISTER_FAIL",
  GET_CONCESSIONRULES = "GET_CONCESSIONRULES",
  GET_CONCESSIONRULES_BRANCH = "GET_CONCESSIONRULES_BRANCH",

  UPDATE_CONCESSIONRULE = "UPDATE_CONCESSIONRULE",
  DELETE_CONCESSIONRULE = "DELETE_CONCESSIONRULE",
  // CONCESSIONAPPROVALRULE
  CONCESSIONAPPROVALRULE_AUTH_ERROR = "CONCESSIONAPPROVALRULE_AUTH_ERROR",
  CONCESSIONAPPROVALRULE_REGISTER_SUCCESS = "CONCESSIONAPPROVALRULE_REGISTER_SUCCESS",
  CONCESSIONAPPROVEDREQUEST_REGISTER_SUCCESS = "CONCESSIONAPPROVEDREQUEST_REGISTER_SUCCESS",

  CONCESSIONAPPROVALRULE_REGISTER_FAIL = "CONCESSIONAPPROVALRULE_REGISTER_FAIL",
  GET_CONCESSIONAPPROVALRULES = "GET_CONCESSIONAPPROVALRULES",
  GET_VIEWCONCESSIONAPPROVALREQUEST = "GET_VIEWCONCESSIONAPPROVALREQUEST",

  GET_CONCESSIONAPPROVALREQUEST = "GET_CONCESSIONAPPROVALREQUEST",

  UPDATE_CONCESSIONAPPROVALRULE = "UPDATE_CONCESSIONAPPROVALRULE",
  DELETE_CONCESSIONAPPROVALRULE = "DELETE_CONCESSIONAPPROVALRULE",
  // BRANCHPAYMENTMETHOD
  BRANCHPAYMENTMETHOD_AUTH_ERROR = "BRANCHPAYMENTMETHOD_AUTH_ERROR",
  BRANCHPAYMENTMETHOD_REGISTER_SUCCESS = "BRANCHPAYMENTMETHOD_REGISTER_SUCCESS",
  BRANCHPAYMENTMETHOD_REGISTER_FAIL = "BRANCHPAYMENTMETHOD_REGISTER_FAIL",
  GET_BRANCHPAYMENTMETHODS = "GET_BRANCHPAYMENTMETHODS",
  UPDATE_BRANCHPAYMENTMETHOD = "UPDATE_BRANCHPAYMENTMETHOD",
  DELETE_BRANCHPAYMENTMETHOD = "DELETE_BRANCHPAYMENTMETHOD",

  // ORGPAYMENTMETHOD
  ORGPAYMENTMETHOD_AUTH_ERROR = "ORGPAYMENTMETHOD_AUTH_ERROR",
  ORGPAYMENTMETHOD_REGISTER_SUCCESS = "ORGPAYMENTMETHOD_REGISTER_SUCCESS",
  ORGPAYMENTMETHOD_REGISTER_FAIL = "ORGPAYMENTMETHOD_REGISTER_FAIL",
  GET_ORGPAYMENTMETHODS = "GET_ORGPAYMENTMETHODS",
  UPDATE_ORGPAYMENTMETHOD = "UPDATE_ORGPAYMENTMETHOD",
  DELETE_ORGPAYMENTMETHOD = "DELETE_ORGPAYMENTMETHOD",

  // LOGO
  LOGO_AUTH_ERROR = "LOGO_AUTH_ERROR",
  LOGO_REGISTER_SUCCESS = "LOGO_REGISTER_SUCCESS",
  ORGLOGO_REGISTER_SUCCESS = "ORGLOGO_REGISTER_SUCCESS",
  BRANCHLOGO_REGISTER_SUCCESS = "BRANCHLOGO_REGISTER_SUCCESS",
  LOGO_REGISTER_FAIL = "LOGO_REGISTER_FAIL",
  GET_LOGOS = "GET_LOGOS",
  UPDATE_LOGO = "UPDATE_LOGO",
  DELETE_LOGO = "DELETE_LOGO",

  // ORGANIZATION
  ORGANIZATION_AUTH_ERROR = "ORGANIZATION_AUTH_ERROR",
  ORGANIZATION_REGISTER_SUCCESS = "ORGANIZATION_REGISTER_SUCCESS",
  ORGANIZATION_REGISTER_FAIL = "ORGANIZATION_REGISTER_FAIL",
  LOGOUT_ORGANIZATION = "LOGOUT_ORGANIZATION",
  GET_ORGANIZATIONS = "GET_ORGANIZATIONS",
  UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
  DELETE_ORGANIZATION = "DELETE_ORGANIZATION",
  GET_ALL_ORGANIZATIONS = "GET_ALL_ORGANIZATIONS",
  VALIDATE_ORGANIZATION_BY_SUBDOMAIN = "VALIDATE_ORGANIZATION_BY_SUBDOMAIN",
  SET_ORGANIZATION_BACKGROUND_IMAGE = "SET_ORGANIZATION_BACKGROUND_IMAGE",

  // REPORTS
  GET_FEEDAYSHEETREPORT = "GET_FEEDAYSHEETREPORT",
  GET_FEEDUEREPORT = "GET_FEEDUEREPORT",
  GET_FEEDAYSHEETREPORT_REQUEST = "GET_FEEDAYSHEETREPORT_REQUEST",
  GET_CLASSWISE_DUE_REPORT = "GET_CLASSWISE_DUE_REPORT",

  //SCHEDULE JOBS
  SCHEDULEJOB_AUTH_ERROR = "SCHEDULEJOB_AUTH_ERROR",
  SCHEDULEJOB_REGISTER_SUCCESS = "SCHEDULEJOB_REGISTER_SUCCESS",
  SCHEDULEJOB_REGISTER_FAIL = "SCHEDULEJOB_REGISTER_FAIL",
  GET_SCHEDULEJOBS = "GET_SCHEDULEJOBS",
  GET_SCHEDULETYPES = "GET_SCHEDULETYPES",
  UPDATE_SCHEDULEJOBS = "UPDATE_SCHEDULEJOBS",
  DELETE_SCHEDULEJOBS = "DELETE_SCHEDULEJOBS",
  //PROFILE
  PROFILE_AUTH_ERROR = "PROFILE_AUTH_ERROR",
  LOGOUT_PROFILE = "LOGOUT_PROFILE",
  PROFILE_REGISTER_SUCCESS = "PROFILE_REGISTER_SUCCESS",
  PROFILE_REGISTER_FAIL = "PROFILE_REGISTER_FAIL",
  GET_PROFILES = "GET_PROFILES",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  DELETE_PROFILE = "DELETE_PROFILE",



  //DASHBOARDS

  STAFF_DATA = 'STAFF_DATA',
  BRANCHES_DATA = 'BRANCHES_DATA',
  FEE_COLLECTION = 'FEE_COLLECTION',
  BRANCH_FEE_DUES = 'BRANCH_FEE_DUES',
  FEE_TYPE_DUES = 'FEE_TYPE_DUES',
  CLASS_FEE_DUES = 'CLASS_FEE_DUES',
  STATUS_RIBBON = 'STATUS_RIBBON',
  ORG_LEAVES_DATA = 'ORG_LEAVES_DATA',
  BRANCH_LEAVES_DATA = 'BRANCH_LEAVES_DATA',
  ORG_APPROVALS_DATA = 'ORG_APPROVALS_DATA',
  BRANCH_APPROVALS_DATA = 'BRANCH_APPROVALS_DATA',
  ORG_SMS_DATA = 'ORG_SMS_DATA',
  BRANCH_SMS_DATA = 'BRANCH_SMS_DATA',
  BRANCH_SMS_DATA_FAIL = "BRANCH_SMS_DATA_FAIL",
  TOTAL_PEOPLE_DATA = 'TOTAL_PEOPLE_DATA',
  ORG_STRENGTH = 'ORG_STRENGTH',
  BRANCH_STRENGTH = 'BRANCH_STRENGTH',
  BRANCH_BIRTH_DAYS = 'BRANCH_BIRTH_DAYS',
  BRANCH_EVENTS = 'BRANCH_EVENTS',
  FEE_SUMMARY_DATA = 'FEE_SUMMARY_DATA',
  FEE_SUMMARY_DATA_FAIL = 'FEE_SUMMARY_DATA_FAIL',
  CLASS_WISE_STUDENT_STRENGTH = "CLASS_WISE_STUDENT_STRENGTH",
  GET_STUDENT_BIRTHDAYS = "GET_STUDENT_BIRTHDAYS",
  GET_STAFF_WORK_ANNIVERSARY = "GET_STAFF_WORK_ANNIVERSARY",
  BRANCH_ANNOUNCEMENTS = 'BRANCH_ANNOUNCEMENTS',
  GET_MONTH_WISE_FEE = "GET_MONTH_WISE_FEE",

  // HR DASHBOARDS

  HR_GENDER_PERCENTAGE = 'HR_GENDERPERCENTAGE',
  HR_STAFF_AGE = "HR_STAFF_AGE",
  HR_STAFF_EXPERIENCE = "HR_STAFF_EXPERIENCE",

  // STAFF HOLIDAYS
  CREATE_STAFFHOLIDAYS_FAIL = "CREATE_STAFFHOLIDAYS_FAIL",
  GET_STAFFHOLIDAYS = "GET_STAFFHOLIDAYS",

  
  //WORKING_DAYS
  GET_WORKING_DAYS = 'GET_WORKING_DAYS',


  //EVENT_CALENDER
  CREATE_EVENT = 'CREATE_EVENT',
  GET_EVENTS = 'GET_EVENTS',
  EDIT_EVENT = 'EDIT_EVENT',
  DELETE_EVENT = 'DELETE_EVENT',

  //EVENT_TYPES
  CREATE_EVENT_TYPE = 'CREATE_EVENT_TYPE',
  GET_EVENT_TYPES = 'GET_EVENT_TYPES',



  //TIME_TABLE
  GET_TIME_TABLES = 'GET_TIME_TABLES',
  GET_TIME_TABLES_BY_ID = 'GET_TIME_TABLES_BY_ID',
  UPDATE_TIME_TABLE = 'UPDATE_TIME_TABLE',
  DELETE_TIME_TABLE = 'DELETE_TIME_TABLE',
  DELETE_SLOT = 'DELETE_SLOT',
  DELETE_SESSION = 'DELETE_SESSION',
  VALIDATE_TIME_TABLE = "VALIDATE_TIME_TABLE",

  //ATTENDENCE
  GET_STUDENT_ATTENDENCE = 'GET_STUDENT_ATTENDENCE',
  GET_STUDENT_ATTENDENCE_REPORTS = 'GET_STUDENT_ATTENDENCE_REPORTS',
  GET_STUDENT_ATTENDENCE_SUMMARY = 'GET_STUDENT_ATTENDENCE_SUMMARY',
  GET_STUDENT_ATTENDENCE_SUMMARY_REQUEST = 'GET_STUDENT_ATTENDENCE_SUMMARY_REQUEST',
  GET_SESSIONS = 'GET_SESSIONS',

  //TIME_LINE
  GET_ORG_TIMELINE = 'GET_ORG_TIMELINE',
  GET_BRANCH_TIMELINE = 'GET_BRANCH_TIMELINE',

  //MODULES
  GET_MODULES = "GET_MODULES",

  //FEE_RECIPT_SEQUENCE

  GET_FEE_RECIPT_SEQUENCES = 'GET_FEE_RECIPT_SEQUENCES',
  UPDATE_FEE_RECIPT_SEQUENCE = 'UPDATE_FEE_RECIPT_SEQUENCE',
  GET_FEE_RECIPT_SEQUENCE = 'GET_FEE_RECIPT_SEQUENCE',

  //CERTIFICATE SEQUENCE NUMBER

  GET_CERTIFICATE_SEQUENCES = 'GET_CERTIFICATE_SEQUENCES',
  GET_CERTIFICATE_SEQUENCES_FAILURE = 'GET_CERTIFICATE_SEQUENCES_FAILURE',
  CREATE_CERTIFICATE_SEQUENCE = 'CREATE_CERTIFICATE_SEQUENCE',
  UPDATE_CERTIFICATE_SEQUENCE = 'UPDATE_CERTIFICATE_SEQUENCE',

  //ENROLLMENT_STUDENT_SEQUENCE

  GET_ENROLLMENT_STUDENT_SEQUENCES = 'GET_ENROLLMENT_STUDENT_SEQUENCES',
  UPDATE_ENROLLMENT_STUDENT_SEQUENCE = 'UPDATE_ENROLLMENT_STUDENT_SEQUENCES',
  GET_ENROLLMENT_STUDENT_SEQUENCE = 'GET_ENROLLMENT_STUDENT_SEQUENCE',
  DELETE_SEQUENCE = "DELETE_SEQUENCE",
  ENROLLMENT_STUDENT_SEQUENCE_REGISTER_SUCCESS = "ENROLLMENT_STUDENT_SEQUENCE_REGISTER_SUCCESS",
  //ENROLLMENT_STAFF_SEQUENCE

  GET_ENROLLMENT_STAFF_SEQUENCES = 'GET_ENROLLMENT_STAFF_SEQUENCES',
  UPDATE_ENROLLMENT_STAFF_SEQUENCE = 'UPDATE_ENROLLMENT_STAFF_SEQUENCE',
  GET_ENROLLMENT_STAFF_SEQUENCE = 'GET_ENROLLMENT_STAFF_SEQUENCE',
  ENROLLMENT_STAFF_SEQUENCE_REGISTER_SUCCESS = "ENROLLMENT_STAFF_SEQUENCE_REGISTER_SUCCESS",

  //RESET_PASSWORD
  REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  //DLT_TEMPLATE
  CREATE_DLT_TEMPLATE = 'CREATE_DLT_TEMPLATE',
  GET_DLT_TEMPLATES = 'GET_DLT_TEMPLATES',
  UPDATE_DLT_TEMPLATE = 'UPDATE_DLT_TEMPLATE',
  DELETE_DLT_TEMPLATE = 'DELETE_DLT_TEMPLATE',
  GET_COMMUNICATION_TYPES = 'GET_COMMUNICATION_TYPES',
  GET_TEMPLATE_VARIABLES = 'GET_TEMPLATE_VARIABLES',

  //TEMPLATE_SETTINGS
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  GET_TEMPLATES = 'GET_TEMPLATES',
  GET_SINGLE_TEMPLATE = 'GET_SINGLE_TEMPLATE',

  //APPROVAL_SETTINGS
  CREATE_APPROVAL_SETTINGS = 'CREATE_APPROVAL_SETTINGS',
  GET_APPROVAL_SETTINGS = 'GET_APPROVAL_SETTINGS',
  UPDATE_APPROVAL_SETTINGS = 'UPDATE_APPROVAL_SETTINGS',
  DELETE_APPROVAL_SETTINGS = 'DELETE_APPROVAL_SETTINGS',

 // CERTIFICATE APPROVAL SETTINGS
 CREATE_CERTIFICATE_APPROVAL_SETTINGS = 'CREATE_CERTIFICATE_APPROVAL_SETTINGS',
 GET_CERTIFICATE_APPROVAL_SETTINGS = 'GET_CERTIFICATE_APPROVAL_SETTINGS',
 GET_CERTIFICATE_APPROVALS = "GET_CERTIFICATE_APPROVALS",
 UPDATE_CERTIFICATE_APPROVAL_SETTINGS = 'UPDATE_CERTIFICATE_APPROVAL_SETTINGS',
 DELETE_CERTIFICATE_APPROVAL_SETTINGS = 'DELETE_CERTIFICATE_APPROVAL_SETTINGS',

  //APPROVALS
  GET_APPROVALS = 'GET_APPROVALS',
  GET_SEND_FOR_APPROVALS = "GET_SEND_FOR_APPROVALS",
  RESET_FILTERED_STUDENT = "RESET_FILTERED_STUDENT",

  //FEE_INSTALLMENTS
  GET_FEE_INSTALLMENTS = 'GET_FEE_INSTALLMENTS',

  //FAVORATES
  CREATE_FAVORATES = 'CREATE_FAVORATES',
  GET_FAVORATES = 'GET_FAVORATES',

  //DEPARTMENT
  CREATE_DEPARTMENT = 'CREATE_DEPARTMENT',
  GET_DEPARTMENT = 'GET_DEPARTMENT',

  //DESIGNATION

  DESIGNATION_REGISTER_SUCCESS = 'DESIGNATION_REGISTER_SUCCESS',
  GET_DESIGNATION = 'GET_DESIGNATION',
  DELETE_DESIGNATION = "DELETE_DESIGNATION",
  UPDATE_DESIGNATION = "UPDATE_DESIGNATION",

  // STAFF_ATTENDANCE
  CREATE_STAFF_ATTENDANCE = "CREATE_STAFF_ATTENDANCE",
  GET_STAFFATTENDANCE_STAFF = "GET_STAFFATTENDANCE_STAFF",
  GET_ALL_DATE_STAFFATTENDANCE = "GET_ALL_DATE_STAFFATTENDANCE",
  GET_BRANCH_LEAVE_STATS = "GET_BRANCH_LEAVE_STATS",
  GET_ORG_LEAVE_STATS = "GET_ORG_LEAVE_STATS",
  GET_STAFFATTENDANCE_DATA = "GET_STAFFATTENDANCE_DATA",
  GET_STAFF_ATTENDENCE_SUMMARY = 'GET_STAFF_ATTENDENCE_SUMMARY',
  GET_STAFF_ATTENDENCE_SUMMARY_REQUEST = 'GET_STAFF_ATTENDENCE_SUMMARY_REQUEST',

  //STAFF_ENROLLMENT
  CREATE_STAFF = 'CREATE_STAFF',

  //STAFF SHIFTS
  STAFF_SHIFTS_REGISTER_SUCCESS = "STAFF_SHIFTS_REGISTER_SUCCESS",
  GET_STAFF_SHIFTS = "GET_STAFF_SHIFTS",
  UPDATE_STAFF_SHIFTS = "UPDATE_STAFF_SHIFTS",
  DELETE_STAFF_SHIFTS = "DELETE_STAFF_SHIFTS",

  //WEEKLY OFF
  GET_WEEKLY_OFF = "GET_WEEKLY_OFF",
  CREATE_WEEKLY_OFF = "CREATE_WEEKLY_OFF",
  UPDATE_WEEKLY_OFF = "UPDATE_WEEKLY_OFF",
  DELETE_WEEKLY_OFF = "DELETE_WEEKLY_OFF",

  // Work Load
  STAFF_WORK_LOAD = "STAFF_WORK_LOAD",
  STAFF_REMAINING_WORK_LOAD = "STAFF_REMAINING_WORK_LOAD",
  // LeaveTypes
  LEAVE_TYPE_REGISTER_SUCCESS = "LEAVE_TYPE_REGISTER_SUCCESS",
  GET_LEAVE_TYPE = "GET_LEAVE_TYPE",
  UPDATE_LEAVE_TYPE = "UPDATE_LEAVE_TYPE",
  DELETE_LEAVE_TYPE = "DELETE_LEAVE_TYPE",
  GET_LEAVE_TYPE_CALCULATIONS = "GET_LEAVE_TYPE_CALCULATIONS",
  GET_LEAVE_REPORT = "GET_LEAVE_REPORT",
  // LeavePlan
  LEAVE_PLAN_REGISTER_SUCCESS = "LEAVE_PLAN_REGISTER_SUCCESS",
  GET_LEAVE_PLAN = "GET_LEAVE_PLAN",
  DELETE_LEAVE_PLAN = "DELETE_LEAVE_PLAN",
  UPDATE_LEAVE_PLAN = "UPDATE_LEAVE_PLAN",

  // LeaveRoleMapping
  LEAVE_ROLE_MAPPING_REGISTER_SUCCESS = "LEAVE_ROLE_MAPPING_REGISTER_SUCCESS",

  // APPLY LEAVE
  APPLY_LEAVE_REGISTER_SUCCESS = "APPLY_LEAVE_REGISTER_SUCCESS",
  GET_STAFF_LEAVE_TYPES = "GET_STAFF_LEAVE_TYPES",
  GET_STAFF_LEAVE_SLOTS = "GET_STAFF_LEAVE_SLOTS",
  GET_STAFF_LEAVE_APPORVAL = "GET_STAFF_LEAVE_APPORVAL",
  UPDATE_LEAVE_APPROVAL_SUCCESS = "UPDATE_LEAVE_APPROVAL_SUCCESS",
  GET_STAFF_LEAVE_APPORVAL_DETAILS = "GET_STAFF_LEAVE_APPORVAL_DETAILS",
  GET_STAFF_LEAVE_AVAILABLE_LEAVES = "GET_STAFF_LEAVE_AVAILABLE_LEAVES",
  GET_STAFF_LEAVE_AVAILABLE_LEAVES_SUCCESS = "GET_STAFF_LEAVE_AVAILABLE_LEAVES_SUCCESS",
  GET_STAFF_LEAVE_AVAILABLE_LEAVES_FAILURE = "GET_STAFF_LEAVE_AVAILABLE_LEAVES_FAILURE",
  //UPLOAD STAFF_ONBORDING_FILES
  UPLOAD_EDUCATION_FILES = 'UPLOAD_EDUCATION_FILES',
  UPLOAD_EXPERIANCE_FILES = 'UPLOAD_EXPERIANCE_FILES',
  CREATE_SUBSTITUTES = "CREATE_SUBSTITUTES",

  //FILTER_STUDENTS
  GET_FILTERD_STUDENTS = 'GET_FILTERD_STUDENTS',

  //FILTER_STAFF
  GET_FILTERD_STAFF = 'GET_FILTERD_STAFF',
  //ORGANIZATION_LOGO
  UPDATE_ORGANIZATION_LOGO = 'UPDATE_ORGANIZATION_LOGO',

  GET_SEARCHED_STUDENTS_FILTERED = 'GET_SEARCHED_STUDENTS_FILTERED',
  SHUFFLE_STUDENTS = 'SHUFFLE_STUDENTS',
  PROMOTE_STUDENTS = 'PROMOTE_STUDENTS',
  DEMOTE_STUDENTS = 'DEMOTE_STUDENTS',
  // STAFF TIMETABLE
  GET_STAFF_TIME_TABLES = "GET_STAFF_TIME_TABLES",

  //AWAIT_APPROVALS
  GET_AWAIT_APPROVALS = 'GET_AWAIT_APPROVALS',
  SEND_ACTION_FOR_APPROVALS = 'SEND_ACTION_FOR_APPROVALS',

  //BOARDS DATA
  GET_SCHOOL_BOARDS = "GET_SCHOOL_BOARDS",

  //CERTIFICATES
  ISSUE_CERTIFICATE_SUCCESS = "ISSUE_CERTIFICATE_SUCCESS",
  ISSUE_CERTIFICATE_FAIL = "ISSUE_CERTIFICATE_FAIL",
  ISSUE_TRANSFER_CERTIFICATE_SUCCESS = "ISSUE_TRANSFER_CERTIFICATE_SUCCESS",
  REPRINT_TRANSFER_CERTIFICATE_SUCCESS = "REPRINT_TRANSFER_CERTIFICATE_SUCCESS",
  REPRINT_CERTIFICATE_SUCCESS = " REPRINT_CERTIFICATE_SUCCESS",
  GET_TRANSFERRED_STUDENTS = "GET_TRANSFERRED_STUDENTS",
  GET_CERTIFIED_STUDENTS = "GET_CERTIFIED_STUDENTS",

  // ORG_SOURCES
  SOURCE_REGISTER_SUCCESS = "SOURCE_REGISTER_SUCCESS",
  SOURCE_REGISTER_FAIL = "SOURCE_REGISTER_FAIL",
  SOURCE_AUTH_ERROR = "SOURCE_AUTH_ERROR",
  GET_SOURCES = "GET_SOURCES",
  UPDATE_SOURCE = "UPDATE_SOURCE",
  DELETE_SOURCE = "DELETE_SOURCE",

  // STUDENT BULK UPLOAD
  STUDENT_BULK_UPLOAD = "STUDENT_BULK_UPLOAD",
  STUDENT_BULK_UPLOAD_VALIDATION = "STUDENT_BULK_UPLOAD_VALIDATION",
  STUDENT_BULK_UPLOAD_FAILURE = "STUDENT_BULK_UPLOAD_FAILURE",

  // STAFF BULK UPLOAD
  STAFF_BULK_UPLOAD = "STAFF_BULK_UPLOAD",
  STAFF_BULK_UPLOAD_FAILURE = "STAFF_BULK_UPLOAD_FAILURE",
  STAFF_BULK_UPLOAD_VALIDATION = "STAFF_BULK_UPLOAD_VALIDATION",

  // FINANCE BULKUPLOAD
  FINANCE_BULK_UPLOAD_FAILURE = "FINANCE_BULK_UPLOAD_FAILURE",
  FINANCE_BULK_UPLOAD = "FINANCE_BULK_UPLOAD",

  // NOTIFICATIONS
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  UPDATE_READ_NOTIFICATIONS = "UPDATE_READ_NOTIFICATIONS",
  UPDATE_OPEN_NOTIFICATIONS = "UPDATE_OPEN_NOTIFICATIONS",

  // ASSIGN TEACHER
  ASSIGN_CLASS_TEACHER = "ASSIGN_CLASS_TEACHER",
  ASSIGN_CLASS_TEACHER_FAILURE = "ASSIGN_CLASS_TEACHER_FAILURE",
  ASSIGN_SUBJECT_TEACHER = "ASSIGN_SUBJECT_TEACHER",
  ASSIGN_SUBJECT_TEACHER_FAILURE = "ASSIGN_SUBJECT_TEACHER_FAILURE",

  // SCHOOL GALLERY
  GET_SCHOOL_GALLERY = "GET_SCHOOL_GALLERY",
  GET_SCHOOL_GALLERY_FAILURE = "GET_SCHOOL_GALLERY_FAILURE",
  GET_SCHOOL_GALLERY_CATEGORIES = "GET_SCHOOL_GALLERY_CATEGORIES",
  GET_SCHOOL_GALLERY_CATEGORIES_FAILURE = "GET_SCHOOL_GALLERY_CATEGORIES_FAILURE",
  CREATE_SCHOOL_GALLERY = "CREATE_SCHOOL_GALLERY",
  CREATE_SCHOOL_GALLERY_FAILURE = "CREATE_SCHOOL_GALLERY_FAILURE",
  // ACTIVE STUDENT 
  GET_ACTIVESTUDENTS_DATA = "GET_ACTIVESTUDENTS_DATA",
  GET_ACTIVESTUDENTS_CARDS_DATA = "GET_ACTIVESTUDENTS_CARDS_DATA",

  // FEE RECEIPT MODIFICATION
  CANCEL_FEE_RECEIPT = "CANCEL_FEE_RECEIPT",
  GET_FEE_RECEIPT_MODIFICATION_REQUESTS = "GET_FEE_RECEIPT_MODIFICATION_REQUESTS",
  APPROVE_FEE_RECEIPT_MODIFICATION = "APPROVE_FEE_RECEIPT_MODIFICATION",

  // ORG CREDENTIALS
  GET_CREDENTIALS = "GET_CREDENTIALS",
  GET_CREDENTIALS_FAILURE = "CREATE_CREDENTIALS_FAILURE",
  CREATE_CREDENTIALS = "CREATE_CREDENTIALS",
  UPDATE_CREDENTIALS = "UPDATE_CREDENTIALS",

  // TEMPLATES
  GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES",
  GET_ALL_TEMPLATES_FAILURE = "GET_ALL_TEMPLATES_FAILURE",
  SELECT_TEMPLATE = "SELECT_TEMPLATE",

  // ADMISSION FORM FIELDS
  GET_ADMISSION_FORM_FIELDS = "GET_ADMISSION_FORM_FIELDS",
  GET_ADMISSION_FORM_FIELDS_FAILURE = "GET_ADMISSION_FORM_FIELDS_FAILURE",
  UPDATE_ADMISSION_FORM_FIELD = "UPDATE_ADMISSION_FORM_FIELD",

  // ANNOUNCEMENTS
  GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS",
  CREATE_ANNOUNCEMENT = "CREATE_ANNOUNCEMENT",
  CREATE_ANNOUNCEMENT_FAILURE = "CREATE_ANNOUNCEMENT_FAILURE",

  // ANNOUNCEMENT TYPES
  GET_ANNOUNCEMENT_TYPES = "GET_ANNOUNCEMENT_TYPES",
  CREATE_ANNOUNCEMENT_TYPE = "CREATE_ANNOUNCEMENT_TYPE",
  CREATE_ANNOUNCEMENT_TYPE_FAILURE = "CREATE_ANNOUNCEMENT_TYPE_FAILURE",
  UPDATE_ANNOUNCEMENT_TYPE = "UPDATE_ANNOUNCEMENT_TYPE",
  DELETE_ANNOUNCEMENT_TYPE = "DELETE_ANNOUNCEMENT_TYPE",

  // ANNOUNCEMENT SETTINGS
  GET_ANNOUNCEMENT_APPROVAL_SETTINGS = "GET_ANNOUNCEMENT_APPROVAL_SETTINGS",
  CREATE_ANNOUNCEMENT_SETTING = "CREATE_ANNOUNCEMENT_SETTING",
  CREATE_ANNOUNCEMENT_SETTING_FAILURE = "CREATE_ANNOUNCEMENT_SETTING_FAILURE",
  UPDATE_ANNOUNCEMENT_SETTING = "UPDATE_ANNOUNCEMENT_SETTING",
  DELETE_ANNOUNCEMENT_SETTING = "DELETE_ANNOUNCEMENT_SETTING",
  GET_ANNOUNCEMENT_APPROVALS = "GET_ANNOUNCEMENT_APPROVALS",
  GET_ANNOUNCEMENT_APPROVALS_FAILURE = "GET_ANNOUNCEMENT_APPROVALS_FAILURE",
  ACCEPT_REJECT_ANNOUNCEMENT_APPROVAL = "ACCEPT_REJECT_ANNOUNCEMENT_APPROVAL",

  // TRANSPORTATION VEHICLE DETAILS
  GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS",
  GET_VEHICLE_DETAILS_FIALED = "GET_VEHICLE_DETAILS_FIALED",
  CREATE_VEHICLE_DETAILS = "CREATE_VEHICLE_DETAILS",
  CREATE_VEHICLE_INSURANCE = "CREATE_VEHICLE_INSURANCE",
  CREATE_VEHICLE_FUELDETAILS = "CREATE_VEHICLE_FUELDETAILS",
  CREATE_VEHICLE_FITNESSDETAILS = "CREATE_VEHICLE_FITNESSDETAILS",
  CREATE_VEHICLE_REPAIRESANDBREAKDOWNDETAILS = "CREATE_VEHICLE_REPAIRESANDBREAKDOWNDETAILS",
  CREATE_VEHICLE_SERVICEHISTORY = "CREATE_VEHICLE_SERVICEHISTORY",
  CREATE_VEHICLE_POLLUTION = "CREATE_VEHICLE_POLLUTION",
  CREATE_VEHICLE_ROADTAX = "CREATE_VEHICLE_ROADTAX",
  DELETE_VEHICLE = "DELETE_VEHICLE",
  GETVEHICLEBYID = "GETVEHICLEBYID",
  UPDATEVEHICLE = "UPDATEVEHICLE",

  VEHICLE_AUTH_ERROR = "VEHICLE_AUTH_ERROR",

  // BRANCH LEVEL SETTINGS
  GET_NOTIFICATION_SETTINGS = "GET_NOTIFICATION_SETTINGS",
  UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS",
  UPDATE_NOTIFICATION_SETTINGS_FAILURE = "UPDATE_NOTIFICATION_SETTINGS_FAILURE",

  GET_SMS_RECHARGE = "GET_SMS_RECHARGE",

  // BUSROUTE
  BUSROUTE_AUTH_ERROR = "BUSROUTE_AUTH_ERROR",
  BUSROUTE_REGISTER_SUCCESS = "BUSROUTE_REGISTER_SUCCESS",
  BUSROUTE_REGISTER_FAIL = "BUSROUTE_REGISTER_FAIL",
  GET_BUSROUTES = "GET_BUSROUTES",
  UPDATE_BUSROUTE = "UPDATE_BUSROUTE",
  DELETE_BUSROUTE = "DELETE_BUSROUTE",

  GET_WEEKOFF_SETTINGS = "GET_WEEKOFF_SETTINGS",
  UPDATE_WEEKOFF_SETTINGS = "UPDATE_WEEKOFF_SETTINGS",
  UPDATE_WEEKOFF_SETTINGS_FAILURE = "UPDATE_WEEKOFF_SETTINGS_FAILURE",

  // ATTENDANCE SETTINGS
  GET_ATTENDANCE_SETTINGS = "GET_ATTENDANCE_SETTINGS",
  UPDATE_ATTENDANCE_SETTINGS = "UPDATE_ATTENDANCE_SETTINGS",
  UPDATE_ATTENDANCE_SETTINGS_FAILURE = "UPDATE_ATTENDANCE_SETTINGS_FAILURE",
  CREATE_ATTENDANCE_SETTINGS = "CREATE_ATTENDANCE_SETTINGS",
  GET_ATTENDANCE_SETTINGS_FAILURE = "GET_ATTENDANCE_SETTINGS_FAILURE",
  // BUSSTOP
  BUSSTOP_AUTH_ERROR = "BUSSTOP_AUTH_ERROR",
  BUSSTOP_REGISTER_SUCCESS = "BUSSTOP_REGISTER_SUCCESS",
  BUSSTOP_REGISTER_FAIL = "BUSSTOP_REGISTER_FAIL",
  GET_BUSSTOPS = "GET_BUSSTOPS",
  UPDATE_BUSSTOP = "UPDATE_BUSSTOP",
  DELETE_BUSSTOP = "DELETE_BUSSTOP",
  // VEHICLEROUTE
  VEHICLEROUTE_AUTH_ERROR = "VEHICLEROUTE_AUTH_ERROR",
  VEHICLEROUTE_REGISTER_SUCCESS = "VEHICLEROUTE_REGISTER_SUCCESS",
  VEHICLEROUTE_REGISTER_FAIL = "VEHICLEROUTE_REGISTER_FAIL",
  GET_VEHICLEROUTES = "GET_VEHICLEROUTES",
  GET_ROUTEPOINTS_BY_ID = "GET_ROUTEPOINTS_BY_ID",
  UPDATE_VEHICLEROUTE = "UPDATE_VEHICLEROUTE",
  DELETE_VEHICLEROUTE = "DELETE_VEHICLEROUTE",

  // CREATE VEHICLE ROUTE FEE

  CREATE_VEHICLE_ROUTE_FEE = "CREATE_VEHICLE_ROUTE_FEE",
  UPDATE_VEHICLE_ROUTE_FEE = "UPDATE_VEHICLE_ROUTE_FEE",
  DELETE_VEHICLE_ROUTE_FEE = "DELETE_VEHICLE_ROUTE_FEE",
  GET_VEHICLE_ROUTE_FEE = "GET_VEHICLE_ROUTE_FEE",
  // VEHICLE DRIVERS

  CREATE_VEHICLE_DRIVER = "CREATE_VEHICLE_DRIVER",
  UPDATE_VEHICLE_DRIVER = "UPDATE_VEHICLE_DRIVER",
  DELETE_VEHICLE_DRIVER = "DELETE_VEHICLE_DRIVER",
  GET_VEHICLE_DRIVERS = "GET_VEHICLE_DRIVERS",

  // ASSIGNROUTETOSTUDENT
  ASSIGNROUTETOSTUDENT_AUTH_ERROR = "ASSIGNROUTETOSTUDENT_AUTH_ERROR",
  ASSIGNROUTETOSTUDENT_REGISTER_SUCCESS = "ASSIGNROUTETOSTUDENT_REGISTER_SUCCESS",
  ASSIGNROUTETOSTUDENT_REGISTER_FAIL = "ASSIGNROUTETOSTUDENT_REGISTER_FAIL",
  GET_ASSIGNROUTETOSTUDENTS = "GET_ASSIGNROUTETOSTUDENTS",
  GET_TRANSORTFEE_STUDENTS = "GET_TRANSORTFEE_STUDENTS",
  UPDATE_ASSIGNROUTETOSTUDENT = "UPDATE_ASSIGNROUTETOSTUDENT",
  DELETE_ASSIGNROUTETOSTUDENT = "DELETE_ASSIGNROUTETOSTUDENT",

  // SMS DELIVERY REPORT
  GET_SMS_DELIVERY_REPORT = "GET_SMS_DELIVERY_REPORT",
  GET_SMS_DELIVERY_REPORT_BY_SMSTYPE = "GET_SMS_DELIVERY_REPORT_BY_SMSTYPE",
  GET_SMS_DELIVERY_REPORT_BY_SMSTYPE_DATA = "GET_SMS_DELIVERY_REPORT_BY_SMSTYPE_DATA",

  // EXPENSE CATEGORIES
  EXPENSE_CATEGORY_REGISTER_SUCCESS = "EXPENSE_CATEGORY_REGISTER_SUCCESS",
  EXPENSE_CATEGORY_REGISTER_FAIL = "EXPENSE_CATEGORY_REGISTER_FAIL",
  GET_EXPENSE_CATEGORIES = "GET_EXPENSE_CATEGORIES",
  UPDATE_EXPENSE_CATEGORIES = "UPDATE_EXPENSE_CATEGORIES",
  DELETE_EXPENSE_CATEGORIES = "DELETE_EXPENSE_CATEGORIES",
  // ALL EXPENSES
  GET_ALL_EXPENSES_REQUEST = "GET_ALL_EXPENSES_REQUEST",
  GET_ALL_EXPENSES_SUCCESS = "GET_ALL_EXPENSES_SUCCESS",
  GET_ALL_EXPENSES_FAILURE = "GET_ALL_EXPENSES_FAILURE",
  CREATE_EXPENSE_REQUEST = "CREATE_EXPENSE_REQUEST",
  CREATE_EXPENSE_SUCCESS = "CREATE_EXPENSE_SUCCESS",
  CREATE_EXPENSE_FAILURE = "CREATE_EXPENSE_FAILURE",
  //EXPENSE TRANSACTIONS
  GET_EXPENSE_TRANSACTIONS_REQUEST="GET_EXPENSE_TRANSACTIONS_REQUEST",
  GET_EXPENSE_TRANSACTIONS_SUCCESS="GET_EXPENSE_TRANSACTIONS_SUCCESS",
  GET_EXPENSE_TRANSACTIONS_FAILURE="GET_EXPENSE_TRANSACTIONS_FAILURE",

  // LEDGER BOOK 
  GET_INCOMES_REQUEST = "GET_INCOMES_REQUEST",
  GET_INCOMES_SUCCESS = "GET_INCOMES_SUCCESS",
  GET_INCOMES_FAILURE = "GET_INCOMES_FAILURE",
  GET_EXPENDITURES_REQUEST ="GET_EXPENDITURES_REQUEST",
  GET_EXPENDITURES_SUCCESS = "GET_EXPENDITURES_SUCCESS",
  GET_EXPENDITURES_FAILURE = "GET_EXPENDITURES_FAILURE",
  GET_LEDGER_TRANSACTIONS_REQUEST ="GET_LEDGER_TRANSACTIONS_REQUEST",
  GET_LEDGER_TRANSACTIONS_SUCCESS = "GET_LEDGER_TRANSACTIONS_SUCCESS",
  GET_LEDGER_TRANSACTIONS_FAILURE = "GET_LEDGER_TRANSACTIONS_FAILURE",
  GET_METHOD_WISE_INCOME_REQUEST = "GET_METHOD_WISE_INCOME_REQUEST",
  GET_METHOD_WISE_INCOME_SUCCESS = "GET_METHOD_WISE_INCOME_SUCCESS",
  GET_METHOD_WISE_INCOME_FAILURE = "GET_METHOD_WISE_INCOME_FAILURE",
  GET_METHOD_WISE_TRANSACTIONS_REQUEST = "GET_METHOD_WISE_TRANSACTIONS_REQUEST",
  GET_METHOD_WISE_TRANSACTIONS_SUCCESS = "GET_METHOD_WISE_TRANSACTIONS_SUCCESS",
  GET_METHOD_WISE_TRANSACTIONS_FAILURE ="GET_METHOD_WISE_TRANSACTIONS_FAILURE",

  // EXPENSE SEQUENCE
  EXPENSE_SEQUENCE_FETCH = "EXPENSE_SEQUENCE_FETCH",
  EXPENSE_SEQUENCE_ACTION = "EXPENSE_SEQUENCE_ACTION",
  GET_EXPENSE_SEQUENCE_SUCCESS = "GET_EXPENSE_SEQUENCE_SUCCESS",
  GET_EXPENSE_SEQUENCE_FAILURE = "GET_EXPENSE_SEQUENCE_FAILURE",
  EXPENSE_SEQUENCE_CREATE_SUCCESS = "EXPENSE_SEQUENCE_CREATE_SUCCESS",
  EXPENSE_SEQUENCE_CREATE_FAILURE = "EXPENSE_SEQUENCE_CREATE_FAILURE",
  EXPENSE_SEQUENCE_UPDATE_SUCCESS = "EXPENSE_SEQUENCE_UPDATE_SUCCESS",
  EXPENSE_SEQUENCE_UPDATE_FAILURE = "EXPENSE_SEQUENCE_UPDATE_FAILURE",

  // Manual Staff Attendance
  GET_MANUAL_STAFF_ATTENDANCE = "GET_MANUAL_STAFF_ATTENDANCE",
  CREATE_MANUAL_STAFF_ATTENDANCE = "CREATE_MANUAL_STAFF_ATTENDANCE",

  // Sessions Setting 
  GET_STAFF_SESSION_SETTINGS = "GET_STAFF_SESSION_SETTINGS",
  CREATE_STAFF_SESSION_SETTINGS = "CREATE_STAFF_SESSION_SETTINGS",
  UPDATE_STAFF_SESSION_SETTINGS = "UPDATE_STAFF_SESSION_SETTINGS",


  // EXAMS
  ASSESSMENT_REGISTER_SUCCESS = "ASSESSMENT_REGISTER_SUCCESS",
  GET_ASSESSMENTS = "GET_ASSESSMENTS",
  UPDATE_ASSESSMENTS = "UPDATE_ASSESSMENTS",
  DELETE_ASSESSMENTS = "DELETE_ASSESSMENTS",
  DELETE_COSCHOLSTIC = "DELETE_COSCHOLSTIC",
  COSCHOLSTIC_REGISTER_SUCCESS = "COSCHOLSTIC_REGISTER_SUCCESS",
  GET_COSCHOLSTIC = "GET_COSCHOLSTIC",
  UPDATE_COSCHOLSTIC = "UPDATE_COSCHOLSTIC",
  GET_TERMS = "GET_TERMS",
  // MANUAL RECEIPT SETTINGS
  GET_MANUAL_RECEIPT_SETTINGS = "GET_MANUAL_RECEIPT_SETTINGS",
  // EXAM STRUCTURE
  GET_ALL_EXAM_STRUCTURE_REQUEST = "GET_ALL_EXAM_STRUCTURE_REQUEST",
  GET_ALL_EXAM_STRUCTURE_SUCCESS = "GET_ALL_EXAM_STRUCTURE_SUCCESS",
  GET_ALL_EXAM_STRUCTURE_FAILURE = "GET_ALL_EXAM_STRUCTURE_FAILURE",
  CREATE_EXAM_STRUCTURE_REQUEST = "CREATE_EXAM_STRUCTURE_REQUEST",
  CREATE_EXAM_STRUCTURE_SUCCESS = "CREATE_EXAM_STRUCTURE_SUCCESS",
  CREATE_EXAM_STRUCTURE_FAILURE = "CREATE_EXAM_STRUCTURE_FAILURE",
  UPDATE_EXAM_STRUCTURE_REQUEST = "UPDATE_EXAM_STRUCTURE_REQUEST",
  UPDATE_EXAM_STRUCTURE_SUCCESS = "UPDATE_EXAM_STRUCTURE_SUCCESS",
  UPDATE_EXAM_STRUCTURE_FAILURE = "UPDATE_EXAM_STRUCTURE_FAILURE",
  DELETE_EXAM_STRUCTURE = "DELETE_EXAM_STRUCTURE",
  DELETE_EXAM_TYPE = "DELETE_EXAM_TYPE",
  GET_EXAM_STRUCTURE_BY_ID = 'GET_EXAM_STRUCTURE_BY_ID',
  SUBSKILLS_REGISTER_SUCCESS = "SUBSKILLS_REGISTER_SUCCESS",
  GET_SUBSKILLS = "GET_SUBSKILLS",
  UPDATE_SUBSKILLS = "UPDATE_SUBSKILLS",
  DELETE_SUBSKILLS = "DELETE_SUBSKILLS",
  //GRADE GROUP SCHOLASTIC
  GET_GRADE_GROUP_SCHOLASTIC_REQUEST="GET_GRADE_GROUP_SCHOLASTIC_REQUEST",
  GET_GRADE_GROUP_SCHOLASTIC_SUCCESS="GET_GRADE_GROUP_SCHOLASTIC_SUCCESS",
  GET_GRADE_GROUP_SCHOLASTIC_FAILURE="GET_GRADE_GROUP_SCHOLASTIC_FAILURE",
  CREATE_GRADE_GROUP_SCHOLASTIC_REQUEST="CREATE_GRADE_GROUP_SCHOLASTIC_REQUEST",
  CREATE_GRADE_GROUP_SCHOLASTIC_SUCCESS="CREATE_GRADE_GROUP_SCHOLASTIC_SUCCESS",
  CREATE_GRADE_GROUP_SCHOLASTIC_FAILURE="CREATE_GRADE_GROUP_SCHOLASTIC_FAILURE",
  UPDATE_GRADE_GROUP_SCHOLASTIC_REQUEST="UPDATE_GRADE_GROUP_SCHOLASTIC_REQUEST",
  UPDATE_GRADE_GROUP_SCHOLASTIC_SUCCESS="UPDATE_GRADE_GROUP_SCHOLASTIC_SUCCESS",
  UPDATE_GRADE_GROUP_SCHOLASTIC_FAILURE="UPDATE_GRADE_GROUP_SCHOLASTIC_FAILURE",
  DELETE_GRADE_GROUP_SCHOLASTIC_REQUEST="DELETE_GRADE_GROUP_SCHOLASTIC_REQUEST",
  DELETE_GRADE_GROUP_SCHOLASTIC_SUCCESS="DELETE_GRADE_GROUP_SCHOLASTIC_SUCCESS",
  DELETE_GRADE_GROUP_SCHOLASTIC_FAILURE="DELETE_GRADE_GROUP_SCHOLASTIC_FAILURE",
  //GRADE GROUP CO_SCHOLASTIC
  GET_GRADE_GROUP_CO_SCHOLASTIC_REQUEST="GET_GRADE_GROUP_CO_SCHOLASTIC_REQUEST",
  GET_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS="GET_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS",
  GET_GRADE_GROUP_CO_SCHOLASTIC_FAILURE="GET_GRADE_GROUP_CO_SCHOLASTIC_FAILURE",
  CREATE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST="CREATE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST",
  CREATE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS="CREATE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS",
  CREATE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE="CREATE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE",
  UPDATE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST="UPDATE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST",
  UPDATE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS="UPDATE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS",
  UPDATE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE="UPDATE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE",
  DELETE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST="DELETE_GRADE_GROUP_CO_SCHOLASTIC_REQUEST",
  DELETE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS="DELETE_GRADE_GROUP_CO_SCHOLASTIC_SUCCESS",
  DELETE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE="DELETE_GRADE_GROUP_CO_SCHOLASTIC_FAILURE",
  

  // EXAM SCHEDULE
  GET_ALL_EXAM_SCHEDULE_REQUEST = "GET_ALL_EXAM_SCHEDULE_REQUEST",
  GET_ALL_EXAM_SCHEDULE_SUCCESS = "GET_ALL_EXAM_SCHEDULE_SUCCESS",
  GET_ALL_EXAM_SCHEDULE_FAILURE = "GET_ALL_EXAM_SCHEDULE_FAILURE",
  CREATE_EXAM_SCHEDULE_REQUEST = "CREATE_EXAM_SCHEDULE_REQUEST",
  CREATE_EXAM_SCHEDULE_SUCCESS = "CREATE_EXAM_SCHEDULE_SUCCESS",
  CREATE_EXAM_SCHEDULE_FAILURE = "CREATE_EXAM_SCHEDULE_FAILURE",
  UPDATE_EXAM_SCHEDULE_REQUEST = "UPDATE_EXAM_SCHEDULE_REQUEST",
  UPDATE_EXAM_SCHEDULE_SUCCESS = "UPDATE_EXAM_SCHEDULE_SUCCESS",
  UPDATE_EXAM_SCHEDULE_FAILURE = "UPDATE_EXAM_SCHEDULE_FAILURE",
  DELETE_EXAM_SCHEDULE = "DELETE_EXAM_SCHEDULE",
  DELETE_ASSESSMENT = "DELETE_ASSESSMENT",
  GET_EXAM_SCHEDULE_BY_ID = 'GET_EXAM_SCHEDULE_BY_ID',
  GET_ALL_EXAM_TYPES = "GET_ALL_EXAM_TYPES",

  // EXAM RULE SETUP
  GET_ALL_EXAM_RULE_SETUP_REQUEST = "GET_ALL_EXAM_RULE_SETUP_REQUEST",
  GET_ALL_EXAM_RULE_SETUP_SUCCESS = "GET_ALL_EXAM_RULE_SETUP_SUCCESS",
  GET_ALL_EXAM_RULE_SETUP_FAILURE = "GET_ALL_EXAM_RULE_SETUP_FAILURE",
  CREATE_EXAM_RULE_SETUP_REQUEST = "CREATE_EXAM_RULE_SETUP_REQUEST",
  CREATE_EXAM_RULE_SETUP_SUCCESS = "CREATE_EXAM_RULE_SETUP_SUCCESS",
  CREATE_EXAM_RULE_SETUP_FAILURE = "CREATE_EXAM_RULE_SETUP_FAILURE",
  UPDATE_EXAM_RULE_SETUP_REQUEST = "UPDATE_EXAM_RULE_SETUP_REQUEST",
  UPDATE_EXAM_RULE_SETUP_SUCCESS = "UPDATE_EXAM_RULE_SETUP_SUCCESS",
  UPDATE_EXAM_RULE_SETUP_FAILURE = "UPDATE_EXAM_RULE_SETUP_FAILURE",
  DELETE_EXAM_RULE_SETUP = "DELETE_EXAM_RULE_SETUP",
  GET_EXAM_RULE_SETUP_BY_ID = 'GET_EXAM_RULE_SETUP_BY_ID',

  // SCHOLASTIC MARKS ALLOCATION
  GET_SCHOLASTIC_MARKS_STUDENTS_REQUEST = "GET_SCHOLASTIC_MARKS_STUDENTS_REQUEST",
  GET_SCHOLASTIC_MARKS_STUDENTS_SUCCESS = "GET_SCHOLASTIC_MARKS_STUDENTS_SUCCESS",
  GET_SCHOLASTIC_MARKS_STUDENTS_FAILURE = "GET_SCHOLASTIC_MARKS_STUDENTS_FAILURE",
  ASSIGN_SCHOLASTIC_MARKS_REQUEST = "ASSIGN_SCHOLASTIC_MARKS_REQUEST",
  ASSIGN_SCHOLASTIC_MARKS_SUCCESS = "ASSIGN_SCHOLASTIC_MARKS_SUCCESS",
  ASSIGN_SCHOLASTIC_MARKS_FAILURE = "ASSIGN_SCHOLASTIC_MARKS_FAILURE",
  GET_SCHOLASTIC_SUBJECTS = "GET_SCHOLASTIC_SUBJECTS",

    // INFRASTRUCTUREMANAGEMENT
    INFRASTRUCTUREMANAGEMENT_AUTH_ERROR = "CASHFLOWACCOUNT_AUTH_ERROR",
    INFRASTRUCTUREMANAGEMENT_REGISTER_SUCCESS = "INFRASTRUCTUREMANAGEMENT_REGISTER_SUCCESS",
    INFRASTRUCTUREMANAGEMENT_REGISTER_FAIL = "INFRASTRUCTUREMANAGEMENT_REGISTER_FAIL",
    GET_INFRASTRUCTUREMANAGEMENTS = "GET_INFRASTRUCTUREMANAGEMENTS",
    GET_FLOORROOMS = "GET_FLOORROOMS",

    UPDATE_INFRASTRUCTUREMANAGEMENT = "UPDATE_INFRASTRUCTUREMANAGEMENT",
    DELETE_INFRASTRUCTUREMANAGEMENT = "DELETE_INFRASTRUCTUREMANAGEMENT",

      // ROOMSELECTIONGENERAL
      ROOMSELECTIONGENERAL_AUTH_ERROR = "ROOMSELECTIONGENERAL_AUTH_ERROR",
      ROOMSELECTIONGENERAL_REGISTER_SUCCESS = "ROOMSELECTIONGENERAL_REGISTER_SUCCESS",
      ROOMSELECTIONGENERAL_REGISTER_FAIL = "ROOMSELECTIONGENERAL_REGISTER_FAIL",
      GET_ROOMSELECTIONGENERALS = "GET_ROOMSELECTIONGENERALS",
  
      UPDATE_ROOMSELECTIONGENERAL = "UPDATE_ROOMSELECTIONGENERAL",
      DELETE_ROOMSELECTIONGENERAL = "DELETE_ROOMSELECTIONGENERAL",
  // MARKS ALLOCATION CO_SCHOLASTIC
  GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_REQUEST="GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_REQUEST",
  GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_SUCCESS="GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_SUCCESS",
  GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_FAILURE="GET_COSCHOLASTIC_MARKS_ALLOCATION_STUDENTS_FAILURE",
  ASSIGN_COSCHOLASTIC_MARKS_REQUEST="ASSIGN_COSCHOLASTIC_MARKS_REQUEST",
  ASSIGN_COSCHOLASTIC_MARKS_SUCCESS="ASSIGN_COSCHOLASTIC_MARKS_SUCCESS",
  ASSIGN_COSCHOLASTIC_MARKS_FAILURE="ASSIGN_COSCHOLASTIC_MARKS_FAILURE",

  
      // ROOMSELECTIONEXAMS
      ROOMSELECTIONEXAMS_AUTH_ERROR = "ROOMSELECTIONEXAMS_AUTH_ERROR",
      ROOMSELECTIONEXAMS_REGISTER_SUCCESS = "ROOMSELECTIONEXAMS_REGISTER_SUCCESS",
      ROOMSELECTIONEXAMS_REGISTER_FAIL = "ROOMSELECTIONEXAMS_REGISTER_FAIL",
      GET_ROOMSELECTIONEXAMS = "GET_ROOMSELECTIONEXAMS",
  
      UPDATE_ROOMSELECTIONEXAMS = "UPDATE_ROOMSELECTIONEXAMS",
      DELETE_ROOMSELECTIONEXAMS = "DELETE_ROOMSELECTIONEXAMS",
  // REPORT CARD
  GET_STUDENTS_MARKS = "GET_STUDENTS_MARKS",
  GET_REPORT_CARD_SUCCESS = "GET_REPORT_CARD_SUCCESS",
  GET_REPORT_CARD_FAILURE = "GET_REPORT_CARD_FAILURE",
  ADD_REPORT_CARD_REMARK = "ADD_REPORT_CARD_REMARK",
 
   // REPORT CARD TEMPLATE
   GET_REPORT_CARD_TEMPLATE_REQUEST="GET_REPORT_CARD_TEMPLATE_REQUEST",
   GET_REPORT_CARD_TEMPLATE_SUCCESS="GET_REPORT_CARD_TEMPLATE_SUCCESS",
   GET_REPORT_CARD_TEMPLATE_FAILURE="GET_REPORT_CARD_TEMPLATE_FAILURE",
   CREATE_REPORT_CARD_TEMPLATE_REQUEST="CREATE_REPORT_CARD_TEMPLATE_REQUEST",
   CREATE_REPORT_CARD_TEMPLATE_SUCCESS="CREATE_REPORT_CARD_TEMPLATE_SUCCESS",
   CREATE_REPORT_CARD_TEMPLATE_FAILURE="CREATE_REPORT_CARD_TEMPLATE_FAILURE",
   UPDATE_REPORT_CARD_TEMPLATE_REQUEST = "UPDATE_REPORT_CARD_TEMPLATE_REQUEST",
  UPDATE_REPORT_CARD_TEMPLATE_SUCCESS = "UPDATE_REPORT_CARD_TEMPLATE_SUCCESS",
  UPDATE_REPORT_CARD_TEMPLATE_FAILURE = "UPDATE_REPORT_CARD_TEMPLATE_FAILURE",
  DELETE_REPORT_CARD_TEMPLATE = "DELETE_REPORT_CARD_TEMPLATE",

  // MARKS APPROVAL
  GET_MARKS_APPROVAL_MARKS_REQUEST="GET_MARKS_APPROVAL_MARKS_REQUEST",
  GET_MARKS_APPROVAL_MARKS_SUCCESS="GET_MARKS_APPROVAL_MARKS_SUCCESS",
  GET_MARKS_APPROVAL_MARKS_FAILURE="GET_MARKS_APPROVAL_MARKS_FAILURE",
  GET_MARKS_APPROVAL_STUDENT_MARKS_REQUEST="GET_MARKS_APPROVAL_STUDENT_MARKS_REQUEST",
  GET_MARKS_APPROVAL_STUDENT_MARKS_SUCCESS="GET_MARKS_APPROVAL_STUDENT_MARKS_SUCCESS",
  GET_MARKS_APPROVAL_STUDENT_MARKS_FAILURE="GET_MARKS_APPROVAL_STUDENT_MARKS_FAILURE",
  POST_APPROVAL_ACTION_REQUEST="POST_APPROVAL_ACTION_REQUEST",
  POST_APPROVAL_ACTION_SUCCESS="POST_APPROVAL_ACTION_SUCCESS",
  POST_APPROVAL_ACTION_FAILURE="POST_APPROVAL_ACTION_FAILURE",

  // STUDENT WISE DUE REPORT 

  GET_STUDENT_WISE_DUE_REPORT = "GET_STUDENT_WISE_DUE_REPORT",
  GET_STUDENT_WISE_DUE_REPORT_REQUEST = "GET_STUDENT_WISE_DUE_REPORT_REQUEST",
  GET_STUDENT_WISE_DUE_REPORT_FAILURE = "GET_STUDENT_WISE_DUE_REPORT_FAILURE",
 
  //DYNAMIC FORM
  GET_DYNAMIC_FORM_REQUEST="GET_DYNAMIC_FORM_REQUEST",
  GET_DYNAMIC_FORM_SUCCESS="GET_DYNAMIC_FORM_SUCCESS",
  GET_DYNAMIC_FORM_FAILURE="GET_DYNAMIC_FORM_FAILURE",
  GET_DYNAMIC_FORM_DATA_REQUEST="GET_DYNAMIC_FORM_DATA_REQUEST",
  GET_DYNAMIC_FORM_DATA_SUCCESS="GET_DYNAMIC_FORM_DATA_SUCCESS",
  GET_DYNAMIC_FORM_DATA_FAILURE="GET_DYNAMIC_FORM_DATA_FAILURE",

 // INVENTORY VENDER
  GET_INVENTORY_VENDORS = "GET_INVENTORY_VENDORS",
  GET_INVENTORY_VENDORS_SUCCESS = "GET_INVENTORY_VENDORS_SUCCESS",
  GET_INVENTORY_VENDORS_FAILURE = "GET_INVENTORY_VENDORS_FAILURE",
  CREATE_INVENTORY_VENDOR = "CREATE_INVENTORY_VENDER",
  CREATE_INVENTORY_VENDOR_SUCCESS = "CREATE_INVENTORY_VENDOR_SUCCESS",
  CREATE_INVENTORY_VENDOR_FAILURE = "CREATE_INVENTORY_VENDOR_FAILURE",
  UPDATE_INVENTORY_VENDOR = "UPDATE_INVENTORY_VENDER",
  UPDATE_INVENTORY_VENDOR_SUCCESS = "UPDATE_INVENTORY_VENDOR_SUCCESS",
  UPDATE_INVENTORY_VENDOR_FAILURE = "UPDATE_INVENTORY_VENDOR_FAILURE",
  DELETE_INVENTORY_VENDOR = "DELETE_INVENTORY_VENDER",
  DELETE_INVENTORY_VENDOR_SUCCESS = "DELETE_INVENTORY_VENDOR_SUCCESS",
  DELETE_INVENTORY_VENDOR_FAILURE = "DELETE_INVENTORY_VENDOR_FAILURE",


  // ITEM CREATION
  GET_ITEM_CREATION_ITEMS = "GET_ITEM_CREATION_ITEMS",
  GET_ITEM_CREATION_ITEMS_REQUEST = "GET_ITEM_CREATION_ITEMS_REQUEST",
  GET_ITEM_CREATION_ITEMS_SUCCESS = "GET_ITEM_CREATION_ITEMS_SUCCESS",
  GET_ITEM_CREATION_ITEMS_FAILURE = "GET_ITEM_CREATION_ITEMS_FAILURE",
  CREATE_ITEM_CREATION_ITEM = "CREATE_ITEM_CREATION_ITEM",
  CREATE_ITEM_CREATION_ITEM_SUCCESS = "CREATE_ITEM_CREATION_ITEM_SUCCESS",
  CREATE_ITEM_CREATION_ITEM_FAILURE = "CREATE_ITEM_CREATION_ITEM_FAILURE",
  UPDATE_ITEM_CREATION_ITEM = "UPDATE_ITEM_CREATION_ITEM",
  UPDATE_ITEM_CREATION_ITEM_SUCCESS = "UPDATE_ITEM_CREATION_ITEM_SUCCESS",
  UPDATE_ITEM_CREATION_ITEM_FAILURE = "UPDATE_ITEM_CREATION_ITEM_FAILURE",
  DELETE_ITEM_CREATION_ITEM = "DELETE_ITEM_CREATION_ITEM",
  DELETE_ITEM_CREATION_ITEM_SUCCESS = "DELETE_ITEM_CREATION_ITEM_SUCCESS",
  DELETE_ITEM_CREATION_ITEM_FAILURE = "DELETE_ITEM_CREATION_ITEM_FAILURE",
  GET_STOCK_ITEMS = "GET_STOCK_ITEMS",
  GET_ITEMS_BY_STOREID = "GET_ITEMS_BY_STOREID",

   //INVENTORY UNIT
  GET_ALL_INPUT_FIELD_REQUEST="GET_ALL_INPUT_FIELD_REQUEST",
  GET_ALL_INPUT_FIELD_SUCCESS="GET_ALL_INPUT_FIELD_SUCCESS",
  GET_ALL_INPUT_FIELD_FAILURE="GET_ALL_INPUT_FIELD_FAILURE",
  CREATE_INPUT_FIELD_REQUEST = "CREATE_INPUT_FIELD_REQUEST",
  CREATE_INPUT_FIELD_SUCCESS = "CREATE_INPUT_FIELD_SUCCESS",
  CREATE_INPUT_FIELD_FAILURE = "CREATE_INPUT_FIELD_FAILURE",
  UPDATE_INPUT_FIELD_REQUEST = "UPDATE_INPUT_FIELD_REQUEST",
  UPDATE_INPUT_FIELD_SUCCESS = "UPDATE_INPUT_FIELD_SUCCESS",
  UPDATE_INPUT_FIELD_FAILURE = "UPDATE_INPUT_FIELD_FAILURE",
  DELETE_INPUT_FIELD = "DELETE_INPUT_FIELD",
  GET_INPUT_FIELD_BY_ID = 'GET_INPUT_FIELD_BY_ID',

  //INVENTORY CATEGORY
  GET_ALL_CATEGORY_REQUEST="GET_ALL_CATEGORY_REQUEST",
  GET_ALL_CATEGORY_SUCCESS="GET_ALL_CATEGORY_SUCCESS",
  GET_ALL_CATEGORY_FAILURE="GET_ALL_CATEGORY_FAILURE",
  CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST",
  CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE",
  UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST",
  UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID',

  //INVENTORY SUB CATEGORY
  GET_ALL_SUB_CATEGORY_REQUEST="GET_ALL_SUB_CATEGORY_REQUEST",
  GET_ALL_SUB_CATEGORY_SUCCESS="GET_ALL_SUB_CATEGORY_SUCCESS",
  GET_ALL_SUB_CATEGORY_FAILURE="GET_ALL_SUB_CATEGORY_FAILURE",
  GET_SUB_CATEGORY_BY_CATEGORY_SUCCESS = "GET_SUB_CATEGORY_BY_CATEGORY_SUCCESS",
  CREATE_SUB_CATEGORY_REQUEST = "CREATE_SUB_CATEGORY_REQUEST",
  CREATE_SUB_CATEGORY_SUCCESS = "CREATE_SUB_CATEGORY_SUCCESS",
  CREATE_SUB_CATEGORY_FAILURE = "CREATE_SUB_CATEGORY_FAILURE",
  UPDATE_SUB_CATEGORY_REQUEST = "UPDATE_SUB_CATEGORY_REQUEST",
  UPDATE_SUB_CATEGORY_SUCCESS = "UPDATE_SUB_CATEGORY_SUCCESS",
  UPDATE_SUB_CATEGORY_FAILURE = "UPDATE_SUB_CATEGORY_FAILURE",
  DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY",
  GET_SUB_CATEGORY_BY_ID = 'GET_SUB_CATEGORY_BY_ID',

  //INVENTORY STORE CREATION 
  GET_ALL_STORES_REQUEST="GET_ALL_STORES_REQUEST",
  GET_ALL_STORES_SUCCESS="GET_ALL_STORES_SUCCESS",
  GET_ALL_STORES_FAILURE="GET_ALL_STORES_FAILURE",
  CREATE_STORE_REQUEST = "CREATE_STORE_REQUEST",
  CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS",
  CREATE_STORE_FAILURE = "CREATE_STORE_FAILURE",
  UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST",
  UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS",
  UPDATE_STORE_FAILURE = "UPDATE_STORE_FAILURE",
  DELETE_STORE = "DELETE_STORE",
  GET_STORE_BY_ID = 'GET_STORE_BY_ID',
  GET_ALL_CATEGORIES_BY_STOREID_REQUEST = "GET_ALL_CATEGORIES_BY_STOREID_REQUEST",
  GET_ALL_CATEGORIES_BY_STOREID ="GET_ALL_CATEGORIES_BY_STOREID",
  GET_ALL_ITEMS_BY_CATEGORY_SUBCATEGORY = "GET_ALL_ITEMS_BY_CATEGORY_SUBCATEGORY",

  //INVENTORY STOCK CREATION 
  GET_ALL_STOCKS_REQUEST="GET_ALL_STOCKS_REQUEST",
  GET_ALL_STOCKS_SUCCESS="GET_ALL_STOCKS_SUCCESS",
  GET_ALL_STOCKS_FAILURE="GET_ALL_STOCKS_FAILURE",
  CREATE_STOCK_REQUEST = "CREATE_STOCK_REQUEST",
  CREATE_STOCK_SUCCESS = "CREATE_STOCK_SUCCESS",
  CREATE_STOCK_FAILURE = "CREATE_STOCK_FAILURE",
  UPDATE_STOCK_REQUEST = "UPDATE_STOCK_REQUEST",
  UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS",
  UPDATE_STOCK_FAILURE = "UPDATE_STOCK_FAILURE",
  DELETE_STOCK = "DELETE_STOCK",

  //INVENTORY KIT CREATION 
  GET_ALL_KITS_REQUEST="GET_ALL_KITS_REQUEST",
  GET_ALL_KITS_SUCCESS="GET_ALL_KITS_SUCCESS",
  GET_ALL_KITS_FAILURE="GET_ALL_KITS_FAILURE",
  CREATE_KIT_REQUEST = "CREATE_KIT_REQUEST",
  CREATE_KIT_SUCCESS = "CREATE_KIT_SUCCESS",
  CREATE_KIT_FAILURE = "CREATE_KIT_FAILURE",
  UPDATE_KIT_REQUEST = "UPDATE_KIT_REQUEST",
  UPDATE_KIT_SUCCESS = "UPDATE_KIT_SUCCESS",
  UPDATE_KIT_FAILURE = "UPDATE_KIT_FAILURE",
  DELETE_KIT = "DELETE_KIT",
  GET_KIT_BY_ID = 'GET_KIT_BY_ID',


  //INVENTORY SERVICE CREATION

  GET_SERVICE_CREATION_SERVICES_REQUEST = "GET_SERVICE_CREATION_SERVICES_REQUEST",
  GET_SERVICE_CREATION_SERVICES_SUCCESS = "GET_SERVICE_CREATION_SERVICES_SUCCESS",
  GET_SERVICE_CREATION_SERVICES_FAILURE = "GET_SERVICE_CREATION_SERVICES_FAILURE",
  CREATE_SERVICE_CREATION_SERVICE_REQUEST = "CREATE_SERVICE_CREATION_SERVICE_REQUEST",
  CREATE_SERVICE_CREATION_SERVICE_SUCCESS = "CREATE_SERVICE_CREATION_SERVICE_SUCCESS",
  CREATE_SERVICE_CREATION_SERVICE_FAILURE = "CREATE_SERVICE_CREATION_SERVICE_FAILURE",
  UPDATE_SERVICE_CREATION_SERVICE_REQUEST = "UPDATE_SERVICE_CREATION_SERVICE_REQUEST",
  UPDATE_SERVICE_CREATION_SERVICE_SUCCESS = "UPDATE_SERVICE_CREATION_SERVICE_SUCCESS",
  UPDATE_SERVICE_CREATION_SERVICE_FAILURE = "UPDATE_SERVICE_CREATION_SERVICE_FAILURE",
  DELETE_SERVICE_CREATION_SERVICE = "DELETE_SERVICE_CREATION_SERVICE",

  //INVENTORY STOCK ITEM RECEIPT

  GET_ALL_STOCK_ITEM_RECEIPT_REQUEST = "GET_ALL_STOCK_ITEM_RECEIPT_REQUEST",
  GET_ALL_STOCK_ITEM_RECEIPT_SUCCESS = "GET_ALL_STOCK_ITEM_RECEIPT_SUCCESS",
  GET_ALL_STOCK_ITEM_RECEIPT_FAILURE = "GET_ALL_STOCK_ITEM_RECEIPT_FAILURE",
  CREATE_STOCK_ITEM_RECEIPT_REQUEST = "CREATE_STOCK_ITEM_RECEIPT_REQUEST",
  CREATE_STOCK_ITEM_RECEIPT_SUCCESS = "CREATE_STOCK_ITEM_RECEIPT_SUCCESS",
  CREATE_STOCK_ITEM_RECEIPT_FAILURE = "CREATE_STOCK_ITEM_RECEIPT_FAILURE",
  GET_ALL_ITEM_SALE_RECEIPT_REQUEST = "GET_ALL_ITEM_SALE_RECEIPT_REQUEST",
  GET_ALL_ITEM_SALE_RECEIPT_SUCCESS = "GET_ALL_ITEM_SALE_RECEIPT_SUCCESST",
  GET_ALL_ITEM_SALE_RECEIPT_FAILURE = "GET_ALL_ITEM_SALE_RECEIPT_FAILURE",
  GET_ALL_STOCK_ITEM_BY_KITID_REQUEST = "GET_ALL_STOCK_ITEM_BY_KITID_REQUEST",
  GET_ALL_STOCK_ITEM_BY_KITID_SUCCESS = "GET_ALL_STOCK_ITEM_BY_KITID_SUCCESS",
  GET_ALL_STOCK_ITEM_BY_KITID_FAILURE = "GET_ALL_STOCK_ITEM_BY_KITID_FAILURE",
  
  // INVENTORY ITEM SALE REPORT 

  GET_ALL_ITEM_SALE_REPORT_REQUEST = "GET_ALL_ITEM_SALE_REPORT_REQUEST",
  GET_ALL_ITEM_SALE_REPORT_SUCCESS = "GET_ALL_ITEM_SALE_REPORT_SUCCESS",
  GET_ALL_ITEM_SALE_REPORT_FAILURE = "GET_ALL_ITEM_SALE_REPORT_FAILURE",

  // INVENTORY APPROVAL

  GET_ALL_INVENTORY_APPROVAL_REQUEST = "GET_ALL_INVENTORY_APPROVAL_REQUEST",
  GET_ALL_INVENTORY_APPROVAL_SUCCESS = "GET_ALL_INVENTORY_APPROVAL_SUCCESS",
  GET_ALL_INVENTORY_APPROVAL_FAILURE = "GET_ALL_INVENTORY_APPROVAL_FAILURE",
  CREATE_INVENTORY_APPROVAL_REQUEST = "CREATE_INVENTORY_APPROVAL_REQUEST",
  CREATE_INVENTORY_APPROVAL_SUCCESS = "CREATE_INVENTORY_APPROVAL_SUCCESS",
  CREATE_INVENTORY_APPROVAL_FAILURE = "CREATE_INVENTORY_APPROVAL_FAILURE",
  INVENTORY_APPROVAL_ACTION_REQUEST = "INVENTORY_APPROVAL_ACTION_REQUEST",
  INVENTORY_APPROVAL_ACTION_SUCCESS = "INVENTORY_APPROVAL_ACTION_SUCCESS",
  INVENTORY_APPROVAL_ACTION_FAILURE = "INVENTORY_APPROVAL_ACTION_FAILURE",

  //INVENTORY_RECIPT_SEQUENCE

  GET_INVENTORY_RECEIPT_SEQUENCES = 'GET_INVENTORY_RECEIPT_SEQUENCES',
  UPDATE_INVENTORY_RECEIPT_SEQUENCE = 'UPDATE_INVENTORY_RECEIPT_SEQUENCE',
  CREATE_INVENTORY_RECEIPT_SEQUENCE = 'CREATE_INVENTORY_RECEIPT_SEQUENCE',

  //DASHLETS

  GET_DASHLETS_REQUEST = "GET_DASHLETS_REQUEST",
  GET_DASHLETS_FAILURE = "GET_DASHLETS_FAILURE",
  GET_DASHLETS_SUCCESS = "GET_DASHLETS_SUCCESS",
  UPDATE_DASHLETS_REQUEST = "UPDATE_DASHLETS_REQUEST",
  UPDATE_DASHLETS_SUCCESS = "UPDATE_DASHLETS_SUCCESS",
  UPDATE_DASHLETS_FAILURE = "UPDATE_DASHLETS_FAILURE",

  // INITIAL SETUP

  GET_INITIAL_SETUP = "GET_INITIAL_SETUP",

}


export default ActionTypes;