import types from "redux/actions/types";
import { IClassWiseDueReportData, AssignClassDueReport, IAssignClassWiseDueReport } from "redux/types/classWiseDueReport";

const initialState: IAssignClassWiseDueReport = {
    loading: true,
    classWiseDueReport: {} as IClassWiseDueReportData,
    classWiseDueReportArr:[] as any
};

const classWiseDueReportReducer = (state = initialState, action: AssignClassDueReport) => {
    switch (action.type) {
        case types.GET_CLASSWISE_DUE_REPORT:
            return {
                ...state,
                loading: false,
                classWiseDueReport: action.payload,
                classWiseDueReportArr: action.payload
            }
        default : 
            return state;
    }
}

export default classWiseDueReportReducer;
