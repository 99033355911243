import types from "redux/actions/types";
import { IOrgpaymentmethodsState, OrgpaymentmethodsActions } from "../types/orgpaymentmethods";
import { IOrgpaymentmethods } from "redux/types/orgpaymentmethods";

const initialState: IOrgpaymentmethodsState = {
    loading: true,
    orgpaymentmethod: {} as IOrgpaymentmethods,
    orgpaymentmethods: [] as IOrgpaymentmethods[],
};

const orgpaymentMethodsReducer = (
    state = initialState,
    action: OrgpaymentmethodsActions
): IOrgpaymentmethodsState => {
    switch (action.type) {
        case types.ORGPAYMENTMETHOD_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                orgpaymentmethods: [...state.orgpaymentmethods, action.payload] as IOrgpaymentmethods[],
            };
        case types.GET_ORGPAYMENTMETHODS:
            return {
                ...state,
                loading: false,
                orgpaymentmethods: action.payload as IOrgpaymentmethods[],
            };

        case types.DELETE_ORGPAYMENTMETHOD:
            return {
                ...state,
                orgpaymentmethods: state.orgpaymentmethods.filter(
                    (orgpaymentmethod) => orgpaymentmethod._id !== action.payload
                ),
            };

        case types.UPDATE_ORGPAYMENTMETHOD:
            return {
                ...state,
                orgpaymentmethods: state.orgpaymentmethods.map((orgpaymentmethod) =>
                    orgpaymentmethod._id === action.payload.id
                        ? { ...action.payload.orgpaymentmethod }
                        : orgpaymentmethod
                ),
            };
        case types.ORGPAYMENTMETHOD_REGISTER_FAIL:
        case types.ORGPAYMENTMETHOD_AUTH_ERROR:

        default:
            return state;
    }
};

export default orgpaymentMethodsReducer;
